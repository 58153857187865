import React, { useState, useEffect, useCallback } from 'react';
import { Modal } from 'react-bootstrap';
import { faPencilAlt, faXmark } from '@fortawesome/free-solid-svg-icons';
import middleware from '../../Api/Middleware';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../Customer.css"
import InputComponent from './InputComponent';
import ToastSuccess from '../../Toasts/ToastSuccess';
import ToastError from '../../Toasts/ToastError';
import Plus from "./Icons/Add.svg";
import Minus from "./Icons/minus.svg";
import * as RandExp from "randexp";
import ToastInfo from '../../Toasts/ToastInfo';

const AddCompanyModal = ({ show, close }) => {
  const [debounceTimer, setDebounceTimer] = useState(null);

  const [showCompany, setShowCompany] = useState(false);
  const [countryInfo, setCountryInfo] = useState({});
  const [countryIdInfo, setCountryIdInfo] = useState([]);
  const [emails, setEmails] = useState(['']);
  const [phoneNumbers, setPhoneNumbers] = useState([{country_phone_prefix: 31}]);
  const [countryId, setCountryId] = useState();
  const [formData, setFormData] = useState({
    newsPaper: false,
    birthday: false,
  });
  const [address, setAddress] = useState({});
  const [btwPlaceholder, setBtwPlaceholder] = useState('');
  const vatRegexPatterns = {
    AT: /^ATU\d{8}$/,                        // Austria
    BE: /^BE0\d{9}$/,                        // Belgium
    BG: /^BG\d{9,10}$/,                      // Bulgaria
    CY: /^CY\d{8}[A-Z]$/,                    // Cyprus
    CZ: /^CZ\d{8,10}$/,                      // Czech Republic
    DE: /^DE\d{9}$/,                         // Germany
    DK: /^DK\d{8}$/,                         // Denmark
    EE: /^EE\d{9}$/,                         // Estonia
    EL: /^EL\d{9}$/,                         // Greece
    ES: /^ES[A-Z0-9]\d{7}[A-Z0-9]$/,         // Spain
    FI: /^FI\d{8}$/,                         // Finland
    FR: /^FR[A-Z0-9]{2}\d{9}$/,              // France
    GB: /^GB(\d{9}|\d{12}|GD\d{3}|HA\d{3})$/, // United Kingdom
    HR: /^HR\d{11}$/,                        // Croatia
    HU: /^HU\d{8}$/,                         // Hungary
    IE: /^IE\d[A-Z0-9+*]\d{5}[A-Z]$/,        // Ireland
    IT: /^IT\d{11}$/,                        // Italy
    LT: /^LT(\d{9}|\d{12})$/,                // Lithuania
    LU: /^LU\d{8}$/,                         // Luxembourg
    LV: /^LV\d{11}$/,                        // Latvia
    MT: /^MT\d{8}$/,                         // Malta
    NL: /^NL\d{9}B\d{2}$/,                   // Netherlands
    PL: /^PL\d{10}$/,                        // Poland
    PT: /^PT\d{9}$/,                         // Portugal
    RO: /^RO\d{2,10}$/,                      // Romania
    SE: /^SE\d{12}$/,                        // Sweden
    SI: /^SI\d{8}$/,                         // Slovenia
    SK: /^SK\d{10}$/                         // Slovakia
  };

  const normalizeLabel = (label) => {
    return label.trim().replace(/[\s\-]+/g, "");
  };


  const getValueInput = useCallback((value, label) => {
    const normalizedLabel = normalizeLabel(label);
    setFormData((prev) => ({ ...prev, [normalizedLabel]: value }));
  }, []);


  const fetchCountry = async () => {
    const [fetchCountries] = await Promise.all([
      middleware.get(`/countryCodes`),
    ]);
    setCountryInfo(fetchCountries.data);
  };
  useEffect(() => {
    if (countryIdInfo && countryIdInfo.country_code_2) {
      let regex = vatRegexPatterns[countryIdInfo.country_code_2];
      const randexp = new RandExp(regex);
      setBtwPlaceholder(randexp.gen());
    }
  }, [countryIdInfo])
  const setPhoneCode = (index, value) => {
    const newPhoneNumbers = [...phoneNumbers];
    newPhoneNumbers[index].country_phone_prefix = value;
    setPhoneNumbers(newPhoneNumbers);
  }
  useEffect(() => {
    if (countryIdInfo.country_code_2 == 'NL') {
      const { Postalcode, HouseNumber } = formData; // Destructure the relevant fields from formData
      const postalCodeRegex = /^\s*[0-9]{4}\s*[A-Za-z]{2}\s*$/;

      // Clear the previous debounce timer if it exists
      if (debounceTimer) {
        clearTimeout(debounceTimer);
      }

      if (Postalcode) {

        // Set a new debounce timer
        const newDebounceTimer = setTimeout(() => {
          // Check if Postalcode matches the regex
          if (postalCodeRegex.test(Postalcode)) {
            const fetchAdress = async () => {
              if (formData.HouseNumber) {
                try {
                  const response = await fetch(`https://gratispostcodeapi.nl/api/v1/lookup/${Postalcode}/${HouseNumber}`);
                  const data = await response.json();

                  // If the API returns valid postal_code data, populate formData
                  if (data && data.postal_code) {
                    setFormData(prevData => ({
                      ...prevData,
                      StreetName: data.street,
                      City: data.city,
                      Addition: data.available_suffixes.length > 0 ? data.available_suffixes[0] : '',
                    }))

                  } else {
                    ToastError('Geen adres gevonden voor deze postcode.');
                  }
                } catch (error) {
                  ToastError('Er is een fout opgetreden bij het ophalen de adress gegevens');
                }
              } else {
                ToastInfo("Voer het huisnummer in om automatisch de straat en stad in te vullen")
              }
            };

            fetchAdress(); // Call the function to fetch the house number
          } else {
            ToastError('Ongeldig postcodeformaat. Zorg ervoor dat je 4 cijfers en 2 letters invoert.');
          }
        }, 1000); // 2 seconds debounce

        setDebounceTimer(newDebounceTimer); // Set the new timer
      }

      return () => clearTimeout(debounceTimer); // Cleanup timer on unmount or when dependencies change
    }
  }, [formData.Postalcode, formData.HouseNumber]);
  useEffect(() => {
    try {
      fetchCountry();
    } catch (error) {
      console.warn(error)
    }
  }, [showCompany]);
  const removeEmailField = async (id) => {
    const confirmDelete = window.confirm("Weet je zeker dat je deze e-mail wilt verwijderen?");
    if (confirmDelete) {
      // Update state after successful deletion

      setEmails(emails.filter((e, index) => index != id));
    }
  };
  const removePhoneNumberField = async (id) => {
    const confirmDelete = window.confirm("Weet je zeker dat je dit telefoonnummer wilt verwijderen?");
    if (confirmDelete) {
      // Update state after successful deletion
      setPhoneNumbers(phoneNumbers.filter((e, index) => index != id));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault(); // Voorkom de standaardactie van het formulier

    if (countryIdInfo.length <= 0) {
      ToastError("Kies een land.");
      return;
    }

    if (formData.CocNumber && (formData.CocNumber.length > 12 || formData.CocNumber.length < 8)) {
      ToastError("Mag niet meer dan 12 characters en niet minder dan 8 characters bij KVK nummer hebben");
      return
    }

    const selectedCountryCode = countryIdInfo.country_code_2;
    const vatRegex = vatRegexPatterns[selectedCountryCode];

    if (formData.VatNumber && vatRegex && !vatRegex.test(formData.VatNumber)) {
      ToastError(`Ongeldig BTW-nummer formaat voor ${countryIdInfo.country}.`);
      return;
    }

    let businessCreated = false; // Houd bij of het bedrijf succesvol is aangemaakt
    let businessId = null; // Sla het ID van het aangemaakte bedrijf op voor mogelijke compensatieactie
    let newphones = phoneNumbers.map(number => ({
      ...number,
      fullnumber: '+' + number.country_phone_prefix + "-" + number.phone_number
    }));
    try {

      await middleware.post("/addBusinesses", {
        company_name: formData.CompanyName,
        cocnumber: formData.CocNumber,
        vatnumber: formData.VatNumber,
        first_name: formData.FirstName,
        last_name: formData.LastName,
        country_code_id: countryIdInfo.country_code_id || null,
        emails: emails,
        phone_numbers: newphones,
        city: formData.City,
        streetname: formData.StreetName,
        birthday: formData.BirthDate,
        housenumber: formData.HouseNumber,
        addition: formData.Addition === "" ? null : formData.Addition,
        postalcode: formData.Postalcode,
        newsletter: formData.newsPaper == false ? 0 : 1,
        birthday_promotion: formData.birthday_promotion == false ? 0 : 1,
        address_kind: 2,
      });

      ToastSuccess("Bedrijf is aangemaakt");
      window.location.reload();
    } catch (err) {
      if (businessCreated) {
        // Als het bedrijf is aangemaakt maar het toevoegen van het adres faalt, voer dan een compensatieactie uit
        await middleware.delete(`/businesses/${businessId}`);
        ToastError("Aanmaken van bedrijf mislukt, bedrijfsrecord verwijderd.");
      } else {
        ToastError("Bedrijfsnaam, KVK nummer, BTW nummer, Voornaam, Achternaam, Email(minimaal 1), Postcode, Huisnummer, Straat en Stad zijn verplichte velden", 10000);

      }
      console.error(err);
    }
  };

  useEffect(() => {
    if (show) {
      setShowCompany(true);
    }
  }, [show]);

  const closeCompanyModal = () => {
    setShowCompany(false);
    close(true);
  };

  const addEmailField = () => {
    if (emails.length < 3) {
      setEmails([...emails, '']);
    }
  };

  const handleEmailChange = (index, value) => {
    const newEmails = [...emails];
    newEmails[index] = value;
    setEmails(newEmails);
  };

  const addPhoneNumberField = () => {
    if (phoneNumbers.length < 3) {
      setPhoneNumbers([...phoneNumbers, '']);
    }
  };

  const handlePhoneNumberChange = (index, value) => {
    const newPhoneNumbers = [...phoneNumbers];
    newPhoneNumbers[index].phone_number = value;
    setPhoneNumbers(newPhoneNumbers);
  };

  return (
    <Modal
      id="modalBlueprint"
      show={showCompany}
      onHide={() => closeCompanyModal()}

      size="xl"
      centered
      backdrop="static"
    >
      <form onSubmit={handleSubmit}>
        <Modal.Header className="modalTopCustomers justify-content-between">
          <div className='d-flex flex-row flex-wrap'>
            <h3 className='ms-3 text-nowrap '>Bedrijf aanmaken</h3>
            <select
              className="form-select inputFieldAddCustomer dropdownsNewCustomers ms-3"
              onChange={(e) => setCountryIdInfo(JSON.parse(e.target.value))}
            >
              <option hidden>Kies een land</option>
              {countryInfo?.length > 0 && countryInfo.map((country, index) => (
                <option key={index} value={JSON.stringify(country)}>
                  {country.country}
                </option>
              ))}
            </select>
          </div>

          <button variant="transparent" className=' btn' onClick={() => closeCompanyModal()}>
            <FontAwesomeIcon icon={faXmark} className="closeModal text-dark" />
          </button>
        </Modal.Header>
        <Modal.Body className="modalCenterCustomers pt-0">
          <div className="contendModal w-100">
            <div>
              <label className=' fw-bold mb-2 secondaryTitles pt-3'>Bedrijfsgegevens</label>
              <div className='col-12 d-flex flex-row align-content-end mb-3'>
                <InputComponent
                  type={"text"}
                  onValueChange={getValueInput}
                  alignmentInputs={"justify-content-start"}
                  parentCol={12}
                  labelName={"Bedrijfsnaam"}
                  labelValue={"CompanyName"}
                  required={true}
                  classnamesInput={"col-12 SearchIcon px-3"}
                />
              </div>
              <div className="col-12 d-flex flex-row align-content-end mb-4">
                <InputComponent
                  type={"number"}
                  onValueChange={getValueInput}
                  alignmentInputs={"justify-content-start"}
                  parentCol={6}
                  labelName={"KVK nummer"}
                  labelValue={"CocNumber"}
                  placeholder={'Bijv. 123456789012'}
                  required={true}
                  classnamesInput={"col-12 widthInputComponents  px-3"}
                />
                <InputComponent
                  type={"text"}
                  alignmentInputs={"justify-content-end"}
                  parentCol={6}
                  onValueChange={getValueInput}
                  labelName={"BTW nummer"}
                  labelValue={"VatNumber"}
                  required={true}
                  disabled={btwPlaceholder == ''}
                  placeholder={btwPlaceholder ? "Bijv. " + btwPlaceholder : "Selecteer een land"}
                  classnamesInput={"col-12 widthInputComponents px-3"}
                />
              </div>
            </div>
            <div>
              <label className=' fw-bold mb-2 secondaryTitles'>Contactpersoon</label>
              <div className="col-12 d-flex flex-row align-content-end mb-3">
                <InputComponent
                  type={"text"}
                  onValueChange={getValueInput}
                  alignmentInputs={"justify-content-start"}
                  parentCol={6}
                  labelName={"Voornaam"}
                  labelValue={"FirstName"}
                  required={true}
                  classnamesInput={"col-12 widthInputComponents px-3"}
                />
                <InputComponent
                  type={"text"}
                  alignmentInputs={"justify-content-end"}
                  parentCol={6}
                  onValueChange={getValueInput}
                  labelName={"Achternaam"}
                  labelValue={"LastName"}
                  required={true}
                  classnamesInput={"col-12 widthInputComponents px-3"}
                />
              </div>
              <div className="col-12 d-flex flex-row align-content-end my-3">
                <div className="d-flex flex-column col-6 pe-3">
                  <div className="d-flex justify-content-between">
                    <label className='fw-semibold mb-2'>Emails</label>
                    <img
                      className='pe-3 addIconSizeModals'
                      src={Plus}
                      onClick={addEmailField}
                      alt="Add"
                      style={{ cursor: 'pointer' }}
                    />
                  </div>
                  {emails.map((email, index) => (<div className="d-flex">
                    <input
                      key={index}
                      className={`inputFieldAddCustomer w-100 mb-2 px-3`}
                      type="email"
                      required={true}
                      value={email}
                      onChange={(e) => handleEmailChange(index, e.target.value)}
                    />
                    <img
                      className='ms-2 removeIconSizeModals'
                      src={Minus}
                      onClick={() => removeEmailField(index)}
                      alt="Remove"
                      style={{ cursor: 'pointer', width: "3%" }}
                    />
                  </div>
                  ))}
                </div>
                <div className="d-flex flex-column col-6 ps-3">
                  <div className="d-flex justify-content-between">
                    <label className='fw-semibold mb-2'>Telefoonnummers</label>
                    <img
                      className='pe-3 addIconSizeModals'
                      src={Plus}
                      onClick={addPhoneNumberField}
                      alt="Add"
                      style={{ cursor: 'pointer' }}
                    />
                  </div>
                  {phoneNumbers.map((phoneNumber, index) => (
                    <div key={index} className="d-flex">
                      <select name="countryCode" onChange={(e) => setPhoneCode(index, e.target.value)} value={phoneNumber.country_phone_prefix} className="standardInput standardDropdownArrow w-50 me-2">
                        <option hidden>Selecteer een landcode</option>
                        <option value="31">Nederland (+31)</option>
                        <option value="32">België (+32)</option>
                        <option value="49">Duitsland (+49)</option>
                        <optgroup label="Andere landen">
                          <option value="213">Algerije (+213)</option>
                          <option value="376">Andorra (+376)</option>
                          <option value="244">Angola (+244)</option>
                          <option value="1264">Anguilla (+1264)</option>
                          <option value="1268">Antigua & Barbuda (+1268)</option>
                          <option value="54">Argentinië (+54)</option>
                          <option value="374">Armenië (+374)</option>
                          <option value="297">Aruba (+297)</option>
                          <option value="61">Australië (+61)</option>
                          <option value="43">Oostenrijk (+43)</option>
                          <option value="994">Azerbeidzjan (+994)</option>
                          <option value="1242">Bahama's (+1242)</option>
                          <option value="973">Bahrein (+973)</option>
                          <option value="880">Bangladesh (+880)</option>
                          <option value="1246">Barbados (+1246)</option>
                          <option value="375">Wit-Rusland (+375)</option>
                          <option value="501">Belize (+501)</option>
                          <option value="229">Benin (+229)</option>
                          <option value="1441">Bermuda (+1441)</option>
                          <option value="975">Bhutan (+975)</option>
                          <option value="591">Bolivia (+591)</option>
                          <option value="387">Bosnië en Herzegovina (+387)</option>
                          <option value="267">Botswana (+267)</option>
                          <option value="55">Brazilië (+55)</option>
                          <option value="673">Brunei (+673)</option>
                          <option value="359">Bulgarije (+359)</option>
                          <option value="226">Burkina Faso (+226)</option>
                          <option value="257">Burundi (+257)</option>
                          <option value="855">Cambodja (+855)</option>
                          <option value="237">Kameroen (+237)</option>
                          <option value="1">Canada (+1)</option>
                          <option value="238">Kaapverdië (+238)</option>
                          <option value="1345">Kaaimaneilanden (+1345)</option>
                          <option value="236">Centraal-Afrikaanse Republiek (+236)</option>
                          <option value="56">Chili (+56)</option>
                          <option value="86">China (+86)</option>
                          <option value="57">Colombia (+57)</option>
                          <option value="269">Comoren (+269)</option>
                          <option value="242">Congo (+242)</option>
                          <option value="682">Cookeilanden (+682)</option>
                          <option value="506">Costa Rica (+506)</option>
                          <option value="385">Kroatië (+385)</option>
                          <option value="53">Cuba (+53)</option>
                          <option value="90392">Noord-Cyprus (+90392)</option>
                          <option value="357">Zuid-Cyprus (+357)</option>
                          <option value="42">Tsjechië (+42)</option>
                          <option value="45">Denemarken (+45)</option>
                          <option value="253">Djibouti (+253)</option>
                          <option value="1809">Dominica (+1809)</option>
                          <option value="1809">Dominicaanse Republiek (+1809)</option>
                          <option value="593">Ecuador (+593)</option>
                          <option value="20">Egypte (+20)</option>
                          <option value="503">El Salvador (+503)</option>
                          <option value="240">Equatoriaal-Guinea (+240)</option>
                          <option value="291">Eritrea (+291)</option>
                          <option value="372">Estland (+372)</option>
                          <option value="251">Ethiopië (+251)</option>
                          <option value="500">Falklandeilanden (+500)</option>
                          <option value="298">Faeröer (+298)</option>
                          <option value="679">Fiji (+679)</option>
                          <option value="358">Finland (+358)</option>
                          <option value="33">Frankrijk (+33)</option>
                          <option value="594">Frans-Guyana (+594)</option>
                          <option value="689">Frans-Polynesië (+689)</option>
                          <option value="241">Gabon (+241)</option>
                          <option value="220">Gambia (+220)</option>
                          <option value="7880">Georgië (+7880)</option>
                          <option value="233">Ghana (+233)</option>
                          <option value="350">Gibraltar (+350)</option>
                          <option value="30">Griekenland (+30)</option>
                          <option value="299">Groenland (+299)</option>
                          <option value="1473">Grenada (+1473)</option>
                          <option value="590">Guadeloupe (+590)</option>
                          <option value="671">Guam (+671)</option>
                          <option value="502">Guatemala (+502)</option>
                          <option value="224">Guinee (+224)</option>
                          <option value="245">Guinee-Bissau (+245)</option>
                          <option value="592">Guyana (+592)</option>
                          <option value="509">Haïti (+509)</option>
                          <option value="504">Honduras (+504)</option>
                          <option value="852">Hongkong (+852)</option>
                          <option value="36">Hongarije (+36)</option>
                          <option value="354">IJsland (+354)</option>
                          <option value="91">India (+91)</option>
                          <option value="62">Indonesië (+62)</option>
                          <option value="98">Iran (+98)</option>
                          <option value="964">Irak (+964)</option>
                          <option value="353">Ierland (+353)</option>
                          <option value="972">Israël (+972)</option>
                          <option value="39">Italië (+39)</option>
                          <option value="1876">Jamaica (+1876)</option>
                          <option value="81">Japan (+81)</option>
                          <option value="962">Jordanië (+962)</option>
                          <option value="7">Kazachstan (+7)</option>
                          <option value="254">Kenia (+254)</option>
                          <option value="686">Kiribati (+686)</option>
                          <option value="850">Noord-Korea (+850)</option>
                          <option value="82">Zuid-Korea (+82)</option>
                          <option value="965">Koeweit (+965)</option>
                          <option value="996">Kirgizië (+996)</option>
                          <option value="856">Laos (+856)</option>
                          <option value="371">Letland (+371)</option>
                          <option value="961">Libanon (+961)</option>
                          <option value="266">Lesotho (+266)</option>
                          <option value="231">Liberia (+231)</option>
                          <option value="218">Libië (+218)</option>
                          <option value="417">Liechtenstein (+417)</option>
                          <option value="370">Litouwen (+370)</option>
                          <option value="352">Luxemburg (+352)</option>
                          <option value="853">Macau (+853)</option>
                          <option value="389">Macedonië (+389)</option>
                          <option value="261">Madagaskar (+261)</option>
                          <option value="265">Malawi (+265)</option>
                          <option value="60">Maleisië (+60)</option>
                          <option value="960">Maldiven (+960)</option>
                          <option value="223">Mali (+223)</option>
                          <option value="356">Malta (+356)</option>
                          <option value="692">Marshalleilanden (+692)</option>
                          <option value="596">Martinique (+596)</option>
                          <option value="222">Mauritanië (+222)</option>
                          <option value="269">Mayotte (+269)</option>
                          <option value="52">Mexico (+52)</option>
                          <option value="691">Micronesië (+691)</option>
                          <option value="373">Moldavië (+373)</option>
                          <option value="377">Monaco (+377)</option>
                          <option value="976">Mongolië (+976)</option>
                          <option value="1664">Montserrat (+1664)</option>
                          <option value="212">Marokko (+212)</option>
                          <option value="258">Mozambique (+258)</option>
                          <option value="95">Myanmar (+95)</option>
                          <option value="264">Namibië (+264)</option>
                          <option value="674">Nauru (+674)</option>
                          <option value="977">Nepal (+977)</option>
                          <option value="687">Nieuw-Caledonië (+687)</option>
                          <option value="64">Nieuw-Zeeland (+64)</option>
                          <option value="505">Nicaragua (+505)</option>
                          <option value="227">Niger (+227)</option>
                          <option value="234">Nigeria (+234)</option>
                          <option value="683">Niue (+683)</option>
                          <option value="672">Norfolk (+672)</option>
                          <option value="670">Noordelijke Marianen (+670)</option>
                          <option value="47">Noorwegen (+47)</option>
                          <option value="968">Oman (+968)</option>
                          <option value="680">Palau (+680)</option>
                          <option value="507">Panama (+507)</option>
                          <option value="675">Papoea-Nieuw-Guinea (+675)</option>
                          <option value="595">Paraguay (+595)</option>
                          <option value="51">Peru (+51)</option>
                          <option value="63">Filipijnen (+63)</option>
                          <option value="48">Polen (+48)</option>
                          <option value="351">Portugal (+351)</option>
                          <option value="1787">Puerto Rico (+1787)</option>
                          <option value="974">Qatar (+974)</option>
                          <option value="262">Réunion (+262)</option>
                          <option value="40">Roemenië (+40)</option>
                          <option value="7">Rusland (+7)</option>
                          <option value="250">Rwanda (+250)</option>
                          <option value="378">San Marino (+378)</option>
                          <option value="239">Sao Tomé en Principe (+239)</option>
                          <option value="966">Saoedi-Arabië (+966)</option>
                          <option value="221">Senegal (+221)</option>
                          <option value="381">Servië (+381)</option>
                          <option value="248">Seychellen (+248)</option>
                          <option value="232">Sierra Leone (+232)</option>
                          <option value="65">Singapore (+65)</option>
                          <option value="421">Slowakije (+421)</option>
                          <option value="386">Slovenië (+386)</option>
                          <option value="677">Salomonseilanden (+677)</option>
                          <option value="252">Somalië (+252)</option>
                          <option value="27">Zuid-Afrika (+27)</option>
                          <option value="34">Spanje (+34)</option>
                          <option value="94">Sri Lanka (+94)</option>
                          <option value="290">Sint-Helena (+290)</option>
                          <option value="1869">Saint Kitts en Nevis (+1869)</option>
                          <option value="1758">Saint Lucia (+1758)</option>
                          <option value="249">Soedan (+249)</option>
                          <option value="597">Suriname (+597)</option>
                          <option value="268">Swaziland (+268)</option>
                          <option value="46">Zweden (+46)</option>
                          <option value="41">Zwitserland (+41)</option>
                          <option value="963">Syrië (+963)</option>
                          <option value="886">Taiwan (+886)</option>
                          <option value="7">Tadzjikistan (+7)</option>
                          <option value="66">Thailand (+66)</option>
                          <option value="228">Togo (+228)</option>
                          <option value="676">Tonga (+676)</option>
                          <option value="1868">Trinidad en Tobago (+1868)</option>
                          <option value="216">Tunesië (+216)</option>
                          <option value="90">Turkije (+90)</option>
                          <option value="7">Turkmenistan (+7)</option>
                          <option value="993">Turkmenistan (+993)</option>
                          <option value="1649">Turks- en Caicoseilanden (+1649)</option>
                          <option value="688">Tuvalu (+688)</option>
                          <option value="256">Oeganda (+256)</option>
                          <option value="44">VK (+44)</option>
                          <option value="380">Oekraïne (+380)</option>
                          <option value="971">Verenigde Arabische Emiraten (+971)</option>
                          <option value="598">Uruguay (+598)</option>
                          <option value="1">VS (+1)</option>
                          <option value="7">Oezbekistan (+7)</option>
                          <option value="678">Vanuatu (+678)</option>
                          <option value="379">Vaticaanstad (+379)</option>
                          <option value="58">Venezuela (+58)</option>
                          <option value="84">Vietnam (+84)</option>
                          <option value="84">Britse Maagdeneilanden (+1284)</option>
                          <option value="84">Amerikaanse Maagdeneilanden (+1340)</option>
                          <option value="681">Wallis en Futuna (+681)</option>
                          <option value="969">Jemen (Noord) (+969)</option>
                          <option value="967">Jemen (Zuid) (+967)</option>
                          <option value="260">Zambia (+260)</option>
                          <option value="263">Zimbabwe (+263)</option>
                        </optgroup>
                      </select>
                      <input
                        key={index}
                        className={`inputFieldAddCustomer mb-2 px-3`}
                        type="text"
                        required={true}
                        value={phoneNumber.phone_number}
                        onChange={(e) => handlePhoneNumberChange(index, e.target.value)}
                      />
                      <img
                        className='ms-2 removeIconSizeModals'
                        src={Minus}
                        onClick={() => removePhoneNumberField(index)}
                        alt="Remove"
                        style={{ cursor: 'pointer', width: "3%" }}
                      />
                    </div>
                  ))}
                </div>
              </div>
              <div className="col-12 d-flex flex-row align-content-end my-3">
                <InputComponent
                  type={"date"}
                  onValueChange={getValueInput}
                  parentCol={6}
                  labelName={"Geboortedatum"}
                  labelValue={"BirthDate"}
                  classnamesInput={"col-12 widthInputComponents diffDateIcon px-3"}
                />
                <div className="col-6 d-flex justify-content-start">
                  <div className="col-4 d-flex flex-column">
                    <label className="mb-2 ms-4 fw-medium">Nieuwsbrief</label>
                    <label className="switchNewCustomer ms-4">
                      <input type="checkbox" />
                      <span className="sliderNewCustomer round"></span>
                    </label>
                  </div>
                  <div className=" col-4  d-flex flex-column">
                    <label className="mb-2 ms-4 fw-medium">Verjaardagactie</label>
                    <label className="switchNewCustomer ms-4">
                      <input type="checkbox" />
                      <span className="sliderNewCustomer round"></span>
                    </label>
                  </div>
                </div>
              </div>

              <div className="col-12 d-flex flex-row align-content-end my-3">
                <InputComponent
                  type={"text"}
                  onValueChange={getValueInput}
                  parentCol={6}
                  labelName={"Postcode"}
                  labelValue={"Postalcode"}
                  disabled={!countryIdInfo.country_code_2}
                  required={true}
                  classnamesInput={"col-12 widthInputComponents px-3"}
                />
                <div className="col-6 d-flex justify-content-end">
                  <div className="col-12 d-flex widthInputComponents flex-row justify-content-end">
                    <InputComponent
                      alignmentInputs={"justify-content-start"}
                      onValueChange={getValueInput}
                      type={"number"}
                      parentCol={6}
                      labelName={"Huisnummer"}
                      labelValue={"HouseNumber"}
                      disabled={!countryIdInfo.country_code_2}
                      required={true}
                      classnamesInput={"col-12 widthInputComponents px-3"}
                    />
                    <InputComponent
                      alignmentInputs={"justify-content-end"}
                      onValueChange={getValueInput}
                      type={"text"}
                      parentCol={6}
                      valueType={1}
                      labelName={"Toevoeging"}
                      labelValue={"Addition"}
                      disabled={!countryIdInfo.country_code_2}
                      defaultValue={formData.Addition}
                      classnamesInput={"col-12 widthInputComponents px-3"}
                    />
                  </div>
                </div>
              </div>
              <div className="col-12 d-flex flex-row align-content-end my-3 ">
                <InputComponent
                  type={"text"}
                  onValueChange={getValueInput}
                  valueType={1}
                  alignmentInputs={"justify-content-start"}
                  parentCol={6}
                  labelName={
                    <>
                      Straat
                      {countryIdInfo.country_code_2 === "NL" && formData.StreetName && (
                        <FontAwesomeIcon
                          style={{ marginLeft: "5px", cursor: "pointer" }}
                          onClick={() => setFormData(prev => ({ ...prev, StreetNameEditable: !prev.StreetNameEditable }))}
                          icon={faPencilAlt}
                        />
                      )}
                    </>
                  }                  labelValue={"StreetName"}
                  disabled={!countryIdInfo.country_code_2 || (!formData.StreetNameEditable && countryIdInfo.country_code_2 === "NL")}
                  defaultValue={formData.StreetName}
                  required={true}
                  classnamesInput={"col-12 widthInputComponents px-3"}
                />
                <InputComponent
                  type={"text"}
                  valueType={1}
                  onValueChange={getValueInput}
                  alignmentInputs={"justify-content-end"}
                  parentCol={6}
                  labelName={
                    <>
                      Stad
                      {countryIdInfo.country_code_2 === "NL" && formData.City && (
                        <FontAwesomeIcon
                          style={{ marginLeft: "5px", cursor: "pointer" }}
                          onClick={() => setFormData(prev => ({ ...prev, CityEditable: !prev.CityEditable }))}
                          icon={faPencilAlt}

                        />
                      )}
                    </>
                  }                  labelValue={"City"}
                  disabled={!countryIdInfo.country_code_2 || (!formData.CityEditable && countryIdInfo.country_code_2 === "NL")}
                  defaultValue={formData.City}
                  required={true}
                  classnamesInput={"col-12 widthInputComponents px-3"}
                />
              </div>
            </div>

          </div>
        </Modal.Body>
        <Modal.Footer className="modalBottomCustomers">
          <button
            type="submit" // It's important to specify type="button" to prevent default button behavior
            className="btn addButtonCustomer px-5 rounded-pill"
          >
            Bedrijf aanmaken
          </button>
        </Modal.Footer>
      </form>
    </Modal>
  );
}

export default AddCompanyModal;
