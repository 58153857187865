import React, { useEffect, useMemo, useRef, useState } from 'react';
import "../Main/Main.css";
import PageTemplate from '../Templates/PageTemplate';
import Accordion from '../CategoryNew/ComponentsCategory/AccordionCategory';
import { useNavigate } from 'react-router';
import middleware from '../Api/Middleware';
import { useTable } from 'react-table';
import TablePreset from '../Table/TablePreset';
import SortableHeader from '../Table/TableComponents/SortableHeader';
import CheckboxHeader from '../Table/TableComponents/CheckboxHeader';
import CheckboxCell from '../Table/TableComponents/CheckboxCell';
import "./Category.css";
import ToastError from '../Toasts/ToastError';
import Filters from '../Filters/Filters';

const Categories = () => {
  const navigate = useNavigate();
  const defaultFilters = [
    {index: 0, order: 1, name: "Status categorie", field: 'status', type: "radio", options: [{name:'Actief', value:1}, {name:'Inactief', value:0}]}, 
  ];
  const [tempfilters, setTempFilters] = useState(defaultFilters)
  const [filters, setFilters] = useState(defaultFilters)
  const [categories, setCategories] = useState([]);
  const [order, setOrder] = useState({ orderBy: 'sorting_order', direction: 'asc' }); // useState for ordering the columns in the table
  const [selectedIds, setSelectedIds] = useState([]);
  const [activePanels, setActivePanels] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchengine, setSearchengine] = useState("");
  const [searchBarInput, setSearchBarInput] = useState("");
  const [pagination, setPagination] = useState({
    "page_size": 10,
    "page": 1,
    "max_items": 0,
    "begin_of_page": 0,
    "end_of_page": 0,
  });
  const [timerId, setTimerId] = useState(null);

  const [showFilterModal, setShowFilterModal] = useState(false);

  const CloseFilterModal = () => {
    setShowFilterModal(false);
  }

  const fetchAll = async () => {
    const [categoriesRes] = await Promise.all([
      middleware.get(`/products/categoriesRecursive?page=${pagination.page}&page_size=${pagination.page_size}&search=${searchengine}&sort_order=${order.direction != '' ? order.direction : 'asc'}&sort_by=${order.direction ? order.orderBy : 'sorting_order'}&` + (filters.filter((e) => e.value != null).map((e) => e.value ? (e.field + '=' + e.value) : '')).join('&'))
    ]);
    if (order.direction == '') {
      setOrder({ orderBy: 'sorting_order', direction: 'asc' });
    }
    console.log(categoriesRes)
    setPagination({ ...pagination, "max_items": categoriesRes.data.pagination.total, "begin_of_page": categoriesRes.data.pagination.from, "end_of_page": categoriesRes.data.pagination.to })
    setCategories(categoriesRes.data.data);
    setLoading(false);
  }

  useEffect(() => {
    fetchAll();
  }, [order, searchengine, pagination.page, pagination.page_size, filters])

  const searchDelay = async (value) => {
    // const value = await e.target.value
    setSearchBarInput(value)
    if (timerId) {
      clearTimeout(timerId);
    }
    const newTimerId = setTimeout(() => {
      setSearchengine(value);
    }, 500);

    setTimerId(newTimerId);
  };

  const handleSelection = (Categoryid, isSelected) => {
    setSelectedIds(previous =>
      isSelected ? [...previous, Categoryid] : previous.filter(id => Categoryid !== id)
    );
  };
  const activatePanel = (Categoryid, isSelected) => {
    setActivePanels(previous =>
      isSelected ? [...previous, Categoryid] : previous.filter(id => Categoryid !== id)
    );
  }

  const openCategory = (id) => {
    navigate(`/warehouse/instellingen/categorieën/bewerken/${id}`)
  }
  // Recursive Accordion Component for categories
  const CategoryAccordion = ({ category, categories, row, layer }) => {
    const subcategories = category.children;
    return (subcategories.length ?
      <Accordion
        openCategory={openCategory}
        title={category.title}
        selectedIds={selectedIds}
        handleSelection={handleSelection}
        setActive={activatePanel}
        active={activePanels}
        layer={layer}
        statusCategory={category.status == "1" ? "Gepubliceerd" : "Niet Gepubliceerd"}
        data={row}
        content={
          subcategories.length ?
            <div>
              {subcategories.map(subcategory => (
                <CategoryAccordion key={subcategory.category_id} layer={layer + 1} row={{ original: subcategory }} category={subcategory} categories={categories} />
              ))}
            </div> : category.description
        }
      /> :
      <Accordion
      layer={layer}
      openCategory={openCategory}
      title={category.title}
      selectedIds={selectedIds}
      handleSelection={handleSelection}
      setActive={activatePanel}
      active={activePanels}
      statusCategory={category.status == "1" ? "Gepubliceerd" : "Niet Gepubliceerd"}
      data={row}
    />
    );
  };
  const columns = useMemo(
    () => [
      {
        Header: () => (
          <div className='d-flex justify-content-between'>
            <div className='pe-5 catHeaderCheckbox'>
              <CheckboxHeader currentItem={categories} selectedIds={selectedIds} setSelectedIds={setSelectedIds} pk={'category_id'}>
              </CheckboxHeader>
            </div>
            <div className='d-flex w-100 justify-content-between'>

              <SortableHeader currentItem={order} setItem={setOrder} field={'title'} pos={'start'}>
                Categorie
              </SortableHeader>
              <SortableHeader currentItem={order} setItem={setOrder} field={'status'} pos={'end'}>
                Status
              </SortableHeader>
            </div>
          </div>
        ),
        accessor: 'category',
        Cell: ({ row }) => {
          const category = row.original;
          return (
            <CategoryAccordion layer={0} row={row} category={category} categories={categories} />
          );
        }
      },
    ],
    [categories, selectedIds, activePanels, pagination.page_size, pagination.page] // Pass `categories` as dependency so it re-renders if categories change
  );
  const DeactivateSelected = async () => {
    if(!selectedIds.length) {
      ToastError("Selecteer een categorie.")
      return;
    }
    let bulk_data = [];
    selectedIds.forEach((e) => {
      bulk_data.push({category_id: e, status: 0});
    })

    try {
      await middleware.put('products/category', {bulk_data: bulk_data})
      fetchAll();

    } catch(e) {
      ToastError("Er ging iets fout met het deactiveren.")
    }
  }
  const ActivateSelected = async () => {
    if(!selectedIds.length) {
      ToastError("Selecteer een categorie.")
      return;
    }
    let bulk_data = [];
    selectedIds.forEach((e) => {
      bulk_data.push({category_id: e, status: 1});
    })

    try {
      await middleware.put('products/category', {bulk_data: bulk_data})
      fetchAll();
    } catch(e) {
      ToastError("Er ging iets fout met het activeren.")
    }
  }
  const actions = (action) => {
    if(action == "deactivate") {
      DeactivateSelected();
    }
    if(action == "activate") {
      ActivateSelected();
    }
  }

  const tableInstance = useTable({ columns, data: categories ?? [] });

  return (
    
    <PageTemplate pageIsLoading={loading} navbarTitle={"Categorieën Overzicht"} backValue={"/warehouse/instellingen"}>
      <div className='w-100'>
        <TablePreset data={[{ 'tableInstance': tableInstance,'pagination': pagination, 'primary_key': 'category_id' }]}
          handleSelection={() => { }}
          setPagination={setPagination}
          primary_key={'product_id'}
          searchBar={[{ shown: true, value: searchBarInput, function: (e) => searchDelay(e) }]}
          actionOptions={[
            { title: "Categorieën activeren", function: () => actions("activate") },
            { title: "Categorieën deactiveren", function: () => actions("deactivate") }

          ]}
          leftButtons={[{ title: "Filteren", function: () => setShowFilterModal(true) }]}
          rightButtons={[
            { title: "Nieuwe categorie", function: () => navigate('/warehouse/instellingen/categorieën/toevoegen') },
          ]}
        />
        <Filters filters={tempfilters} showFilterModal={showFilterModal} closeFilterModal={CloseFilterModal} setTempFilters={setTempFilters} setFilters={setFilters} defaultFilters={defaultFilters} />
      </div>
    </PageTemplate>
  );
}

export default Categories;
