import React, { useState, useEffect, useRef, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { debounce } from "lodash";
import { toast } from "react-toastify";
import { Info, X } from 'lucide-react';
import Cookies from "universal-cookie";
import middleware from "../Api/Middleware";
import saleschannels from "../Api/SalesChannels";
import PageTemplate from "../Templates/PageTemplate";
import Backbutton from "../Products/subpages/images/BackIcon.svg";
import OrderRowEdit from "./OrderRowEdit";
import CustomerComponent from "./Components/CustomerComponent";
import ToastSuccess from "../Toasts/ToastSuccess";
import ToastError from "../Toasts/ToastError";
import Loading from "../LoadingScreen/LoadingScreen";
import "./Orders.css";
import NavbarIcon from "../Navbars/Sidebar/Icons/envelope.png";
import "../Table/Table.css";
import ToolTip from "./Components/ToolTipComponent";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faX } from "@fortawesome/free-solid-svg-icons";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import ItemNoteModalComponent from "./Components/ItemNoteModalComponent";

const MAX_NOTE_LENGTH = 5000;
const MAX_REFERENCE_LENGTH = 500;
const OrdersNew = () => {
  const { order_id } = useParams();
  const cookies = new Cookies();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [products, setProducts] = useState([]);
  const [productsPrices, setProductsPrices] = useState([]);
  const [consumers, setConsumers] = useState([]);
  const [businesses, setBusinesses] = useState([]);
  const [customer, setCustomer] = useState([]);
  const [orderConcept, setOrderConcept] = useState([]);
  const [order, setOrder] = useState({});
  const [allSaleschannels, setAllSaleschannels] = useState([]);
  const [shippingMethods, setShippingMethods] = useState([]);
  const [showOrderRow, setShowOrderRow] = useState(false);
  const [activeSaleType, setActiveSaleType] = useState(1);
  const [activeSaleTypeVerzend, setActiveSaleTypeVerzend] = useState(1);
  const [orderProcessing, setOrderProcessing] = useState(null);
  const [salesChannel, setSalesChannel] = useState('');
  const [status, setStatus] = useState(null);
  const [totalAfterOrderDiscountInclTax, setTotalAfterOrderDiscountInclTax] = useState(0);
  const [totalAfterOrderDiscountExclTax, setTotalAfterOrderDiscountExclTax] = useState(0);
  const [reference, setReference] = useState('');
  const dropdownRef = useRef(null);
  const [wholeSale, setWholeSale] = useState({
    sale_amount: 0,
    sale_unit: "%",
  });
  const [btwState, setBtwState] = useState("incl");
  const [submittedButton, setSubmittedButton] = useState("");
  const [totalDiscount, setTotalDiscount] = useState(0);
  const [totalExclTax, setTotalExclTax] = useState(0);
  const [totalInclTax, setTotalInclTax] = useState(0);
  const [orderDiscount, setOrderDiscount] = useState(0);
  const [discountTotalOrder, setDiscountTotalOrder] = useState(0);
  const [searchTerm, setSearchTerm] = useState('');
  const [consumerSearch, setConsumerSearch] = useState('');
  const [customers, setCustomers] = useState([]);
  const [productSearch, setProductSearch] = useState('')
  const [products1, setProducts1] = useState([]);
  const [product, setProduct] = useState([])
  const [filteredProducts, setFilteredProducts] = useState(products1);
  const [orderItems, setOrderItems] = useState([]);
  const [note, setNote] = useState('');
  const [shippingCost, setShippingCost] = useState(6.95);
  const [orderInfo, setOrderInfo] = useState({
    note: '',
    noteError: ''
  });
  const [referenceError, setReferenceError] = useState('');
  const [selectedShippingMethod, setSelectedShippingMethod] = useState(null);
  const [subtotal, setSubtotal] = useState(0);
  const [tax, setTax] = useState(0);
  const [itemNoteModalVisible, setItemNoteModalVisible] = useState(false);

  const debouncedFetchCustomers = useCallback(
    debounce(async (searchTerm) => {
      try {
        const businessesResponse = await middleware.get(`businesses?page=1&page_size=10&company_name=${searchTerm}&LIKE=true`);
        const response = await middleware.get(`consumers?page=1&page_size=10&LIKE=true&first_name=${searchTerm}`);
        setCustomers(businessesResponse.data.data || []);
      } catch (error) {
        console.error("An error occurred: ", error);
      }
    }, 500),
    []
  );

  const handleCustomerSelection = (selectedCustomer) => {
    setCustomer(selectedCustomer);
  };

  const handleProductSearch = (e) => {
    const searchTerm = e.target.value;
    setProductSearch(searchTerm);
  };

  const debouncedFetchProducts = useCallback(
    debounce(async (searchTerm) => {
      try {
        const response = await middleware.get(`/sp/productsTableActive?page=1&page_size=10&LIKE=true&searchTerm=${searchTerm}&noSearch=["product_id","image_path","incl_tax","excl_tax","stock","barcode","created_at","status"]`);

        const products = response.data.data || [];

        const validProducts = products.filter(({ excl_tax, incl_tax }) =>
          excl_tax !== null && excl_tax !== 0 &&
          incl_tax !== null && incl_tax !== 0
        );

        setProducts1(validProducts);
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    }, 500),
    []
  );


  console.log(products1)

  const updateQuantity = (id, newQuantity) => {
    const numericValue = parseFloat(newQuantity);
    if (!isNaN(numericValue) && numericValue > 0) {
      setOrderItems(prevItems =>
        prevItems.map(item => {
          if (item.id === id) {
            const validQuantity = Math.min(numericValue, item.stock);
            if (validQuantity !== numericValue) {
              ToastError(`Maximum voorraad voor dit product is ${item.stock}`);
            }
            return { ...item, quantity: validQuantity };
          }
          return item;
        })
      );
    }
    calculateTotals();
  };

  const updateDiscount = (id, newDiscount, newDiscountType) => {
    setOrderItems(prevItems =>
      prevItems.map(item => {
        if (item.id === id) {
          let discountValue = parseFloat(newDiscount) || 0;
          if (newDiscountType === '€') {
            discountValue = Math.min(discountValue, item.price);
          } else {
            discountValue = Math.min(discountValue, 100);
          }

          return {
            ...item,
            discount: discountValue,
            discountType: newDiscountType
          };
        }
        return item;
      })
    );
  };

  const calculateDiscountedPrice = (item) => {
    if (item.discountType === '€') {
      return item.discount >= item.price ? 0 : Math.max(0, item.price - item.discount);
    } else {
      return item.discount >= 100 ? 0 : Math.max(0, item.price * (1 - item.discount / 100));
    }
  };

  const calculateDiscountedPriceExclTax = (item) => {
    if (item.discountType === '€') {
      const discountExclTax = (item.discount * item.price_excl_tax) / item.price;
      return Math.max(0, item.price_excl_tax - discountExclTax);
    } else {
      const percentageDiscount = Math.min(100, parseFloat(item.discount) || 0);
      return item.price_excl_tax * (1 - percentageDiscount / 100);
    }
  };

  const calculateRowTotal = (item) => {
    if (item.discountType === '€') {
      const discountAmount = Math.min(item.price, parseFloat(item.discount) || 0);
      const priceAfterDiscount = Math.max(0, item.price - discountAmount);
      return priceAfterDiscount * item.quantity;
    }
    else {
      const percentageDiscount = Math.min(100, parseFloat(item.discount) || 0);
      const priceAfterDiscount = item.price * (1 - percentageDiscount / 100);
      return priceAfterDiscount * item.quantity;
    }
  };

  const renderDiscountCell = (item) => (
    <td>
      <div className="d-flex align-items-center">
        <div className="d-flex">
          <button
            type="button"
            className={`euroSaleButton p-2 fw-semibold ${item.discountType === '€' ? "activeSaleType" : ""}`}
            onClick={() => updateDiscount(item.id, item.discount.toString(), '€')}
          >
            €
          </button>
          <button
            type="button"
            className={`percentageSaleButton p-2 fw-semibold ${item.discountType === '%' ? "activeSaleType" : ""}`}
            onClick={() => updateDiscount(item.id, item.discount.toString(), '%')}
          >
            %
          </button>
        </div>
        <input
          className="p-2 mx-2 inputSaleOrder text-center fw-semibold"
          placeholder="0"
          step="any"
          min={0}
          value={item.discount.toString()}
          onChange={(e) => updateDiscount(item.id, e.target.value, item.discountType)}
        />
      </div>
    </td>
  );

  const updateTotal = (type, value, isShipping = false) => {
    // Disable extra discount if subtotal is 0
    if (subtotal === 0) {
      setOrderDiscount(0);
      setShippingCost(0);
      return;
    }

    const numericValue = parseFloat(value);
    if (isNaN(numericValue)) return;

    if (isShipping) {
      if (selectedShippingMethod === 'pickup') {
        setShippingCost(0);
        return;
      }

      if (type === '€') {
        setShippingCost(numericValue);
        setActiveSaleType(1);
      } else if (type === '%') {
        const percentValue = Math.min(numericValue, 100);
        const newShippingCost = (subtotal * percentValue) / 100;
        setShippingCost(newShippingCost);
        setActiveSaleType(2);
      }
    } else {
      if (type === '€') {
        const maxDiscount = subtotal - calculateTotalProductDiscounts();
        setOrderDiscount(Math.min(numericValue, maxDiscount));
        setActiveSaleTypeVerzend(1);
      } else if (type === '%') {
        setOrderDiscount(Math.min(numericValue, 100));
        setActiveSaleTypeVerzend(2);
      }
    }

    calculateTotals();
  };

  const calculateTotalProductDiscounts = () => {
    return orderItems.reduce((sum, item) => {
      const originalTotal = item.price * item.quantity;
      const discountedTotal = calculateRowTotal(item);
      return sum + (originalTotal - discountedTotal);
    }, 0);
  };

  const totaalProducten = orderItems.reduce((sum, item) => sum + (item.price * item.quantity), 0);
  const totaalKorting = calculateTotalProductDiscounts();

  const handleShippingCostChange = (value) => {
    if (value === '') {
      setShippingCost('');
      return;
    }
    const numericValue = parseFloat(value);
    if (!isNaN(numericValue) && numericValue >= 0) {
      setShippingCost(numericValue);
    }
    calculateTotals();
  };

  const handleOrderDiscountChange = (value) => {
    const numericValue = parseFloat(value);
    if (!isNaN(numericValue) && numericValue >= 0) {
      if (activeSaleTypeVerzend === 1) {
        setOrderDiscount(Math.min(numericValue, subtotal));
      } else {
        setOrderDiscount(Math.min(numericValue, 100));
      }
    } else if (value === '') {
      setOrderDiscount(0);
    }
    calculateTotals();
  };

  const removeItem = (id) => {
    setOrderItems(prevItems => prevItems.filter(item => item.id !== id));
    calculateTotals();
  };

  useEffect(() => {
    if (consumerSearch) {
      debouncedFetchCustomers(consumerSearch);
    } else {
      setCustomers([]);
    }
  }, [consumerSearch, debouncedFetchCustomers]);

  useEffect(() => {
    if (productSearch) {
      debouncedFetchProducts(productSearch);
    } else {
      setProducts1([]);
    }
  }, [productSearch, debouncedFetchProducts]);

  useEffect(() => {
    if (Array.isArray(products)) {
      setFilteredProducts(products.filter(p =>
        p?.name?.toLowerCase().includes(searchTerm.toLowerCase()) || false
      ));
    } else {
      setFilteredProducts([]);
    }
  }, [searchTerm, products]);

  useEffect(() => {
    const totaalProducten = orderItems.reduce((sum, item) => sum + (item.price * item.quantity), 0);
    const totaalKorting = calculateTotalProductDiscounts();
    const finalTotal = Math.max(0, totaalProducten - totaalKorting);

    setSubtotal(totaalProducten);
    setTotalDiscount(totaalKorting);
    setTotalInclTax(finalTotal);

  }, [orderItems, orderDiscount, activeSaleTypeVerzend, shippingCost, selectedShippingMethod]);

  const handleNoteChange = (e) => {
    const value = e.target.value;
    if (value.length <= MAX_NOTE_LENGTH) {
      setOrderInfo({
        ...orderInfo,
        note: value,
        noteError: ''
      });
    } else {
      setOrderInfo({
        ...orderInfo,
        noteError: `Notitie mag niet langer zijn dan ${MAX_NOTE_LENGTH} tekens`
      });
      ToastError(`Notitie mag niet langer zijn dan ${MAX_NOTE_LENGTH} tekens`);
    }
  };

  const handleShippingMethodChange = (shippingId) => {
    const method = shippingMethods.find(m => m.shipping_id === shippingId);
    setSelectedShippingMethod(method);
    if (method) {
      setShippingCost(method.cost);
    }
    calculateTotals();
  };

  useEffect(() => {
    calculateTotals();
  }, [shippingCost, activeSaleType, subtotal, totalDiscount, totalExclTax, totalInclTax, orderDiscount, orderItems]);

  const calculateTotals = useCallback(() => {
    const productTotal = orderItems.reduce((sum, item) => {
      const discountedPrice = calculateDiscountedPrice(item);
      return sum + (discountedPrice * item.quantity);
    }, 0);

    const extraDiscountAmount = activeSaleTypeVerzend === 1
      ? Math.min(parseFloat(orderDiscount) || 0, productTotal)
      : (productTotal * Math.min(parseFloat(orderDiscount) || 0, 100)) / 100;

    const amountAfterExtraDiscount = Math.max(0, productTotal - extraDiscountAmount);

    const shippingCostValue = selectedShippingMethod === 'pickup' ? 0 : parseFloat(shippingCost) || 0;

    const subtotalAmount = amountAfterExtraDiscount + shippingCostValue;

    const exclBTW = subtotalAmount / 1.21;
    const btwAmount = subtotalAmount - exclBTW;

    setSubtotal(productTotal);
    setTotalDiscount(extraDiscountAmount);
    setTotalExclTax(exclBTW);
    setTotalInclTax(subtotalAmount);
    setTax(btwAmount);
  }, [orderItems, orderDiscount, activeSaleTypeVerzend, shippingCost, selectedShippingMethod]);

  const handleProductSelection = (product) => {
    const stockQuantity = parseInt(product.stock);

    const existingItem = orderItems.find(item => item.id === product.product_id);
    if (existingItem) {
      if (existingItem.quantity >= stockQuantity) {
        ToastError(`Maximum voorraad (${stockQuantity}) bereikt voor "${existingItem.name}"`);
        return;
      }
      updateQuantity(product.product_id, existingItem.quantity + 1);
      ToastSuccess(`Added "${existingItem.name}" x ${existingItem.quantity + 1}`);
    } else {
      const newItem = {
        id: product.product_id,
        name: product.product_name,
        price: parseFloat(product.incl_tax),
        price_excl_tax: parseFloat(product.excl_tax),
        quantity: 1,
        stock: stockQuantity,
        discount: 0,
        discountType: '€'
      };
      setOrderItems(prevItems => [...prevItems, newItem]);
      ToastSuccess(`Added "${newItem.name}" x 1`);
    }

    setProductSearch('');
    setProducts1([]);
  };


  useEffect(() => {
    calculateTotals();
  }, [orderItems, orderDiscount, activeSaleTypeVerzend, shippingCost, calculateTotals]);

  const handleReferenceChange = (e) => {
    const value = e.target.value;
    if (value.length <= MAX_REFERENCE_LENGTH) {
      setReference(value);
      setReferenceError('');
    } else {
      setReferenceError(`Referentie mag niet langer zijn dan ${MAX_REFERENCE_LENGTH} tekens`);
      ToastError(`Referentie mag niet langer zijn dan ${MAX_REFERENCE_LENGTH} tekens`);
    }
  };

  const NewOrder = async (e) => {
    e.preventDefault();

    if (orderInfo.note.length > MAX_NOTE_LENGTH) {
      ToastError(`Notitie mag niet langer zijn dan ${MAX_NOTE_LENGTH} tekens`);
      return;
    }

    if (reference.length > MAX_REFERENCE_LENGTH) {
      ToastError(`Referentie mag niet langer zijn dan ${MAX_REFERENCE_LENGTH} tekens`);
      return;
    }

    /*console.log("NewOrder function called");*/

    /*console.log("Order Items:", orderItems);*/
    if (orderItems.length === 0) {
      /*console.log("No order items found");*/
      ToastError("Voeg eerst producten toe aan de bestelling");
      return;
    }

    /*console.log("Customer:", customer);*/
    if (!customer.internal_id) {
      /*console.log("No customer selected");*/
      ToastError("Selecteer eerst een klant");
      return;
    }

    if (!selectedShippingMethod) {
      ToastError("Selecteer eerst een verzendmethode");
      return;
    }

    if (totalInclTax <= 0) {
      ToastError("Totaal inclusief BTW moet groter of gelijk aan 0 zijn");
      return;
    }

    if (!orderProcessing || !status) {
      ToastError("Graag de bestelling status en Verzendend status invullen!")
      return;
    }

    /*console.log("customer customer", customer)*/
    /*console.log("Preparing order data");*/
    const orderData = {
      internal_id: customer.internal_id,
      sales_channel_id: parseInt(salesChannel) || 86,
      order_status: parseInt(orderProcessing),
      note: orderInfo.note,
      total_price_incl_tax: totalInclTax,
      total_price_excl_tax: totalExclTax,
      sale_amount: totalDiscount,
      payment_status: 1,
      delivery_status: parseInt(status),
      order_sold_products: orderItems.map(item => ({
        product_id: item.id,
        amount: item.quantity,
        product_name: item.name,
        incl_tax: item.price,
        excl_tax: item.price_excl_tax,
        sale_amount: item.discount,
        sale_unit: item.discountType === "%" ? 0 : 1,
      })),
      reference: reference,
    };

    /*console.log("Order Data:", orderData);*/

    try {
      /*console.log("Setting loading state to true");*/
      setLoading(true);

      /*console.log("Sending POST request to create order");*/
      const orderResponse = await middleware.post("orders", orderData);
      /*console.log("Order Response:", orderResponse);*/

      /*console.log("Order created successfully");*/
      ToastSuccess(`Bestelling ${orderResponse.data.order_number} aangemaakt`);

      /*console.log("Preparing invoice data");*/
      const invoiceData = {
        internal_id: customer.internal_id,
        order_number: orderResponse.data.order_number,
        price: totalInclTax,
        note: orderInfo.note,
        billed_on: new Date().toISOString(),
        status: submittedButton === "withInvoice" ? 1 : 0,
      };
      /*console.log("Invoice Data:", invoiceData);*/

      /*console.log("Sending POST request to create invoice");*/
      const invoiceResponse = await middleware.post("invoices", invoiceData);
      /*console.log("Invoice Response:", invoiceResponse);*/

      if (submittedButton === "withInvoice") {
        /*console.log("Invoice sent");*/
        ToastSuccess(`Factuur verstuurd voor ${orderResponse.data.order_number}`);
      } else {
        /*console.log("Invoice not sent");*/
        ToastError(`Nog geen factuur verstuurd voor ${orderResponse.data.order_number}`);
      }

      /*console.log("Navigating to /bestellingen");*/
      console.log(orderResponse.data)
      const order_id = orderResponse.data.order_id
      const sender_email = "noreply@techdogsoftware.nl"
      const receiver_email = customer.invoicemail || ''
      const subject = "Bedankt voor je bestelling !"
      const content = "Dit is de order bevestegings e-mail voor je order bij unified smb. Hieronder meer details over de order."
      if (receiver_email.length > 0) {
        try {
          const response = await middleware.post('orders/emails', {
            order_id,
            sender_email,
            receiver_email,
            subject,
            content
          });

          if (response.status !== 200) {
            throw new Error('Failed to send email');
          }
          ToastSuccess("E-mail is succesvol verzonden.");

        } catch (err) {
          ToastError(`Er is een fout opgetreden bij het verzenden van de e-mail: ${err.message}`);
        }
      }

    } catch (error) {
      console.error("Error occurred:", error);
      console.error("Error details:", error.response ? error.response.data : "No response data");
      ToastError("Er is een fout opgetreden bij het aanmaken van de bestelling");
    } finally {
      /*console.log("Setting loading state to false");*/
      setLoading(false);
    }
    navigate("/bestellingen");
  };

  if (loading) {
    return (
      <div className="loading-container">
        <Loading />
      </div>
    );
  }

  return (
    <PageTemplate navbarTitle="Order HUB" iconNavbar={NavbarIcon}>
      <OrderRowEdit openOrderRow={showOrderRow} closeOrderRow={() => setShowOrderRow(false)} />
      <ItemNoteModalComponent itemNoteModalVisible={itemNoteModalVisible} setItemNoteModalVisible={setItemNoteModalVisible}/>

      <div className="NewOrder w-100">
        <form className="row" onSubmit={NewOrder}>
          {/* Header */}
          <div className="d-flex flex-row mb-4 pb-3 pt-5 justify-content-between mx-4">
            <div className="d-flex flex-row align-items-center" onClick={() => navigate("/bestellingen")}>
              <img style={{ width: "32px" }} className="me-3" src={Backbutton} alt="Back" />
              <p className="mb-0 fw-semibold fs-5">Alle bestellingen</p>
            </div>
            <div>
              <button type="submit" className="darkBlueStandardButton me-3" onClick={() => setSubmittedButton("withInvoice")}>
                Maak order aan
              </button>
              <button type="submit" className="lightBlueStandardButton" onClick={() => setSubmittedButton("asConcept")}>
                Sla op als concept
              </button>
            </div>
          </div>




          {/* Products Section - Second on mobile, First on left desktop */}
          {/* Products Section */}
          <div className="col-12 col-lg-8 order-1 mb-4">
            <div className="bg-white rounded-4">
              <div className="p-4">
                <div className="fs-5 mb-4 fw-bold">Producten</div>

                {/* Search with dropdown */}
                <div className="position-relative mb-4" ref={dropdownRef}>
                  <div className="position-relative">
                    <input
                      type="text"
                      value={productSearch}
                      onChange={handleProductSearch}
                      placeholder="Zoek product"
                      className="form-control rounded-pill"
                      style={{
                        padding: '10px 40px 10px 20px',
                        border: '1px solid #E5E7EB'
                      }}
                    />
                    <FontAwesomeIcon
                      icon={faSearch}
                      className="position-absolute top-50 end-0 translate-middle-y me-3 text-muted"
                    />
                  </div>

                  {/* Products Dropdown */}
                  {products1.length > 0 && (
                    <div
                      className="position-absolute w-100 bg-white border rounded mt-1 shadow-lg overflow-hidden"
                      style={{
                        zIndex: 1050,
                        maxHeight: '300px',
                        overflowY: 'auto'
                      }}
                    >
                      {loading ? (
                        <div className="text-center p-3">
                          <div className="spinner-border text-primary" role="status">
                            <span className="visually-hidden">Loading...</span>
                          </div>
                        </div>
                      ) : (
                        products1.map((product) => (
                          <button
                            key={product.product_id}
                            className="w-100 text-start px-3 py-2 border-0 bg-transparent hover:bg-gray-50"
                            onClick={() => handleProductSelection(product)}
                            style={{
                              borderBottom: '1px solid #E5E7EB',
                              transition: 'background-color 0.2s'
                            }}
                          >
                            <div className="fw-medium">{product.product_name}</div>
                            <div className="text-muted small d-flex justify-content-between">
                              <span>€{parseFloat(product.incl_tax).toFixed(2)}</span>
                              <span>Voorraad: {product.stock}</span>
                            </div>
                          </button>
                        ))
                      )}
                    </div>
                  )}
                </div>

                {/* Selected Products Table */}
                {orderItems.length > 0 && (
                  <div className="table-responsive">
                    <table className="table">
                      <thead className="text-center ">
                        <tr>
                          <th>Aantal</th>
                          <th>Product</th>
                          <th>Prijs incl. BTW</th>
                          <th>Korting</th>
                          <th>Kortingsprijs</th>
                          <th>Totaal</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>{/* wawaaaawaaa*/}
                        {orderItems.map((item) => (
                          <tr key={item.id} className="align-middle text-center">
                            <td>
                              <div className="d-flex align-items-center">
                                <button
                                  type="button"
                                  className="btn border rounded-0 rounded-start d-flex align-items-center justify-content-center"
                                  onClick={() => updateQuantity(item.id, Math.max(1, item.quantity - 1))}
                                >
                                  -
                                </button>
                                <input
                                  type="number"
                                  className="form-control rounded-0 text-center"
                                  style={{ width: "40px" }}
                                  value={item.quantity}
                                  onChange={(e) => updateQuantity(item.id, Math.max(1, parseInt(e.target.value) || 0))}
                                  min="1"
                                />
                                <button
                                  type="button"
                                  className="btn border rounded-0 rounded-end d-flex align-items-center justify-content-center"
                                  onClick={() => updateQuantity(item.id, item.quantity + 1)}
                                >
                                  +
                                </button>
                              </div>
                            </td>
                            <td>
                              <div>
                                <span onClick={() => setItemNoteModalVisible(true)} className="text-info product-description-width text-decoration-none">
                                  {item.name}
                                </span>
                                <div className="text-muted small text-truncate product-description-width">
                                  {item.name}
                                </div>
                              </div>
                            </td>
                            <td>€{item.price.toFixed(2)}</td>
                            {renderDiscountCell(item)}
                            <td>€{(item.price - calculateDiscountedPrice(item)).toFixed(2)}</td>
                            <td>€{calculateRowTotal(item).toFixed(2)}</td>
                            <td>
                              <button
                                type="button"
                                className="btn btn-sm rounded-circle d-flex align-items-center justify-content-center"
                                onClick={() => removeItem(item.id)}
                              >
                                <FontAwesomeIcon icon={faX} style={{ color: "#000000" }} />
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>

                    {/* Totals at bottom of products table */}
                    <div className="d-flex justify-content-end mt-4 me-5">
                      <div style={{ width: "250px" }}>
                        <div className="d-flex justify-content-between mb-2">
                          <span className="fw-bold">Totaal producten</span>
                          <span>€ {orderItems.reduce((sum, item) => sum + item.price * item.quantity, 0).toFixed(2)}</span>
                        </div>
                        <div className="d-flex justify-content-between pb-2 mb-2 bottom-line-overzicht">
                          <span className="fw-bold">Totaal korting</span>
                          <span className="text-danger">-€ {Math.abs(calculateTotalProductDiscounts()).toFixed(2)}</span>
                        </div>
                        <div className="d-flex justify-content-between fw-bold">
                          <span>Totaal</span>
                          <span>€ {(orderItems.reduce((sum, item) => {
                            const discountedPrice = calculateDiscountedPrice(item);
                            return sum + (discountedPrice * item.quantity);
                          }, 0)).toFixed(2)}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {/* Notes Section */}
                <div className="row mt-4">
                  <div className="col-md-6 mb-4">
                    <div className="fw-bold mb-2">Opmerking op factuur</div>
                    <textarea
                      className="form-control rounded-4"
                      rows="4"
                      placeholder="Opmerking op factuur"
                      value={orderInfo.note}
                      onChange={handleNoteChange}
                    ></textarea>
                  </div>
                  <div className="col-md-6 mb-4">
                    <div  className="fw-bold mb-2">Extra informatie</div>
                    <textarea
                      className="form-control rounded-4"
                      rows="4"
                      placeholder="Referentie op factuur"
                      value={reference}
                      onChange={handleReferenceChange}
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>
          </div>





          <div className="right-side-ordernew col-12 col-lg-4 order-2 row overflow-hidden">
            {/* Customer Card - First on mobile, First on right desktop */}
            <div className="pe-0 mb-4">
              <CustomerComponent
                mode="add"
                customer={customer}
                onSelectCustomer={handleCustomerSelection}
                addCustomer={true}
              />
            </div>



            {/* Overview & Status - Last on mobile, Second on right desktop */}
            {/* Order Overview Card */}
            {/* Order Overview Card */}
            {/* Order Overview Card */}
            <div className="pe-0 mb-4">
            <div className="whiteBox p-4 pt-2 pb-2">
              <h2 className="fs-5 mt-4 mb-4 fw-bold">Bestelling overzicht</h2>

              {/* Total Section */}
              <div className="bottom-line-overzicht mt-3 mb-3">
                <div className="d-flex justify-content-between">
                  <p className="fw-bold">Totaal</p>
                  <p className="fw-bold">€ {subtotal.toFixed(2)}</p>
                </div>

                {/* Extra Discount Section */}
                <div className="d-flex justify-content-between mb-3">
                  <div className="d-flex align-items-center">
                    Extra korting
                  </div>
                  <div className="d-flex buttonswitch">
                    <div className="d-flex align-items-center">
                      <div className="d-flex">
                        <button
                          type="button"
                          className={`euroSaleButton p-2 fw-semibold ${activeSaleTypeVerzend === 1 ? "activeSaleType" : ""}`}
                          onClick={() => updateTotal("€", orderDiscount, false)}
                          disabled={subtotal === 0}
                        >€</button>
                        <button
                          type="button"
                          className={`percentageSaleButton p-2 fw-semibold ${activeSaleTypeVerzend === 2 ? "activeSaleType" : ""}`}
                          onClick={() => updateTotal("%", orderDiscount, false)}
                          disabled={subtotal === 0}
                        >%</button>
                      </div>
                      <input
                        className="p-2 mx-2 inputSaleOrder text-center fw-semibold"
                        placeholder="0"
                        type="number"
                        step="any"
                        min={0}
                        max={activeSaleTypeVerzend === 1 ? subtotal : 100}
                        value={orderDiscount}
                        onChange={(e) => handleOrderDiscountChange(e.target.value)}
                        disabled={subtotal === 0}
                      />
                      <div className="ms-3">
                        € {(activeSaleTypeVerzend === 1
                          ? Math.min(orderDiscount, subtotal)
                          : (subtotal * Math.min(orderDiscount, 100) / 100)).toFixed(2)}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* Bedrag na extra korting Section */}
              <div className="bottom-line-overzicht pb-2">
                <div className="d-flex justify-content-between fw-bold">
                  <p className="mb-0">Bedrag na extra korting</p>
                  <p className="mb-0">€ {Math.max(0, subtotal - (activeSaleTypeVerzend === 1
                    ? Math.min(orderDiscount, subtotal)
                    : (subtotal * Math.min(orderDiscount, 100) / 100))).toFixed(2)}</p>
                </div>

                {/* Shipping Section */}
                <div className="d-flex justify-content-between align-items-center mt-3">
                  <div className="d-flex align-items-center gap-2">
                    <p className="mb-0">Verzendmethode</p>
                    <select
                      className="form-select-shipping rounded-3"
                      style={{ width: 'auto' }}
                      value={selectedShippingMethod}
                      onChange={(e) => {
                        setSelectedShippingMethod(e.target.value);
                        if (e.target.value === 'pickup') {
                          setShippingCost(0);
                        } else if (e.target.value === 'shipping') {
                          setShippingCost(6.95);
                        }
                      }}
                    >
                      <option value="" hidden>selecteer</option>
                      <option value="pickup">Ophalen</option>
                      <option value="shipping">Verzenden</option>
                      {shippingMethods.map((method) => (
                        <option key={method.shipping_id} value={method.shipping_id}>
                          {method.title}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="d-flex align-items-center">
                    <span className="me-2">€</span>
                    <input
                      className="shipping-cost px-3 py-1 rounded"
                      placeholder="0"
                      type="number"
                      step="0.01"
                      min="0"
                      value={selectedShippingMethod === 'pickup' ? '0' : shippingCost}
                      onChange={(e) => handleShippingCostChange(e.target.value)}
                      disabled={selectedShippingMethod === 'pickup'}
                    />
                  </div>
                </div>
              </div>

              {/* Subtotal and BTW Section */}
              <div className="pt-3 bottom-line-overzicht pb-3">
                <div className="d-flex justify-content-between fw-bold mb-2">
                  <h4 className="fs-5 mb-0 fw-bold">Subtotaal</h4>
                  <h5 className="fs-5 fw-bold">€ {totalInclTax.toFixed(2)}</h5>
                </div>
                <div className="d-flex justify-content-between mb-2">
                  <div>Totaal excl. BTW</div>
                  <div>€ {totalExclTax.toFixed(2)}</div>
                </div>
                <div className="d-flex justify-content-between">
                  <div>BTW</div>
                  <div className="d-flex gap-5">
                    <div>NL 21%</div>
                    <div>€ {tax.toFixed(2)}</div>
                  </div>
                </div>
              </div>

              {/* Total Including VAT */}
              <div className="d-flex justify-content-between pt-3 pb-3">
                <h5 className="fw-bold mb-0">Totaal incl. BTW</h5>
                <h5 className="fs-5 fw-bold">€ {totalInclTax.toFixed(2)}</h5>
              </div>
              </div>
            </div>






            {/* Status Card */}
            <div className="pe-0">
              <div className="whiteBox p-4">
                <div className="fs-5 mb-2 fw-bold">Status</div>

                  <label className="form-label">Bestelling status</label><ToolTip message={"Wat is de status van de bestelling."} className="pl-1 ms-2" />
                  <select className="form-select mb-3 rounded-pill" onChange={(e) => setOrderProcessing(e.target.value)} value={orderProcessing}>
                    <option>Bestelling status</option>
                    <option value="0" >geannuleerd</option>
                    <option value="2">nieuw</option>
                    <option value="3">voltooid</option>
                  </select>

                  <label className="form-label">Verkooplocatie</label><ToolTip message={"De verkoop locatie"} className="pl-1 ms-2"></ToolTip>
                  <select className="form-select mb-3 rounded-pill" onChange={(e) => setSalesChannel(e.target.value)} value={salesChannel}>
                    <option value="">Selecteer verkooplocatie</option>
                    {allSaleschannels.map((channel) => (
                      <option key={channel.id} value={channel.id}>{channel.name}</option>
                    ))}
                    <option>Webshop</option>
                  </select>

                  <label className="form-label">Verzendstatus</label><ToolTip message={"Verzend status van de order"} className="pl-1 ms-2"></ToolTip>
                  <select className="form-select rounded-pill" onChange={(e) => setStatus(e.target.value)} value={status}>
                    <option>Verzend Status</option>
                    <option value="0">Niet verzonden</option>
                    <option value="1">Verzonden</option>
                    <option value="2">Afgeleverd</option>
                  </select>
              </div>
            </div>


          </div>


        </form>
      </div >
    </PageTemplate >
  );
};

export default OrdersNew;