import React, { useState, useEffect } from 'react';
import middleware from "../../Api/Middleware";
import ToastError from "../../Toasts/ToastError";
import OpenEmailModal from "./OpenEmailModal";
import { ChevronLeft, ChevronRight } from 'lucide-react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSort } from '@fortawesome/free-solid-svg-icons'
import './ReceiveEmailModal.css';

const ReceiveEmailModal = ({ orderId, refresh }) => {
  const [data, setData] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [sortField, setSortField] = useState('date');
  const [sortDirection, setSortDirection] = useState('desc');
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(5);

  const fetchEmails = async () => {
    try {
      const response = await middleware.get(`/orders/emails`);
      setData(response.data);
    } catch (error) {
      ToastError(`Error message ${error.message}`);
    }
  };

  useEffect(() => {
    fetchEmails();
  }, [orderId, refresh]);

  const handleSort = (field) => {
    if (sortField === field) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortField(field);
      setSortDirection('desc');
    }
  };

  const filteredData = data
    .filter(item =>
      item.subject.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item.created_at.includes(searchTerm)
    )
    .sort((a, b) => {
      if (sortField === 'subject') {
        return sortDirection === 'asc'
          ? a.subject.localeCompare(b.subject)
          : b.subject.localeCompare(a.subject);
      } else if (sortField === 'date') {
        return sortDirection === 'asc'
          ? new Date(a.created_at) - new Date(b.created_at)
          : new Date(b.created_at) - new Date(a.created_at);
      }
      return 0;
    });

  const pageCount = Math.ceil(filteredData.length / itemsPerPage);

  useEffect(() => {
    if (currentPage > pageCount) {
      setCurrentPage(Math.max(1, pageCount));
    }
  }, [filteredData.length, itemsPerPage, pageCount]);

  const handleItemsPerPageChange = (newItemsPerPage) => {
    const newPageCount = Math.ceil(filteredData.length / newItemsPerPage);
    const newCurrentPage = Math.ceil((currentPage * itemsPerPage) / newItemsPerPage);

    setItemsPerPage(newItemsPerPage);
    setCurrentPage(Math.min(newCurrentPage, newPageCount) || 1);
  };

  const displayedData = filteredData.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const getPaginationRange = () => {
    const maxPageNumbersToShow = 3;
    const paginationRange = [];
    const startPage = Math.max(1, currentPage - 1);
    const endPage = Math.min(pageCount, startPage + maxPageNumbersToShow - 1);

    for (let i = startPage; i <= endPage; i++) {
      paginationRange.push(i);
    }

    return paginationRange;
  };

  return (
    <>
      {/* Search Bar */}
      <div className="search-bar">
        <input
          type="text"
          placeholder="Zoeken"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="ps-4 search-input rounded-5"
        />
        <div className='SearchIcon' style={{width: "42px", height: "42px", position: "absolute", right: "52px"}}/>
      </div>

      <div className="email-container pt-2 pb-0 rounded-4 mt-4">
      {/* Email List Table */}
      <table className="w-100">
        {/* Table Header */}
        <thead className='bottom-border-line'>
          <tr>
            <th className="ps-3">
              <label className="checkbox-container">
                <input
                  type="checkbox"
                  checked={undefined}
                  onChange={undefined}
                />
                <span className="checkmark"></span>
              </label>
            </th>
            <th 
              onClick={() => handleSort('subject')}
              className="pr-4"
            >
              <div>
                <span>Onderwerp </span>
                <FontAwesomeIcon icon={faSort} style={{fontSize: "10px"}} />
              </div>
            </th>
            <th
              onClick={() => handleSort('date')}
            >
              <div>
                <span>Datum aangemaakt </span>
                <FontAwesomeIcon icon={faSort} style={{fontSize: "10px"}} />
              </div>
            </th>
          </tr>
        </thead>
        
        {/* Table Body */}
        <tbody>
          {displayedData.length > 0 ? (
            displayedData.map((item) => (
              <tr 
                key={item.client_order_email_id} 
              >
                <td className="ps-3">
                  <label className="checkbox-container">
                  <input
                    type="checkbox"
                    checked={undefined}
                    onChange={undefined}
                  />
                  <span className="checkmark"></span>
                </label>
                </td>
                <td className="pr-4">
                  <a
                    onClick={() => {}}
                    className="new-link-color"
                    style={{fontSize: "14px"}}
                  >
                    {item.subject.length > 30 ? item.subject.slice(0, 30) + "..." : item.subject}
                  </a>
                </td>
                <td>
                    <div style={{fontSize: "14px"}}>
                    <span>
                      {new Date(item.created_at).toLocaleDateString("nl-NL", {
                        day: 'numeric',
                        month: 'long',
                        year: 'numeric',
                      }) + " "}
                    </span>
                    <span>
                      {new Date(item.created_at).toLocaleTimeString([], {
                        hour: '2-digit',
                        minute: '2-digit'
                      })}
                    </span>
                  </div>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="3" className="px-4 py-8 text-center">
                Er zijn nog geen emails
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
      {/* Pagination */}
      <div className="pagination flex items-center justify-between mt-2 mb-2">
        <div className="d-flex gap-2">
            <select value={itemsPerPage} onChange={(e) => handleItemsPerPageChange(Number(e.target.value))} className="page-sizer email-page-sizer">
              <option value={5}>5</option>
              <option value={10}>10</option>
          </select>
          <div className="page-display text-nowrap me-4 Nunito">
            {currentPage * itemsPerPage - itemsPerPage + 1}-{Math.min(currentPage * itemsPerPage, filteredData.length)} van {filteredData.length}
          </div>
        </div>

        <div className="pagination-controls d-flex gap-1">
          <button
            onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
            disabled={currentPage === 1}
            className="pagination-btn"
          >
            <ChevronLeft size={18} />
          </button>

          {getPaginationRange().map((pageNumber) => (
            <button
              key={pageNumber}
              onClick={() => setCurrentPage(pageNumber)}
              className={'pagination-btn'}
            >
              {pageNumber}
            </button>
          ))}

          <button
            onClick={() => setCurrentPage(prev => Math.min(prev + 1, pageCount))}
            disabled={currentPage === pageCount}
            className="pagination-btn"
          >
            <ChevronRight size={18} />
          </button>
        </div>
      </div>
    </>
  );
};

export default ReceiveEmailModal;
