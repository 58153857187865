import React, { useEffect, useState } from 'react';
import middleware from '../Api/Middleware';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';

const NetworkEventListener = () => {
    const [status, setStatus] = useState(true);

    useEffect(() => {
        let timeoutId;
        async function checkStatus() {
            const response = await middleware.get('/state').catch(() => {});
            if (response && response.status != null && response.status === 200) {
                setStatus(true);
            }
            // Schedule the next check after the current one completes
            timeoutId = setTimeout(checkStatus, 30000);
        }
    
        checkStatus();
    
        function handleNetworkError() {
            setStatus(false);
        }
    
        window.addEventListener('network-error', handleNetworkError);
    
        // Cleanup the timeout and the event listener on component unmount
        return () => {
            clearTimeout(timeoutId);
            window.removeEventListener('network-error', handleNetworkError);
        };
    }, []);

    return (
        <>
            <div className={'errorpage ' + (!status ? 'errorpage-enabled' : '')}>
            </div>
            <div className={"errorpage-popup" + (!status ? ' errorpage-popup-enabled' : '')}>
                <FontAwesomeIcon className="errorpage-icon" icon={faTriangleExclamation} />
                <h4 className="m-0">Kan geen verbinding maken met het netwerk. Controleer uw internet verbinding.</h4>
            </div>
        </>
    );
}

export default NetworkEventListener;
