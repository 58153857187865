import React, { useMemo, useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router";
import Cookies from "universal-cookie";
import middleware from "../../Api/Middleware";
import PageTemplate from "../../Templates/PageTemplate";
import TablePreset from "../../Table/TablePreset";
import CheckboxHeader from "../../Table/TableComponents/CheckboxHeader";
import CheckboxCell from "../../Table/TableComponents/CheckboxCell";
import SortableHeader from "../../Table/TableComponents/SortableHeader";
import ToastError from "../../Toasts/ToastError";
import ToastSuccess from "../../Toasts/ToastSuccess";
import Modal from "react-bootstrap/Modal";
import { useTable } from "react-table";

const account_status_definer = {
  0: { color: "red", text: "Geblokkeerd" },
  1: { color: "green", text: "Actief" },
};

const account_role_definer = {
  0: { color: "#A0A0A0", text: "Alleen bestellingen" },
  1: { color: "#007BFF", text: "Medewerker" },
  2: { color: "#17A2B8", text: "Leidinggevende" },
  3: { color: "purple", text: "Manager" },
  4: { color: "darkblue", text: "Admin" },
};

const ACCOUNT_STATUS = {
  ACTIVE: 1,
  SUSPENDED: 0,
};

const ACTION_TYPES = {
  SELECT_ALL: "select-all",
  UNSELECT_ALL: "unselect-all",
  SUSPEND: "suspend",
};

const Users = ({ userRole }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const cookies = new Cookies();

  const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState([]);
  const [pagination, setPagination] = useState({
    page: 1,
    page_size: 10,
    max_items: 0,
    begin_of_page: 0,
    end_of_page: 0,
  });
  const [order, setOrder] = useState({ orderBy: "", direction: "" });
  const [selectedUserIds, setSelectedUserIds] = useState([]);
  const [usersSearchTerm, setUsersSearchTerm] = useState("");
  const [searchBarInput, setSearchBarInput] = useState("");
  const [timerId, setTimerId] = useState(null);
  const [show, setShow] = useState(false);

  // Move fetchUsers to the component level
  const fetchUsers = async () => {
    try {
      setLoading(true);
      const response = await middleware.get(
        `sp/usersTableAll?account_status=1&page=${pagination.page}&page_size=${pagination.page_size}&searchTerm=${usersSearchTerm}&noSearch=[password,api_token,updated_at,created_at]${
          order.orderBy && order.direction
            ? `&orderBy=${order.orderBy}&orderDirection=${order.direction}`
            : ""
        }`
      );
      setUsers(response.data.data);
      setPagination((prev) => ({
        ...prev,
        max_items: response.data.total,
        begin_of_page: response.data.from,
        end_of_page: response.data.to,
      }));
      setLoading(false);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        cookies.remove("token", { path: "/" });
        navigate("/");
      } else {
        ToastError("Er is een fout opgetreden bij het ophalen van gebruikers");
      }
    }
  };

  useEffect(() => {
    fetchUsers();
  }, [usersSearchTerm, pagination.page, pagination.page_size, order]);

  useEffect(() => {
    setPagination((prev) => ({
      ...prev,
      page: 1,
    }));
  }, [order]);

  // Remove the redundant useEffect that conflicts with fetchUsers

  // Handle checkbox selection
  const handleUserSelection = (userId, isSelected) => {
    if (isSelected) {
      setSelectedUserIds([...selectedUserIds, userId]);
    } else {
      setSelectedUserIds(selectedUserIds.filter((id) => id !== userId));
    }
  };

  const columns = useMemo(
    () => [
      {
        Header: (
          <CheckboxHeader
            currentItem={users}
            selectedIds={selectedUserIds}
            setSelectedIds={setSelectedUserIds}
            pk={"user_id"}
          ></CheckboxHeader>
        ),
        accessor: "select",
        Cell: ({ row }) => {
          return (
            <CheckboxCell
              row={row}
              handleSelection={handleUserSelection}
              selectedIds={selectedUserIds}
              pk={"user_id"}
            />
          );
        },
      },
      {
        Header: () => (
          <SortableHeader
            currentItem={order}
            setItem={setOrder}
            field="username"
            pos={"start"}
          >
            Gebruiker
          </SortableHeader>
        ),
        accessor: "username",
        Cell: ({ row }) => {
          return (
            <a
              href={`/algemene/instellingen/gebruikers/bewerken/${row.original.user_id}`}
              className="text-info text-decoration-none "
            >
              {row.original.username}
            </a>
          );
        },
      },
      {
        Header: () => (
          <SortableHeader
            currentItem={order}
            setItem={setOrder}
            field="email"
            pos={"start"}
          >
            Email
          </SortableHeader>
        ),
        accessor: "email",
        Cell: ({ row }) => {
          return row.original.email;
        },
      },
      {
        Header: () => (
          <SortableHeader
            currentItem={order}
            setItem={setOrder}
            field="role"
            pos={"start"}
          >
            Toegangslevel
          </SortableHeader>
        ),
        accessor: "role",
        Cell: ({ row }) => {
          const roleNumber = row.original.role;
          const role = account_role_definer[roleNumber];

          if (!role) {
            return <span style={{ color: "#000000" }}>Onbekend</span>;
          }

          return <span style={{ color: role.color }}>{role.text}</span>;
        },
      },
      {
        Header: () => (
          <SortableHeader
            currentItem={order}
            setItem={setOrder}
            field="account_status"
            pos={"start"}
          >
            Status
          </SortableHeader>
        ),
        accessor: "account_status",
        Cell: ({ row }) => {
          const statusCode = row.original.account_status;
          const status = account_status_definer[statusCode];

          if (!status) {
            return <span style={{ color: "#000000" }}>Onbekend</span>;
          }

          return <span style={{ color: status.color }}>{status.text}</span>;
        },
      },
    ],
    [order.orderBy, order.direction, selectedUserIds, users]
  );

  const tableInstance = useTable({ columns, data: users });

  const searchDelay = (value) => {
    setSearchBarInput(value);
    if (timerId) {
      clearTimeout(timerId);
    }
    const newTimerId = setTimeout(() => {
      setUsersSearchTerm(value);
    }, 500);

    setTimerId(newTimerId);
  };

  const suspend = async () => {
    try {
      await Promise.all(
        selectedUserIds.map((userId) =>
          middleware.put("users", {
            user_id: userId,
            account_status: ACCOUNT_STATUS.SUSPENDED,
          })
        )
      );
      await fetchUsers();
      setSelectedUserIds([]);
      ToastSuccess("Gebruikers succesvol gedeactiveerd");
    } catch (error) {
      ToastError("Er is een fout opgetreden bij het deactiveren van gebruikers");
    }
  };

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const Action = (method) => {
    if (method === ACTION_TYPES.SELECT_ALL) {
      setSelectedUserIds([
        ...selectedUserIds,
        ...users
          .filter((user) => !selectedUserIds.includes(user.user_id))
          .map((e) => e.user_id),
      ]);
    } else if (method === ACTION_TYPES.UNSELECT_ALL) {
      setSelectedUserIds([]);
    } else if (method === ACTION_TYPES.SUSPEND) {
      handleShow();
    }
  };

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton className="bg-white">
          <Modal.Title>Let op!</Modal.Title>
        </Modal.Header>
        <Modal.Body className="bg-white">
          U gaat {selectedUserIds.length}{" "}
          {selectedUserIds.length === 1 ? "item" : "items"} de-activeren. Weet u
          dat zeker?
        </Modal.Body>
        <Modal.Footer className="bg-white">
          <button className="btn bg-danger text-white" onClick={handleClose}>
            Annuleren
          </button>
          <button
            className="btn bg-success text-white"
            onClick={async () => {
              handleClose();
              await suspend();
            }}
          >
            Ik weet het zeker
          </button>
        </Modal.Footer>
      </Modal>
      <PageTemplate
        navbarTitle={"Gebruikers Overzicht"}
        backValue={"/"}
      >
        <div className="w-100">
          <div className="tableUsers">
            <TablePreset
              data={[
                {
                  selectedIds: selectedUserIds,
                  tableInstance: tableInstance,
                  pagination: pagination,
                  primary_key: "user_id",
                },
              ]}
              tableLoading={loading}
              setPagination={setPagination}
              handleSelection={handleUserSelection}
              searchBar={[
                { shown: true, value: searchBarInput, function: searchDelay },
              ]}
              actionOptions={[
                {
                  title: "Deactiveer",
                  function: () => Action(ACTION_TYPES.SUSPEND),
                },
                {
                  title: "Selecteer alles",
                  function: () => Action(ACTION_TYPES.SELECT_ALL),
                },
                {
                  title: "Deselecteer alles",
                  function: () => Action(ACTION_TYPES.UNSELECT_ALL),
                },
              ]}
              rightButtons={[
                {
                  title: "Nieuwe Gebruiker",
                  function: () =>
                    navigate("/algemene/instellingen/gebruikers/aanmaken"),
                },
              ]}
            />
          </div>
        </div>
      </PageTemplate>
    </>
  );
};

export default Users;