import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTruck, faCamera } from '@fortawesome/free-solid-svg-icons';
import { Link, useNavigate } from 'react-router-dom';
import placeholder from '../Icons/empty-placeholder.jpg';
import deliveryTruck from '../Icons/delivery-truck.png';
import "./OrderComponents.css"

const VerwerkOrderComponent = ({
  productsToProcess,
  productPic,
  showPrice,
  showSalePrice,
  calculateSalePrice,
  statuses,
  paymentStatus,
  orderId


  
}) => {



  const navigate = useNavigate();

  const [quantities, setQuantities] = useState(
    productsToProcess.reduce((acc, product) => ({
      ...acc,
      [product.sold_product_id]: 0
    }), {})
  );

  // Get status tags based on current status
  const getStatusTags = () => {
    const tags = [];

    //Order status tag
    if (statuses[0] === 0) {
      tags.push({ label: 'geannuleerd', type: 'danger' });
    } else if (statuses[0] === 1) {
      tags.push({ label: 'Concept', type: 'primary' });
    } else if (statuses[0] === 2) {
      tags.push({ label: 'Nieuw', type: 'info' });
    } else if (statuses[0] === 3) {
      tags.push({ label: 'Voltooid', type: 'succes' });
    }

    // Payment Status Tag
    if (statuses[1] === 0) {
      tags.push({ label: 'Concept', type: 'primary' });
    } else if (statuses[1] === 1) {
      tags.push({ label: 'Open staand', type: 'danger' });
    } else if (statuses[1] === 2) {
      tags.push({ label: 'Deels Betaald', type: 'warning' });
    } else if (statuses[1] === 3) {
      tags.push({ label: 'Volledig betaald', type: 'succes' });
    } 

    console.log("stauts ", statuses)
    console.log("stauts ", statuses[1])
    
    // Delivery Status Tag
    if (statuses[2] === 0) {
      tags.push({ label: 'Nog niet verzonden', type: 'danger' });
    } else if (statuses[2] === 1) {
      tags.push({ label: 'Verzonden', type: 'warning' });
    } else if (statuses[2] === 2) {
      tags.push({ label: 'Aangekomen', type: 'success' });
    }

    console.log("Tags ", tags)
    return tags;
  };

  const handleQuantityChange = (productId, value) => {
    const quantity = Math.max(0, Math.min(
      parseInt(value) || 0,
      productsToProcess.find(p => p.sold_product_id === productId)?.amount || 0
    ));
    setQuantities(prev => ({
      ...prev,
      [productId]: quantity
    }));
  };

  if (!productsToProcess.length) {
    return (
      <div className="card mb-3 rounded-4 card-shadow">
        <div className="card-body text-center py-4">
          <FontAwesomeIcon icon={faTruck} style={{ color: "#009fe3" }} size="2x" className="mb-3" />
          <p className="mb-0">Geen producten meer te verwerken</p>
        </div>
      </div>
    );
  }

  return (
    <div className="whiteBox p-5 pt-4 pb-4 mt-0 align-center">
      <div className='header d-flex justify-content-start align-items-center pb-4 bottom-border-line'>
        <div>
          <h5 className="mb-0 fw-bold">Nog te verwerken</h5>
        </div>

        {/* Status Tags */}
        <div className="d-flex flex-wrap ms-2">
          {getStatusTags().map((tag, index) => (
            <span
              key={index}
              className={`badge rounded-pill alert alert-${tag.type} border border-${tag.type} mb-0 ms-2`}
              style={{
                padding: '6px 12px',
                fontSize: '0.7rem',
                fontWeight: '500'
              }}
            >
              {tag.label}
            </span>
          ))}
        </div>
      </div>
      {productsToProcess.length > 0 ? (
        <ul className='list-group list-group-flush'>
          {productsToProcess.map((item, index) => (
            <li key={index} className='verwerk-list-group-item bottom-border-line d-flex justify-content-between align-items-center gap-3 pb-2 pt-2'>
              <div className='d-flex flex-row gap-3 flex-grow-1'>
                <div className='d-flex align-items-center'>
                  {/* Product image */}
                  <img src={placeholder} className="rounded-circle p-2 me-3" height={100} width={100} alt="placeholder..." />
                </div>
                
                {/* Product info */}
                <div className='d-flex flex-column justify-content-center align-items-start'>
                  <a href={`/producten/bewerken/${item.product_id}`} className='new-link-color'>{item.product_name}</a>
                  <p className='text-muted small mb-0'>Lorem ipsum dolor sit amet, consectetur adipiscing elit</p>
                </div>
              </div>

              {/* Product price info */}
              <div className='d-flex align-items-center justify-content-end gap-3 gap-xxl-5 flex-grow-1'>
                <span className='text-nowrap'>€{parseFloat(item.price_incl_tax).toFixed(2)}</span>
                <span className='text-nowrap'>x{item.amount}</span>
                <span className='text-nowrap'>-€{item.sale_amount}</span>
                <span className='fw-semibold'>€{((item.price_incl_tax - item.sale_amount) * item.amount).toFixed(2)}</span>
              </div>
            </li>
          ))}
        </ul>
      ) : (
        <div className="text-center p-3">
          <div className="text-center" role="status">
            <h5>There are no product to process...</h5>
          </div>
        </div>
      )}

      <div className="d-flex justify-content-end mt-3">
        <button
          className="btn lightBlueStandardButton"
          onClick={() => navigate('/bestellingen/verwerken/' + orderId)}
          style={{"width": "160px"}}
        >
          <span>
            <img width="30px" height="30px" className='img-fluid me-2' src={deliveryTruck}></img>
            Verwerken
          </span>
        </button>
      </div>
    </div>

  );
};

export default React.memo(VerwerkOrderComponent);