import React, { useEffect, useState } from 'react'
import PageTemplate from '../Templates/PageTemplate'
import middleware from '../Api/Middleware'
import { useParams, useNavigate, useLocation } from 'react-router'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";

const ProviderOnboarding = () => {
    const provider_id = sessionStorage.getItem('provider_id');
    const [provider, setProvider] = useState([])
    const [backofficeSettings, setBackofficeSettings] = useState([])
    const [loading, setLoading] = useState(true);
    const nav = useNavigate();
    const location = useLocation();
    const [formData, setFormData] = useState({
        provider_id: 0,
        legal_name: '',
        coc: '',
        address: '',
        house_number: '',
        postal_code: '',
        city: '',
        bank_account_owner: '',
        bank_account_number: '',
        bic_swift: '',
        prefix: '',
        first_name: '',
        last_name: '',
        email: '',
        autorization: '',
        ubo: '',
        businesscategory: '',
        authorised: false,
        agreedtac: false
    });


    const fetchAll = async () => {
        try {
            const [fetchProvider, fetchBackofficeSettings, fetchExistingOnboarding, fetchBasicOnboarding] = await Promise.all([
                middleware.get(`paymentproviders?provider_id=${provider_id}`),
                middleware.get(`settings/backoffice`),
                middleware.get(`paymentproviders/onboarding?provider_id=${provider_id}`),
                middleware.get(`paymentproviders/onboarding?provider_id=0`)
            ])
            setProvider(fetchProvider.data)
            setBackofficeSettings(fetchBackofficeSettings.data[0])
            setFormData(prev => ({...prev, ...fetchBasicOnboarding.data[0], provider_id: parseInt(provider_id), onboarding_id: null}));
            if(fetchExistingOnboarding.data.length > 0) {
                const [postProviderStatus] = await Promise.all([
                    middleware.put('paymentproviders', {provider_id: provider_id, provider_status: 0})
                ]);
                nav('/betalingproviders')
            }
            setLoading(false)
        } catch (err) {
            console.warn(err)
        }
    }

    const uploadToDatabase = async () => {
        const [postOnboardRes] = await Promise.all([
            middleware.post('paymentproviders/onboarding', {...formData, onboarding_id: null})
        ]);
        console.log(postOnboardRes);
        const [postProviderStatus] = await Promise.all([
            middleware.put('paymentproviders', {provider_id: provider_id, provider_status: 0})
        ]);
}

    useEffect(() => {
        if(!provider_id || provider.provider_status == 0) {
            nav('/betalingproviders')
        }
        fetchAll()
    }, [])

    const UpdateValueInForm = (e) => {
        const { id, type, checked, value } = e.target;
        setFormData(prev => ({
            ...prev,
            [id]: type === 'checkbox' ? checked : value
        }));
    }
 
  return (
    <PageTemplate navbarTitle={'Activeer Provider'} pageIsLoading={loading} backValue={"/betalingproviders"}>
        <div className='d-flex flex-column w-100'>
                <div className="d-flex flex-row mb-3">
                    <button
                    className="btn bg-white fw-semibold rounded-pill"
                    onClick={() => nav(-1)}
                    >
                        <FontAwesomeIcon icon={faChevronLeft}/>
                    </button>
                    <h3 className="ms-3 mb-0">{provider.provider_name} Payments / Onboarding</h3>
                </div>
            <form>
                <div className='whiteBox mt-5 col-7'>
                    <h4 className='mb-4'>Juridische informatie</h4>
                    <div className='d-flex flex-column'>
                        <label htmlFor='legal_name'>Juridische naam</label>
                        <input type='text' name='legal_name' id='legal_name' onChange={e => UpdateValueInForm(e)} value={formData.legal_name}></input>
                    </div>
                    <div className='d-flex flex-column'>
                        <label htmlFor='coc'>Kamer van Koophandel (KvK)</label>
                        <input type='number' name='coc' id='coc' onChange={e => UpdateValueInForm(e)} value={formData.coc}></input>
                    </div>
                    <div className='d-flex flex-column'>
                        <label htmlFor='address'>Bedrijfsadres</label>
                        <div className='w-100 justify-content-between d-flex'>
                            <input className='col-9' type='text' name='address' id='address' onChange={e => UpdateValueInForm(e)} value={formData.address}></input>
                            <input className='col-3' type='number' name='house_number' id='house_number' onChange={e => UpdateValueInForm(e)} value={formData.house_number}></input>
                        </div>
                    </div>
                    <div className='d-flex flex-column'>
                        <div className='w-100 justify-content-between d-flex flex-row'>
                            <div className='d-flex flex-column col-2'>
                                <label htmlFor='postal_code'>Postcode</label>
                                <input type='text' name='postal_code' id='postal_code' onChange={e => UpdateValueInForm(e)} value={formData.postal_code}></input>
                            </div>
                            <div className='d-flex flex-column col-9'>
                                <label htmlFor='city'>Stad</label>
                                <input type='text' name='city' id='city' onChange={e => UpdateValueInForm(e)} value={formData.city}></input>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='whiteBox mt-5 col-7'>
                    <h4 className='mb-4'>Bankrekening</h4>
                    <div className='d-flex flex-column'>
                        <label htmlFor='bank_account_owner'>Eigenaar bankrekening</label>
                        <input type='text' name='bank_account_owner' id='bank_account_owner' onChange={e => UpdateValueInForm(e)} value={formData.bank_account_owner}></input>
                    </div>
                    <div className='d-flex flex-column'>
                        <div className='w-100 justify-content-between d-flex flex-row'>
                            <div className='d-flex flex-column col-8'>
                                <label htmlFor='bank_account_number'>Bankaccountnummer (IBAN)</label>
                                <input type='text' name='bank_account_number' id='bank_account_number' onChange={e => UpdateValueInForm(e)} value={formData.bank_account_number}></input>
                            </div>
                            <div className='d-flex flex-column col-3'>
                                <label htmlFor='bic_swift'>BIC/Swift</label>
                                <input type='number' name='bic_swift' id='bic_swift' onChange={e => UpdateValueInForm(e)} value={formData.bic_swift}></input>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='whiteBox mt-5 col-7'>
                    <h4 className='mb-4'>Financieel contact</h4>
                    <div className='d-flex flex-column'>
                        <div className='w-100 justify-content-between d-flex flex-row'>
                            <div className='d-flex flex-column col-1'>
                                <label htmlFor='address'> Aanhef</label>
                                <select name='prefix' id='prefix'>
                                    <option>heer</option>
                                    <option>mevrouw</option>
                                </select>
                            </div>
                            <div className='d-flex flex-column col-5'>
                                <label htmlFor='first_name'>Voornaam(en)</label>
                                <input type='text' name='first_name' id='first_name' onChange={e => UpdateValueInForm(e)} value={formData.first_name}></input>
                            </div>
                            <div className='d-flex flex-column col-5'>
                                <label htmlFor='last_name'>Achternaam</label>
                                <input type='text' name='last_name' id='last_name' onChange={e => UpdateValueInForm(e)} value={formData.last_name}></input>
                            </div>
                        </div>
                    </div>
                    <div className='d-flex flex-column'>
                        <label htmlFor='email'>Email</label>
                        <input type='text' name='email' id='email' onChange={e => UpdateValueInForm(e)} value={formData.email}></input>
                    </div>
                    <div className='d-flex flex-column'>
                        <div className='w-100 justify-content-between d-flex flex-row'>
                            <div className='d-flex flex-column col-5'>
                                <label htmlFor='autorization'>Tekenbevoegd</label>
                                <select name='autorization' id='autorization'>
                                    <option>Zelfstandig bevoegd</option>
                                    <option>Multiplayer</option>
                                </select>
                            </div>
                            <div className='d-flex flex-column col-5'>
                                <label htmlFor='ubo'>UBO (uiteindeliijjk belanghebbende)</label>
                                <select name='ubo' id='ubo'>
                                    <option>Ja</option>
                                    <option>Nee</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
                
                <div className='whiteBox mt-5 col-7'>
                    <h4 className='mb-4'>Jouw bedrijfscategorie</h4>
                    <div className='d-flex flex-column'>
                        <div className='w-100 justify-content-between d-flex flex-row'>
                            <div className='d-flex flex-column col-12'>
                                <label htmlFor='businesscategory'>bedrijfscategorie</label>
                                <select name='businesscategory' id='businesscategory'>
                                    <option>1</option>
                                    <option>2</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
                
                <div className='whiteBox mt-5 col-7'>
                    <h4 className='mb-4'>Voorwaarden</h4>
                    <div className='d-flex flex-column'>
                        <div className='w-100 justify-content-between d-flex flex-row'>
                            <div className='d-flex flex-column'>
                                <div className='d-flex flex-row'>
                                    <input type='checkbox' name='authorised' id='authorised' onChange={e => UpdateValueInForm(e)} checked={formData.authorised}></input>
                                    <label>Ik mag betalingsdiensten afnemen namens dit bedrijf</label>
                                </div>
                                <div className='d-flex flex-row'>
                                    <input type='checkbox' name='agreedtac' id='agreedtac' onChange={e => UpdateValueInForm(e)} checked={formData.agreedtac}></input>
                                    <label className='d-flex flex-row'>Ik ga akkoord met de volgende voorwaarden: <a className='link-primary text-decoration-underline ms-2 hover' target='_' href={provider.terms_and_conditions}>{provider.provider_name} algemene voorwaarden</a></label>
                                </div>
                                <div className='btn btn-success mt-4 col-6' onClick={e => uploadToDatabase()}>{provider.provider_name} Payments activeren</div>
                                
                            </div>
                        </div>
                    </div>
                </div>

            </form>
        </div>
    </PageTemplate>
  )
}

export default ProviderOnboarding