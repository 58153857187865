import React, { useState, useEffect, useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBox, faClipboardList, faHouse } from '@fortawesome/free-solid-svg-icons';
import middleware from '../../Api/Middleware';
import PrinterIcon from "../Icons/printer.png";
import placeholder from '../Icons/empty-placeholder.jpg';
import packageIcon from '../Icons/package.png';
import clipboardWithCheckIcon from '../Icons/order-verified.png';
import homeIcon from '../Icons/home.png';

const VerzendComponent = ({ orderId, productsToProcess = [] }) => {
  const [shipments, setShipments] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  function getDate(amount) {
    const today = new Date();
    const months = ['januari', 'februari', 'maart', 'april', 'mei', 'juni', 'juli', 'augustus', 'september', 'oktober', 'november', 'december'];
    const month = months[today.getMonth()];
    const year = today.getFullYear();
    const date = today.getDate() - amount;
    return `${date} ${month} ${year}`;
  }

  // useEffect(() => {
  //   const fetchShipments = async () => {
  //     if (!orderId) return;
  //     try {
  //       setLoading(true);
  //       setError(null);
  //       const response = await middleware.get(`shipments/order?order_id=${orderId}`);
  //       setShipments(response.data.Response || []);
  //     } catch (error) {
  //       console.error('Error fetching shipments:', error);
  //       setError('Er is een fout opgetreden bij het ophalen van de verzendingen');
  //     } finally {
  //       setLoading(false);
  //     }
  //   };

  //   fetchShipments();
  // }, [orderId]);


  // You can later expand this section to show actual shipments
  // when the API is working properly
  return (
    <div className="whiteBox p-5 pb-0 pt-4 mt-4 align-center">
      <div className="d-flex justify-content-between align-items-center pb-4 creditering-header">
        <h5 className="mb-0 fw-bold">Verzending: #{orderId}</h5>
        <button className='lightGrayStandardButton2'><img style={{"height": "14px"}} className="me-2" src={PrinterIcon}/>Print verzendlabel</button>
      </div>

      {productsToProcess.length > 0 ? (
        <ul className='list-group list-group-flush mb-5'>
          {productsToProcess.map((item, index) => (
              <li key={index} className='verwerk-list-group-item bottom-border-line d-flex justify-content-between align-items-center gap-3 pb-2 pt-2'>
              <div className='d-flex flex-row gap-3 flex-grow-1'>
                <div className='d-flex align-items-center'>
                  {/* Product image */}
                  <img src={placeholder} className="rounded-circle p-2 me-3" height={100} width={100} alt="placeholder..." />

                  {/* Product info */}
                  <div className='d-flex flex-column justify-content-center align-items-start'>
                    <a href={`/producten/bewerken/${item.product_id}`} className='new-link-color'>{item.product_name}</a>
                    <p className='text-muted small mb-0'>Lorem ipsum dolor sit amet, consectetur adipiscing elit</p>
                  </div>
                </div>
              </div>

              {/* Product price info */}
              <div className='d-flex align-items-center justify-content-end gap-3 gap-xxl-5 flex-grow-1'>
                <span className='text-nowrap'>€{parseFloat(item.price_incl_tax).toFixed(2)}</span>
                <span className='text-nowrap'>x{item.amount}</span>
                <span className='text-nowrap'>-€{item.sale_amount}</span>
                <span className='fw-semibold'>€{((item.price_incl_tax - item.sale_amount) * item.amount).toFixed(2)}</span>
              </div>
            </li>
          ))}
        </ul>
      ) : (
        <div className="text-center p-3">
          <div className="text-center" role="status">
            <h5>There are no product to ship...</h5>
          </div>
        </div>
      )}
      <div className='d-flex justify-content-start pb-3 bottom-border-line'>
        <h5 className='fw-bold'> Pakket: #5412</h5>
      </div>
      <div className='shipment-status-info-container d-flex column align-center justify-content-between pt-4 pb-4'>
        <div className='row border-right-gray col-6 packaging-info w-50'>
          <div className='col-6'>
            <p>Track en trace: </p>
            <p>Datum aangemaakt: </p>
            <p>Methode: </p>
          </div>
          <div className='col-6 pe-0 d-flex flex-column align-items-end'>
            <p className='new-link-color text-decoration-underline' href='#'>3SMYPA686366824</p>
            <p>22 oktober 2024 16:11</p>
            <p>Pakket (MyParcel)</p>
          </div>
        </div>
        <div className='package-status-container w-50'>
          <div className='packaging-status'>
            <div className='package-status-icon' id='completed'>
              <img src={packageIcon}/>
            </div>
            <span>
              Aangemaakt
            </span>
          </div>
          <div className='packaging-status'>
            <div className='package-status-icon'>
              <img src={clipboardWithCheckIcon}/>
            </div>
            <span>
              Verzonden
            </span>
          </div>
          <div className='packaging-status'>
            <div className='package-status-icon'>
              <img src={homeIcon}/>
            </div>
            <span>
              Afgeleverd
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(VerzendComponent);