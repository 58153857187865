import React, { useEffect, useRef, useState } from 'react';
import PageTemplate from '../Templates/PageTemplate';
import { useNavigate, useParams } from 'react-router';
import productPic from "../WarehouseSettings/Icons/size.png"
import middleware from '../Api/Middleware';
import ToastWarning from '../Toasts/ToastWarning';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import countries from 'i18n-iso-countries';
import ToastError from '../Toasts/ToastError';
import MailIcon from './Icons/mail.png';
import PhoneIcon from './Icons/telephone.png';
import TruckIcon from './Icons/delivery-truck.png';
import PaperIcon from './Icons/paper-white.png';
import placeholder from './Icons/empty-placeholder.jpg';
import deliveryTruckBlue from './Icons/delivery-truck-blue.png';
import Backbutton from "../Products/subpages/images/BackIcon.svg";


countries.registerLocale(require("i18n-iso-countries/langs/nl.json"));

const CancelOrder = () => {
    const { order_number } = useParams();

    const navigate = useNavigate();
    const [pageIsLoading, setPageIsLoading] = useState(true);

    const [soldProducts, setSoldProducts] = useState([]);
    const [order, setOrder] = useState([]);
    const [productsToProcces, setProductsToProcces] = useState([]);
    const [productsToProccesFullyShown, setProductsToProccesFullyShown] = useState([]);
    const [proccessingProducts, setProccessingProducts] = useState([]);

    const fetchAll = async () => {
        const ordersResponse = await middleware.get(`orders?order_number=${order_number}`);
        if (!ordersResponse.data) {
            navigate(`/bestellingen`);
            return;
        }
        setPageIsLoading(false);
        setOrder(ordersResponse.data[0]);
        const soldProductsResponse = await middleware.get(`orders/soldproducts?order_id=${ordersResponse.data[0].order_id}`);
        const soldProductsData = soldProductsResponse.data
        setSoldProducts(soldProductsData);

        // If there are products that are not proccessed it adds them to the products to proccess. It also calculates how many products where bought in the order
        const addToProcces = [];
        const addToProccessingProducts = {};
        soldProductsData.map(soldProduct => {
            if (soldProduct.amount > soldProduct.items_processed) {
                addToProccessingProducts[soldProduct.sold_product_id] = (soldProduct.amount - soldProduct.items_processed);
                addToProcces.push(soldProduct);
            }
        });
        setProductsToProcces(addToProcces);
        setProccessingProducts(addToProccessingProducts);
    }

    useEffect(() => {
        fetchAll();
    }, [])

    return (
        <PageTemplate pageIsLoading={pageIsLoading} navbarTitle={"Order HUB"}>
            <div className='w-100'>
                <div className="d-flex flex-row mb-4 pb-3 pt-5 justify-content-between mx-4">
                    <div role='button' className="d-flex flex-row align-items-center cursorPointer" onClick={() => navigate("/bestellingen")}>
                        <img style={{ width: "32px" }} className="me-3" src={Backbutton} alt="Back" />
                        <p className="mb-0 fs-5">Annuleren bestelling #{order.order_number}</p>
                    </div>
                </div>
                <div className='row pe-3'>
                    <div className='d-flex flex-column col-xxl-8 col-12'>
                        <div className='whiteBox mb-4 me-2 p-4 pb-5'>
                            <h5 className='fw-semibold'>Niet verwerkt</h5>
                            <div className=''>
                                <div>
                                    <table className='w-100 border-0 mb-0'>
                                        <thead className='bottom-border-gray'>
                                            <tr className='ps-0'>
                                                <th className='ps-0'>Producten</th>
                                                <th className='text-center'>Aantal</th>
                                                <th className='text-center'>Restitutie bedrag</th>
                                                <th className='text-center'>Voorraad toevoegen</th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            {productsToProcces.map((productToProcces, index) => (
                                                <tr key={index} className='bottom-border-gray'>
                                                    <td className="text-start w-75 ps-0">
                                                        <div className='d-flex align-items-center linkColor'>
                                                            <div className='pe-4'>
                                                                <img src={placeholder} className="rounded-circle" height={80} width={80} alt="placeholder..." />
                                                            </div>
                                                            {productToProcces.product_name.length > 100 ? (
                                                                productsToProccesFullyShown[index] === undefined ? (
                                                                    <>
                                                                        <span className='linkColor'>
                                                                            {productToProcces.product_name.slice(0, 90)}
                                                                        </span>
                                                                        <span
                                                                            className="text-info hover text-nowrap ms-2"
                                                                            onClick={() => { }}>
                                                                            (...)
                                                                        </span>
                                                                    </>
                                                                ) : (
                                                                    <>
                                                                        <span className='linkColor'>
                                                                            {productToProcces.product_name}
                                                                        </span>
                                                                        <span
                                                                            className="text-info hover text-nowrap ms-2"
                                                                            onClick={() => { }}>
                                                                            Toon minder
                                                                        </span>
                                                                    </>
                                                                )
                                                            ) : (
                                                                productToProcces.product_name
                                                            )}
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className='d-flex justify-content-center'>
                                                            <input
                                                                className='stockInput standardInput fw-semibold px-2 py-2 text-center'
                                                                type='text'
                                                                value={proccessingProducts[productToProcces.sold_product_id] ?? ''}
                                                                pattern="[0-9]*"
                                                                onChange={(event) => { }}
                                                            />
                                                            <div className='stockInput3 standardInput py-2'>van 1</div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className='d-flex justify-content-center'>
                                                            <div className='standardInput squareInput packagesInput fit-content text-nowrap text-center py-2 px-2'>
                                                                € 62,10
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className='d-flex justify-content-center'>
                                                            <label class="checkbox-container">
                                                                <input type="checkbox" />
                                                                <span class="checkmark"></span>
                                                            </label>
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div className='whiteBox mb-4 me-2 p-4 pb-5'>
                            <h5 className='fw-semibold'>Verwerkt</h5>
                            <div className=''>
                                <div>
                                    <table className='w-100 border-0 mb-0'>
                                        <thead className='bottom-border-gray'>
                                            <tr>
                                                <th className='p-0'>Producten</th>
                                                <th className='text-center'>Aantal</th>
                                                <th className='text-center'>Restitutie bedrag</th>
                                                <th className='text-center'>Voorraad toevoegen</th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            {productsToProcces.map((productToProcces, index) => (
                                                <tr key={index} className='bottom-border-gray'>
                                                    <td className="text-start w-75 ps-0">
                                                        <div className='d-flex align-items-center linkColor'>
                                                            <div className='pe-4'>
                                                                <img src={placeholder} className="rounded-circle" height={80} width={80} alt="placeholder..." />
                                                            </div>
                                                            {productToProcces.product_name.length > 100 ? (
                                                                productsToProccesFullyShown[index] === undefined ? (
                                                                    <>
                                                                        <span className='linkColor'>
                                                                            {productToProcces.product_name.slice(0, 90)}
                                                                        </span>
                                                                        <span
                                                                            className="text-info hover text-nowrap ms-2"
                                                                            onClick={() => { }}>
                                                                            (...)
                                                                        </span>
                                                                    </>
                                                                ) : (
                                                                    <>
                                                                        <span className='linkColor'>
                                                                            {productToProcces.product_name}
                                                                        </span>
                                                                        <span
                                                                            className="text-info hover text-nowrap ms-2"
                                                                            onClick={() => { }}>
                                                                            Toon minder
                                                                        </span>
                                                                    </>
                                                                )
                                                            ) : (
                                                                productToProcces.product_name
                                                            )}
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className='d-flex justify-content-center'>
                                                            <input
                                                                className='stockInput standardInput fw-semibold px-2 py-2 text-center'
                                                                type='text'
                                                                value={proccessingProducts[productToProcces.sold_product_id] ?? ''}
                                                                pattern="[0-9]*"
                                                                onChange={(event) => { }}
                                                            />
                                                            <div className='stockInput3 standardInput py-2'>van 1</div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className='d-flex justify-content-center'>
                                                            <div className='standardInput squareInput packagesInput fit-content text-nowrap text-center py-2 px-2'>
                                                                € 62,10
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className='d-flex justify-content-center'>
                                                            <label class="checkbox-container">
                                                                <input type="checkbox" />
                                                                <span class="checkmark"></span>
                                                            </label>
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div className='whiteBox mb-4 me-2 p-4 h-100'>
                            <h5 className='fw-semibold'>Extras</h5>
                            <table className='w-100 border-0 mb-0'>
                                <thead className='bottom-border-gray'>
                                    <tr>
                                        <th className='p-0'>Producten</th>
                                        <th className='text-center'>Aantal</th>
                                        <th className='text-center'>Restitutie bedrag</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    <tr className='bottom-border-gray'>
                                        <td className="text-start w-75 ps-0">
                                            <div className='d-flex align-items-center linkColor'>
                                                <div className='pe-4'>
                                                    <img src={deliveryTruckBlue} className="p-2 ps-0 pe-5" height={80} alt="placeholder..." />
                                                    <span>Verzendkosten</span>
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <div className='d-flex justify-content-center'>
                                                <input
                                                    className='stockInput standardInput fw-semibold px-2 py-2 text-center'
                                                    type='text'
                                                    value={'0'}
                                                    pattern="[0-9]*"
                                                    onChange={() => {}}
                                                />
                                                <div className='stockInput3 standardInput py-2'>van 1</div>
                                            </div>
                                        </td>
                                        <td>
                                            <div className='d-flex justify-content-center'>
                                                <div className='standardInput squareInput w-100 packagesInput text-nowrap text-center py-2 mx-3'>
                                                    € 62,10
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className='whiteBox mb-4 me-2 p-4 h-100'>
                            <h5 className='fw-semibold pb-4'>Annuleringsreden</h5>
                            <input className='w-100 standardInput py-4' placeholder='Zoeken' />
                        </div>
                    </div>
                    <div className='d-flex col-xxl-4 col-7 h-100'>
                        <div className='whiteBox w-100 mb-4 me-3 p-4'>
                            <h5 className='fw-semibold pb-3'>Samenvatting</h5>
                            <div className='d-flex justify-content-between '>
                                <h6>
                                    Producten restitutie
                                </h6>
                                <h6>
                                    € 62,10
                                </h6>
                            </div>
                            <div className='d-flex justify-content-between align-items-center pb-4 bottom-border-gray'>
                                <h6 className='m-0'>
                                    Restitutiekosten
                                </h6>
                                <div className='d-flex align-items-center justify-content-between'>
                                    -
                                    <div className='standardInput ms-4 squareInput packagesInput text-nowrap text-center py-2 px-2'>
                                        € 62,10
                                    </div>
                                </div>
                            </div>
                            <div className='d-flex justify-content-between align-items-center pt-4 pb-4'>
                                <h6 className='m-0 fw-bold'>
                                    Totaal incl. BTW
                                </h6>
                                <h6 className='fw-bold'>
                                    € 62,10
                                </h6>
                            </div>
                            <div className='d-flex justify-content-start align-items-center pb-4'>
                                <label class="checkbox-container">
                                    <input type="checkbox" />
                                    <span class="checkmark"></span>
                                </label>
                                <h6 className='m-0 pt-1'>Alle producten aan voorraad toevoegen</h6>
                            </div>
                            <button className='lightBlueStandardButton'>Crediteren</button>
                        </div>
                    </div>
                </div>
            </div>
        </PageTemplate>
    );
}

export default CancelOrder;