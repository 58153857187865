import React from 'react';
import Modal from 'react-bootstrap/Modal';

const roleOptions = [
  { value: 0, label: "Alleen bestellingen" },
  { value: 1, label: "Medewerker" },
  { value: 2, label: "Leidinggevende" },
  { value: 3, label: "Manager" },
  { value: 4, label: "Admin" },
];

const RoleInfoModal = ({ show, onHide }) => (
  <Modal show={show} onHide={onHide}>
    <Modal.Header closeButton className="bg-white">
      <Modal.Title>Gebruikersrollen uitleg</Modal.Title>
    </Modal.Header>
    <Modal.Body className="bg-white">
      <div className="roles-overview">
        {roleOptions.map((role) => (
          <div key={role.value} className="role-card">
            <h5 className="role-title">{role.label}</h5>
            <p className="role-description">Een {role.label} kan overal bij en kan alles zien.</p>
          </div>
        ))}
      </div>
    </Modal.Body>
    <Modal.Footer className="bg-white">
      <button className="btn btn-outline-primary" onClick={onHide}>
        Ik heb het begrepen
      </button>
    </Modal.Footer>
  </Modal>
);

export default RoleInfoModal;
