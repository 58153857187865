import * as XLSX from 'xlsx';
import ToastError from '../../Toasts/ToastError';
const MAX_CELL_LENGTH = 32767;

const handleXLSXDownload = async (base64Data, fileName = 'data', delimiter = ';', isCSV = false) => {
    try {
        // Decode the base64 string and parse to JSON
        const decodedData = atob(base64Data);
        const jsonData = JSON.parse(decodedData);
      
        // Ensure jsonData is an array of objects
        if (!Array.isArray(jsonData)) {
            throw new Error('Invalid JSON data. Expected an array of objects.');
        }

        // Check each cell in the JSON data for length
        jsonData.forEach((row, rowIndex) => {
            for (const [column, cellValue] of Object.entries(row)) {
                if (typeof cellValue === 'string' && cellValue.length > MAX_CELL_LENGTH) {
                    ToastError("Een cell in het geëxporteerde bestand heeft een karakter lengte groter dan 32.767");
                    throw new Error(`Cell value exceeds the max length at row ${rowIndex + 1}, column "${column}". Length: ${cellValue.length}`);
                }
            }
        });

        // Convert JSON to worksheet
        const worksheet = XLSX.utils.json_to_sheet(jsonData, { skipHeader: true });
      
        // Create a new workbook and append the worksheet
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
      
        if (isCSV) {
            // Generate CSV output
            const csvOutput = XLSX.utils.sheet_to_csv(worksheet, { FS: delimiter });
            const csvBlob = new Blob([csvOutput], { type: 'text/csv' });
            const url = window.URL.createObjectURL(csvBlob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${fileName}.csv`);
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
            window.URL.revokeObjectURL(url);
        } else {
            // Generate XLSX output
            const workbookBlob = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
            const xlsxBlob = new Blob([workbookBlob], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            const url = window.URL.createObjectURL(xlsxBlob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${fileName}.xlsx`);
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
            window.URL.revokeObjectURL(url);
        }
    } catch (error) {
        console.error('Error:', error);
    }
};

export default handleXLSXDownload;
