import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { faPencil, faPlus, faX, faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import middleware from '../../../Api/Middleware';
import { useParams } from 'react-router';
import ToastError from '../../../Toasts/ToastError';

const RemovePointsModal = ({ openRemovePoints, closeRemovePoints, setUpdatedLoyalty }) => {
  const [showRemovePoints, setShowRemovePoints] = useState(false);
  const [points, setPoints] = useState("");
  const [reasons, setReasons] = useState([]);
  const [reason, setReason] = useState(0);
  const [newReason, setNewReason] = useState(null);
  const [editReason, setEditReason] = useState(null);
  const [addNewReason, setAddNewReason] = useState(false);
  const { internal_id } = useParams();
  const fetchAll = async () => {
    const reasonsResponse = await middleware.get('/loyaltypointreasons?type=1&base=1');
    setReasons(reasonsResponse.data);
  }

  useEffect(() => {
    if (openRemovePoints) {
      setShowRemovePoints(true)
    }
  }, [openRemovePoints]);

  const closeRemovePointsModal = () => {
    setShowRemovePoints(false);
    closeRemovePoints(true)
  }
  useEffect(() => {
    if (openRemovePoints) {
      fetchAll();

      setShowRemovePoints(true);
    }
  }, [openRemovePoints]);
  useEffect(() => {
    if (reason == 16 || reason == 17) {
      setAddNewReason(true);
    }
    else {
      setAddNewReason(false)
    }
  }, [reason])
  const submitRemovePoints = async (event) => {
    if (addNewReason && newReason == "") {
      ToastError("Vul een nieuwe reden in.")
      return;
    }
    if (points < 1) {
      ToastError("Je mag niet meer dan 1 miljoen punten verwijderen.")
      return;
    }
    if (points > 1000000) {
      ToastError("Je mag niet meer dan 1 miljoen punten verwijderen.")
      return;
    }
    try {
      if (event) {
        event.preventDefault();
      }
      // Convert points to a string with a plus sign
      const pointsWithMin = `-${points}`;
      if (addNewReason) {
        let res;
        res = await middleware.post('loyaltypointreasons', {
          reason: newReason,
          type: 1,
        })
        let reason_id = res.data.reason_id;
        await middleware.post("loyaltymutations", {
          internal_id: internal_id,
          amount: pointsWithMin, // Send points with a plus sign
          reason_id: reason_id,
        }).then(() => setUpdatedLoyalty(true) + closeRemovePointsModal());
      } else {
        await middleware.post("loyaltymutations", {
          internal_id: internal_id,
          amount: pointsWithMin, // Send points with a plus sign
          reason_id: reason,
        }).then(() => setUpdatedLoyalty(true) + closeRemovePointsModal());
      }
      setAddNewReason(false);
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <Modal
      id="modalBlueprint"
      show={showRemovePoints}
      onHide={() => closeRemovePointsModal()}
      className="colormodals"
      size="xl"
      centered
      backdrop="static"
    >
      <Modal.Header className="modalTop ps-5 pt-5 justify-content-between">
        <h3>Punten verwijderen</h3>
        <button variant="transparent" className=' btn' onClick={() => closeRemovePointsModal()}>
          <FontAwesomeIcon icon={faXmark} className="closeModal" />
        </button>
      </Modal.Header>
      <Modal.Body className="modalCenter" style={{ height: "300px" }}>
        <div className='col-12'>
          <div className='d-flex flex-column col-4 mb-3'>
            <label className='mb-2 fw-medium'>Punten verwijderen</label>
            <div className='d-flex align-items-center'>
              <input
                type='text'
                value={points}
                onChange={(e) => {
                  const reg = /^$|^[1-9][0-9]*$/;
                  const newValue = e.target.value;
                  if (reg.test(newValue)) {
                    setPoints(newValue);
                  }
                }}
                className='standardInput px-2'
              />
            </div>
          </div>
          <div className='d-flex flex-column col-12'>
            <label className='mb-2 fw-medium'>Reden
              <span className='ms-2 p-2' role='button'>
              </span>
            </label>
            <select value={reason} className='standardInput standardDropdownArrow px-2' onChange={(e) => setReason(e.target.value)}>
              <option value={0}>Selecteer een reden</option>
              {reasons && reasons.map((e, index) => (
                <option value={e.reason_id} key={index}>
                  {e.reason}
                </option>
              ))}
            </select>
            {addNewReason && (
              <input
                type='text'
                value={newReason}
                onChange={(e) => setNewReason(e.target.value)}
                className='standardInput px-2 mt-2'
                placeholder='Vul een reden in'
              />
            )}
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className="modalBottom">
        <button disabled={reason == 0 || (addNewReason && newReason == "") || points == ""} className='lightBlueStandardButton' onClick={(e) => submitRemovePoints(e)}>Opslaan</button>
      </Modal.Footer>
    </Modal>
  );
}


export default RemovePointsModal;
