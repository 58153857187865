import React from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import { Modal } from "react-bootstrap";
import { faXmark } from "@fortawesome/free-solid-svg-icons";

const CancelModalComponent = (props) => {
    const navigate = useNavigate();

    console.log(props);

    return (
    <Modal
        id="modalCancelOrder"
        show={props.cancelModalVisible}
        onHide={{}}
        className="colormodals"
        size="lg"
        centered
        backdrop="static"
    >
        <Modal.Header className="modalTop justify-content-between px-5" >
        <h3>Weet je zeker dat je deze order wil annuleren?</h3>
            <button variant="transparent" className=' btn' onClick={() => {props.setCancelModalVisible(false)}}>
                <FontAwesomeIcon icon={faXmark} className="closeModal" />
            </button>
        </Modal.Header>
        <Modal.Body className="modalCenter">
        </Modal.Body>
        <Modal.Footer className="modalBottom">
            <div className="d-flex justify-content-center" >
                <div className="buttonPresetStyle lightGrayStandardButton2 me-3" onClick={() => {navigate("/bestellingen/annuleren/" + props.orderId)}}>Annuleren en crediteren</div>
                <div className="buttonPresetStyle lightGrayStandardButton2 newButtons" onClick={() => {props.setCancelModalVisible(false)}}>Order behouden</div>
            </div>
        </Modal.Footer>
    </Modal>
  )
}

export default CancelModalComponent
