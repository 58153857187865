import React, { useState, useCallback, useRef, useEffect } from 'react';
import { debounce } from 'lodash';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TDSTextNavigation from "../../Templates/TDSTextNavigation";
import middleware from '../../Api/Middleware';
import AddCustomerModal from '../../Customer/AddCustomers/AddCustomerModal';
import AddCompanyModal from '../../Customer/AddCustomers/AddCompanyModal';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import MailIcon from '../Icons/mail.png'
import PhoneIcon from '../Icons/telephone.png'
import TruckIcon from '../Icons/delivery-truck.png'
import PaperIcon from '../Icons/paper-white.png'
const IconSquare = ({ icon }) => (
  <div
    className="d-flex align-items-center justify-content-center"
    style={{
      backgroundColor: '#009FE3',
      width: '36px',
      height: '36px',
      borderRadius: '8px',
      minWidth: '36px'
    }}
  >
    <FontAwesomeIcon icon={icon} className="text-white" />
  </div>
);

const CustomerComponent = ({
  mode = 'add',
  customer,
  emails = [],
  phoneNumbers = [],
  deliveryAddress,
  invoiceAddress,
  countries,
  onSelectCustomer
}) => {
  const navigate = useNavigate();
  const [isSearching, setIsSearching] = useState(mode === 'add' && !customer);
  const [searchTerm, setSearchTerm] = useState('');
  const [customers, setCustomers] = useState({ businesses: [], consumers: [] });
  const [isLoading, setIsLoading] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);

  const [showCustomerModal, setShowCustomerModal] = useState(false);
  const [showCompanyModal, setShowCompanyModal] = useState(false);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const debouncedSearch = useCallback(
    debounce(async (term) => {
      if (!term) {
        setCustomers({ businesses: [], consumers: [] });
        setIsDropdownOpen(false);
        return;
      }

      setIsLoading(true);
      try {
        const [businessResponse] = await Promise.all([
          middleware.get(`businesses?page=1&page_size=5&company_name=${term}&LIKE=true`),
        ]);

        setCustomers({
          businesses: businessResponse.data.data || [],
          consumers: []
        });
        setIsDropdownOpen(true);
      } catch (error) {
        console.error("Error fetching customers:", error);
      } finally {
        setIsLoading(false);
      }
    }, 300),
    []
  );

  const handleSearch = (e) => {
    const term = e.target.value;
    setSearchTerm(term);
    debouncedSearch(term);
  };

  const handleCustomerSelect = (selectedCustomer) => {
    if (onSelectCustomer) {
      onSelectCustomer(selectedCustomer);
    }
    setIsSearching(false);
    setSearchTerm('');
    setIsDropdownOpen(false);
  };

  const handleModalClose = () => {
    setShowCustomerModal(false);
    setShowCompanyModal(false);
  };

  const renderSearchView = () => (
    <div>
      <h5 className="mb-4 fw-bold">Klant</h5>

      {mode === 'add' && (
        <div className="d-flex gap-2 mb-4">
          <button
            className="darkBlueStandardButton"
            onClick={() => setShowCustomerModal(true)}
          >
            Nieuwe consument
          </button>
          <button
            className="lightBlueStandardButton"
            onClick={() => setShowCompanyModal(true)}
          >
            Nieuw bedrijf
          </button>
        </div>
      )}

      <div className="mb-3">
        <p className="mb-2">Klantnaam</p>
        <div className="position-relative" ref={dropdownRef}>
          <div className="position-relative">
            <input
              type="text"
              value={searchTerm}
              onChange={handleSearch}
              placeholder="Zoek klant op naam, e-mail etc."
              className="form-control rounded-pill"
              style={{
                padding: '10px 40px 10px 20px',
                border: '1px solid #E5E7EB'
              }}
            />
            <FontAwesomeIcon
              icon={faSearch}
              className="position-absolute top-50 end-0 translate-middle-y me-3 text-muted"
            />
          </div>

          {isDropdownOpen && (customers.businesses.length > 0 || isLoading) && (
            <div
              className="position-absolute w-100 bg-white border rounded mt-1 shadow-lg overflow-hidden"
              style={{
                zIndex: 1050,
                maxHeight: '300px',
                overflowY: 'auto'
              }}
            >
              {isLoading ? (
                <div className="text-center p-3">
                  <div className="spinner-border text-primary" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div>
                </div>
              ) : (
                customers.businesses.map((business, index) => (
                  <button
                    key={business.internal_id}
                    className="w-100 text-start px-3 py-2 border-0 bg-transparent"
                    onClick={() => handleCustomerSelect(business)}
                    style={{
                      borderBottom: index < customers.businesses.length - 1 ? '1px solid #E5E7EB' : 'none',
                      transition: 'background-color 0.2s'
                    }}
                    onMouseEnter={e => e.currentTarget.style.backgroundColor = '#F3F4F6'}
                    onMouseLeave={e => e.currentTarget.style.backgroundColor = 'transparent'}
                  >
                    <div className="fw-medium">{business.company_name}</div>
                    <div className="text-muted small">
                      {business.email || business.invoicemail || 'Geen email'}
                    </div>
                  </button>
                ))
              )}
            </div>
          )}
        </div>
      </div>

      {/* Add the modals when showCustomerModal is true */}
      {showCustomerModal && (
        <>
          <AddCustomerModal
            show={showCustomerModal}
            close={handleModalClose}
          />
          <AddCompanyModal
            show={showCompanyModal}
            close={handleModalClose}
          />
        </>
      )}
    </div>
  );

  const renderCustomerInfo = () => {
    if (!customer || !customer.internal_id) return renderSearchView();

    const customerName = customer.company_name ||
      (customer.first_name && customer.last_name ?
        `${customer.first_name} ${customer.last_name}` : "Geen naam");

    return (
      <div className='whiteBox p-2 d-flex flex-column h-100'>
        <h5 className='fw-bold mb-4'>
          Klant informatie
        </h5>
        <h5 className='linkColor pb-2'>
          Piet pieter
        </h5>
        <div className='d-flex w-100 flex-column pb-3'>
          <div className='d-flex align-items-center'>
            <div className='verwerkIcon d-flex align-items-center justify-content-center rounded-2' style={{ width: '30px', height: '30px' }}>
              <img className='' style={{width: "20px"}} src={MailIcon} />
            </div>
            <h6 className='ms-3 fw-semibold align-middle m-0'>pietjepiet@gmail.com</h6>
          </div>
        </div>
        <div className='d-flex w-100 flex-column pb-3'>
          <div className='d-flex align-items-center'>
            <div className='verwerkIcon d-flex align-items-center justify-content-center rounded-2' style={{ width: '30px', height: '30px' }}>
              <img className='' width={20} src={PhoneIcon} />
            </div>
            <h6 className='ms-3 fw-semibold align-middle m-0'>0678214521</h6>
          </div>
        </div>
        <h6 className='pb-2 fw-semibold'>
          Bestelling gemaakt door: webshop
        </h6>
        <div className='d-flex w-100 flex-column pb-3'>
          <div className='d-flex align-items-center'>
            <div className='align-self-start verwerkIcon d-flex align-items-center justify-content-center rounded-2' style={{ width: '30px', height: '30px' }}>
              <img width={25} src={TruckIcon} />
            </div>
            <div className='ms-3'>
              <p className='pt-1 mb-1 fw-semibold'>Verzendadres</p>
              <div className='p-0'>
                <p className='mb-0'>Piet pieter,</p>
                <p className='mb-0'>Appeltjestraat 1,</p>
                <p className='mb-0'>1234AB Hengelo, Nederland</p>
              </div>
            </div>
          </div>
        </div>
        <div className='d-flex w-100 flex-column pb-2'>
          <div className='d-flex align-items-center'>
            <div className='align-self-start verwerkIcon d-flex align-items-center justify-content-center rounded-2' style={{ width: '30px', height: '30px' }}>
              <img width={14} src={PaperIcon} />
            </div>
            <div className='ms-3'>
              <p className='pt-1 mb-1 fw-semibold'>Factuuradres</p>
              <div className='p-0'>
                <p className='m-0'>Piet pieter,</p>
                <p className='m-0'>Appeltjestraat 1,</p>
                <p className='m-0'>1234AB Hengelo, Nederland</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const shouldShowSearch = mode === 'add' && (!customer || !customer.internal_id) || isSearching;

  return (
    <div className="whiteBox p-4">
      {shouldShowSearch ? renderSearchView() : renderCustomerInfo()}
    </div>
  );
};

export default CustomerComponent;