import React from 'react';
import { Link } from 'react-router-dom';

const TDSTextNavigation = ({ text, link, extraClassNames, status = 1 }) => {
  return status ? (
    <Link
      to={link}
      className={`text-info hover text-decoration-none ${extraClassNames}`}
    >
      {text}
    </Link>
  ) : (
    <span className={`text-muted ${extraClassNames}`} style={{ cursor: 'not-allowed' }}>
      {text}
    </span>
  );
};

export default TDSTextNavigation;
