import React, { useState } from "react";
import PageTemplate from "../../../Templates/PageTemplate";
import middleware from "../../../Api/Middleware";
import { useNavigate } from "react-router";
import Key from "./Icons/key.svg";
import Eye from "./Icons/eye.svg";
import EyeClosed from "./Icons/eye-closed.svg";
import "../EditUsers/EditUsers.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import ToastSuccess from "../../../Toasts/ToastSuccess";
import ToastError from "../../../Toasts/ToastError";
import PasswordRequirementsModal from "../EditUsers/Modals/PasswordRequirementsModal";
import SecurityCodeModal from "../EditUsers/Modals/SecurityCodeModal";
import RoleInfoModal from "../EditUsers/Modals/RoleInfoModal";
import ToastInfo from "../../../Toasts/ToastInfo";

const roleOptions = [
  { value: 0, label: "Alleen bestellingen" },
  { value: 1, label: "Medewerker" },
  { value: 2, label: "Leidinggevende" },
  { value: 3, label: "Manager" },
  { value: 4, label: "Admin" },
];

const languageOptions = [
  { value: "NL", label: "Nederlands" },
  { value: "BE", label: "Belgisch" },
  { value: "DE", label: "Deutsch" },
  { value: "FR", label: "Français" },
];

const AddUser = ({ ownUserRole, ownUserId }) => {
  const navigate = useNavigate();
  const [updateUserValues, setUpdateUserValues] = useState({
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    role: "",
    language: "",
    security_code: "",
  });
  const [showPassword, setShowPassword] = useState(false);
  const [showSecurityCode, setShowSecurityCode] = useState(false);
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [fieldErrors, setFieldErrors] = useState({});
  const [modals, setModals] = useState({
    password: false,
    role: false,
    securityCode: false,
  });
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const passwordRegex =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

  const toggleModal = (modal) =>
    setModals((prev) => ({ ...prev, [modal]: !prev[modal] }));

  const validateEmail = (email) => {
    if (!emailRegex.test(email)) {
      setEmailError("Ongeldig e-mailadres.");
      setFieldErrors((prev) => ({ ...prev, email: true }));
    } else {
      setEmailError("");
      setFieldErrors((prev) => ({ ...prev, email: false }));
    }
  };

  const validatePassword = (password) => {
    if (!passwordRegex.test(password)) {
      setPasswordError(
        "Wachtwoord moet minimaal 8 tekens bevatten, inclusief hoofdletters, kleine letters, een cijfer en een speciaal teken."
      );
      setFieldErrors((prev) => ({ ...prev, password: true }));
    } else {
      setPasswordError("");
      setFieldErrors((prev) => ({ ...prev, password: false }));
    }
  };

  const hasRequiredFields = () => {
    const requiredFields = [
      "first_name",
      "last_name",
      "email",
      "password",
      "role",
    ];
    let allFieldsValid = true;
    let missingFields = [];

    requiredFields.forEach((field) => {
      if (!updateUserValues[field] || updateUserValues[field] === "") {
        setFieldErrors((prev) => ({ ...prev, [field]: true }));
        missingFields.push(field);
        allFieldsValid = false;
      } else {
        setFieldErrors((prev) => ({ ...prev, [field]: false }));
      }
    });

    if (emailError !== "") {
      allFieldsValid = false;
    }

    if (passwordError !== "") {
      allFieldsValid = false;
    }

    if (!allFieldsValid) {
      let errorMessage = "Vul alle verplichte velden correct in:\n";
      missingFields.forEach((field) => {
        const fieldName = getFieldLabel(field);
        errorMessage += `- ${fieldName} is verplicht.\n`;
      });
      if (emailError) errorMessage += `- ${emailError}\n`;
      if (passwordError) errorMessage += `- ${passwordError}\n`;
      ToastError(errorMessage);
    }

    return allFieldsValid;
  };

  const getFieldLabel = (field) => {
    const fieldLabels = {
      first_name: "Voornaam",
      last_name: "Achternaam",
      email: "E-mail",
      password: "Wachtwoord",
      role: "Rol",
    };
    return fieldLabels[field] || field;
  };

  const generateRandomPassword = async (event) => {
    event.preventDefault();

    const length = 15;
    const lowercase = "abcdefghijklmnopqrstuvwxyz";
    const uppercase = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    const digits = "0123456789";
    const specialChars = "!@#$%^&*";

    let password = [
      lowercase[Math.floor(Math.random() * lowercase.length)],
      uppercase[Math.floor(Math.random() * uppercase.length)],
      digits[Math.floor(Math.random() * digits.length)],
      specialChars[Math.floor(Math.random() * specialChars.length)],
    ];

    const allChars = lowercase + uppercase + digits + specialChars;
    while (password.length < length) {
      password.push(allChars[Math.floor(Math.random() * allChars.length)]);
    }

    password = password.sort(() => Math.random() - 0.5).join("");

    setUpdateUserValues({ ...updateUserValues, password });
    validatePassword(password);

    try {
      await navigator.clipboard.writeText(password);
      ToastInfo("Wachtwoord gekopieerd naar klembord", 1500);
    } catch (err) {
      console.error("Failed to copy password: ", err);
    }
  };

  const generateRandomSecurityCode = async (event) => {
    event.preventDefault();
    const length = 6;
    const charset = "0123456789";
    let code = "";

    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * charset.length);
      let char = charset[randomIndex];
      code += char;
    }

    setUpdateUserValues({ ...updateUserValues, security_code: code });

    try {
      await navigator.clipboard.writeText(code);
      ToastInfo("Beveiligingspin gekopieerd naar klembord", 1500);
    } catch (err) {
      console.error("Failed to copy security code: ", err);
    }
  };

  const addUser = async () => {
    if (hasRequiredFields()) {
      try {
        await middleware.post("users", updateUserValues);
        ToastSuccess("Gebruiker toegevoegd");
        navigate("/algemene/instellingen/gebruikers");
      } catch (error) {
        ToastError(error.message);
      }
    }
  };

  const changeUser = (column, event) => {
    const value = event.target.value;

    setUpdateUserValues({ ...updateUserValues, [column]: value });

    if (column === "email") validateEmail(value);
    if (column === "password") validatePassword(value);
    if (value !== "") {
      setFieldErrors((prev) => ({ ...prev, [column]: false }));
    }
  };

  return (
    <>
      <PasswordRequirementsModal
        show={modals.password}
        onHide={() => toggleModal("password")}
      />
      <SecurityCodeModal
        show={modals.securityCode}
        onHide={() => toggleModal("securityCode")}
      />
      <RoleInfoModal show={modals.role} onHide={() => toggleModal("role")} />

      <PageTemplate
        navbarTitle={"Gebruiker toevoegen"}
        pageIsLoading={false}
        backValue={"/algemene/instellingen/gebruikers"}
      >
        <form className="w-100">
          <div className="whiteBox parentBoxEditUser">
            <div className="m-3">
              <div className="m-3">
                <div className="d-flex col-12 border-bottom py-4">
                  <h5 className="titlesEditUsers inputTitle col-3 ps-3">
                    Volledige naam
                  </h5>
                  <input
                    className={`inputFieldEditUser mx-2 col-4 ps-2 ${
                      fieldErrors.first_name ? "error" : ""
                    }`}
                    type="text"
                    placeholder="Voornaam"
                    name="first_name"
                    onChange={(event) => changeUser("first_name", event)}
                    value={updateUserValues.first_name}
                  ></input>
                  <input
                    className={`inputFieldEditUser mx-3 ms-4 col-4 ps-2 ${
                      fieldErrors.last_name ? "error" : ""
                    }`}
                    type="text"
                    placeholder="Achternaam"
                    name="last_name"
                    onChange={(event) => changeUser("last_name", event)}
                    value={updateUserValues.last_name}
                  ></input>
                </div>
                <div className="d-flex col-12 border-bottom py-4">
                  <h5 className="titlesEditUsers inputTitle col-3 ps-3">
                    E-mail
                  </h5>
                  <div className="col-9 d-flex flex-column">
                    <div className="d-flex flex-row">
                      <input
                        className={`inputFieldEditUser ms-2 col-9 ps-2 ${
                          fieldErrors.email || emailError ? "error" : ""
                        }`}
                        type="email"
                        name="email"
                        onChange={(event) => changeUser("email", event)}
                        value={updateUserValues.email}
                        style={{
                          borderTopRightRadius: "0px",
                          borderBottomRightRadius: "0px",
                        }}
                      ></input>
                    </div>
                    <small className="ms-4 text-secondary">
                      Het is niet mogelijk om uw wachtwoord te herstellen met
                      systeem-e-mail.
                    </small>
                  </div>
                </div>
                <div className="d-flex col-12 border-bottom py-4">
                  <h5 className="titlesEditUsers inputTitle col-3 ps-3">
                    Wachtwoord{" "}
                    <FontAwesomeIcon
                      className="iconInfoStyle"
                      onClick={() => toggleModal("password")}
                      icon={faCircleInfo}
                    />
                  </h5>
                  <div className="col-9 d-flex">
                    <input
                      className={`inputFieldEditUser ps-2 ms-2 col-10 ${
                        fieldErrors.password || passwordError ? "error" : ""
                      }`}
                      type={showPassword ? "text" : "password"}
                      name="password"
                      onChange={(event) => changeUser("password", event)}
                      value={updateUserValues.password}
                      placeholder="Voer wachtwoord in"
                      style={{
                        borderTopRightRadius: "0px",
                        borderBottomRightRadius: "0px",
                      }}
                    ></input>
                    <button
                      className="btn btnPasswordShow"
                      onClick={(event) => {
                        event.preventDefault();
                        setShowPassword(!showPassword);
                      }}
                    >
                      <img
                        className="h-100"
                        src={showPassword ? Eye : EyeClosed}
                      />
                    </button>
                    <button
                      className="btn btnEditUser"
                      onClick={generateRandomPassword}
                    >
                      <img className="h-100" src={Key} />
                    </button>
                  </div>
                </div>
                <div className="d-flex col-12 border-bottom py-4">
                  <h5 className="titlesEditUsers inputTitle col-3 ps-3">
                    Beveiligingspin instellen{" "}
                    <FontAwesomeIcon
                      className="iconInfoStyle"
                      onClick={() => toggleModal("securityCode")}
                      icon={faCircleInfo}
                    />
                  </h5>
                  <div className="col-9 d-flex">
                    <input
                      className="inputFieldEditUser ps-2 ms-2 col-10"
                      type={showSecurityCode ? "text" : "password"}
                      name="security_code"
                      value={updateUserValues.security_code}
                      onChange={(event) => changeUser("security_code", event)}
                      placeholder="Beveiligingspin instellen"
                      style={{
                        borderTopRightRadius: "0px",
                        borderBottomRightRadius: "0px",
                      }}
                    ></input>
                    <button
                      className="btn btnPasswordShow"
                      onClick={(event) => {
                        event.preventDefault();
                        setShowSecurityCode(!showSecurityCode);
                      }}
                    >
                      <img
                        className="h-100"
                        src={showSecurityCode ? Eye : EyeClosed}
                      />
                    </button>
                    <button
                      className="btn btnEditUser"
                      onClick={generateRandomSecurityCode}
                    >
                      <img className="h-100" src={Key} />
                    </button>
                  </div>
                </div>
                <div className="d-flex col-12 border-bottom py-4">
                  <h5 className="titlesEditUsers inputTitle mb-0 col-3 ps-3">
                    Taal
                  </h5>
                  <div className="col-9 d-flex flex-column">
                    <div className="d-flex flex-row">
                      <select
                        className="inputFieldEditUser ps-2 ms-2 col-11"
                        name="language"
                        onChange={(event) => changeUser("language", event)}
                        value={updateUserValues.language}
                      >
                        <option value="">Selecteer taal</option>
                        {languageOptions.map((language, index) => (
                          <option key={index} value={language.value}>
                            {language.label}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="d-flex col-12 border-bottom py-4">
                  <h5 className="titlesEditUsers inputTitle mb-0 col-3 ps-3">
                    Rol{" "}
                    <FontAwesomeIcon
                      className="iconInfoStyle"
                      onClick={() => toggleModal("role")}
                      icon={faCircleInfo}
                    />
                  </h5>
                  <div className="col-9 d-flex flex-column">
                    <div className="d-flex flex-column">
                      <div className="d-flex flex-column">
                        <select
                          className={`inputFieldEditUser ps-2 ms-2 col-11 ${
                            fieldErrors.role ? "error" : ""
                          }`}
                          value={updateUserValues.role}
                          onChange={(event) => changeUser("role", event)}
                        >
                          <option value="">Selecteer rol</option>
                          {roleOptions.map((role, index) => (
                            <option key={index} value={role.value}>
                              {role.label}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="d-flex col-12 justify-content-center align-items-center py-4">
                  <button
                    className="btn btn-primary rounded-pill"
                    onClick={(event) => {
                      event.preventDefault();
                      addUser();
                    }}
                  >
                    Gebruiker toevoegen
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </PageTemplate>
    </>
  );
};

export default AddUser;