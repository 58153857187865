import React, { useEffect, useRef, useState } from 'react';
import PageTemplate from '../Templates/PageTemplate';
import { useNavigate, useParams } from 'react-router';
import productPic from "../WarehouseSettings/Icons/size.png"
import middleware from '../Api/Middleware';
import ToastWarning from '../Toasts/ToastWarning';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import countries from 'i18n-iso-countries';
import ToastError from '../Toasts/ToastError';
import MailIcon from './Icons/mail.png';
import PhoneIcon from './Icons/telephone.png';
import TruckIcon from './Icons/delivery-truck.png';
import PaperIcon from './Icons/paper-white.png';
import placeholder from './Icons/empty-placeholder.jpg';
import Backbutton from "../Products/subpages/images/BackIcon.svg";
countries.registerLocale(require("i18n-iso-countries/langs/nl.json"));

const ProccessOrders = () => {
  const { order_number } = useParams();

  const navigate = useNavigate();
  const [pageIsLoading, setPageIsLoading] = useState(true);

  const [order, setOrder] = useState([]);
  const [soldProducts, setSoldProducts] = useState([]);
  const [productsToProcces, setProductsToProcces] = useState([]);
  const [productsToProccesFullyShown, setProductsToProccesFullyShown] = useState([]);
  const [proccessingProducts, setProccessingProducts] = useState([]);
  const [makeNewPackage, setMakeNewPackage] = useState(true);
  const [shippingMethod, setShippingMethod] = useState('');
  const [packageAmount, setPackageAmount] = useState(1);
  const [deliveryAddress, setDeliveryAddress] = useState(null);
  const [invoiceAddress, setInvoiceAddress] = useState(null);

  useEffect(() => {
    (async () => {
      try {
        const ordersResponse = await middleware.get(`orders?order_number=${order_number}`);
        if (!ordersResponse.data) {
          navigate(`/bestellingen`);
          return;
        }
        const order = ordersResponse.data[0];
        setOrder(order);

        const addressResponse = await middleware.get(`addresses?internal_id=${order.internal_id}`);
        if (addressResponse.data.length > 0) {
          const countryCodesResponse = await middleware.get(`countryCodes`);
          addressResponse.data.map((address) => {
            address.country = countryCodesResponse.data.find((countryCode) => countryCode.country_code_id === address.country_code_id).country_code_2;
            address.address_kind === 0 || address.address_kind === 2 ? setDeliveryAddress(address) : setInvoiceAddress(address);
          });
        }

        const soldProductsResponse = await middleware.get(`orders/soldproducts?order_id=${order.order_id}`);
        const soldProductsData = soldProductsResponse.data
        setSoldProducts(soldProductsData);

        // If there are products that are not proccessed it adds them to the products to proccess. It also calculates how many products where bought in the order
        const addToProcces = [];
        const addToProccessingProducts = {};
        soldProductsData.map(soldProduct => {
          if (soldProduct.amount > soldProduct.items_processed) {
            addToProccessingProducts[soldProduct.sold_product_id] = (soldProduct.amount - soldProduct.items_processed);
            addToProcces.push(soldProduct);
          }
        });
        setProductsToProcces(addToProcces);
        setProccessingProducts(addToProccessingProducts);

        // If everything has been proccessed you can't access this page
        if (Object.values(addToProccessingProducts).length === 0) {
          ToastWarning(`Alles is al verwerkt`);
          navigate(`/bestellingen/bewerken/${order_number}`);
        }

        setPageIsLoading(false);
      } catch (error) {
        console.error(error);
      }
    })();
  }, []);

  const changeProductProccessingAmount = (event, productToProcces) => {
    let value = event.target.value;
    if (value === '') {
      value = 0;
    }

    value = parseInt(value, 10);
    const max = productToProcces.amount - productToProcces.items_processed;

    if (value > max) {
      setProccessingProducts({ ...proccessingProducts, [productToProcces.sold_product_id]: max });
    } else {
      setProccessingProducts({ ...proccessingProducts, [productToProcces.sold_product_id]: value });
    }
  }

  const proccessProducts = async (event) => {
    event.preventDefault();
    console.log('Form submitted'); // Debugging statement

    try {
      console.log(!makeNewPackage);
      if (!makeNewPackage) {
        const soldProductsToUpdate = [];
        productsToProcces.forEach(productToProcces => {
          const selectedProcessingAmount = proccessingProducts[productToProcces.sold_product_id];
          if (selectedProcessingAmount > 0) {
            soldProductsToUpdate.push({
              sold_product_id: productToProcces.sold_product_id,
              items_processed: productToProcces.items_processed + selectedProcessingAmount
            });
          }
        });

        if (soldProductsToUpdate.length === 0) {
          ToastError(`Er zijn geen producten geselecteerd om te verwerken`);
          return;
        }

        const ressoldproducts = await middleware.put(`orders/soldproducts`, {
          bulk_data: soldProductsToUpdate
        });

        console.log(ressoldproducts);

        const shipmentsResponse = await middleware.post(`shipments`, {
          order_id: order.order_id
        });

        console.log(productsToProcces);

        const shipmentSoldProducts = [];
        productsToProcces.forEach(productToProcces => {
          const selectedProcessingAmount = proccessingProducts[productToProcces.sold_product_id];
          console.log(selectedProcessingAmount);
          if (selectedProcessingAmount > 0) {
            shipmentSoldProducts.push({
              shipment_id: shipmentsResponse.data.shipment_id,
              order_sold_product_id: productToProcces.sold_product_id,
              product_name: productToProcces.product_name,
              amount: selectedProcessingAmount,
              price_incl_tax: productToProcces.price_incl_tax,
              price_excl_tax: productToProcces.price_excl_tax,
              product_number: productToProcces.product_number,
              product_description: productToProcces.product_description,
              btw_rate: productToProcces.btw_rate,
              internal_id: productToProcces.internal_id,
              sold_at: productToProcces.sold_at,
              temperature: productToProcces.temperature,
              sale_amount: productToProcces.sale_amount,
              sale_unit: productToProcces.sale_unit,
              internal_id: order.internal_id,
            });
          }
        });

        const res = await middleware.post(`shipments/soldProducts`, {
          bulk_data: shipmentSoldProducts
        });
        console.log(res);

        navigate(`/bestellingen/bewerken/${order_number}`);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <PageTemplate pageIsLoading={pageIsLoading} navbarTitle={"Order HUB"}>
      <div className='w-100'>
        <div className="d-flex flex-row mb-4 pb-3 pt-5 justify-content-between mx-4">
          <div role='button' className="d-flex flex-row align-items-center cursorPointer" onClick={() => navigate("/bestellingen")}>
            <img style={{ width: "32px" }} className="me-3" src={Backbutton} alt="Back" />
            <p className="mb-0 fw-semibold fs-5">Verwerk bestelling #{order.order_number}</p>
          </div>
        </div>
        <div className="d-flex justify-content-between parentBoxes w-100 row">
          <div className='proccessOrdersContainer d-flex flex-column col-8'>
            <div className='me-3 mb-3'>
              {soldProducts.length > 0 ? (
                <div className="bg-white rounded-4 p-4 mb-3">
                  <div>
                    <h5 className='fw-bold'>Items om te verwerken</h5>
                  </div>

                  <div>
                    <table className='w-100 border-0 mb-0'>
                      <thead className='bottom-border-gray'>
                        <tr>
                          <th>Producten</th>
                          <th>Aantal</th>
                        </tr>
                      </thead>

                      <tbody>
                        {productsToProcces.map((productToProcces, index) => (
                          <tr key={index} className='bottom-border-gray'>
                            <td className="text-start  w-75">
                              <div className='d-flex align-items-center linkColor'>
                                <div className='px-4 py-2'>
                                  <img src={placeholder} className="rounded-circle p-2" height={100} width={100} alt="placeholder..." />
                                </div>
                                {productToProcces.product_name.length > 100 ? (
                                  productsToProccesFullyShown[index] === undefined ? (
                                    <>
                                      <span className='linkColor'>
                                        {productToProcces.product_name.slice(0, 90)}
                                      </span>
                                      <span
                                        className="text-info hover text-nowrap ms-2"
                                        onClick={() => setProductsToProccesFullyShown([...productsToProccesFullyShown, index])}>
                                        (...)
                                      </span>
                                    </>
                                  ) : (
                                    <>
                                      <span className='linkColor'>
                                        {productToProcces.product_name}
                                      </span>
                                      <span
                                        className="text-info hover text-nowrap ms-2"
                                        onClick={() => setProductsToProccesFullyShown(productsToProccesFullyShown.filter(i => i !== index))}>
                                        Toon minder
                                      </span>
                                    </>
                                  )
                                ) : (
                                  productToProcces.product_name
                                )}
                              </div>
                            </td>
                            <td>
                              <div className='d-flex'>
                                <input
                                  className='stockInput standardInput fw-semibold'
                                  type='text'
                                  value={proccessingProducts[productToProcces.sold_product_id] ?? ''}
                                  pattern="[0-9]*"
                                  onChange={(event) => changeProductProccessingAmount(event, productToProcces)}
                                />
                                <div className='stockInput2 standardInput'>van 1</div>
                                <div className='stockInput3 standardInput'>3 op voorraad</div>

                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              ) : (
                <div className="bg-white rounded-4 p-4 mb-3">
                  <h1>Deze bestelling heeft geen verkochte producten</h1>
                </div>
              )}
            </div>

            <div className='me-3'>
              <div className='bg-white rounded-4 p-4 mb-3 px-4'>
                <div className='mb-3 d-flex justify-content-between'>
                  <h5 className='fw-semibold'>
                    Pakketlabel aanmaken
                  </h5 >
                  <label className="switchNewCustomer me-2">
                    <input type="checkbox" defaultChecked onClick={() => setMakeNewPackage(!makeNewPackage)} />
                    <span className="sliderNewCustomer  round"></span>
                  </label>
                </div>
                <div className='row'>
                  <div className='d-flex flex-column pb-3 col-4'>
                    <div className='d-flex align-items-center'>
                      <div className=''>
                        <p className='pt-2 mb-1 fw-semibold'>Verzendadres</p>
                        <div className='p-0'>
                          <p className='mb-0'>Piet pieter,</p>
                          <p className='mb-0'>Appeltjestraat 1,</p>
                          <p className='mb-0'>1234AB Hengelo, Nederland</p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <form className='col-4' onSubmit={proccessProducts}>
                    {makeNewPackage ? (
                      <>
                        <div className=''>
                          <p className='fw-semibold pt-2 mb-1'>Pakket provider</p>
                          <select className='standardInput sendInput fw-semibold px-4 pe-5 standardDropdownArrow' value={shippingMethod ?? ''} onChange={event => setShippingMethod(event.target.value)} required>
                            <option value={''}>Selecteer een verzendmethode</option>
                            <option value={'0'}>Afhalen</option>
                            <option value={'1'}>Eigen bezorging</option>
                          </select>
                        </div>
                      </>
                    ) : (
                      <div className='mb-3 alert alert-info'>
                        <p>
                          <FontAwesomeIcon className='text-info' icon={faCircleInfo} /> {' '}
                          Er wordt nu geen pakket aangemaakt, maar je kunt later altijd pakket toevoegen.
                        </p>
                      </div>
                    )}
                  </form>
                  <div className='col-4 px-2'>
                    <div className='d-flex flex-column ms-5'>
                      <p className='pt-2 mb-1 d-flex fw-semibold justify-content-center' >Aantal pakketten</p>
                      <div className='d-flex pb-2 justify-content-center'>
                        <input
                          type='number'
                          className='standardInput packagesInput'
                          min={1}
                          required
                          value={packageAmount}
                          onChange={event => setPackageAmount(event.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='whiteBox col-4 px-4 p-4 d-flex flex-column h-100'>
            <h5 className='fw-bold mb-4'>
              Klant informatie
            </h5>
            <h5 className='linkColor pb-2'>
              Piet pieter
            </h5>
            <div className='d-flex w-100 flex-column pb-3'>
              <div className='d-flex align-items-center'>
                <div className='verwerkIcon d-flex align-items-center justify-content-center rounded-3' style={{ width: '40px', height: '40px' }}>
                  <img className='' width={25} src={MailIcon} />
                </div>
                <h6 className='ms-3 fw-semibold align-middle m-0'>pietjepiet@gmail.com</h6>
              </div>
            </div>
            <div className='d-flex w-100 flex-column pb-3'>
              <div className='d-flex align-items-center'>
                <div className='verwerkIcon d-flex align-items-center justify-content-center rounded-3' style={{ width: '40px', height: '40px' }}>
                  <img className='' width={25} src={PhoneIcon} />
                </div>
                <h6 className='ms-3 fw-semibold align-middle m-0'>0678214521</h6>
              </div>
            </div>
            <h6 className='pb-2 fw-semibold'>
              Bestelling gemaakt door: webshop
            </h6>
            <div className='d-flex w-100 flex-column pb-3'>
              <div className='d-flex align-items-center'>
                <div className='align-self-start verwerkIcon d-flex align-items-center justify-content-center rounded-3' style={{ width: '40px', height: '40px' }}>
                  <img width={25} src={TruckIcon} />
                </div>
                <div className='ms-3'>
                  <p className='pt-2 mb-1 fw-semibold'>Verzendadres</p>
                  <div className='p-0'>
                    <p className='mb-0'>Piet pieter,</p>
                    <p className='mb-0'>Appeltjestraat 1,</p>
                    <p className='mb-0'>1234AB Hengelo, Nederland</p>
                  </div>
                </div>
              </div>
            </div>
            <div className='d-flex w-100 flex-column pb-3'>
              <div className='d-flex align-items-center'>
                <div className='align-self-start verwerkIcon d-flex align-items-center justify-content-center rounded-3' style={{ width: '40px', height: '40px' }}>
                  <img width={15} src={PaperIcon} />
                </div>
                <div className='ms-3'>
                  <p className='pt-2 mb-1 fw-semibold'>Factuuradres</p>
                  <div className='p-0'>
                    <p className='mb-0'>Piet pieter,</p>
                    <p className='mb-0'>Appeltjestraat 1,</p>
                    <p className='mb-0'>1234AB Hengelo, Nederland</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </PageTemplate>
  );
}

export default ProccessOrders;