import React from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import { Modal } from "react-bootstrap";
import { faXmark } from "@fortawesome/free-solid-svg-icons";

const TerugbetalenModalComponent = (props) => {
    const navigate = useNavigate();

    return (
        <Modal
            id="modalBlueprint"
            show={props.paybackModalVisible}
            onHide={{}}
            className="colormodals"
            size="lg"
            centered
            backdrop="static"
        >
            <Modal.Header className="modalTop justify-content-between px-5" >
                <h3 className='fw-semibold'>Terugbetalen</h3>
                <button variant="transparent" className=' btn' onClick={() => { props.setPaybackModalVisible(false) }}>
                    <FontAwesomeIcon icon={faXmark} className="closeModal" />
                </button>
            </Modal.Header>
            <Modal.Body className="modalCenter">
            </Modal.Body>
            <Modal.Footer className="modalBottom px-5">
                <div className='d-flex flex-column'>
                    <div className='d-flex justify-content-between bottom-line-overzicht mb-4'>
                        <h6 className='fw-semibold'>Totaal bedrag</h6>

                        <h6>€ 62,10</h6>
                    </div>
                    <div className='d-flex justify-content-between bottom-line-overzicht align-items-center mb-4'>
                        <h6  className='fw-semibold'>Betaal methode</h6>
                        <select class="form-select mb-3 rounded-pill w-50">
                            <option>Selecteer een betaalmethode</option>
                            <option value="0">geannuleerd</option>
                            <option value="2">nieuw</option>
                            <option value="3">voltooid</option></select>
                    </div>
                    <div className="d-flex justify-content-end" >
                        <div className="buttonPresetStyle me-3" onClick={() => { props.setPaybackModalVisible(false) }}>Annuleren</div>
                        <div className="buttonPresetStyle lightGrayStandardButton2 newButtons">Terugbetalen</div>
                    </div>
                </div>
            </Modal.Footer>
        </Modal>
    )
}

export default TerugbetalenModalComponent;
