import React, { useEffect, useState } from "react";
import "./components/Main/Darkmode.css"
import "./components/Main/Network.css"
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Cookies from 'universal-cookie';
import MainPage from "./components/MainPage/MainPage";
import AddSimple from "./components/Products/subpages/add/AddSimple";
import AddVariation from "./components/Products/subpages/add/AddVariation";
import EditPage from "./components/Products/subpages/edit/EditPage";
import Orders from "./components/Orders/Orders";
import OrdersEdit from "./components/Orders/OrdersEdit";
import OrdersNew from "./components/Orders/OrdersNew";
import Login from "./components/Login/Login";
import NotFound from "./components/NotFound/NotFound";
import Products from "./components/Products/Products";
import WarehouseSettings from "./components/WarehouseSettings/WarehouseSettings";
import ColorSettings from "./components/ColorSettings/ColorSettings";
import Blueprints from "./components/Blueprints/Blueprints";
import EditBlueprints from "./components/Blueprints/EditBlueprints/EditBlueprints";
import Brands from "./components/Brands/Brands";
import BrandsEdit from "./components/Brands/BrandsEdit/BrandsEdit";
import Categories from "./components/Categories/Categories";
import GeneralSettings from "./components/GeneralSettings/GeneralSettings";
import Users from "./components/GeneralSettings/Users/Users";
import EditUsers from "./components/GeneralSettings/Users/EditUsers/EditUsers";
import { ToastContainer } from 'react-toastify';
import CategoryEdit from "./components/Categories/CategoryEdit/CategoryEdit";
import AddUsers from "./components/GeneralSettings/Users/AddUsers/AddUser";
import ProccessOrders from "./components/Orders/ProccessOrders";
import Customer from "./components/Customer/Customer";
import EditCustomers from "./components/Customer/EditCustomers/EditCustomers";
import PaymentProviders from "./components/Payments/PaymentProviders";
import Provider from "./components/Payments/Provider";
import ProviderDetails from "./components/Payments/ProviderDetails";
import ProviderOnboarding from "./components/Payments/ProviderOnboarding";
import ForgotPassword from "./components/Login/pages/ForgotPassword";
import ResetPassword from "./components/Login/pages/ResetPassword";
import CatEdit from "./components/CategoryNew/CategoryEditen/CatEdit";
import CatAdd from "./components/CategoryNew/CategoryAdd/CatAdd";
import Labels from "./components/Labels/Labels";
import NetworkEventListener from "./components/Events/NetworkEventListener";
import UnauthorizedEventListener from "./components/Events/UnauthorizedEventListener";
import ImportExportHome from './components/Import/importExportHome';
import ExportPage from './components/Import/exportPage';
import ExportInfoPage from './components/Import/exportInfoPage';
import ImportPage from './components/Import/importPage';
import ColumnConfigration from './components/Import/columnConfigurationPage';
import Results from './components/Import/resultPage';
import middleware from "./components/Api/Middleware";
import NoAccesScreen from "./components/LoadingScreen/NoAccesScreen"
import LoadingScreen from "./components/LoadingScreen/LoadingScreen";
import Shipping from "./components/Shipping/Shipping";
import Packages from "./components/Shipping/Packages";
import EditProfile from "./components/GeneralSettings/EditProfile";
import CancelOrder from "./components/Orders/CancelOrder";
import AnimatedLine from "./components/MainPage/line/AnimatedLineWithDoubleBump";

function App() {
  const cookies = new Cookies();
  const [csvData, setCsvData] = useState([]);
  const [downloadfiles, setDownloadFiles] = useState([]);
  const [selectedTable, setSelectedTable] = useState('');
  const [importResponse, setImportResponse] = useState(() => {
    const saved = localStorage.getItem("importResponse");
    return saved ? JSON.parse(saved) : [];
  });
  const [navInfo, setNavInfo] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      const token = cookies.get('token');
      if (!token) {
        console.error('No token found. Cannot fetch navigation details.');
        setNavInfo(null);
        setLoading(false); // Add this here to ensure it stops loading even without a token
        return;
      }
  
      const cleanupNavInfo = () => {
        const currentToken = token;
        const keysToRemove = [];
        for (let i = 0; i < localStorage.length; i++) {
          const key = localStorage.key(i);
          if (key && key.startsWith('navInfo[') && key.endsWith(']')) {
            const storedToken = key.slice('navInfo['.length, -1);
            if (storedToken !== currentToken) {
              keysToRemove.push(key);
            }
          }
        }
        keysToRemove.forEach((key) => localStorage.removeItem(key));
      };
  
      cleanupNavInfo();
  
      if (!navInfo) {
        try {
          const response = await middleware.get('navInfo');
          const data = response.data;
  
          localStorage.setItem(`navInfo[${token}]`, JSON.stringify(data));
          setNavInfo(data);
        } catch (error) {
          console.error('Error fetching navigation details:', error);
          setNavInfo(null);
        } finally {
          setLoading(false); // Ensure loading stops here, regardless of success or failure
        }
      }
    };
  
    fetchData();
  }, []);


  return (
    <BrowserRouter>
      <NetworkEventListener />
      <UnauthorizedEventListener />
      <ToastContainer />
      <Routes>
        <Route path="/" element={<MainPage />}></Route>
        <Route path="/login" element={<Login />}></Route>
        <Route path="/wachtwoord-vergeten" element={<ForgotPassword />}></Route>
        <Route path="/wachtwoord-resetten" element={<ResetPassword />}></Route>
        <Route path="/*" element={<NotFound />}></Route>

        <Route path="/klanten" element={<Customer />}></Route>
        <Route path="/klanten/bewerken/:internal_id" element={<EditCustomers />}></Route>

        <Route path="/producten" element={<Products />}></Route>
        <Route path="/producten/alles" element={<Products />}></Route>
        <Route path="/producten/nieuw" element={<AddSimple />}></Route>
        <Route path="/producten/nieuw/variatie" element={<AddVariation />}></Route>
        <Route path="/producten/bewerken/:product_id" element={<EditPage />}></Route>

        <Route path="/algemene/instellingen" element={!loading ? navInfo?.role === 4 ? <GeneralSettings /> : <NoAccesScreen /> : <LoadingScreen/>}></Route>
        <Route path="/algemene/instellingen/gebruikers" element={!loading ? navInfo?.role === 4 ? <Users /> : <NoAccesScreen /> : <LoadingScreen />}></Route>
        <Route path="/algemene/instellingen/gebruikers/aanmaken" element={!loading ? navInfo?.role === 4 ? <AddUsers /> : <NoAccesScreen /> : <LoadingScreen/>}></Route>
        <Route path="/algemene/instellingen/gebruikers/bewerken/:user_id" element={!loading ? navInfo?.role === 4 ? <EditUsers /> : <NoAccesScreen /> : <LoadingScreen/>}></Route>

        <Route path="/profiel" element={!loading ? <EditProfile /> : <LoadingScreen />} />


        <Route path="/warehouse/instellingen" element={<WarehouseSettings />}></Route>
        <Route path="/warehouse/instellingen/kleuren" element={<ColorSettings />}></Route>
        <Route path="/warehouse/instellingen/blauwdrukken" element={<Blueprints />}></Route>
        <Route path="/warehouse/instellingen/blauwdrukken/bewerken" element={<EditBlueprints />}></Route>
        <Route path="/warehouse/instellingen/merken" element={<Brands />}></Route>
        <Route path="/warehouse/instellingen/merken/bewerken/:brand_id" element={<BrandsEdit />}></Route>
        {/* <Route path="/warehouse/instellingen/categoriën" element={<Category />}></Route> */}
        {/* <Route path="/warehouse/instellingen/categorieën/bewerken/:primary_key" element={<CategoryEdit />}></Route> */}
        <Route path="/warehouse/instellingen/categorieën" element={<Categories />}></Route>
        <Route path="/warehouse/instellingen/categorieën/bewerken/:primary_key" element={<CatEdit />}></Route>
        <Route path="/warehouse/instellingen/categorieën/toevoegen" element={<CatAdd />}></Route>
        <Route path="/warehouse/instellingen/labels" element={<Labels />}></Route>

        <Route path="/bestellingen" element={<Orders />}></Route>
        <Route path="/bestellingen/nieuw" element={<OrdersNew />}></Route>
        <Route path="/bestellingen/bewerken/:order_number" element={<OrdersEdit />}></Route>
        <Route path="/bestellingen/annuleren/:order_number" element={<CancelOrder />}></Route>
        <Route path="/bestellingen/verwerken/:order_number" element={<ProccessOrders />}></Route>

        <Route path="/verzendingen" element={<Shipping />}></Route>
        <Route path="/verzendingen/:shipment_id" element={<Packages />}></Route>

        <Route path="/betalingproviders" element={<PaymentProviders />}></Route>
        <Route path="/betalingproviders/details" element={<ProviderDetails />}></Route>
        <Route path="/betalingproviders/onboarding" element={<ProviderOnboarding />}></Route>
        <Route path="/provider" element={<Provider />}></Route>

        <Route path="/import-export-center" element={<ImportExportHome/>}></Route>
        <Route path="/export" element={<ExportPage/>}></Route>
        <Route path="/export/resultaat/:export_id" element={<ExportInfoPage/>}></Route>
        <Route path="/import" element={<ImportPage setCsvData={setCsvData} setDownloadFiles={setDownloadFiles} setSelectedTable={setSelectedTable} selectedTable={selectedTable}/>}></Route>
        <Route path="/import/kolom-configuratie" element={<ColumnConfigration csvData={csvData} downloadfiles={downloadfiles} selectedTable={selectedTable} importResponse={importResponse} setImportResponse={setImportResponse}/>}></Route>
        <Route path="/import/resultaat/:import_id" element={<Results/>}></Route>
        
      </Routes>
    </BrowserRouter>
  );
}

export default App;