import React from 'react'

const CheckboxHeader = ({currentItem, selectedIds, setSelectedIds, pk}) => {
  return (
    <label className="checkbox-container">
      <input
        type="checkbox"
        onChange={e => {
          setSelectedIds(e.target.checked ? currentItem?.map(data => data[`${pk}`]) : []);
        }}
        checked={selectedIds.length > 0}
      />
      <span className="checkmark"></span>
    </label>
  )
}

export default CheckboxHeader