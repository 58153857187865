import React, { useState, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { IoMdMail } from 'react-icons/io';
import middleware from "../../Api/Middleware";
import ToastError from "../../Toasts/ToastError";
import "../ModalStyle.css";

function OpenEmailModal({ order_id, client_order_email_id, subject }) {
  const [show, setShow] = useState(false);
  const [emailData, setEmailData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (show && order_id && client_order_email_id) {
      fetchEmailData();
    }
  }, [show, order_id, client_order_email_id]);

  const fetchEmailData = async () => {
    setIsLoading(true);
    try {
      const response = await middleware.get(`/orders/emails?order_id=${order_id}&client_order_email_id=${client_order_email_id}`);
      console.log("open", order_id, client_order_email_id)
      if (response.data && response.data.length > 0) {
        setEmailData(response.data[0]);
      } else {
        throw new Error('No email data found');
      }
    } catch (error) {
      ToastError(`Error fetching email: ${error.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Button variant="link" onClick={handleShow} className="p-0 text-decoration-none email-subject text-start new-link-color">
        {subject.length > 30 ? subject.slice(0, 30) + "..." : subject}
      </Button>
      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header closeButton className='emailModalHeader'>
          <Modal.Title>Email Details</Modal.Title>
        </Modal.Header>
        <Modal.Body className='bg-white'>
          {isLoading ? (
            <p>Loading email data...</p>
          ) : emailData ? (
            <div>
              <p><strong>From:</strong> {emailData.sender_email}</p>
              <p><strong>To:</strong> {emailData.receiver_email}</p>
              <p><strong>Subject:</strong> {emailData.subject}</p>
              <p><strong>Date:</strong> {new Date(emailData.created_at).toLocaleString()}</p>
              <hr />
              <div dangerouslySetInnerHTML={{ __html: emailData.content }} />Voor het vollidige bericht, kijk uw mail.
            </div>
          ) : (
            <p>No email data available</p>
          )}
        </Modal.Body>
        <Modal.Footer className='emailModalFooter'>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default OpenEmailModal;