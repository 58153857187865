import React, { useMemo, useState, useRef, forwardRef, useEffect } from "react";
import Cookies from "universal-cookie";
import { useNavigate, useParams } from "react-router-dom";
import Sidebar from "../../../Navbars/Sidebar/Sidebar";
import Navbar from "../../../Navbars/Navbar/Navbar";
import middleware from "../../../Api/Middleware";
import salesChannelsApi from "../../../Api/SalesChannels";
import "../css/AddProductPages.css";
import "../../../Main/Main.css";
import "../../product.css";
import PageTemplate from "../../../Templates/PageTemplate";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlus,
  faPenToSquare,
  faCaretLeft,
  faGear,
} from "@fortawesome/free-solid-svg-icons";
import Loading from "../../../LoadingScreen/LoadingScreen";
import TablePreset from "../../../Table/TablePreset";
import ToastError from "../../../Toasts/ToastError";
import ToastSuccess from "../../../Toasts/ToastSuccess";
import ToastWarning from "../../../Toasts/ToastWarning";
import useUpdateEffect from "./useUpdateEffect";
import Backbutton from "../images/BackIcon.svg";
// Media
import "../css/UploadArea.css";
import LoadingScreen from "../../../LoadingScreen/LoadingScreen";
// boxes
import FirstBox from "./edit_components/boxfirst";
import PriceBox from "./edit_components/boxPrice";
import ThirdBox from "./edit_components/boxthird";
import FourthBox from "./edit_components/boxfourth";
import BoxVariation from "./edit_components/BoxVariation";
import MediaUploaderComponent from "../../../MediaUpload/MediaUploaderComponent";
import Categories from "../sub_components/Categories";
import { color } from "@uiw/react-color";
import ErrorScreen from "../../../LoadingScreen/ErrorScreen"

const EditPage = () => {
  // variables
  const cookies = new Cookies();
  //variation
  const [allVariations, setAllVariations] = useState([]);
  const [allSizes, setAllSizes] = useState([]);
  const [allColors, setAllColors] = useState([]);

  const { product_id } = useParams();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [showBrandModal, setShowBrandModal] = useState(true);
  const [selectedOption, setSelectedOption] = useState("3");
  const [tax, setTax] = useState("0");
  const [taxClass, setTaxClass] = useState(0);
  const [selectedTax, setSelectedTax] = useState("");
  const [product, setProduct] = useState([{}]);
  const [oldProduct, setOldProduct] = useState([{}]);
  const [barcode, setBarcode] = useState();
  const [adjustedBarcode, setAdjustedBarcode] = useState();
  const [oldBarcode, setOldBarcode] = useState();
  const [barcodeId, setBarcodeId] = useState([{}]);
  const [brands, setBrands] = useState([{}]);
  const [prices, setPrices] = useState({
    price: { incl_tax: "", excl_tax: "" },
    sale_price: { incl_tax: "", excl_tax: "" },
    buy_in_price: { incl_tax: "", excl_tax: "" },
  });
  const [stock, setStock] = useState({});
  const [suppliers, setSuppliers] = useState([]);
  const [businesses, setBusinesses] = useState([]);
  const [attributeConnections, setAttributeConnections] = useState([]);
  const [updatedProduct, setUpdatedProduct] = useState(false);
  const [updatedTax, setUpdatedTax] = useState(false);
  const [updatedBarcode, setUpdatedBarcode] = useState(false);
  const [updatedBrands, setUpdatedBrands] = useState(false);
  const [updatedPrices, setUpdatedPrices] = useState(false);
  const [updatedMedia, setUpdatedMedia] = useState(false);
  const [updatedGender, setUpdatedGender] = useState(false);
  const [updatedCategories, setUpdatedCategories] = useState(false);
  const [updatedStock, setUpdatedStock] = useState(false);
  const [updatedVariations, setUpdatedVariations] = useState(false);
  const [firstLoad, setFirstLoad] = useState(false);
  const [productsHasBrands, setProductsHasBrands] = useState([{}]);
  const [productBrand, setProductBrand] = useState({});
  const [generatedBarcode, setGeneratedBarcode] = useState({});
  const [categories, setCategories] = useState([]);
  const [requiredCheck, setRequiredCheck] = useState(false);
  const [genders, setGenders] = useState([]);
  const [selectedGenders, setSelectedGenders] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState({});
  const [selectedVariations, setSelectedVariations] = useState([]);
  const [variationSwitch, setVariationSwitch] = useState();
  const [errors, setErrors] = useState([]);
  const [apiCheck, setApiCheck] = useState(true);
  const [apiError, setApiError] = useState("");
  const [images, setImages] = useState([]);
  const [salesChannels, setSalesChannels] = useState([]);
  const [selectedSalesChannels, setSelectedSalesChannels] = useState([]);
  // set to track errors to prevent duplicate errors
  const processedErrors = new Set();

  // State for holding categories.
  const [initialCategories, setInitialCategories] = useState([
    { id: 1, headId: null, name: "Categories" },
  ]);

  const [initialCategoriesNew, setInitialCategoriesNew] = useState([
    { id: 1, headId: null, name: "Categories" },
  ]);

  // A helper function that recursively organizes the products based on their headId.
  const organizeCategory = (categories, parentId = null) => {
    // Ensure the input is an array
    return categories
      .filter((category) => category.headId === parentId)
      .map((category) => {
        return {
          ...category,
          subcategories: organizeCategory(categories, category.id),
        };
      });
  };
  const [searchQuery, setSearchQuery] = useState("");
  const [checkedCategories, setCheckedCategories] = useState({});
  const [checkedCategory, setCheckedCategory] = useState({});
  const [categoryTree, setCategoryTree] = useState(
    organizeCategory(initialCategories)
  );
  const [categoryTreeNew, setCategoryTreeNew] = useState(
    organizeCategory(initialCategoriesNew)
  );
  const [filteredCategory, setFilteredCategory] = useState(categoryTree);
  const [showCategory, setShowCategory] = useState(false);

  // Variables end

  // Functions

  const fetchAll = async () => {
    try {
      const [
        fetchProduct,
        fetchGeneratedBarcode,
      ] = await Promise.all([
        middleware.get(`products/full?product_id=${product_id}`),
        middleware.get("products/generateBarcode"),
      ]);
      // check if the prodcut exists
      if (fetchProduct.data?.Message != null) {
        // Display message and sent user back to the products page
        ToastError(fetchProduct.data.Message);
        navigate("/producten");
        return;
      }
      setProduct(fetchProduct.data.product.product);
      setVariationSwitch(fetchProduct.data.product.product.variation_type == 0 ? false : true ?? null);
      setOldProduct(fetchProduct.data.product.product);
      setBarcode(fetchProduct.data.product.product.barcode?.barcode ?? null);
      setOldBarcode(fetchProduct.data.product.product.barcode?.barcode ?? null);
      setBarcodeId(fetchProduct.data.product.product.barcode?.barcode_id ?? null);
      setBrands(fetchProduct.data.data.brands);
      setPrices(fetchProduct.data.product.product.prices);
      // set prices with 2 decimals
      setPrices(prevPrices =>
        prevPrices.map(price => ({
          ...price, incl_tax: Number(price.incl_tax).toFixed(2)
        }))
      );
      setStock(fetchProduct.data.product.product.stock ?? { amount: 0, stock_behavior: 0, deliverability: 0, maximal: 0, minimal: 0 });
      setSuppliers(fetchProduct.data.data.suppliers);
      setBusinesses(fetchProduct.data.data.businesses);
      setAttributeConnections(fetchProduct.data.product.product.attributes);
      setProductsHasBrands(fetchProduct.data.product.product.brand);
      setGeneratedBarcode(fetchGeneratedBarcode.data.generatedBarcode);
      setGenders(fetchProduct.data.data.genders);
      setSelectedCategories(fetchProduct.data.product.product.categories);
      // Select the genders that were selected before
      const oldGenders = fetchProduct.data.product.product.genders.map((e) => e.gender_id);
      setSelectedGenders([...selectedGenders, ...oldGenders]);
      setTaxClass(fetchProduct.data.product.product.tax_id);
      if (fetchProduct.data.product.product.tax_id == 2) {
        setTax("9");
        setSelectedTax("9");
      } else if (fetchProduct.data.product.product.tax_id == 1) {
        setTax("21");
        setSelectedTax("21");
      } else {
        setTax("0");
        setSelectedTax("0");
      }

      // Category
      const cat = fetchProduct.data.data.categories.map((map) => {
        return {
          primary_key: map.primary_key,
          id: map.category_id,
          headId: map.head_category_id,
          name: map.title,
        };
      });
      setInitialCategories(cat);
      setInitialCategoriesNew(cat);
      setCategories(fetchProduct.data.data.categories);
      setAllSizes(fetchProduct.data.data.sizes);
      setAllColors(fetchProduct.data.data.colors);

      if (fetchProduct.data.product.product.type == 2) {
        // fetch selected variations when the product type is 1 and reverse the array so the most recent variations get showed first
        setSelectedVariations(fetchProduct.data.product.variations.reverse());
      }
      setFirstLoad(true);
      setLoading(false);
    }
    catch (error) {
      // show error when Api returns an error
      setLoading(false);
      console.log(error);
      setApiCheck(false);
      if (error?.response?.data?.error != null) {
        setApiError(error.response.data.error);
      }
      else if (error.message != null) {
        setApiError(error.message);
      } else {
        setApiError("Er is een fout met het ophalen van de Api");
      }
    }

    // fetch sales channels
    try {
      const [
        fetchSalesChannels
      ] = await Promise.all([
        salesChannelsApi.get('saleschannels?status=1'),
      ]);
      setSalesChannels(fetchSalesChannels.data);
    }
    catch (error) {
      console.log(error);
    }
  };
  const validateImages = async () => {
    //Check if there are any images that are faulty (like too big or unsupported file type)
    let faultyImages = false;
    for (let index = 0; index < images.length; index++) {
      const CurrentImage = images[index];
      if (CurrentImage.error) {
        faultyImages = true;
      }
    }
    let everythingFilledIn = true;
    const useColors = product.type == 2;
    if (useColors) {
      //Check if every file has a color assigned to it
      for (let index = 0; index < images.length; index++) {
        const CurrentImage = images[index];
        if (CurrentImage.colorId === undefined) {
          everythingFilledIn = false;
        }
      }
    }
    //If there are any images that are faulty, a pop-up will appear and will stop the code from running any further
    if (faultyImages) {
      return "Verwijder de foto's die ongeldig zijn voordat je opslaat.";
    }
    // If there are any images that have no color assigned to them, a pop-up will appear and will stop the code from running any further
    else if (useColors && !everythingFilledIn) {
      return true;
      // return "Vul alle kleuren/maten in!";
    } else {
      //This is so if the upload went succesful and you add a new image, it won't have a red ring around it.
      // setRequiredCheck(false);
      // if (setCheck != null) {
      //   setCheck(false);
      // }
    }
    return true;
  }
  const blobToBase64 = (blob) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onload = () =>
        resolve(reader.result.replace("data:", "").replace(/^.+,/, ""));
      reader.onerror = (error) => reject(error);
    });
  const saveImages = async (productID) => {
    try {
      let currentProductId = productID;
      const currentMonth = (new Date().getMonth() + 1)
        .toString()
        .padStart(2, "0");
      const currentYear = new Date().getFullYear();
      const folderPath = `warehouse/uploads/${currentYear}/${currentMonth}/${currentProductId}`;

      //The string with all the data that will be send to the API so it saves the image path, order and color
      let bulkDataString = "";
      let imagestrings = [];
      const useColors = product.type == 2;

      //Loop through the images and preparing them to upload
      for (let index = 0; index < images.length; index++) {
        // if(!images[index].changed) {
        //   continue;
        // }
        const CurrentImage = images[index];
        // This displays the image that is currently being uploaded
        // setUploadImgStatus(
        //   `${CurrentImage.name} (${CurrentImage.file.size.toString().length >= 6
        //     ? (CurrentImage.file.size / 1024 / 1000).toFixed(1) + " MB"
        //     : (CurrentImage.file.size / 1024).toFixed(1) + " kB"
        //   })`
        // );

        const base64String = await blobToBase64(CurrentImage.file);
        imagestrings.push(base64String);

        // Increase the upload count to notify the user the image has been uploaded
        // setUploadCount((prev) => prev + 1);

        //Add the data to the bulkdata that will eventually be sent to the middleware API
        const colorId = CurrentImage.colorId != 0 ? CurrentImage.colorId : null;
        bulkDataString += `${CurrentImage.name}|${useColors ? colorId : null
          }|${folderPath}/${CurrentImage.name.replace(/\s/g, "_")}|${index + 1
          }|${useColors ? CurrentImage.sizeId : null}`;
        if (index < images.length - 1) {
          bulkDataString += ",";
        }
      }
      // Post request to the middleware api to save the image data to the database and delete the older information
      await middleware.post(
        `products/media/upload?product_id=${currentProductId}&bulk_data=${encodeURIComponent(
          bulkDataString
        )}`,
        { imagestrings: imagestrings }
      );

      return true;
    } catch (error) {
      // When something goes wrong the user gets logged out and send to the login page
      return (
        error.response?.data?.Message ??
        error.response?.data?.error ??
        false
      );
    }
  }
  useEffect(() => {
    let variations = [];
    let count = 0;
    if (
      allColors != null &&
      selectedVariations != null &&
      allColors.length != 0 &&
      allSizes != null &&
      allSizes.length != 0 &&
      selectedVariations.length != 0
    ) {
      // loop trough selectedVariations and replace the color and the size object to get the correct data
      selectedVariations.forEach((variation) => {
        // set colors
        if (variation.color != null) {
          const color = allColors.find(
            (color) => color.color_id === variation.colors[0].color_id
          );
          variation.colors = color;
        }
        // set sizes
        if (variation.size != null) {
          const size = allSizes.find(
            (size) => size.size_id === variation.sizes[0].size_id
          );
          variation.sizes = size;
        }
        const customVariation = {
          ...variation, // spread the original properties
          id: count, // add or update the id field
          color_name: variation.colors[0]?.name ?? null, // assign the color name from colors object
          size_name: variation.sizes[0]?.name ?? null,
          hex: variation.colors?.hex ?? null, // assign the size name from sizes object
        };
        customVariation.color = customVariation.colors?.color_id ?? null;
        customVariation.size = customVariation.sizes?.size_id ?? null;
        variations.push(customVariation);
        count++;
      });
      setAllVariations(variations);
    }
  }, [allColors, selectedVariations]);

  useEffect(() => {
    if (product.product_id != null) {
      setProductBrand(productsHasBrands);
      setSelectedOption("3");
    }
  }, [productsHasBrands]);

  // Set the fetched selected categories as selected
  useEffect(() => {
    if (selectedCategories.length) {
      const prev = { ...checkedCategories };
      initialCategories.forEach((initialCategory) => {
        if (
          selectedCategories.find((e) => e.category_id == initialCategory.id)
        ) {
          prev[
            `1-${initialCategory.primary_key}-${initialCategory.id}-${initialCategory.headId ?? 0
            }`
          ] = true;
        }
      });
      setCheckedCategories(prev);
    }
  }, [selectedCategories]);

  // Effect to recalculate prices when tax rate changes
  // useEffect(() => {
  //   // Function defined inside useEffect to avoid dependencies on external functions
  //   const recalculatePrices = () => {
  //     setPrices((currentPrices) => {
  //       const updatedPrices = [];
  //       for (const [key, value] of Object.entries(currentPrices)) {
  //         const inclTax = value.incl_tax;
  //         const exclTax = inclTax ? (inclTax / (1 + tax / 100)).toFixed(3) : "";

  //         updatedPrices[key] = { ...value, excl_tax: exclTax };
  //       }

  //       return updatedPrices;
  //     });
  //   };

  //   recalculatePrices();
  // }, [tax]);

  const MediaUpload = useRef();

  const proccessCategorieIds = (obj) => {
    const regex = /^(\d+)-(\d+)-(\d+)-(\d+)$/;
    const categoryIds = [];
    const HeadIds = [];

    Object.entries(obj)
      .filter(([key, value]) => value === true && regex.test(key))
      .forEach(([key]) => {
        const match = key.match(regex);
        if (match) {
          categoryIds.push(match[3]); // Voeg het derde deel toe aan match3
          HeadIds.push(match[4]); // Voeg het vierde deel toe aan match4
        }
      });

    return {
      categoryIds,
      HeadIds,
    };
  };

  const transformData = (data) => {
    let headCategoryMap = {};

    data.HeadIds.forEach((headId) => {
      const headCategory = categories.find(
        (cat) => cat.category_id === parseInt(headId)
      );
      if (headCategory) {
        headCategoryMap[headCategory.title] = data.categoryIds
          .map((catId) => {
            const category = categories.find(
              (cat) => cat.category_id === parseInt(catId)
            );
            return category &&
              category.head_category_id === headCategory.category_id
              ? category.title
              : null;
          })
          .filter((title) => title !== null);
      }
    });

    return headCategoryMap;
  };

  const transformedData = transformData(
    proccessCategorieIds(checkedCategories)
  );

  // End category code
  useEffect(() => {
    if (selectedOption == 1) {
      setBarcode(product.product_number);
    } else if (selectedOption == 2) {
      setBarcode(generatedBarcode);
    } else if (selectedOption == 0) {
      setBarcode(adjustedBarcode);
    }

  }, [selectedOption]);

  const categoryIds = proccessCategorieIds(checkedCategories).categoryIds;
  useEffect(() => {
    if (selectedOption != 3) {
      UpdateAll();
    }
  }, [barcode])

  useEffect(() => {
    return () => clearTimeout(timeout.current);
  }, [])
  const validate = (e) => {
    e.target.checkValidity();
    e.target.reportValidity();
  }
  const timeout = useRef();
  // updatebutton function
  const UpdateAll = async () => {
    // check if a field changed
    if (timeout.current) {
      clearTimeout(timeout.current);
    }
    timeout.current = setTimeout(async () => {
      // Set prices
      const newprices = prices.reduce((acc, curr) => {
        acc[curr.type] = {
          price_id: curr.price_id,
          incl_tax: typeof curr.incl_tax === 'string' ? Number(curr.incl_tax.replace(",", ".")).toFixed(2) : Number(curr.incl_tax).toFixed(2),
          excl_tax: typeof curr.excl_tax === 'string' ? Number(curr.excl_tax.replace(",", ".")).toFixed(2) : Number(curr.excl_tax).toFixed(2),
        };
        return acc;
      }, {});
      // Validate input fields
      let errorMessage = [];
      let result = await validateImages();
      if (result && result.length) {
        errorMessage.push(result);
      }
      if (product.type == 2) {

        allVariations.forEach(element => {
          if (element.barcode == "" || element.barcode == null) {
            errorMessage.push("Vul een barcode in");
          }
        });
      }
      // if (stock.maximal === "") {
      //   errorMessage.push("Het maximale voorraad veld is verplicht!");
      // }
      // if (stock.minimal === "") {
      //   errorMessage.push("Het minimale voorraad veld is verplicht!");
      // }
      // if (stock.amount === "") {
      //   errorMessage.push("Het voorraad hoeveelheid veld is verplicht!");
      // }
      if (product.product_name == "" || product.product_name == null)
        errorMessage.push("Het productnaam veld is verplicht!");
      // if (product.product_number == "" || product.product_number == null)
      //   errorMessage.push("Het productnummer veld is verplicht!");
      // if (barcode == "" || barcode == null)
      //   if (product.type != 2) {
      //     errorMessage.push("Het barcode veld is verplicht!");
      //   }
      // if (prices.length == 0 || prices == null)
      //   errorMessage.push("Prijs is verplicht!");
      if (errorMessage == "" || errorMessage == null) {

        //Check if barcode is null or 13

        // Put request to the API
        // console.log(errors);
        if (checkBarcodeFormat(barcode) && errors.length == 0) {
          try {
            // Send the edit request to the api
            const response = await middleware.put(`/products/edit`, {
              product: {
                product_id: product.product_id,
                name: product.product_name,
                number: product.product_number,
                description: product.product_description,
                tax_id: taxClass,
                variation_type: variationSwitch,
                barcode: barcode,
              },
              prices: newprices,
              category_ids: categoryIds,
              brand_id: Number(productBrand?.brand_id) ?? null,
              gender_ids: selectedGenders,
              stock: product.type != 2 ? stock : null,
              variations: allVariations,
            });
            await saveImages(product.product_id);
            ToastSuccess("product is bijgewerkt");
            // set prices and stock to the response in case the prices don't have a price id wich results in errors
            setPrices(
              response.data.product.prices.map((curr) => ({
                ...curr,
                incl_tax: Number(curr.incl_tax).toFixed(2),
                excl_tax: Number(curr.excl_tax).toFixed(2),
              }))
            );
            setProduct(response.data.product)
            setStock(response.data.product.stock);
          } catch (error) {
            if (error.response?.data?.error) {
              // show all barcode duplicate errors
              if (error.response.data.error != null) {
                // loop trough all errors
                if (Array.isArray(error.response.data.error)) {
                  error.response.data.error.forEach((barcodeError) => {
                    if (!processedErrors.has(barcodeError.message)) {
                      ToastError(barcodeError.message);
                      console.log(error);
                      // Prevent to show the same error multiple times
                      processedErrors.add(barcodeError.message);
                    }
                  });
                  setErrors(error.response.data.error.map((er) => er.index));
                } else if (Array.isArray(error.response?.data?.details)) {
                  error.response.data.details.forEach((barcodeError) => {
                    if (!processedErrors.has(barcodeError.message)) {
                      ToastError(barcodeError.message);
                      console.log(error);
                      // Prevent to show the same error multiple times
                      processedErrors.add(barcodeError.message);
                    }
                  });
                } else {
                  console.log(error);
                  ToastError(error.response.data.error);
                }
              } else {
                ToastError(
                  "Er is een fout met het verturen van de data. Controleer de ingevulde gegevens en probeer opnieuw."
                );
              }
            }
            else {
              console.log(error);
            }
          }
        }
      } else {
        if (errorMessage.length > 1) {
          ToastError("Er zijn meerdere velden niet ingevuld!");
          console.log(errorMessage);
        } else {
          ToastError(errorMessage[0]);
        }
      }
    }, 1500)
  };

  const checkBarcodeFormat = (barcode) => {
    if (barcode == null || barcode == "") {
      return true;
    }
    // check correct barcode length
    if (barcode.length == 13) {
      // check if prefix is between 021 - 029
      let isCorrectPrefix = true;
      const barcodePrefix = parseInt(barcode.toString().slice(0, 3), 10);
      isCorrectPrefix = barcodePrefix >= 21 && barcodePrefix <= 29;
      if (isCorrectPrefix) {
        // Check if checksum is correct
        const digits = barcode.toString().split('').map(Number);

        const sum = digits
          .slice(0, 12) // Get the first 12 digits
          .reduce((acc, digit, index) => {
            return acc + digit * (index % 2 === 0 ? 1 : 3);
          }, 0);

        const checksum = (10 - (sum % 10)) % 10;
        if (checksum === digits[12]) {
          return true;
        }
        else {
          ToastError("Het laatse berekende veld van de barcode (checksum) is niet correct ingevuld");
        }
        return;
      }
      else {
        ToastError("Eerste 3 getallen van de barcode voldoen niet aan het EAN-13 formaat (tussen 021 en 029)");
      }
    } else {
      ToastError("Barcode voldoet niet aan het EAN-13 formaat");
    }
    return false;
  }

  const handleChange = () => {

    UpdateAll();
  }
  // Gender change
  const handleGendersChange = (event) => {
    const valueAsInt = parseInt(event.target.value, 10); // Zet de waarde om naar een integer
    setUpdatedGender(true);

    if (event.target.checked) {
      setSelectedGenders([...selectedGenders, valueAsInt]); // Voeg de integer waarde toe'
    } else {
      setSelectedGenders(selectedGenders.filter((e) => e !== valueAsInt)); // Verwijder de integer waarde
    }
  };

  const handleSalesChannelsChange = event => {
    const valueAsInt = parseInt(event.target.value, 10); // Zet de waarde om naar een integer

    if (event.target.checked) {
      setSelectedSalesChannels([...selectedSalesChannels, valueAsInt]); // Voeg de integer waarde toe
    } else {
      setSelectedSalesChannels(selectedSalesChannels.filter(e => e !== valueAsInt)); // Verwijder de integer waarde
    }
  }

  useEffect(() => {
    if (updatedCategories) {
      UpdateAll();
      setUpdatedCategories(false);
    }
  }, [updatedCategories])
  useEffect(() => {
    if (updatedProduct) {
      UpdateAll();
      setUpdatedProduct(false);
    }
  }, [updatedProduct])
  useEffect(() => {
    if (updatedGender) {
      UpdateAll();
      setUpdatedGender(false);
    }
  }, [updatedGender]);
  useEffect(() => {
    if (updatedVariations) {
      UpdateAll();
      setUpdatedVariations(false);
    }
  }, [updatedVariations])
  useEffect(() => {
    if (updatedMedia) {
      UpdateAll();
      setUpdatedMedia(false);
    }
  }, [updatedMedia])
  useEffect(() => {
    if (updatedStock) {
      UpdateAll();
      setUpdatedStock(false);
    }
  }, [updatedStock])
  useEffect(() => {
    if (updatedTax) {
      UpdateAll();
      setUpdatedTax(false);
    }
  }, [updatedTax])
  // updatebutton function end
  const ShowPrice = (type, turned, fixed) => {
    var res = [];
    if (turned == undefined || turned == false) {
      res = prices
        .filter((filter) => filter.type == type)
        .map((map) => map[tax == 0 ? "incl_tax" : "excl_tax"]);
    } else {
      res = prices
        .filter((filter) => filter.type == type)
        .map((map) => map[tax == 0 ? "excl_tax" : "incl_tax"]);
    }
    return fixed !== undefined || fixed == false
      ? Number(res).toFixed(2)
      : String(Number(res));
  };

  const UpdatePrice = (e, type, turned) => {
    // const index = prices.findIndex((price) => price.type == type) + 1;
    // if (index) {
    //   const updated = [...prices];
    //   updated[index - 1][tax == 0 ? "incl_tax" : "excl_tax"] = Number(
    //     e.target.value.replace(",", ".")
    //   );
    //   setPrices(updated);
    // }
  };

  const handlePriceChange = (event) => {
    const priceType = event.target.name;
    const inclTax = event.target.value;
    const exclTax = inclTax ? (inclTax / (1 + tax / 100)).toFixed(3) : "";
    setPrices((prevPrices) => {
      // if price was not ever set then make a new object
      if (prevPrices == null || prevPrices.length == 0) {
        prevPrices = [
          {
            price_id: null,
            incl_tax: 0,
            excl_tax: 0,
            type: "price",
          },
          {
            price_id: null,
            incl_tax: 0,
            excl_tax: 0,
            type: "sale_price",
          },
          {
            price_id: null,
            incl_tax: 0,
            excl_tax: 0,
            type: "buy_in_price",
          },
        ];
      }
      setUpdatedPrices(true);
      if (event.target.value !== '') {
        return prevPrices.map((price) =>
          price.type === priceType
            ? {
              ...price,
              incl_tax: inclTax === '' ? '' : Number(inclTax),  // Allow empty value
              excl_tax: exclTax === '' ? '' : Number(exclTax)   // Allow empty value
            }
            : price
        );
      } else {
        return prevPrices.map((price) =>
          price.type === priceType
            ? {
              ...price,
              incl_tax: inclTax === '' ? '' : Number(inclTax),  // Same here for empty
              excl_tax: exclTax === '' ? '' : Number(exclTax)   // Same here for empty
            }
            : price
        );
      }
    }
    );
  };

  const ChangeStatus = async (newStatus) => {
    try {
      const change = await middleware.put("/products", {
        product_id: product_id,
        status: newStatus,
      });
      setProduct(change.data);
      ToastSuccess("Status product aangepast");
    }
    catch (error) {
      ToastError(error);
      console.log(error);
    }
  };


  //functions end
  //useEffects

  useEffect(() => {
    fetchAll();
  }, []);
  //useEffects end
  // if the loading state is true put on a loading screen
  if (loading) {
    return <LoadingScreen />;
  }
  // if the api returns an error then show it in the errorscreen
  if (!apiCheck) {
    return <ErrorScreen error={apiError} />
  }
  // if the product doesn't exist or is a variation (type 3) then put on the error page instead of showing an empty edit page
  if (product == null || product.length == 0 || product.type == 3) {
    return <ErrorScreen error={"Dit product bestaat niet of is niet een geldig product"} />
  }

  return (
    <PageTemplate navbarTitle={"Warehouse HUB"}>
      {/* back to products btn */}
      <div className="d-flex flex-column gap-5 w-100">
        <form onSubmit={UpdateAll}>
          <div className="d-flex mb-4 mt-4 justify-content-between">
            <div className="d-flex gap-5">
              <div className="d-flex flex-row gap-2 align-items-center">
                <img className="back-button-img" src={Backbutton} onClick={() => navigate("/producten")} />
                <h5 className="p-0 m-0">Terug</h5>
              </div>
              {/* Published btn*/}
              <div className="d-flex flex-column align-items-center">
                {!product.status ? <p className="mb-1 p-0">Gepubliceerd</p> : <p className="mb-1 p-0">Gearchiveerd</p>}
                <label class="switchActiveProduct">
                  <input type="checkbox" checked={!product.status}
                    onChange={(e) => ChangeStatus(!e.target.checked)} />
                  <span class="sliderActiveProduct round">
                  </span>
                </label>
              </div>
            </div>
            <div>
              <button className="darkBlueStandardButton" disabled>Verwijderen</button>
            </div>
            {/* {product.status == 0 ? (
                <div
                  className="btn btn-secondary m-2 fw-bolder"
                  onClick={(e) => ChangeStatus(1)}
                >
                  Deactiveer
                </div>
              ) : (
                <div
                  className="btn btn-success m-2 fw-bolder"
                  onClick={(e) => ChangeStatus(0)}
                >
                  Activeer
                </div>
              )} */}
            {/* <div className=" saveButtonsBox fitContent">
              <button
                type="submit"
                id="2"
                className="btn lightBlueStandardButton Nunito m-2"
              >
                Opslaan
              </button>
            </div> */}
          </div>
          <div className="d-flex flex-column flex-xxl-row">
            <div className="col-xxl-8 col-12">
              <FirstBox
                handleChange={handleChange}
                product={product}
                setProduct={setProduct}
                selectedOption={selectedOption}
                setSelectedOption={setSelectedOption}
                setShowBrandModal={setShowBrandModal}
                brands={brands}
                barcode={barcode}
                setBarcode={setBarcode}
                setUpdatedProduct={setUpdatedProduct}
                setUpdatedBarcode={setUpdatedBarcode}
                setUpdatedBrands={setUpdatedBrands}
                brand={productBrand}
                setProductBrand={setProductBrand}
                generatedBarcode={generatedBarcode}
                setGeneratedBarcode={setGeneratedBarcode}
                adjustedBarcode={adjustedBarcode}
                setAdjustedBarcode={setAdjustedBarcode}
                oldBarcode={oldBarcode}
                validate={validate}
                edit={true}
              />
              {/* only products with type 2 can edit/add variations */}
              {product.type == 2 ? (
                <div className="mt-5">
                  <BoxVariation
                    allVariations={allVariations}
                    setAllVariations={setAllVariations}
                    allSizes={allSizes}
                    allColors={allColors}
                    setUpdatedVariations={setUpdatedVariations}
                    errors={errors}
                    setErrors={setErrors}
                    prices={prices}
                  />
                </div>
              ) : (
                <></>
              )}

              <div className="whiteBox my-5">
                <MediaUploaderComponent
                  edit={true}
                  updatedMedia={updatedMedia}
                  setUpdatedMedia={setUpdatedMedia}
                  setImages={setImages}
                  images={images}
                  useColors={product.type == 2}
                  availableVariations={allVariations}
                  setCheck={setRequiredCheck}
                  product={product}
                  variationSwitch={variationSwitch}
                  setVariationSwitch={setVariationSwitch}
                ></MediaUploaderComponent>
              </div>
            </div>
            <div className="d-flex flex-row flex-xxl-column row col-xxl-4">
              <div className="col-6 col-xxl-12">
                <div className="whiteBox boxCategories mb-4 ms-xxl-4">
                  <div className="d-flex flex-column">
                    <div className="widthBoxes categories">
                      {/* categories component */}
                      <Categories
                        initialCategories={initialCategories}
                        checkedCategories={checkedCategories}
                        setCheckedCategories={setCheckedCategories}
                        setUpdatedCategories={setUpdatedCategories}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-6 col-xxl-12 pb-4">
                <div className="whiteBox h-100 pt-auto px-5 pt-5 pb-4 ms-xxl-4 my-auto">
                  <h5 className="inputTitle mb-4">Geslacht</h5>
                  <div>
                    {genders.map((gender, index) => (
                      <div key={index} className="d-flex flex-row mb-3">
                        <label className="checkbox-containerSimple">
                          <input
                            type="checkbox"
                            value={gender.gender_id}
                            defaultChecked={selectedGenders.find((e) => e == gender.gender_id)}
                            onChange={(e) => handleGendersChange(e)}
                          />
                          <span className="checkmarkSimple"></span>
                        </label>
                        <h5 className="inputTitle fw-medium">{gender.name}</h5>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              {/* prices component */}
              <div className="col-6 col-xxl-12 pb-4">
                <PriceBox
                  selectedTax={selectedTax}
                  setSelectedTax={setSelectedTax}
                  setTax={setTax}
                  taxClass={taxClass}
                  setTaxClass={setTaxClass}
                  ShowPrice={ShowPrice}
                  UpdatePrice={UpdatePrice}
                  HandlePriceChange={handlePriceChange}
                  handleChange={handleChange}
                  prices={prices}
                  setPrices={setPrices}
                  setUpdatedTax={setUpdatedTax}
                  validate={validate}
                  edit={true}
                />
              </div>
              {product.type != 2 &&
                <div className="col-6 col-xxl-12 pb-4">
                  <ThirdBox
                    stock={stock}
                    setStock={setStock}
                    validate={validate}
                    setUpdatedStock={setUpdatedStock}
                    edit={true}
                  />
                </div>
              }
              {/* sales channels */}
              <div className="pb-4 col-6 col-xxl-12">
                <div className="whiteBox p-4 h-100 ms-xxl-4">
                  <div className="d-flex flex-column h-100">
                    <h5 className="inputTitle mb-4 ps-2">Verkoopkanaal</h5>
                    <div className="px-2 d-flex h-100 flex-column">
                      {salesChannels.length > 0 ? (
                        salesChannels.map((salesChannel, index) => (
                          <div
                            key={index}
                            className="d-flex flex-row mb-3 me-3 slidersSellchannel justify-content-between"
                          >
                            <h6 className="switchText">
                              {salesChannel.sales_channel_name}
                            </h6>
                            <label className="defaultSwitch ms-4">
                              <input
                                type="checkbox"
                                value={salesChannel.sales_channel_id}
                                onChange={handleSalesChannelsChange}
                              />
                              <span className="sliderActiveProduct round"></span>
                            </label>
                          </div>
                        ))
                      ) : (
                        <p>Er zijn nog geen verkoopkanalen gemaakt.</p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="pb-4 col-6 col-xxl-12">
                <FourthBox
                  suppliers={suppliers}
                  setSuppliers={setSuppliers}
                  businesses={businesses}
                  setBusinesses={setBusinesses}
                  validate={validate}
                  attributeConnections={attributeConnections}
                  setAttributeConnections={setAttributeConnections}
                  edit={true}
                />
              </div>
            </div>
          </div>
        </form>
      </div>
    </PageTemplate>
  );
};

export default EditPage;
