import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router';
import Cookies from 'universal-cookie';
import './LoadingScreen.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRotateRight } from '@fortawesome/free-solid-svg-icons';
import { removeLoginCookies } from '../Login/Login';
import { hourglass } from 'ldrs'



const LoadingScreen = ({title, logoutOption, retryOption}) => {
    hourglass.register()

    
    const [showButton, setShowButton] = useState(false);
    const [logOut, setLogOut] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
      const timer = setTimeout(() => {
        if (logoutOption == null && logoutOption) {
            setLogOut(true);
        }
        if (retryOption == null && retryOption) {
            setShowButton(true);
        }
      }, 5000);
  
      return () => clearTimeout(timer);
    }, []);


    return (
        <div className="parent position-absolute" >
            <div className="d-flex align-items-center position-relative align-middle flex-column" style={{top: '20vh'}}>
                <l-hourglass
                size="80"
                bg-opacity="0.1"
                speed="1.75" 
                color="white" 
                ></l-hourglass>
                {showButton && (
                    <button onClick={e => window.location.reload() } className='text-white link bg-transparent border-0 mb-4 rounded'>Probeer opnieuw <FontAwesomeIcon icon={faRotateRight}/></button>
                )}
                {logOut && (
                    <button onClick={() => removeLoginCookies(navigate('/login'))} className='text-white link bg-transparent border-0 rounded'>Log uit</button>
                )}
            </div>
        </div>
    );
}

export default LoadingScreen;
