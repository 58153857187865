import React, { useEffect, useState } from "react";
import CategoryList from "../../Products/subpages/sub_components/CategoryList";
import '../Filters.css';

const Categoryfilter = ({ setFilters, filter }) => {
    const [checkedCategories, setCheckedCategories] = useState([]);
    useEffect(() => {
    }, [filter])
    const toggle = (cat) => {
        let newcats = [...checkedCategories];
        if (checkedCategories[cat]) {
            delete newcats[cat];
            setCheckedCategories(newcats);
            setFilters((filters) => {
                const newFilters = [...filters];
                newFilters[filters.findIndex((e) => e.index == filter.index)].checkedcats = newcats;
                newFilters[filters.findIndex((e) => e.index == filter.index)].value = null;
                return newFilters
            })
        } else {
            newcats = [...checkedCategories];
            newcats[cat] = 1;
            let parts = cat.split('-');
            setCheckedCategories(newcats);
            setFilters((filters) => {
                const newFilters = [...filters];
                newFilters[filters.findIndex((e) => e.index == filter.index)].checkedcats = newcats;
                newFilters[filters.findIndex((e) => e.index == filter.index)].value = parts[1] ?? '';
                return newFilters
            })
        }
    }
    const [open, setOpen] = useState(false);
    const organizeCategory = (categories, parentId = null) => {
        // Ensure the input is an array
        return categories
            .filter((category) => category.headId === parentId)
            .map((category) => {
                return {
                    ...category,
                    subcategories: organizeCategory(categories, category.id),
                };
            });
    };

    return <div className="d-flex w-100 h-100">
        <div className="bg-white rounded-4 h-100 p-4 border border-gray w-100 position-relative">
            <h6 className="fw-bold">{filter.name}</h6>
            <button onClick={() => setOpen(!open)} className={`standardInput bg-white ${open ? 'open' : 'closed'} d-flex w-100 px-2 standardDropdownArrow align-items-center`}>Selecteer categorie</button>
            {open && <div className={`d-flex flex-row categoryfilter ${open ? 'open' : 'closed'} bg-white shadow-lg rounded-4 p-2 position-absolute`}>
                <CategoryList categories={organizeCategory(filter.options)} checkedCategories={filter.checkedcats} toggle={toggle} />
            </div>}
        </div>
    </div>
}

export default Categoryfilter;