import React, { useMemo, useState } from "react";

const EmailComponents = React.memo(({ReceiveEmailModal, SendEmailModal, order, emails, productsToProcess}) => {
    const [refresh, setRefresh] = useState(0)
    const canSendEmail = useMemo(() => productsToProcess.length > 0, [productsToProcess]);

    const handleEmailSent = () => {
        setRefresh(prev => prev + 1);
      };
      
    return (
        <div className="whiteBox email-main-container pt-4 pb-4">
            {/* Send Email Section */}
            {canSendEmail ? (
                <div className="email-header">
                    <h2>E-mails</h2>
                    <SendEmailModal order={order} emails={emails} handleEmailSent={handleEmailSent} />
                </div>
            ) : (
                <h3>Order is al verwerkt, u kunt geen nieuwe mail sturen.</h3>
            )}
            {/* Email Receiving Section */}
            <ReceiveEmailModal orderId={order.order_id} refresh={refresh} />
        </div>
    );
}, (prevProps, nextProps) => {
    return (
        prevProps.order.order_id === nextProps.order.order_id &&
        prevProps.emails.length === nextProps.emails.length &&
        prevProps.productsToProcess.length === nextProps.productsToProcess.length
    );
});

export default EmailComponents;