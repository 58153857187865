import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import Cookies from "universal-cookie";
import "./LoadingScreen.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeftLong } from "@fortawesome/free-solid-svg-icons";
import { faRightFromBracket } from "@fortawesome/free-solid-svg-icons";
import { faRotateRight } from "@fortawesome/free-solid-svg-icons";
import { removeLoginCookies } from "../Login/Login";
import ToastError from "../Toasts/ToastError";

const LoadingScreen = (props) => {
  const [isShown, setIsShown] = useState(false);
  const navigate = useNavigate();
  if (!isShown) {
  ToastError(props.error);
  setIsShown(true);
  }

  return (
    <div className="parent position-absolute" style={{ width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.8)' }}>
    <div className="d-flex align-items-center position-relative align-middle flex-column" style={{ top: '20vh' }}>
        <h2 className="text-danger mb-3" style={{ fontWeight: 'bold', maxWidth: 'fit-content' }}>Error</h2>
        <p className="text-white mb-3" style={{ fontSize: '18px', maxWidth: 'fit-content' }}>{props.error}</p>
        
        <div className="d-flex align-items-center position-relative align-middle flex-row w-100 gap-3 justify-content-center">
            <button onClick={e => navigate(-1)} 
                className='btn btn-outline-light rounded-pill mb-4 errorScreenBtn'>
                <FontAwesomeIcon icon={faArrowLeftLong} /> Terug 
            </button>
            <button onClick={e => window.location.reload()} 
                className='btn btn-outline-light rounded-pill mb-4 errorScreenBtn'>
                Probeer opnieuw <FontAwesomeIcon icon={faRotateRight} />
            </button>
        </div>
        
        <button onClick={() => removeLoginCookies(navigate('/login'))}
            className='btn btn-danger rounded-pill mt-3'>
            Log uit <FontAwesomeIcon icon={faRightFromBracket} />
        </button>
    </div>
</div>
  );
};

export default LoadingScreen;
