import React, { useMemo, useState, useEffect, useCallback, useRef } from "react";
import { useTable } from "react-table";
import middleware from "../../../Api/Middleware";
import TablePreset from "../../../Table/TablePreset";
import "../../../Table/Table.css";
import "../../../Orders/Orders.css";
import "../../../Main/Main.css";
import { useNavigate, useParams } from "react-router";
import CheckboxHeader from "../../../Table/TableComponents/CheckboxHeader";
import CheckboxCell from "../../../Table/TableComponents/CheckboxCell";
import SortableHeader from "../../../Table/TableComponents/SortableHeader";
import InputComponent from "../../AddCustomers/InputComponent";
import ToastError from "../../../Toasts/ToastError";
import ToastSuccess from "../../../Toasts/ToastSuccess";
import ToastInfo from "../../../Toasts/ToastInfo";
import { faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


const AddressData = ({ setDataUpdated }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [addAdress, setAddAdress] = useState(false);
  const [formData, setFormData] = useState({});
  const [adressData, setAdressData] = useState({});
  const [editMode, setEditMode] = useState(false);
  const [addressToEdit, setAddressToEdit] = useState(null);
  const [pagination, setPagination] = useState({
    page_size: 10,
    page: 1,
    max_items: 0,
    begin_of_page: 0,
    end_of_page: 0,
  });
  const [order, setOrder] = useState({ orderBy: '', direction: '' });
  const [searchBar, setSearchBar] = useState("");
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [countries, setCountries] = useState([]);
  const [countryIdInfo, setCountryIdInfo] = useState([]);
  const { internal_id } = useParams();
  const [postalCodeError, setPostalCodeError] = useState(false);
  const [debounceTimer, setDebounceTimer] = useState(null);
  const [isPostalCodeEditable, setIsPostalCodeEditable] = useState(false);

  const fetchAll = async () => {
    setLoading(true);
    try {
      const [adressesResponse, countrycodesResponses] = await Promise.all([
        middleware.get(`addresses?internal_id=${internal_id}&page=${pagination.page}&page_size=${pagination.page_size}&${order.orderBy !== '' && order.direction !== '' ? `&orderBy=${order.orderBy}&direction=${order.direction}` : ''}`),
        middleware.get(`countryCodes`),
      ]);
      setData(adressesResponse.data.data);
      setPagination(prev => ({ ...prev, max_items: adressesResponse.data.total, begin_of_page: adressesResponse.data.from, end_of_page: adressesResponse.data.to }));
      setFilteredData(adressesResponse.data.data);
      setCountries(countrycodesResponses.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
    setLoading(false);
  };
  useEffect(() => {
    if (countryIdInfo.country_code_2 == 'NL' && (!editMode || (addressToEdit && (addressToEdit.postalcode != formData.Postalcode || addressToEdit.housenumber != formData.HouseNumber)))) {
      const { Postalcode, HouseNumber } = formData; // Destructure the relevant fields from formData
      const postalCodeRegex = /^\s*[0-9]{4}\s*[A-Za-z]{2}\s*$/;

      setPostalCodeError('');

      // Clear the previous debounce timer if it exists
      if (debounceTimer) {
        clearTimeout(debounceTimer);
      }

      if (Postalcode && HouseNumber) {

        // Set a new debounce timer
        const newDebounceTimer = setTimeout(() => {
          // Check if Postalcode matches the regex
          if (postalCodeRegex.test(Postalcode)) {
            const fetchAdress = async () => {
              if (formData.HouseNumber) {
                try {
                  const response = await fetch(`https://gratispostcodeapi.nl/api/v1/lookup/${Postalcode}/${HouseNumber}`);
                  const data = await response.json();

                  // If the API returns valid postal_code data, populate formData
                  if (data && data.postal_code) {
                    setFormData(prevData => ({
                      ...prevData,
                      StreetName: data.street,
                      City: data.city,
                    }));
                    setPostalCodeError(false)
                  } else {
                    ToastError('Geen adres gevonden voor deze postcode.');
                    setPostalCodeError(true)
                  }
                } catch (error) {
                  ToastError('Er is een fout opgetreden bij het ophalen de adress gegevens');
                  setPostalCodeError(true)
                }
              } else {
                ToastInfo("Voer het huisnummer in om automatisch de straat en stad in te vullen")
                setPostalCodeError(true)
              }
            };

            fetchAdress(); // Call the function to fetch the house number
          } else {
            ToastError('Ongeldig postcodeformaat. Zorg ervoor dat je 4 cijfers en 2 letters invoert.');
            setPostalCodeError(true)
          }
        }, 1000); // 2 seconds debounce

        setDebounceTimer(newDebounceTimer); // Set the new timer
      }

      return () => clearTimeout(debounceTimer); // Cleanup timer on unmount or when dependencies change
    }
  }, [formData.Postalcode, formData.HouseNumber, countryIdInfo]);

  useEffect(() => {
    fetchAll();
  }, [order, order.direction]);

  useEffect(() => {
    if (searchBar) {
      const lowerCaseSearch = searchBar.toLowerCase();
      setFilteredData(
        data.filter(item =>
          item.streetname.toLowerCase().includes(lowerCaseSearch) ||
          item.city.toLowerCase().includes(lowerCaseSearch) ||
          item.postalcode.toLowerCase().includes(lowerCaseSearch) ||
          item.housenumber.toString().toLowerCase().includes(lowerCaseSearch) ||
          (item.addition && item.addition.toLowerCase().includes(lowerCaseSearch)) ||
          getCountryName(item.country_code_id).toLowerCase().includes(lowerCaseSearch)
        )
      );
    } else {
      setFilteredData(data);
    }
  }, [searchBar, data]);

  const [selectedIds, setSelectedIds] = useState([]);

  const handleSelection = (productId, isSelected) => {
    if (isSelected) {
      setSelectedIds(previous => [...previous, productId]);
    } else {
      setSelectedIds(previous => previous.filter(id => id !== productId));
    }
  };

  const getAddressType = (addressKind) => {
    if ([1, 3].includes(addressKind)) {
      return "Factuuradres"
    } else if ([0, 2].includes(addressKind)) {
      return "Bezorgadres"
    } else if ([4, 5].includes(addressKind)) {
      return "Factuuradres en bezorgadres"
    }
  };

  const getCountryName = (country_code_id) => {
    const country = countries.find(country => country.country_code_id === country_code_id);
    return country ? country.country : "Onbekend land";
  };

  const columns = useMemo(
    () => [
      {
        Header: (
          <CheckboxHeader currentItem={filteredData} selectedIds={selectedIds} setSelectedIds={setSelectedIds} pk={'address_id'}>
          </CheckboxHeader>
        ),
        accessor: "select",
        Cell: ({ row }) => {
          return <CheckboxCell row={row} handleSelection={handleSelection} selectedIds={selectedIds} pk={'address_id'} />
        }
      },
      {
        Header: () => (
          <SortableHeader currentItem={order} setItem={setOrder} pos={"start"} field="streetname">
            Adres
          </SortableHeader>
        ),
        accessor: 'streetname',
        Cell: ({ row }) => {
          const ad = row.original;
          return (
            <div className="d-flex flex-column cursorPointer" style={{ color: "#009fe3" }} onClick={() => handleNewAdres(ad)}>
              <p className="mb-0">{`${ad.streetname || ""} ${ad.housenumber || ""}${ad.addition || ""}, ${ad.postalcode || ""} ${ad.city || ""} ${getCountryName(ad.country_code_id)}`}</p>
            </div>
          );
        }
      },
      {
        Header: () => (
          <SortableHeader currentItem={order} setItem={setOrder} classNameHeader={"TypeAdressInfoHeader"} field="address_kind">
            Actief
          </SortableHeader>
        ),
        accessor: 'address_kind',
        Cell: ({ row }) => {
          return (
            <div className="text-center text-nowrap">{getAddressType(row.original.address_kind)}</div>
          );
        }
      },
    ], [order.orderBy, order.direction, selectedIds, setSelectedIds, filteredData, countries]
  );

  const tableInstance = useTable({ columns, data: filteredData });

  const normalizeLabel = (label) => {
    return label.trim().replace(/[\s\-]+/g, "");
  };

  const getValueInput = useCallback((value, label) => {
    const normalizedLabel = normalizeLabel(label);
    setFormData(prev => ({ ...prev, [normalizedLabel]: value }));
  }, []);

  const validateAddressForm = async () => {
    const { Postalcode, HouseNumber, StreetName, City, deliveryaddress, invoiceaddress } = formData;

    let addressresponse = (await middleware.get(`addresses?internal_id=${internal_id}`)).data;
    addressresponse = editMode ? addressresponse.filter(e => e.address_id != addressToEdit.address_id) : addressresponse;

    if (addressresponse.find(e => e.country_code_id == countryIdInfo.country_code_id && e.city == City && e.streetname == StreetName && e.postalcode == Postalcode && e.addition == formData.Addition && e.housenumber == HouseNumber)) {
      ToastError("Dat adres bestaat al");
      return false;
    }
    if (!invoiceaddress && deliveryaddress && (!addressresponse.find((e) => [1, 3, 4, 5].includes(e.address_kind)))) {
      ToastError("Er moet minimaal een factuuradres geselecteerd zijn");
      return false;
    }
    else if (!deliveryaddress && invoiceaddress && (!addressresponse.find((e) => [0, 2, 4, 5].includes(e.address_kind)))) {
      ToastError("Er moet minimaal een bezorgadres geselecteerd zijn");
      return false;
    }
    else if ((!deliveryaddress && !invoiceaddress) && !addressresponse.find((e) => [4, 5].includes(e.address_kind))) {
      ToastError("Er moet minimaal een bezorgadres en een factuuradres geselecteerd zijn");
      return false;
    }
    if (!Postalcode || !HouseNumber || !StreetName || !City || !countryIdInfo.country_code_id) {
      ToastError("Land, Postcode, Huisnummer, Straat, en Stad velden zijn verplicht");
      return false;
    }
    return true;
  };

  const handleNewAdres = async (address = null) => {
    if (address) {
      setFormData({
        Postalcode: address.postalcode,
        HouseNumber: address.housenumber,
        Addition: address.addition,
        StreetName: address.streetname,
        City: address.city,
        invoiceaddress: [1, 3, 4, 5].includes(address.address_kind),
        deliveryaddress: [0, 2, 4, 5].includes(address.address_kind),
        primaryAddress: address.address_kind === 1 || address.address_kind === 3 ? "invoice" : "delivery",
      });
      setCountryIdInfo(countries.find(country => country.country_code_id === address.country_code_id));
      setEditMode(true);
      setAddressToEdit(address);
      setAddAdress(true);
    } else {
      setFormData({});
      setCountryIdInfo([]);
      setEditMode(false);
      setAddressToEdit(null);
      setAddAdress(true);
    }
  };

  const handleSubmitAddress = async (e) => {
    if (e) {
      e.preventDefault();
    }

    if (await validateAddressForm()) {
      let adress_kind = 0;

      if (internal_id.startsWith('B')) {
        if (formData.deliveryaddress && !formData.invoiceaddress) {
          adress_kind = 2;
        } else if (!formData.deliveryaddress && formData.invoiceaddress) {
          adress_kind = 3;
        } else if (formData.deliveryaddress && formData.invoiceaddress) {
          adress_kind = 5;
        } else {
          adress_kind = null
        }
      } else if (internal_id.startsWith('C')) {
        if (formData.deliveryaddress && !formData.invoiceaddress) {
          adress_kind = 0;
        } else if (!formData.deliveryaddress && formData.invoiceaddress) {
          adress_kind = 1;
        } else if (formData.deliveryaddress && formData.invoiceaddress) {
          adress_kind = 4;
        } else {
          adress_kind = null
        }
      }

      try {
        if (editMode && addressToEdit) {
          await middleware.put(`/editAddress?address_id=${addressToEdit.address_id}`, {
            country_code_id: countryIdInfo.country_code_id,
            postalcode: formData.Postalcode,
            housenumber: formData.HouseNumber,
            addition: formData.Addition || null,
            streetname: formData.StreetName,
            city: formData.City,
            address_kind: adress_kind,
          });
          ToastSuccess("Adres is bijgewerkt");
        } else {
          await middleware.post("/editAddress", {
            internal_id: internal_id,
            country_code_id: countryIdInfo.country_code_id,
            postalcode: formData.Postalcode,
            housenumber: formData.HouseNumber,
            addition: formData.Addition || null,
            streetname: formData.StreetName,
            city: formData.City,
            address_kind: adress_kind,
          });
          ToastSuccess("Adres is aangemaakt");
        }
        setAddAdress(false);
        fetchAll();
      } catch (err) {
        console.error(err);
      }
      setDataUpdated(true);
    }
  };

  return (
    <div className="adressOverviewTab h-100 mt-4 px-3">
      {addAdress ? (
        <form onSubmit={handleSubmitAddress}>
          <div className="mt-5">
            <div>
              <div className=" d-flex flex-row mb-4">
                <h5 className="me-5">{editMode ? "Adres bewerken" : "Adres toevoegen"}</h5>
                <select
                  className="form-select inputFieldAddCustomer ms-5 dropdownsNewCustomers" style={{ width: "250px" }}
                  value={JSON.stringify(countryIdInfo)}
                  onChange={(e) => setCountryIdInfo(JSON.parse(e.target.value))}
                >
                  <option hidden>Kies een land</option>
                  {countries?.length > 0 && countries.map(country => (
                    <option key={country.country_code_id} value={JSON.stringify(country)}>
                      {country.country}
                    </option>
                  ))}
                </select>
              </div>
              <div className="d-flex flex-row align-items-center mb-3">
                <label className="checkbox-containerAdressAdd me-2">
                  <input
                    type="checkbox"
                    name="invoiceaddress"
                    checked={formData.invoiceaddress === true}
                    onChange={(e) => setFormData(prev => ({ ...prev, invoiceaddress: e.target.checked }))}
                  />
                  <span className="checkmarkAdressAdd"></span>
                </label>
                <p className="text-black fitContent mb-0 fw-medium">Factuuradres</p>
              </div>
              <div className="d-flex flex-row align-items-center mb-3">
                <label className="checkbox-containerAdressAdd me-2">
                  <input
                    type="checkbox"
                    name="deliveryaddress"
                    checked={formData.deliveryaddress === true}
                    onChange={(e) => setFormData(prev => ({ ...prev, deliveryaddress: e.target.checked }))}
                  />
                  <span className="checkmarkAdressAdd"></span>
                </label>
                <p className="text-black fitContent mb-0 fw-medium">Bezorgadres</p>
              </div>
              <div className="mb-4">
                <div className="col-12 d-flex flex-row align-content-end my-3">
                  <InputComponent
                    type={"text"}
                    onValueChange={getValueInput}
                    parentCol={6}
                    labelName={"Postcode"}
                    labelValue={"Postalcode"}
                    disabled={!countryIdInfo.country_code_2}
                    classnamesInput={"col-12 widthInputComponents px-3 " + (postalCodeError ? "border-danger border-2" : "")}
                    defaultValue={adressData.Postalcode || formData.Postalcode}
                  />
                  <div className="col-6 d-flex justify-content-end">
                    <div className="col-12 d-flex widthInputComponents flex-row justify-content-end">
                      <InputComponent
                        alignmentInputs={"justify-content-start"}
                        onValueChange={getValueInput}
                        type={"number"}
                        parentCol={6}
                        labelName={"Huisnummer"}
                        labelValue={"HouseNumber"}
                        disabled={!countryIdInfo.country_code_2}
                        classnamesInput={"col-12 widthInputComponents px-3 " + (postalCodeError ? "border-danger border-2" : "")}
                        defaultValue={adressData.HouseNumber || formData.HouseNumber}
                      />
                      <InputComponent
                        alignmentInputs={"justify-content-end"}
                        onValueChange={getValueInput}
                        type={"text"}
                        valueType={1}
                        parentCol={6}
                        labelName={"Toevoeging"}
                        labelValue={"Addition"}
                        disabled={!countryIdInfo.country_code_2}
                        classnamesInput={"col-12 widthInputComponents px-3"}
                        defaultValue={adressData.Addition || formData.Addition}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-12 d-flex flex-row align-content-end my-3">
                  <InputComponent
                    type={"text"}
                    onValueChange={getValueInput}
                    alignmentInputs={"justify-content-start"}
                    parentCol={6}

                    labelName={
                      <>
                        Straat
                        {countryIdInfo.country_code_2 === "NL" && formData.StreetName && (
                          <FontAwesomeIcon
                            style={{ marginLeft: "5px", cursor: "pointer" }}
                            onClick={() => setFormData(prev => ({ ...prev, StreetNameEditable: !prev.StreetNameEditable }))}
                            icon={faPencilAlt}
                          />
                        )}
                      </>
                    }
                    valueType={1}
                    labelValue={"StreetName"}
                    classnamesInput={"col-12 widthInputComponents px-3"}
                    defaultValue={adressData.StreetName || formData.StreetName}
                    disabled={(!countryIdInfo.country_code_2) || (!formData.StreetNameEditable && countryIdInfo.country_code_2 === "NL")}
                  />
                  <InputComponent
                    type={"text"}
                    onValueChange={getValueInput}
                    alignmentInputs={"justify-content-end"}
                    parentCol={6}
                    labelName={
                      <>
                        Stad
                        {countryIdInfo.country_code_2 === "NL" && formData.City && (
                          <FontAwesomeIcon
                            style={{ marginLeft: "5px", cursor: "pointer" }}
                            onClick={() => setFormData(prev => ({ ...prev, CityEditable: !prev.CityEditable }))}
                            icon={faPencilAlt}

                          />
                        )}
                      </>
                    }
                    valueType={1}
                    labelValue={"City"}
                    classnamesInput={"col-12 widthInputComponents px-3"}
                    defaultValue={adressData.City || formData.City}
                    disabled={(!countryIdInfo.country_code_2) || (!formData.CityEditable && countryIdInfo.country_code_2 === "NL")}
                  />
                </div>

              </div>
            </div>
            <div className="d-flex justify-content-between mb-3">
              <button className="btn DarkStandardButton" type="button" onClick={() => { setAddAdress(false); setFormData({}); setAdressData({}) }}>Annuleren</button>
              <button className="btn lightBlueStandardButton" type="submit">{editMode ? "Bijwerken" : "Toevoegen"}</button>
            </div>
          </div>
        </form>
      ) : (
        <div className="mb-3 mt-5 tablesCustomerEdit h-100">
          {loading ? (
            <div className="loader text-center"></div>
          ) : (
            <TablePreset
              data={[{ selectedIds, tableInstance, pagination, primary_key: 'order_id' }]}
              setPagination={setPagination}
              handleSelection={handleSelection}
              searchBar={[{ shown: true, value: searchBar, function: setSearchBar }]}
              leftButtons={[{ title: "Filteren", function: () => console.log('a') }]}
              rightButtons={[{ title: "Nieuw adres", function: () => handleNewAdres() }]}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default AddressData;
