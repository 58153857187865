import React from "react";
import { Info } from "lucide-react";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";

const ToolTip = ({
  message,
  side = "top",
  color = "#57C0EC",
  size = 16,
  className = "",
}) => {
  return (
    <Tippy content={message} placement={side}>
      <Info
        size={size}
        className={`cursor-help ${className}`}
        style={{ color }}
      />
    </Tippy>
  );
};

export default ToolTip;