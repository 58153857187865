import React, { useState, useEffect, useCallback, useRef, useMemo } from "react";
import { useParams } from "react-router";
import InputComponent from '../../AddCustomers/InputComponent';
import Plus from "../../AddCustomers/Icons/Add.svg";
import Minus from "../../AddCustomers/Icons/minus.svg"; // You need to add this icon to your project
import middleware from "../../../Api/Middleware";
import ToastError from "../../../Toasts/ToastError";
import LoadingScreen from "../../../LoadingScreen/LoadingScreen";
import ToastSuccess from "../../../Toasts/ToastSuccess";
import { useTable } from "react-table";
import TablePreset from "../../../Table/TablePreset";
import Trash from "../Icons/trash-can.svg";
import Pin from "../Icons/thumbtack-solid.svg";
import CancelPin from "../Icons/thumbtack-slash-solid.svg";
import editIcon from "../Icons/pen-to-square-solid.svg"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import AddContactsModal from "../CustomerModals/AddContactsModal";

const GeneralInfoTab = ({ typeCustomer, setDataUpdated, dataUpdated, setConsumerInfoUpdated }) => {
  const { internal_id } = useParams();
  const [loading, setLoading] = useState(true);
  const [consumerData, setConsumerData] = useState([]);
  const [businessInfo, setBusinessInfo] = useState({});
  const [emailSettings, setEmailSettings] = useState({});
  const [emails, setEmails] = useState([]);
  const [adress, setAdress] = useState([]);
  const [countryCodes, setCountryCodes] = useState([]);
  const [removedEmails, setRemovedEmails] = useState([]); // State for removed emails
  const [phoneNumbers, setPhoneNumbers] = useState([{}]);
  const [removedPhoneNumbers, setRemovedPhoneNumbers] = useState([]); // State for removed phone numbers
  const [showAddContactsModal, setShowAddContactsModal] = useState(false);
  const [initialData, setInitialData] = useState({});
  const [formData, setFormData] = useState({});
  const [rowToEdit, setRowToEdit] = useState(null);
  const [updatedContacts, setUpdatedContacts] = useState(false);
  const formRef = useRef(null); // Step 1: Create a ref for the form
  const vatPatterns = {
    AT: /^ATU\d{8}$/,                        // Austria
    BE: /^BE0\d{9}$/,                        // Belgium
    BG: /^BG\d{9,10}$/,                      // Bulgaria
    CY: /^CY\d{8}[A-Z]$/,                    // Cyprus
    CZ: /^CZ\d{8,10}$/,                      // Czech Republic
    DE: /^DE\d{9}$/,                         // Germany
    DK: /^DK\d{8}$/,                         // Denmark
    EE: /^EE\d{9}$/,                         // Estonia
    EL: /^EL\d{9}$/,                         // Greece
    ES: /^ES[A-Z0-9]\d{7}[A-Z0-9]$/,         // Spain
    FI: /^FI\d{8}$/,                         // Finland
    FR: /^FR[A-Z0-9]{2}\d{9}$/,              // France
    GB: /^GB(\d{9}|\d{12}|GD\d{3}|HA\d{3})$/, // United Kingdom
    HR: /^HR\d{11}$/,                        // Croatia
    HU: /^HU\d{8}$/,                         // Hungary
    IE: /^IE\d[A-Z0-9+*]\d{5}[A-Z]$/,        // Ireland
    IT: /^IT\d{11}$/,                        // Italy
    LT: /^LT(\d{9}|\d{12})$/,                // Lithuania
    LU: /^LU\d{8}$/,                         // Luxembourg
    LV: /^LV\d{11}$/,                        // Latvia
    MT: /^MT\d{8}$/,                         // Malta
    NL: /^NL\d{9}B\d{2}$/,                   // Netherlands
    PL: /^PL\d{10}$/,                        // Poland
    PT: /^PT\d{9}$/,                         // Portugal
    RO: /^RO\d{2,10}$/,                      // Romania
    SE: /^SE\d{12}$/,                        // Sweden
    SI: /^SI\d{8}$/,                         // Slovenia
    SK: /^SK\d{10}$/                         // Slovakia
    // Add more countries as needed
  };
  const [pagination, setPagination] = useState({
    "page_size": 10,
    "page": 1,
    "max_items": 0,
    "begin_of_page": 0,
    "end_of_page": 0,
  });
  useEffect(() => {
    if (!loading) {
      fetchContacts();
      setUpdatedContacts(false);
    }
  }, [pagination.page, pagination.page_size, updatedContacts]);
  const fetchContacts = async () => {
    try {
      const [noteRes] = await Promise.all([
        middleware.get(`businessescontacts?internal_id=${internal_id}&page_size=${pagination.page_size}&page=${pagination.page}&orderBy=created_at&direction=desc`)
      ]);
      setConsumerData(noteRes.data.data);
      setPagination({ ...pagination, "max_items": noteRes.data.total, "begin_of_page": noteRes.data.from, "end_of_page": noteRes.data.to });
    } catch (error) {
      console.error(error);
    }
  }


  const fetchCorrectConsumer = async (internalId) => {
    if (internalId) {
      setLoading(true);
      try {
        let initialData = {};
        if (internalId.startsWith('B')) {
          const [businessResponse, businessContactResponse, emailRes, phoneNumberRes, emailSettingsRes] = await Promise.all([
            middleware.get(`businesses?internal_id=${internalId}`),
            middleware.get(`businessescontacts?internal_id=${internalId}&page_size=${pagination.page_size}&page=${pagination.page}&orderBy=created_at`),
            middleware.get(`emails?internal_id=${internalId}`),
            middleware.get(`phonenumbers?internal_id=${internalId}`),
            middleware.get(`emailsettings?internal_id=${internalId}`)
          ]);

          setBusinessInfo(businessResponse.data[0] ?? {
            cocnumber: '',
            company_id: '',
            company_name: '',
            fax_number: '',
            has_loyalty: 0,
            internal_id: internal_id,
            invoicemail: '',
            loyaltypoints: '',
            loyalty_signup_date: '',
            permanent_sale: '',
            status: '',
            url: '',
            vatnumber: ''
          });
          setConsumerData(businessContactResponse.data.data);
          setPagination({ ...pagination, "max_items": businessContactResponse.data.total, "begin_of_page": businessContactResponse.data.from, "end_of_page": businessContactResponse.data.to });

          setEmails(emailRes.data);
          setPhoneNumbers(phoneNumberRes.data);
          setEmailSettings(emailSettingsRes.data[0]);
          
          initialData = {
            business: businessResponse.data[0] ?? {
              cocnumber: '',
              company_id: '',
              company_name: 'test',
              fax_number: '',
              has_loyalty: 0,
              internal_id: internal_id,
              invoicemail: '',
              loyaltypoints: '',
              loyalty_signup_date: '',
              permanent_sale: '',
              status: '',
              url: '',
              vatnumber: ''
            },
            business_contact: businessContactResponse.data,
            emails: emailRes.data,
            phone_numbers: phoneNumberRes.data,
            email_settings: emailSettingsRes.data[0],
          };
        } else if (internalId.startsWith('C')) {
          const [consumerResponse, emailRes, phoneNumberRes, emailSettingsRes] = await Promise.all([
            middleware.get(`consumers?internal_id=${internalId}`),
            middleware.get(`emails?internal_id=${internalId}`),
            middleware.get(`phonenumbers?internal_id=${internalId}`),
            middleware.get(`emailsettings?internal_id=${internalId}`)
          ]);

          setConsumerData(consumerResponse.data[0]);
          setEmails(emailRes.data);
          setPhoneNumbers(phoneNumberRes.data);
          setEmailSettings(emailSettingsRes.data[0]);

          initialData = {
            consumer: consumerResponse.data[0],
            emails: emailRes.data,
            phone_numbers: phoneNumberRes.data,
            email_settings: emailSettingsRes.data[0],
          };
        }
        const [adressesResponse, countrycodesResponses] = await Promise.all([
          middleware.get(`addresses?internal_id=${internalId}`),
          middleware.get(`countryCodes`),
        ]);
        if (adressesResponse.data.length) {

          countrycodesResponses.data.forEach(countrycode => {
            if (countrycode.country_code_id == adressesResponse.data[0].country_code_id) {
              setCountryCodes(countrycode)
            }
          });
        }
        setInitialData(initialData);
        setFormData(structuredClone(initialData)); // Initialize formData with a deep copy of initial data
      } catch (error) {
        console.error('Error fetching data:', error);
      }
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCorrectConsumer(internal_id);
  }, [dataUpdated])
  const updateNestedFormData = useCallback((key, value, fieldType) => {
    setFormData(prev => ({
      ...prev,
      [fieldType]: {
        ...structuredClone(prev[fieldType]), // Clone the nested object to avoid mutating the original
        [key]: value,
      }
    }));
  }, []);

  const getValueInput = useCallback((value, label) => {
    if (label === 'vatnumber') {
      if (!validateVATNumber(countryCodes.country_code_2, value)) {
        ToastError("BTW-nummer is niet geldig voor het geselecteerde land.");
        return; // Stop further processing if VAT number is invalid
      }
    }

    if (label in businessInfo) {
      updateNestedFormData(label, value, 'business');
    } else if (label in consumerData) {
      updateNestedFormData(label, value, 'consumer');
    } else if (label in emailSettings) {
      updateNestedFormData(label, value, 'email_settings');
    }
  }, [businessInfo, consumerData, emailSettings, countryCodes]);


  const handleBlurInput = useCallback((value, label) => {
    if (label === 'vatnumber') {
      if (!validateVATNumber(countryCodes.country_code_2, value)) {
        ToastError("BTW-nummer is niet geldig voor het geselecteerde land.");
        return; // Stop further processing if VAT number is invalid
      }
    }

    if (label in businessInfo) {
      updateNestedFormData(label, value, 'business');
    } else if (label in consumerData) {
      updateNestedFormData(label, value, 'business_contact');
    } else if (label in emailSettings) {
      updateNestedFormData(label, value, 'email_settings');
    }
  }, [businessInfo, consumerData, emailSettings, countryCodes]);

  const handleSubmit = useCallback(async (e) => {
    e.preventDefault();
    const changedData = {};

    if (formData.emails != null) {
      const newEmails = formData.emails.filter(email => !email.email_id); // Detect new emails without ID
      const newPhoneNumbers = formData.phone_numbers.filter(phone => !phone.phone_number_id); // Detect new phone numbers without ID

      // Handle changes in existing data
      if (typeCustomer === "B") {
        if (JSON.stringify(initialData.business) !== JSON.stringify(formData.business)) {
          changedData.business = formData.business;
        }
        if (JSON.stringify(initialData.business_contact) !== JSON.stringify(formData.business_contact)) {
          changedData.business_contact = formData.business_contact;
        }
      } else if (typeCustomer === "C") {
        if (JSON.stringify(initialData.consumer) !== JSON.stringify(formData.consumer)) {
          changedData.consumer = formData.consumer;
        }
      }

      if (JSON.stringify(initialData.emails) !== JSON.stringify(formData.emails)) {
        changedData.emails = formData.emails.filter(email => email.email_id); // Only include existing emails
      }

      if (JSON.stringify(initialData.phone_numbers) !== JSON.stringify(formData.phone_numbers)) {
        changedData.phone_numbers = formData.phone_numbers.filter(phone => phone.phone_number_id); // Only include existing phone numbers
      }

      let hasError = false; // Track if any error occurs

      try {
        // Send POST requests for new emails and phone numbers
        if (newEmails.length > 0) {
          for (const newEmail of newEmails) {
            if (newEmail.email !== undefined && newEmail.email.trim() !== "") {
              try {
                const res = await middleware.post(`emails`, { internal_id, email: newEmail.email });

                const emails = await middleware.get(`emails?internal_id=${internal_id}`);
                setFormData({...formData, emails: emails.data});
                setEmails(emails.data);
              } catch (error) {
                hasError = true;
                ToastError("Email format is incorrect. voorbeeld: JohnDoe@gmail.com");
                console.log(error);
              }
            } else {
              hasError = true;
            }
          }
        }
        if (newPhoneNumbers.length > 0) {
          for (const newNumber of newPhoneNumbers) {
            if (newNumber.phone_number !== undefined && newNumber.phone_number.trim() !== "") {
              try {
                const res = await middleware.post(`phonenumbers`, {
                  internal_id,
                  phone_number: Number(newNumber.phone_number),
                  country_phone_prefix: newNumber.country_phone_prefix,
                  fullnumber: '+' + newNumber.country_phone_prefix + "-" + Number(newNumber.phone_number)
                });
                let phones = await middleware.get(`phonenumbers?internal_id=${internal_id}`);

                setFormData({ ...formData, phone_numbers: phones.data });
                setPhoneNumbers(phones.data);
              } catch (error) {
                hasError = true;
                ToastError("Telefoonummer format is incorrect.");
                console.log(error);
              }
            } else {
              hasError = true;
            }
          }
        }



        // Handle PUT requests for existing data
        if (Object.keys(changedData).length > 0) {
          if (changedData.business) {
            await middleware.put(`businesses?internal_id=${internal_id}`, changedData.business);
          }
          if (changedData.business_contact) {
            await middleware.put(`businessescontacts?internal_id=${internal_id}`, changedData.business_contact);
          }
          if (changedData.consumer) {
            await middleware.put(`consumers?internal_id=${internal_id}`, changedData.consumer);
          }
          if (changedData.emails) {
            await Promise.all(changedData.emails.map(email =>
              middleware.put(`emails?internal_id=${internal_id}`, {
                email_id: email.email_id,
                email: email.email,
              }).catch(error => {
                hasError = true;
                ToastError("Email format is incorrect. voorbeeld: JohnDoe@gmail.com");
              })
            ));
          }
          if (changedData.phone_numbers) {
            await Promise.all(changedData.phone_numbers.map(phone_number =>
              middleware.put(`phonenumbers?internal_id=${internal_id}`, {
                phone_number_id: phone_number.phone_number_id,
                phone_number: Number(phone_number.phone_number),
                country_phone_prefix: phone_number.country_phone_prefix,
                fullnumber: '+' + phone_number.country_phone_prefix + "-" + Number(phone_number.phone_number)
              }).catch(error => {
                hasError = true;
                ToastError(`Telefoonnummer is ongeldig`);
              })
            ));
          }
        }

        // Check for errors before showing success message
        if (hasError) {

        } else if (Object.keys(changedData).length > 0 || newEmails.length > 0 || newPhoneNumbers.length > 0) {
          ToastSuccess("Veranderingen successvol opgeslagen!");
          // Update initialData to reflect the new state after save
          setInitialData(structuredClone(formData));
          setConsumerInfoUpdated(true);
        }

        // // Refetch the data to get the updated data including newly created IDs


      } catch (error) {
        console.error('Error submitting data:', error);
        ToastError("Error submitting data.");
      }
    }
  }, [formData, initialData, typeCustomer, internal_id]);



  const addEmailField = () => {
    if (emails.length < 3) {
      setEmails([...emails, '']);
    } else {
      ToastError("Je kunt maximaal 3 e-mailadressen toevoegen");
    }
  };

  const handleEmailChange = (index, value) => {
    const newEmails = structuredClone(emails);
    if (typeof newEmails[index] === 'object') {
      newEmails[index].email = value;
    } else {
      newEmails[index] = { email: value };
    }
    setEmails(newEmails);
    setFormData(prev => ({
      ...prev,
      emails: newEmails
    }));
  };

  const handleEmailBlur = (index, value) => {
    const newEmails = structuredClone(emails);
    if (typeof newEmails[index] === 'object') {
      newEmails[index].email = value;
    } else {
      newEmails[index] = { email: value };
    }
    setEmails(newEmails);
    setFormData(prev => ({
      ...prev,
      emails: newEmails
    }));
  };

  const removeEmailField = async (index) => {
    const confirmDelete = window.confirm("Weet je zeker dat je deze e-mail wilt verwijderen?");
    if (confirmDelete) {
      const emailToRemove = emails[index];

      if (emailToRemove.email_id) { // Only delete if the email has an ID
        try {
          await middleware.delete(`/emails?email_id=${emailToRemove.email_id}`);
          ToastSuccess("E-mail succesvol verwijderd!");
          setConsumerInfoUpdated(true);
        } catch (error) {
          console.error('Error deleting email:', error);
          ToastError("Er is een fout opgetreden bij het verwijderen van de e-mail.");
          return;
        }
      }

      // Update state after successful deletion
      const newEmails = emails.filter((_, i) => i !== index);
      setEmails(newEmails);
      setFormData(prev => ({
        ...prev,
        emails: newEmails
      }));
    }
  };

  const validateVATNumber = (countryCode, vatNumber) => {
    const pattern = vatPatterns[countryCode];
    if (!pattern) {
      ToastError("Country code is invalid or not supported.");
      return false;
    }
    return pattern.test(vatNumber);
  };

  const addPhoneNumberField = () => {
    if (phoneNumbers.length < 3) {
      setPhoneNumbers([...phoneNumbers, {country_phone_prefix: '31'}]);
    } else {
      ToastError("Je kunt maximaal 3 telefoonnummers toevoegen");
    }
  };

  const handlePhoneNumberChange = (index, value) => {
    const newPhoneNumbers = structuredClone(phoneNumbers); // Create a deep copy to avoid mutating initialData
    if (typeof newPhoneNumbers[index] === 'object') {
      newPhoneNumbers[index].phone_number = value;
    } else {
      newPhoneNumbers[index] = { phone_number: value };
    }
    setPhoneNumbers(newPhoneNumbers);
    setFormData(prev => ({
      ...prev,
      phone_numbers: newPhoneNumbers
    }));
  };
  const setPhoneCode = (index, value) => {
    const newPhoneNumbers = structuredClone(phoneNumbers); // Create a deep copy to avoid mutating initialData
    if (typeof newPhoneNumbers[index] === 'object') {
      newPhoneNumbers[index].country_phone_prefix = value;
    } else {
      newPhoneNumbers[index] = { country_phone_prefix: value };
    }
    setPhoneNumbers(newPhoneNumbers);
    setFormData(prev => ({
      ...prev,
      phone_numbers: newPhoneNumbers
    }));
  }
  const handlePhoneNumberBlur = (index, value) => {
    const newPhoneNumbers = structuredClone(phoneNumbers); // Create a deep copy to avoid mutating initialData
    if (typeof newPhoneNumbers[index] === 'object') {
      newPhoneNumbers[index].phone_number = value;
    } else {
      newPhoneNumbers[index] = { phone_number: value };
    }
    setPhoneNumbers(newPhoneNumbers);
    setFormData(prev => ({
      ...prev,
      phone_numbers: newPhoneNumbers
    }));
  };

  const removePhoneNumberField = async (index) => {
    const confirmDelete = window.confirm("Weet je zeker dat je dit telefoonnummer wilt verwijderen?");
    if (confirmDelete) {
      const phoneNumberToRemove = phoneNumbers[index];

      if (phoneNumberToRemove.phone_number_id) { // Only delete if the phone number has an ID
        try {
          await middleware.delete(`phonenumbers?phone_number_id=${phoneNumberToRemove.phone_number_id}`);
          ToastSuccess("Telefoonnummer succesvol verwijderd!");
          setConsumerInfoUpdated(true);
        } catch (error) {
          console.error('Error deleting phone number:', error);
          ToastError("Er is een fout opgetreden bij het verwijderen van het telefoonnummer.");
          return;
        }
      }

      // Update state after successful deletion
      const newPhoneNumbers = phoneNumbers.filter((_, i) => i !== index);
      setPhoneNumbers(newPhoneNumbers);
      setFormData(prev => ({
        ...prev,
        phone_numbers: newPhoneNumbers
      }));
    }
  };

  const handleEmailSettingsChange = async (settingKey, value) => {
    const updatedSettings = { ...emailSettings, [settingKey]: value ? 1 : 0 };
    setEmailSettings(updatedSettings);

    const res = await middleware.get(`emailsettings?internal_id=${internal_id}`);

    if (!res.data.length) {
      await middleware.post(`emailsettings?internal_id=${internal_id}`, updatedSettings);
    } else {
      try {
        await middleware.put(`emailsettings?internal_id=${internal_id}`, updatedSettings);
        ToastSuccess("Voorkeur successvol veranderd!");
      } catch (error) {
        console.error('Er ging iets fout met het veranderen van de voorkeur:', error);
        ToastError("Er ging iets fout met het veranderen van de voorkeur");
      }
    }
  };
  const handleEditClick = (row) => {
    setRowToEdit(row);
    setShowAddContactsModal(true);
  };
  const handleDeleteClick = async (row) => {
    const confirm = window.confirm('Weet je zeker dat je deze contactpersoon wil verwijderen?');
    if (confirm) {
      await middleware.delete(`businessescontacts?business_contact_id=${row.business_contact_id}`);
      fetchContacts();
    }
  };

  const columns = useMemo(
    () => [
      {
        Header: () => (
          <div className='fw-bold'>Voornaam</div>
        ),
        accessor: 'firstName',
        Cell: ({ row }) => {

          return (
            <div className="d-flex flex-column">
              <p
                className="mb-0 text-nowrap text-center cursorPointer"
                onClick={() => { }}
                style={{ color: "#009fe3" }}
              >
                {row.original.first_name}
              </p>
            </div>
          );
        }
      },
      {
        Header: () => (
          <div className='text-center fw-bold'>Achternaam</div>
        ),
        accessor: 'lastName',
        Cell: ({ row }) => {
          return (
            <div className="d-flex flex-column">
              <p
                className="mb-0 text-nowrap text-center cursorPointer"
                onClick={() => { }}
                style={{ color: "#009fe3" }}
              >
                {row.original.last_name}
              </p>
            </div>
          );
        }
      },
      {
        Header: () => (
          <div className='text-center fw-bold'>Geboortedatum</div>
        ),
        accessor: 'birthDay',
        Cell: ({ row }) => {
          return (
            <div className="text-center">
              <p className='mb-0'>{row.original.birthday}</p>
            </div>
          );
        }
      },
      {
        Header: () => (
          ""
        ),
        accessor: 'Edit',
        Cell: ({ row }) => {
          return (
            <div className="d-flex flex-column">
              <img className="trashImg" onClick={() => handleEditClick(row.original)} src={editIcon} />
            </div>
          )
        }
      },
      {
        Header: () => (
          ""
        ),
        accessor: 'Delete',
        Cell: ({ row }) => {
          return (
            <div className="d-flex flex-column">
              <img className="trashImg" onClick={() => handleDeleteClick(row.original)} src={Trash} />
            </div>
          )
        }
      },
    ],
    [consumerData]
  );

  const tableInstance = useTable({ columns, data: consumerData.length ? consumerData : [] });

  if (loading) {
    return <h2 className="m-4 loader"></h2>
  }

  return (
    <div className="px-4 py-4">
      <form className="col-12" ref={formRef} onBlur={handleSubmit}>
        {typeCustomer === "B" &&
          <div>
            <label className='fw-bold mb-2 secondaryTitles'>Bedrijfsgegevens</label>
              <div className='col-12 d-flex flex-row align-content-end mb-3'>
                <InputComponent
                  type={"text"}
                  onValueChange={getValueInput}
                  onBlur={handleBlurInput}
                  alignmentInputs={"justify-content-start"}
                  parentCol={12}
                  defaultValue={businessInfo?.company_name || ""}
                  labelName={"Bedrijfsnaam"}
                  labelValue={"company_name"}
                  classnamesInput={"col-12 SearchIcon px-3"}
                />
              </div>
              <div className="col-12 d-flex flex-row align-content-end mb-4">
                <InputComponent
                  type={"text"}
                  onValueChange={getValueInput}
                  onBlur={handleBlurInput}
                  alignmentInputs={"justify-content-start"}
                  parentCol={6}
                  defaultValue={businessInfo?.cocnumber || ""}
                  labelValue={"cocnumber"}
                  labelName={"KVK nummer"}
                  classnamesInput={"col-12 widthInputComponents px-3"}
                />
                <InputComponent
                  type={"text"}
                  onValueChange={getValueInput}
                  onBlur={handleBlurInput}
                  alignmentInputs={"justify-content-end"}
                  parentCol={6}
                  defaultValue={businessInfo?.vatnumber || ""}
                  labelValue={"vatnumber"}
                  labelName={"BTW nummer"}
                  classnamesInput={"col-12 widthInputComponents px-3"}
                />
              </div>
          </div>
        }

        <AddContactsModal rowToEdit={rowToEdit} setRowToEdit={setRowToEdit} openAddContacts={showAddContactsModal} setUpdatedContacts={setUpdatedContacts} closeAddContacts={() => setShowAddContactsModal(false)} />
        <div>
          {typeCustomer === 'C' && <>
            <div className="col-12 d-flex flex-row align-content-end mb-3">
              <InputComponent
                type={"text"}
                onValueChange={getValueInput}
                onBlur={handleBlurInput}
                alignmentInputs={"justify-content-start"}
                parentCol={6}
                defaultValue={consumerData?.first_name || ""}
                labelValue={"first_name"}
                labelName={"Voornaam"}
                classnamesInput={"col-12 widthInputComponents px-3"}
              />
              <InputComponent
                type={"text"}
                onValueChange={getValueInput}
                onBlur={handleBlurInput}
                alignmentInputs={"justify-content-end"}
                parentCol={6}
                defaultValue={consumerData?.last_name || ""}
                labelValue={"last_name"}
                labelName={"Achternaam"}
                classnamesInput={"col-12 widthInputComponents px-3"}
              />
            </div>
          </>}
          <div className="col-12 d-flex flex-row align-content-end my-3">
            <div className="d-flex flex-column col-6 pe-3">
              <div className="d-flex justify-content-between">
                <label className='fw-semibold mb-2'>E-mailadres</label>
                <img
                  className='pe-3 addIconSizeModals'
                  src={Plus}
                  onClick={addEmailField}
                  alt="Add"
                  style={{ cursor: 'pointer' }}
                />
              </div>
              {emails.map((email, index) => (
                <div key={index} className="d-flex align-items-center mb-2">
                  <input
                    className={`inputFieldAddCustomer px-3`}
                    type="email"
                    required={true}
                    style={{ width: "97%" }}
                    value={email.email || ""}
                    onChange={(e) => handleEmailChange(index, e.target.value)}
                    onBlur={(e) => handleEmailBlur(index, e.target.value)}
                  />
                  <img
                    className='ms-2 removeIconSizeModals'
                    src={Minus}
                    onClick={() => removeEmailField(index)}
                    alt="Remove"
                    style={{ cursor: 'pointer', width: "3%" }}
                  />
                </div>
              ))}
            </div>
            <div className="d-flex flex-column col-6 ps-3">
              <div className="d-flex justify-content-between">
                <label className='fw-semibold mb-2'>Telefoonnummer</label>
                <img
                  className='pe-3 addIconSizeModals'
                  src={Plus}
                  onClick={addPhoneNumberField}
                  alt="Add"
                  style={{ cursor: 'pointer' }}
                />
              </div>
              {phoneNumbers.map((phoneNumber, index) => (
                <div key={index} className="d-flex">
                  <select name="countryCode" onChange={(e) => setPhoneCode(index, e.target.value)}  value={phoneNumber.country_phone_prefix} className="standardInput standardDropdownArrow w-50 me-2">
                    <option hidden>Selecteer een landcode</option>
                    <option value="0" code="31">Nederland (+31)</option>
                    <option value="32">België (+32)</option>
                    <option value="49">Duitsland (+49)</option>
                    <optgroup label="Andere landen">
                      <option value="213">Algerije (+213)</option>
                      <option value="376">Andorra (+376)</option>
                      <option value="244">Angola (+244)</option>
                      <option value="1264">Anguilla (+1264)</option>
                      <option value="1268">Antigua & Barbuda (+1268)</option>
                      <option value="54">Argentinië (+54)</option>
                      <option value="374">Armenië (+374)</option>
                      <option value="297">Aruba (+297)</option>
                      <option value="61">Australië (+61)</option>
                      <option value="43">Oostenrijk (+43)</option>
                      <option value="994">Azerbeidzjan (+994)</option>
                      <option value="1242">Bahama's (+1242)</option>
                      <option value="973">Bahrein (+973)</option>
                      <option value="880">Bangladesh (+880)</option>
                      <option value="1246">Barbados (+1246)</option>
                      <option value="375">Wit-Rusland (+375)</option>
                      <option value="501">Belize (+501)</option>
                      <option value="229">Benin (+229)</option>
                      <option value="1441">Bermuda (+1441)</option>
                      <option value="975">Bhutan (+975)</option>
                      <option value="591">Bolivia (+591)</option>
                      <option value="387">Bosnië en Herzegovina (+387)</option>
                      <option value="267">Botswana (+267)</option>
                      <option value="55">Brazilië (+55)</option>
                      <option value="673">Brunei (+673)</option>
                      <option value="359">Bulgarije (+359)</option>
                      <option value="226">Burkina Faso (+226)</option>
                      <option value="257">Burundi (+257)</option>
                      <option value="855">Cambodja (+855)</option>
                      <option value="237">Kameroen (+237)</option>
                      <option value="1">Canada (+1)</option>
                      <option value="238">Kaapverdië (+238)</option>
                      <option value="1345">Kaaimaneilanden (+1345)</option>
                      <option value="236">Centraal-Afrikaanse Republiek (+236)</option>
                      <option value="56">Chili (+56)</option>
                      <option value="86">China (+86)</option>
                      <option value="57">Colombia (+57)</option>
                      <option value="269">Comoren (+269)</option>
                      <option value="242">Congo (+242)</option>
                      <option value="682">Cookeilanden (+682)</option>
                      <option value="506">Costa Rica (+506)</option>
                      <option value="385">Kroatië (+385)</option>
                      <option value="53">Cuba (+53)</option>
                      <option value="90392">Noord-Cyprus (+90392)</option>
                      <option value="357">Zuid-Cyprus (+357)</option>
                      <option value="42">Tsjechië (+42)</option>
                      <option value="45">Denemarken (+45)</option>
                      <option value="253">Djibouti (+253)</option>
                      <option value="1809">Dominica (+1809)</option>
                      <option value="1809">Dominicaanse Republiek (+1809)</option>
                      <option value="593">Ecuador (+593)</option>
                      <option value="20">Egypte (+20)</option>
                      <option value="503">El Salvador (+503)</option>
                      <option value="240">Equatoriaal-Guinea (+240)</option>
                      <option value="291">Eritrea (+291)</option>
                      <option value="372">Estland (+372)</option>
                      <option value="251">Ethiopië (+251)</option>
                      <option value="500">Falklandeilanden (+500)</option>
                      <option value="298">Faeröer (+298)</option>
                      <option value="679">Fiji (+679)</option>
                      <option value="358">Finland (+358)</option>
                      <option value="33">Frankrijk (+33)</option>
                      <option value="594">Frans-Guyana (+594)</option>
                      <option value="689">Frans-Polynesië (+689)</option>
                      <option value="241">Gabon (+241)</option>
                      <option value="220">Gambia (+220)</option>
                      <option value="7880">Georgië (+7880)</option>
                      <option value="233">Ghana (+233)</option>
                      <option value="350">Gibraltar (+350)</option>
                      <option value="30">Griekenland (+30)</option>
                      <option value="299">Groenland (+299)</option>
                      <option value="1473">Grenada (+1473)</option>
                      <option value="590">Guadeloupe (+590)</option>
                      <option value="671">Guam (+671)</option>
                      <option value="502">Guatemala (+502)</option>
                      <option value="224">Guinee (+224)</option>
                      <option value="245">Guinee-Bissau (+245)</option>
                      <option value="592">Guyana (+592)</option>
                      <option value="509">Haïti (+509)</option>
                      <option value="504">Honduras (+504)</option>
                      <option value="852">Hongkong (+852)</option>
                      <option value="36">Hongarije (+36)</option>
                      <option value="354">IJsland (+354)</option>
                      <option value="91">India (+91)</option>
                      <option value="62">Indonesië (+62)</option>
                      <option value="98">Iran (+98)</option>
                      <option value="964">Irak (+964)</option>
                      <option value="353">Ierland (+353)</option>
                      <option value="972">Israël (+972)</option>
                      <option value="39">Italië (+39)</option>
                      <option value="1876">Jamaica (+1876)</option>
                      <option value="81">Japan (+81)</option>
                      <option value="962">Jordanië (+962)</option>
                      <option value="7">Kazachstan (+7)</option>
                      <option value="254">Kenia (+254)</option>
                      <option value="686">Kiribati (+686)</option>
                      <option value="850">Noord-Korea (+850)</option>
                      <option value="82">Zuid-Korea (+82)</option>
                      <option value="965">Koeweit (+965)</option>
                      <option value="996">Kirgizië (+996)</option>
                      <option value="856">Laos (+856)</option>
                      <option value="371">Letland (+371)</option>
                      <option value="961">Libanon (+961)</option>
                      <option value="266">Lesotho (+266)</option>
                      <option value="231">Liberia (+231)</option>
                      <option value="218">Libië (+218)</option>
                      <option value="417">Liechtenstein (+417)</option>
                      <option value="370">Litouwen (+370)</option>
                      <option value="352">Luxemburg (+352)</option>
                      <option value="853">Macau (+853)</option>
                      <option value="389">Macedonië (+389)</option>
                      <option value="261">Madagaskar (+261)</option>
                      <option value="265">Malawi (+265)</option>
                      <option value="60">Maleisië (+60)</option>
                      <option value="960">Maldiven (+960)</option>
                      <option value="223">Mali (+223)</option>
                      <option value="356">Malta (+356)</option>
                      <option value="692">Marshalleilanden (+692)</option>
                      <option value="596">Martinique (+596)</option>
                      <option value="222">Mauritanië (+222)</option>
                      <option value="269">Mayotte (+269)</option>
                      <option value="52">Mexico (+52)</option>
                      <option value="691">Micronesië (+691)</option>
                      <option value="373">Moldavië (+373)</option>
                      <option value="377">Monaco (+377)</option>
                      <option value="976">Mongolië (+976)</option>
                      <option value="1664">Montserrat (+1664)</option>
                      <option value="212">Marokko (+212)</option>
                      <option value="258">Mozambique (+258)</option>
                      <option value="95">Myanmar (+95)</option>
                      <option value="264">Namibië (+264)</option>
                      <option value="674">Nauru (+674)</option>
                      <option value="977">Nepal (+977)</option>
                      <option value="687">Nieuw-Caledonië (+687)</option>
                      <option value="64">Nieuw-Zeeland (+64)</option>
                      <option value="505">Nicaragua (+505)</option>
                      <option value="227">Niger (+227)</option>
                      <option value="234">Nigeria (+234)</option>
                      <option value="683">Niue (+683)</option>
                      <option value="672">Norfolk (+672)</option>
                      <option value="670">Noordelijke Marianen (+670)</option>
                      <option value="47">Noorwegen (+47)</option>
                      <option value="968">Oman (+968)</option>
                      <option value="680">Palau (+680)</option>
                      <option value="507">Panama (+507)</option>
                      <option value="675">Papoea-Nieuw-Guinea (+675)</option>
                      <option value="595">Paraguay (+595)</option>
                      <option value="51">Peru (+51)</option>
                      <option value="63">Filipijnen (+63)</option>
                      <option value="48">Polen (+48)</option>
                      <option value="351">Portugal (+351)</option>
                      <option value="1787">Puerto Rico (+1787)</option>
                      <option value="974">Qatar (+974)</option>
                      <option value="262">Réunion (+262)</option>
                      <option value="40">Roemenië (+40)</option>
                      <option value="7">Rusland (+7)</option>
                      <option value="250">Rwanda (+250)</option>
                      <option value="378">San Marino (+378)</option>
                      <option value="239">Sao Tomé en Principe (+239)</option>
                      <option value="966">Saoedi-Arabië (+966)</option>
                      <option value="221">Senegal (+221)</option>
                      <option value="381">Servië (+381)</option>
                      <option value="248">Seychellen (+248)</option>
                      <option value="232">Sierra Leone (+232)</option>
                      <option value="65">Singapore (+65)</option>
                      <option value="421">Slowakije (+421)</option>
                      <option value="386">Slovenië (+386)</option>
                      <option value="677">Salomonseilanden (+677)</option>
                      <option value="252">Somalië (+252)</option>
                      <option value="27">Zuid-Afrika (+27)</option>
                      <option value="34">Spanje (+34)</option>
                      <option value="94">Sri Lanka (+94)</option>
                      <option value="290">Sint-Helena (+290)</option>
                      <option value="1869">Saint Kitts en Nevis (+1869)</option>
                      <option value="1758">Saint Lucia (+1758)</option>
                      <option value="249">Soedan (+249)</option>
                      <option value="597">Suriname (+597)</option>
                      <option value="268">Swaziland (+268)</option>
                      <option value="46">Zweden (+46)</option>
                      <option value="41">Zwitserland (+41)</option>
                      <option value="963">Syrië (+963)</option>
                      <option value="886">Taiwan (+886)</option>
                      <option value="7">Tadzjikistan (+7)</option>
                      <option value="66">Thailand (+66)</option>
                      <option value="228">Togo (+228)</option>
                      <option value="676">Tonga (+676)</option>
                      <option value="1868">Trinidad en Tobago (+1868)</option>
                      <option value="216">Tunesië (+216)</option>
                      <option value="90">Turkije (+90)</option>
                      <option value="7">Turkmenistan (+7)</option>
                      <option value="993">Turkmenistan (+993)</option>
                      <option value="1649">Turks- en Caicoseilanden (+1649)</option>
                      <option value="688">Tuvalu (+688)</option>
                      <option value="256">Oeganda (+256)</option>
                      <option value="44">VK (+44)</option>
                      <option value="380">Oekraïne (+380)</option>
                      <option value="971">Verenigde Arabische Emiraten (+971)</option>
                      <option value="598">Uruguay (+598)</option>
                      <option value="1">VS (+1)</option>
                      <option value="7">Oezbekistan (+7)</option>
                      <option value="678">Vanuatu (+678)</option>
                      <option value="379">Vaticaanstad (+379)</option>
                      <option value="58">Venezuela (+58)</option>
                      <option value="84">Vietnam (+84)</option>
                      <option value="84">Britse Maagdeneilanden (+1284)</option>
                      <option value="84">Amerikaanse Maagdeneilanden (+1340)</option>
                      <option value="681">Wallis en Futuna (+681)</option>
                      <option value="969">Jemen (Noord) (+969)</option>
                      <option value="967">Jemen (Zuid) (+967)</option>
                      <option value="260">Zambia (+260)</option>
                      <option value="263">Zimbabwe (+263)</option>
                    </optgroup>
                  </select>
                  <input
                    key={index}
                    className={`inputFieldAddCustomer mb-2 px-3`}
                    type="number"
                    required={true}
                    value={phoneNumber.phone_number}
                    onChange={(e) => handlePhoneNumberChange(index, e.target.value)}
                  />
                  <img
                    className='ms-2 removeIconSizeModals'
                    src={Minus}
                    onClick={() => removePhoneNumberField(index)}
                    alt="Remove"
                    style={{ cursor: 'pointer', width: "3%" }}
                  />
                </div>

              ))}
            </div>
          </div>
          <div className="col-12 d-flex flex-row align-content-end my-3">
            {typeCustomer === "C" &&
              <InputComponent
                type={"date"}
                onValueChange={getValueInput}
                onBlur={handleBlurInput}
                parentCol={6}
                defaultValue={consumerData?.birthday || ""}
                labelValue={"birthday"}
                labelName={"Geboortedatum"}
                classnamesInput={"col-12 widthInputComponents diffDateIcon px-3"}
              />
            }
            <div className="col-6 d-flex justify-content-start ps-3">
              <div className="col-4 d-flex flex-column">
                <label className="mb-2 fw-medium fw-semibold">Nieuwsbrief</label>
                <label className="switchNewCustomer">
                  <input
                    type="checkbox"
                    checked={emailSettings?.newsletter === 1}
                    onChange={(e) => handleEmailSettingsChange('newsletter', e.target.checked)}
                  />
                  <span className="sliderNewCustomer round"></span>
                </label>
              </div>
              <div className=" col-4  d-flex flex-column">
                <label className="mb-2 fw-semibold">Verjaardagactie</label>
                <label className="switchNewCustomer">
                  <input
                    type="checkbox"
                    checked={emailSettings?.birthday_promotion === 1}
                    onChange={(e) => handleEmailSettingsChange('birthday_promotion', e.target.checked)}
                  />
                  <span className="sliderNewCustomer round"></span>
                </label>
              </div>
            </div>
          </div>
          {typeCustomer === "B" && <>
            <label className={`fw-bold mb-2 secondaryTitles ${typeCustomer === "B" ? "" : "d-none"}`}>Contactpersonen <span className="p-2" role="button" onClick={() => setShowAddContactsModal(true)}><FontAwesomeIcon icon={faPlus} /></span> </label>
            <div className="px-0 tableContacts">
              <TablePreset data={[{ 'tableInstance': tableInstance, 'pagination': pagination, 'primary_key': 'business_contact_id' }]}
                setPagination={setPagination}
                handleSelection={() => { }}
                noCheckbox={true} />
            </div>
          </>
          }
        </div>
      </form >
    </div >
  );
}

export default GeneralInfoTab;
