import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { faPencil, faPlus, faX, faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useParams } from 'react-router';
import middleware from '../../../Api/Middleware';
import ToastError from '../../../Toasts/ToastError';

const AddPointsModal = ({ openAddPoints, closeAddPoints, setUpdatedLoyalty }) => {
  const [showAddPoints, setShowAddPoints] = useState(false);
  const [points, setPoints] = useState("");
  const [reasons, setReasons] = useState([]);
  const [reason, setReason] = useState(0);
  const [newReason, setNewReason] = useState("");
  const [addNewReason, setAddNewReason] = useState(false);
  const { internal_id } = useParams();
  const fetchAll = async () => {
    const reasonsResponse = await middleware.get('/loyaltypointreasons?type=0&base=1');
    setReasons(reasonsResponse.data);
  }
  useEffect(() => {
    if (openAddPoints) {
      fetchAll();
      setShowAddPoints(true);
    }
  }, [openAddPoints]);

  useEffect(() => {
    if (reason == 16 || reason == 17) {
      setAddNewReason(true);
    }
    else {
      setAddNewReason(false)
    }
  }, [reason])
  const closeAddPointsModal = () => {
    setShowAddPoints(false);
    closeAddPoints(true);
  }

  const submitNewAddPoints = async (event) => {
    if (addNewReason && newReason == "") {
      ToastError("Vul een nieuwe reden in.")
      return;
    }
    if (points < 1) {
      ToastError("Je mag niet meer dan 1 miljoen punten toevoegen.")
      return;
    }
    if (points > 1000000) {
      ToastError("Je mag niet meer dan 1 miljoen punten toevoegen.")
      return;
    }
    try {
      if (event) {
        event.preventDefault();
      }

      // Convert points to a string with a plus sign
      const pointsWithPlus = `+${points}`;
      if (addNewReason) {
        let res;
        res = await middleware.post('loyaltypointreasons', {
          reason: newReason,
          type: 0,
        })
        let reason_id = res.data.reason_id;
        await middleware.post("loyaltymutations", {
          internal_id: internal_id,
          amount: pointsWithPlus, // Send points with a plus sign
          reason_id: reason_id,
        }).then(() => setUpdatedLoyalty(true) + closeAddPointsModal());
      } else {
        await middleware.post("loyaltymutations", {
          internal_id: internal_id,
          amount: pointsWithPlus, // Send points with a plus sign
          reason_id: reason,
        }).then(() => setUpdatedLoyalty(true) + closeAddPointsModal());
      }
    } catch (err) {
      console.error(err);
    }
  };
  return (
    <Modal
      id="modalBlueprint"
      show={showAddPoints}
      onHide={() => closeAddPointsModal()}
      className="colormodals"
      size="xl"
      centered
      backdrop="static"
    >
      <Modal.Header className="modalTop ps-5 pt-5 justify-content-between">
        <h3>Punten toevoegen</h3>
        <button variant="transparent" className=' btn' onClick={() => closeAddPointsModal()}>
          <FontAwesomeIcon icon={faXmark} className="closeModal" />
        </button>
      </Modal.Header>
      <Modal.Body className="modalCenter" style={{ height: "300px" }}>
        <div className='col-12'>
          <div className='d-flex flex-column col-4 mb-3'>
            <label className='mb-2 fw-medium'>Punten toevoegen</label>
            <div className='d-flex align-items-center'>
              <input
                type='text'
                value={points}
                onChange={(e) => {
                  const reg = /^$|^[1-9][0-9]*$/;
                  const newValue = e.target.value;
                  if (reg.test(newValue)) {
                    setPoints(newValue);
                  }
                }}
                className='standardInput px-2'
              />
            </div>
          </div>
          <div className='d-flex flex-column col-12'>
            <label className='mb-2 fw-medium'>Reden
            </label>
            <select value={reason} className='standardInput standardDropdownArrow px-2' onChange={(e) => setReason(e.target.value)}>
              <option value={0}>Selecteer een reden</option>
              {reasons && reasons.map((e, index) => (
                <option value={e.reason_id} key={index}>
                  {e.reason}
                </option>
              ))}
            </select>
            {addNewReason && (
              <input
                type='text'
                value={newReason}
                onChange={(e) => setNewReason(e.target.value)}
                className='standardInput px-2 mt-2'
                placeholder='Vul een reden in'
              />
            )}
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className="modalBottom">
        <button disabled={reason == 0 || (addNewReason && newReason == "") || points == ""} className='lightBlueStandardButton' onClick={(e) => submitNewAddPoints(e)}>Opslaan</button>
      </Modal.Footer>
    </Modal>
  );
}

export default AddPointsModal;
