import React from 'react';
import Modal from 'react-bootstrap/Modal';

const PasswordRequirementsModal = ({ show, onHide }) => (
  <Modal show={show} onHide={onHide}>
    <Modal.Header closeButton className="bg-white">
      <Modal.Title>Minimale wachtwoordvereisten</Modal.Title>
    </Modal.Header>
    <Modal.Body className="bg-white">
      <ul>
        <li>6 Tekens</li>
        <li>1 Kleine letter</li>
        <li>1 Hoofdletter</li>
        <li>1 cijfer</li>
        <li>1 speciaal teken</li>
      </ul>
    </Modal.Body>
    <Modal.Footer className="bg-white">
      <button className="btn btn-outline-primary" onClick={onHide}>
        Ik heb het begrepen
      </button>
    </Modal.Footer>
  </Modal>
);

export default PasswordRequirementsModal;