import React, { useState, useEffect } from "react";
import middleware from "../../Api/Middleware";
import Cookies from "universal-cookie";
import { useNavigate } from "react-router";
import "../../Main/Main.css";
import "./EditBlueprints.css";
import Loading from "../../LoadingScreen/LoadingScreen";
import NavbarIcon from "../../WarehouseSettings/Icons/size.png"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faPen,
  faTriangleExclamation,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import TableDrag from "../../Table/TableDrag/TableDrag";
import { Modal } from "react-bootstrap";
import PageTemplate from "../../Templates/PageTemplate";

function EditBlueprints() {
  const navigate = useNavigate();
  const cookies = new Cookies();
  const [show, setShow] = useState(false);
  const [selectedSizeIds, setSelectedSizeIds] = useState([]); // usestate for the selected checkboxes
  const [loading, setLoading] = useState(true); // useState for page loading
  const [searchengine, setSearchengine] = useState("");
  const [optionChosen, setOptionChosen] = useState("");
  const [editBlueprintName, setEditBlueprintName] = useState("");
  const [blueprintData, setBlueprintData] = useState([]);
  const [blueprintTitle, setBlueprintTitle] = useState("");
  const [loadingScreen, setLoadingScreen] = useState(true); // useState for the Loadingscreen
  const [oldSizes, setOldSizes] = useState([]); // useState for all products in the database
  const [sizes, setSizes] = useState([]); // useState for all products in the database
  const [dataEditSizeModal, setDataEditSizeModal] = useState([]);
  const [sizeName, setSizeName] = useState("");
  const [sizeNameNew, setSizeNameNew] = useState("");
  const [sizeNewSortingOrder, setSizeNewSortingOrder] = useState();
  const [showEditSizeModal, setShowEditSizeModal] = useState(false);
  const [searchBarInput, setSearchBarInput] = useState("");
  const [timerId, setTimerId] = useState(null);
  const [showNewSizeModal, setShowNewSizeModal] = useState(false);
  const [pagination, setPagination] = useState({
    page_size: 10,
    page: 1,
    max_items: 0,
    begin_of_page: 0,
    end_of_page: 0,
  }); // the useState for the pagination for the table

  // handles to close the de-activate modal
  const handleClose = () => setShow(false);
  const handleCloseEditSize = () => setShowEditSizeModal(false);
  const handleCloseNewSize = () => setShowNewSizeModal(false);

  // fetched all the products and the stock an the colors from the database
  const fetchAll = async () => {
    try {
      const storedData = localStorage.getItem("Blueprintdata");
      if (!storedData) {
        console.log("Error: Blueprint data not found in local storage");
      } else {
        setBlueprintData(JSON.parse(storedData));
      }
      setLoading(true);
      const [fetchSizes] = await Promise.all([
        middleware.get(`products/sizes?page=${pagination.page}&page_size=${pagination.page_size}&orderBy=sorting_order&status=0&size_id=${JSON.parse(storedData).size_ids}`),
      ]);

      const updatedSizesOrder = fetchSizes.data.data.map((item, index) => ({
        ...item,
        sorting_order:
          index +
          (pagination.page === 1
            ? 0
            : pagination.page_size * (pagination.page - 1)) +
          1, // updating the sorting order based on position
      }));

      const isOrderChanged = fetchSizes.data.data.some((item, index) => {
        return item.sorting_order !== updatedSizesOrder[index].sorting_order;
      });

      if (isOrderChanged) {
        await middleware.put(`products/sizes`, {
          bulk_data: updatedSizesOrder,
        });
      }

      setSizes(updatedSizesOrder);
      setOldSizes(updatedSizesOrder);
      setPagination({
        ...pagination,
        max_items: fetchSizes.data.total,
        begin_of_page: fetchSizes.data.from,
        end_of_page: fetchSizes.data.to,
      }); // sets the pagination usestate with the max items begin of the page and the end of the page
      setLoadingScreenFunc();
    } catch (error) {
      if (error.response.status === 401) {
        cookies.remove("token", { path: "/" });
        navigate("/");
      }
      console.log(error.response);
    }
  };

  // function that does an api call to only get the colors the user searched for and updates whenever the colorsearchengine changes
  useEffect(() => {
    try {
      if (searchengine.trim() === "" && searchengine === "") {
        // Code to fetch all when searchengine is empty
        fetchAll();
      } else if (searchengine.trim() !== "") {
        // Code to perform the search when searchengine has actual characters
        const doIt = async () => {
          const [fetchSizes] = await Promise.all([
            middleware.get(
              `products/sizes?page=${pagination.page}&page_size=${pagination.page_size}&searchTerm=${searchengine}&noSearch=[primary_key,size_id,version,status,updated_at,created_at]`
            ),
          ]);
          setSizes(fetchSizes.data.data);
          setPagination({
            ...pagination,
            max_items: fetchSizes.data.total,
            begin_of_page: fetchSizes.data.from,
            end_of_page: fetchSizes.data.to,
          });
        };
        doIt();
      } 
    } catch (error) {
    if (error.response.status === 401) {
      cookies.remove("token", { path: "/" });
      navigate("/");
    }
  }
    
}, [searchengine]);

  const searchDelay = async (value) => {
    // const value = await e.target.value
    setSearchBarInput(value)
    if (timerId) {
      clearTimeout(timerId);
    }
    const newTimerId = setTimeout(() => {
      setSearchengine(value);
    }, 500);

    setTimerId(newTimerId);
  };

  // useEffect that fires every time the pagination.page_size or pagination.page changes and on every fire it uses the fetchall function
  useEffect(() => {
    fetchAll();
  }, [pagination.page_size, pagination.page]);

  // function that first sets the set loading to fals and than setloading screen to give everything time to load in
  function setLoadingScreenFunc() {
    setTimeout(() => {
      setLoading(false);
    }, 400);
  }

  // a function that delays something for 0,5sec
  function delayedFunction(Func) {
    setTimeout(() => {
      Func();
    }, 500);
  }

  // function that suspends colors the are selected
  const suspend = async () => {
    // Iterate over each product id in the selectedSizeIds array
    let bulkData = [];
    selectedSizeIds.map(async (data) => {
      bulkData.push({
        primary_key: data,
        status: 1,
        sorting_order: null,
      });
    });

    await middleware.put("products/sizes", {
      bulk_data: bulkData,
    });

    // After updating all products, call fetchAll function to get updated list of products
    await fetchAll();
  };

  const handleEditSize = async (event) => {
    setDataEditSizeModal(event[0]);
    setShowEditSizeModal(event[1]);
  };

  const submitEditSizes = async (event) => {
    event.preventDefault();

    try {
      if (!sizeName || sizeName == dataEditSizeModal.name) {
        return;
      }
      await middleware.post(`products/sizes`, {
        size_id: dataEditSizeModal.size_id,
        name: sizeName,
      });


    } catch (error) {
      console.error(error);
    }
  };

  const submitSizes = async (event) => {
    event.preventDefault();

    try {
      const sizeResponse = await middleware.post(`products/sizes`, {
        name: sizeNameNew,
        sorting_order: sizeNewSortingOrder,
      });

      const newData = await putSizesBlueprint(sizeResponse.data.size_id);
      if (!newData) {
        console.error("Error while updating");
        return;
      }

      localStorage.setItem("Blueprintdata", JSON.stringify(newData));
      setShowNewSizeModal(false);
      window.location.reload();
    } catch (error) {
      console.error(error);
    }
  };

  const putSizesBlueprint = async (newSizeId) => {
    try {
      const res = await middleware.put(`products/sizes/blueprints`, {
        blueprint_id: blueprintData.blueprint_id,
        size_ids:
          (blueprintData.size_ids ? blueprintData.size_ids + "," : "") +
          newSizeId,
      });
      return res.data;
    } catch (error) {
      console.error(error);
    }
  };

  const putNameBlueprint = async (name) => {
    try {
      const res = await middleware.put(`products/sizes/blueprints`, {
        blueprint_id: blueprintData.blueprint_id,
        title: name,
      });
      localStorage.setItem("Blueprintdata", JSON.stringify(res.data));
      setBlueprintData(res.data);
    } catch (error) {
      console.error(error);
    }
  };

  const openSizeModal = (modal) => {
    if (modal == "maat") {
      setShowNewSizeModal(true);
    }
  };

  // if (loading) {
  //   return (
  //     <div
  //       className={`loading-container ${
  //         loadingScreen ? "fade-in" : "fade-out"
  //       }`}
  //     >
  //       <Loading />
  //     </div>
  //   );
  // }

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton className="bg-white">
          <Modal.Title>Let op!</Modal.Title>
        </Modal.Header>
        <Modal.Body className="bg-white">
          U gaat {selectedSizeIds.length}{" "}
          {selectedSizeIds.length === 1 ? "item" : "items"} de-activeren. Weet U
          dat zeker?
        </Modal.Body>
        <Modal.Footer className="bg-white">
          <button className="btn bg-danger text-white" onClick={handleClose}>
            Annuleren
          </button>
          <button
            className="btn bg-success text-white"
            onClick={() => {
              handleClose();
              delayedFunction(suspend);
              setSelectedSizeIds([]);
            }}
          >
            Ik weet het zeker
          </button>
        </Modal.Footer>
      </Modal>

      <Modal
        id="modalBlueprint"
        show={showEditSizeModal}
        onHide={handleCloseEditSize}
        
        size="md"
        backdrop="static"
      >
        <Modal.Header className="modalTop d-flex justify-content-between">
          <Modal.Title className="ms-3">Edit maat</Modal.Title>
          <button
            className="border-0 bg-transparent"
            variant="transparent"
            onClick={handleCloseEditSize}
          >
            <FontAwesomeIcon icon={faXmark} className="closeModal" />
          </button>
        </Modal.Header>
        <form onSubmit={submitEditSizes}>
          <Modal.Body className="modalCenter pb-0 justify-content-around">
            <div className="w-100">
              <div>
                <label className="inputTitle">Maat naam*</label>
                <input
                  className="optionsList optionListWidt text-center mb-3 w-100"
                  placeholder="Maat naam"
                  name="size_name"
                  defaultValue={dataEditSizeModal.name}
                  onChange={(e) => setSizeName(e.target.value)}
                ></input>
                <small>
                  <FontAwesomeIcon
                    icon={faTriangleExclamation}
                    className="text-warning me-1"
                  ></FontAwesomeIcon>
                  Let op bij het opslaan van deze maat naam zou het doorgevoerd
                  worden naar waar deze naam aan gekoppelt is
                  <FontAwesomeIcon
                    icon={faTriangleExclamation}
                    className="text-warning ms-1"
                  ></FontAwesomeIcon>
                </small>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer className="modalBottom">
            <button type="submit" className="btn bg-success text-white">
              Opslaan
            </button>
          </Modal.Footer>
        </form>
      </Modal>

      <Modal
        id="modalBlueprint"
        show={showNewSizeModal}
        onHide={handleCloseNewSize}
        
        size="md"
        backdrop="static"
      >
        <Modal.Header className="modalTop d-flex justify-content-between">
          <Modal.Title className="ms-3">Nieuwe maat</Modal.Title>
          <button
            className="border-0 bg-transparent"
            variant="transparent"
            onClick={handleCloseNewSize}
          >
            <FontAwesomeIcon icon={faXmark} className="closeModal" />
          </button>
        </Modal.Header>
        <form onSubmit={submitSizes}>
          <Modal.Body className="modalCenter pb-0 justify-content-around">
            <div className="w-100">
              <div>
                <label className="inputTitle">Maat naam*</label>
                <input
                  className="optionsList optionListWidt text-center mb-3 w-100"
                  placeholder="Maat naam"
                  name="size_name"
                  required
                  onChange={(e) => setSizeNameNew(e.target.value)}
                ></input>
              </div>
              <div>
                <label className="inputTitle">Sorteer volgorde*</label>
                <input
                  className="optionsList optionListWidt text-center w-100"
                  placeholder="Sorteer volgorde"
                  name="sorting_order"
                  required
                  onChange={(e) => setSizeNewSortingOrder(e.target.value)}
                ></input>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer className="modalBottom">
            <button type="submit" className="btn bg-success text-white">
              Opslaan
            </button>
          </Modal.Footer>
        </form>
      </Modal>

      <PageTemplate navbarTitle={"Bewerken blueprint"} iconNavbar={NavbarIcon} backValue={"/warehouse/instellingen"}>
        <div className="w-100">
          <div className="d-flex justify-content-between flex-column mb-4  editBlueprintOverview">
            <div className="d-flex flex-column justify-content-start">
              {editBlueprintName == false ? (
                <div className="d-flex flex-row mb-3">
                  <button
                    className="btn bg-white fw-semibold rounded-pill"
                    onClick={() => navigate("/warehouse/instellingen/blauwdrukken")}
                  >
                      <FontAwesomeIcon icon={faChevronLeft}/>
                  </button>
                  <h3 className="mb-0 ms-3 d-flex">
                    {blueprintData.name || blueprintTitle}
                  </h3>
                  <div className="editIconBlueprintName">
                    <FontAwesomeIcon
                      className=" ms-2 d-flex flex-row hover"
                      onClick={() => setEditBlueprintName(true)}
                      style={{ width: "15px", height: "15px" }}
                      icon={faPen}
                    />
                  </div>
                </div>
              ) : (
                <div>
                  <input
                    className="optionsList optionListWidth mb-2 text-center pe-2 ps-0 mt-2"
                    placeholder="Blueprint titel"
                    name="colorName"
                    defaultValue={blueprintData.title ?? ""}
                    style={{ height: "38px" }}
                    onBlur={(e) => {
                      setEditBlueprintName(false);
                      setBlueprintTitle(e.target.value)
                      putNameBlueprint(e.target.value);
                    }}
                  />
                </div>
              )}
            </div>
            <div className=" d-flex justify-content-start w-100 flex-wrap">
              <div className="editBlueprint">
                <TableDrag
                  dataParameter={sizes}
                  tableLoading={loading}
                  pagination={pagination}
                  handlePaginationChange={(event) => setPagination(event)}
                  primaryKey={"primary_key"}
                  urlEndPoint={"products/sizes"}
                  dataEditModalSize={handleEditSize}
                  closeEditModalSize={showEditSizeModal}
                  optionChosen={optionChosen}
                  openModal={(e) => openSizeModal(e)}
                  newItemTitle={"maat"}
                  searchTerm={(e) => searchDelay(e)}
                  editColor={() => {}}
                  columns={[
                    { key: "sorting_order", title: "Sorteer volgorde" },
                    { key: "name", title: "Naam" },
                  ]}
                />
              </div>
            </div>
          </div>
        </div>
      </PageTemplate>
    </>
  );
}

export default EditBlueprints;
