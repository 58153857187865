import React, { useState, useEffect, useRef } from "react";
import Cookies from "universal-cookie";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router";
import middleware from "../../../Api/Middleware";
import salesChannelsApi from "../../../Api/SalesChannels";
import { Modal } from "react-bootstrap";
import VariationModal from "../sub_components/VariationModals";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGear } from "@fortawesome/free-solid-svg-icons";
import "../css/AddProductPages.css";
import "../../../Main/Main.css";
import "../../product.css";
import ToastError from "../../../Toasts/ToastError";
import ToastSuccess from "../../../Toasts/ToastSuccess";
import NavbarIcon from "../../../Navbars/Sidebar/Icons/globe.png";
import Backbutton from "../images/BackIcon.svg";
import ThirdBox from "../edit/edit_components/boxthird";
import PriceBox from "../edit/edit_components/boxPrice";
// Media
import { faPlus, faXmark } from "@fortawesome/free-solid-svg-icons";
import CategoryList from "../sub_components/CategoryList";
import PageTemplate from "../../../Templates/PageTemplate";
import CategoryModal from "../../../Modals/CategoryModal";

// boxes
import FirstBox from "../edit/edit_components/boxfirst";
import FourthBox from "../edit/edit_components/boxfourth";

import TextEditor from "../../../Main/TextEditor";
import MediaUploaderComponent from "../../../MediaUpload/MediaUploaderComponent";
import Categories from "../sub_components/Categories";

const AddSimple = () => {
  const navigate = useNavigate();
  const [brands, setBrands] = useState([]);
  const [salesChannels, setSalesChannels] = useState([]);
  const [genders, setGenders] = useState([]);
  const [categories, setCategories] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [buttonPressed, setbuttonPressed] = useState("");
  const [brandId, setBrandId] = useState(null);
  const [description, setDescription] = useState("");
  const [selectedOption, setSelectedOption] = useState("0");
  const previousSelectedOption = useRef(selectedOption);
  const [barcode, setBarcode] = useState("");
  const [tax, setTax] = useState("0");
  const [selectedTax, setSelectedTax] = useState("21");
  const [images, setImages] = useState([]);
  const [taxClass, setTaxClass] = useState(1);
  const [status, setStatus] = useState(false);
  const [selectedGenders, setSelectedGenders] = useState([]);
  const [selectedSalesChannels, setSelectedSalesChannels] = useState([]);
  const [stock, setStock] = useState({});
  const [suppliers, setSuppliers] = useState([]);
  const [businesses, setBusinesses] = useState([]);
  const [attributeConnections, setAttributeConnections] = useState([]);
  const [values, setValues] = useState({
    product_name: "",
    product_number: "",
    barcode: "",
  });
  // State for holding categories.
  const [initialCategories, setInitialCategories] = useState([
    { id: 1, headId: null, name: "Categories" },
  ]);

  const [checkedCategories, setCheckedCategories] = useState({});
  const [prices, setPrices] = useState([]);
  const blobToBase64 = (blob) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onload = () =>
        resolve(reader.result.replace("data:", "").replace(/^.+,/, ""));
      reader.onerror = (error) => reject(error);
    });
    const validateImages = async() => {
      //Check if there are any images that are faulty (like too big or unsupported file type)
      let faultyImages = false;
      for (let index = 0; index < images.length; index++) {
        const CurrentImage = images[index];
        if (CurrentImage.error) {
          faultyImages = true;
        }
      }
      let everythingFilledIn = true;
      // if (useColors) {
      //   //Check if every file has a color assigned to it
      //   for (let index = 0; index < images.length; index++) {
      //     const CurrentImage = images[index];
      //     if (CurrentImage.colorId === undefined) {
      //       everythingFilledIn = false;
      //     }
      //   }
      // }
      //If there are any images that are faulty, a pop-up will appear and will stop the code from running any further
      if (faultyImages) {
        return "Verwijder de foto's die ongeldig zijn voordat je opslaat.";
      }
      //If there are any images that have no color assigned to them, a pop-up will appear and will stop the code from running any further
      // else if (useColors && !everythingFilledIn) {
      //   return "Vul alle kleuren/maten in!";
      // } else {
      //   //This is so if the upload went succesful and you add a new image, it won't have a red ring around it.
      //   // setRequiredCheck(false);
      //   // if (setCheck != null) {
      //   //   setCheck(false);
      //   // }
      // }
      return true;
    }
  const saveImages = async (productID) => {
    try {
      let currentProductId = productID;
      const currentMonth = (new Date().getMonth() + 1)
        .toString()
        .padStart(2, "0");
      const currentYear = new Date().getFullYear();
      const folderPath = `warehouse/uploads/${currentYear}/${currentMonth}/${currentProductId}`;

      //The string with all the data that will be send to the API so it saves the image path, order and color
      let bulkDataString = "";
      let imagestrings = [];

      //Loop through the images and preparing them to upload
      for (let index = 0; index < images.length; index++) {
        // if(!images[index].changed) {
        //   continue;
        // }
        const CurrentImage = images[index];
        // This displays the image that is currently being uploaded
        // setUploadImgStatus(
        //   `${CurrentImage.name} (${CurrentImage.file.size.toString().length >= 6
        //     ? (CurrentImage.file.size / 1024 / 1000).toFixed(1) + " MB"
        //     : (CurrentImage.file.size / 1024).toFixed(1) + " kB"
        //   })`
        // );

        const base64String = await blobToBase64(CurrentImage.file);
        imagestrings.push(base64String);

        // Increase the upload count to notify the user the image has been uploaded
        // setUploadCount((prev) => prev + 1);

        //Add the data to the bulkdata that will eventually be sent to the middleware API
        bulkDataString += `${CurrentImage.name}|${null
          }|${folderPath}/${CurrentImage.name.replace(/\s/g, "_")}|${index + 1
          }|${null}`;
        if (index < images.length - 1) {
          bulkDataString += ",";
        }
      }
      // Post request to the middleware api to save the image data to the database and delete the older information
      await middleware.post(
        `products/media/upload?product_id=${currentProductId}&bulk_data=${encodeURIComponent(
          bulkDataString
        )}`,
        { imagestrings: imagestrings }
      );
      
      return true;
    } catch (error) {
      // When something goes wrong the user gets logged out and send to the login page
      return (
        error.response?.data?.Message ??
        error.response?.data?.error ??
        false
      );
    }
  }

  const fetchAll = async () => {
    setIsLoading(true);
    const [fetchBrands, fetchCategories, fetchGenders, fetchSalesChannels] =
      await Promise.all([
        middleware.get(`products/brands`),
        middleware.get(`products/category`),
        middleware.get(`products/genders`),
        salesChannelsApi.get("saleschannels?status=1"),
      ]);
    const cat = fetchCategories.data.map((map) => {
      return {
        primary_key: map.primary_key,
        id: map.category_id,
        headId: map.head_category_id,
        name: map.title,
      };
    });
    setInitialCategories(cat);
    setBrands(fetchBrands.data);
    setCategories(fetchCategories.data);
    setGenders(fetchGenders.data);
    setSalesChannels(fetchSalesChannels.data);
    setIsLoading(false);
  };
  const MediaUploader = useRef();
  useEffect(() => {
    fetchAll();
  }, []);
  const validate = (e) => {
    e.target.checkValidity();
    e.target.reportValidity();
  }

  useEffect(() => {
    (async () => {
      let newBarcodeValue = "";

      // Check if the selectedOption has changed
      if (
        previousSelectedOption.current !== selectedOption &&
        selectedOption === "0"
      ) {
        setValues((prevValues) => ({
          ...prevValues,
          barcode: "",
        }));
      }

      if (selectedOption === "0") {
        newBarcodeValue = values.barcode;
      } else if (selectedOption === "2") {
        newBarcodeValue = await handleGenerateBarcode();
      } else if (selectedOption === "1") {
        newBarcodeValue = values.product_number;
      }

      setBarcode(newBarcodeValue);
      // Update the previous selected option to the current one
      previousSelectedOption.current = selectedOption;
    })();
  }, [selectedOption, values.barcode]);

  useEffect(() => {
    if (selectedOption === "1") {
      setBarcode(values.product_number);
    }
  }, [values.product_number]);

  // Effect to recalculate prices when tax rate changes
  // useEffect(() => {
  //   // Function defined inside useEffect to avoid dependencies on external functions
  //   const recalculatePrices = () => {
  //     setPrices((currentPrices) => {
  //       const updatedPrices = {};

  //       for (const [key, value] of Object.entries(currentPrices)) {
  //         const inclTax = value.incl_tax;
  //         const exclTax = inclTax ? (inclTax / (1 + tax / 100)).toFixed(3) : "";

  //         updatedPrices[key] = { ...value, excl_tax: exclTax };
  //       }

  //       return updatedPrices;
  //     });
  //   };

  //   recalculatePrices();
  // }, [tax]);

  const proccessCategorieIds = (obj) => {
    const regex = /^(\d+)-(\d+)-(\d+)-(\d+)$/;
    const categoryIds = [];
    const HeadIds = [];

    Object.entries(obj)
      .filter(([key, value]) => value === true && regex.test(key))
      .forEach(([key]) => {
        const match = key.match(regex);
        if (match) {
          categoryIds.push(match[3]); // Voeg het derde deel toe aan match3
          HeadIds.push(match[4]); // Voeg het vierde deel toe aan match4
        }
      });

    return {
      categoryIds,
      HeadIds,
    };
  };

  const handleChange = (event) => {
    setValues({ ...values, [event.target.name]: event.target.value });
  };

  const handleBarcodeChange = (event) => {
    // change automatic generated barcode
    console.log(event.target.value);
    setBarcode(event.target.value);
  }

  const submitNew = async (event) => {
    event.preventDefault(); // Voorkom de standaardactie van het formulier

    var newBarcode = null;
    newBarcode = barcode;

    const categoryIds = proccessCategorieIds(checkedCategories).categoryIds;
    const newprices = prices.reduce((acc, curr) => {
      acc[curr.type] = {
        price_id: curr.price_id,
        incl_tax: typeof curr.incl_tax === 'string' ? Number(curr.incl_tax.replace(",", ".")).toFixed(2) : Number(curr.incl_tax).toFixed(2),
        excl_tax: typeof curr.excl_tax === 'string' ? Number(curr.excl_tax.replace(",", ".")).toFixed(2) : Number(curr.excl_tax).toFixed(2),
      };
      return acc;
    }, {});
    let product_id;
    try {
      const test = await validateImages();
      if(test !== true) {
        console.log(test);
        ToastError(test);
        return false;
      }
      if (checkBarcodeFormat(barcode)) {
      await middleware
        .post(`products/create`, {
          product: {
            name: values.product_name,
            number: values.product_number,
            barcode: barcode,
            tax_id: taxClass,
            description: description,
          },
          prices: newprices,
          category_ids: categoryIds,
          gender_ids: selectedGenders,
          sales_channel_ids: selectedSalesChannels,
          brand_id: brandId,
          stock: stock,
        })
        .then(async (e) => {
          product_id = e.data.product.product_id;
          await saveImages(product_id);
        });
      ToastSuccess("Product is aangemaakt");
      if (buttonPressed == 1) {
        window.location.reload();
      } else if (buttonPressed == 2) {
        navigate("/producten/bewerken/" + product_id);
      }
    }
    } catch (err) {
      setIsLoading(false);
      if (err.response && err.response.data && err.response.data.details) {
        const details = err.response.data.details;

        if (details["product.number"]) {
          ToastError(`Productnummer bestaat al`);
          return;
        }
        if (details["product.barcode"]) {
          ToastError("Barcode bestaat al");
          return;
        }
      } else {
        console.error(err);
      }
      ToastError("Er is een fout opgetreden bij het aanmaken van het product.");
    }
  };

  const checkBarcodeFormat = (barcode) => {
    if (barcode == null || barcode == "") {
      return true;
    }
    // check correct barcode length
    if (barcode.length == 13) {
      // check if prefix is between 021 - 029
      let isCorrectPrefix = true;
      const barcodePrefix = parseInt(barcode.toString().slice(0, 3), 10);
      isCorrectPrefix = barcodePrefix >= 21 && barcodePrefix <= 29;
      if (isCorrectPrefix) {
        // Check if checksum is correct
        const digits = barcode.toString().split('').map(Number);

        const sum = digits
          .slice(0, 12) // Get the first 12 digits
          .reduce((acc, digit, index) => {
            return acc + digit * (index % 2 === 0 ? 1 : 3);
          }, 0);

        const checksum = (10 - (sum % 10)) % 10;
        if (checksum === digits[12]) {
          return true;
        }
        else {
          ToastError("Het laatse berekende veld van de barcode (checksum) is niet correct ingevuld");
        }
        return ;
      }
      else {
        ToastError("Eerste 3 getallen van de barcode voldoen niet aan het EAN-13 formaat (tussen 021 en 029)");
      }
    } else {
      ToastError("Barcode voldoet niet aan het EAN-13 formaat");
    }
    return false;
  }

  const handleGenerateBarcode = async () => {
    // Generate a 13-digit barcode
    try {
      const res = await middleware.get("products/generateBarcode");
      const barcode = res.data.generatedBarcode;
      return barcode;
    } catch (error) {
      console.log(error);
    }
  };

  const handleGendersChange = (event) => {
    const valueAsInt = parseInt(event.target.value, 10); // Zet de waarde om naar een integer

    if (event.target.checked) {
      setSelectedGenders([...selectedGenders, valueAsInt]); // Voeg de integer waarde toe
    } else {
      setSelectedGenders(selectedGenders.filter((e) => e !== valueAsInt)); // Verwijder de integer waarde
    }
  };

  const handleSalesChannelsChange = (event) => {
    const valueAsInt = parseInt(event.target.value, 10); // Zet de waarde om naar een integer

    if (event.target.checked) {
      setSelectedSalesChannels([...selectedSalesChannels, valueAsInt]); // Voeg de integer waarde toe
    } else {
      setSelectedSalesChannels(
        selectedSalesChannels.filter((e) => e !== valueAsInt)
      ); // Verwijder de integer waarde
    }
  };

  return (
    <PageTemplate navbarTitle={"Warehouse HUB"} pageIsLoading={isLoading} iconNavbar={NavbarIcon}>
      <form
        onSubmit={submitNew}
        method="post"
        className="d-flex justify-content-center minFitContent w-100"
      >
        <div className="mb-3 d-flex flex-column formItems  minFitContent w-100">
          <div className="d-flex justify-content-between mb-4 w-100 flex-wrap-reverse">
          <div className="d-flex gap-5">
              <div className="d-flex flex-row gap-2 align-items-center">
                <img className="back-button-img" src={Backbutton} onClick={() => navigate("/producten")} />
                <h5 className="p-0 m-0">Terug</h5>
              </div>
              {/* Published btn*/}
              <div className="d-flex flex-column align-items-center">
              {!status ? <p className="mb-1 p-0">Gepubliceerd</p> : <p className="mb-1 p-0">Gearchiveerd</p>}
                <label class="switchActiveProduct">
                  <input type="checkbox" checked={!status}
                   onChange={(e) => setStatus(!e.target.checked)}/>
                  <span class="sliderActiveProduct round">
                  </span>
                </label>
              </div>
            </div>
            <div className="fitContent text-nowrap">
              <button
                type="submit"
                id="1"
                className="btn darkBlueStandardButton me-3 Nunito w-auto px-5"
                onClick={(e) => setbuttonPressed(e.target.id)}
              >
                Aanmaken en nieuwe toevoegen
              </button>
              <button
                type="submit"
                id="2"
                className="btn lightBlueStandardButton Nunito"
                onClick={(e) => setbuttonPressed(e.target.id)}
              >
                Aanmaken
              </button>
            </div>
          </div>
          <div className="d-flex flex-column flex-xxl-row justify-content-between">
            <div className="col-xxl-8">
              <div>
                <div className="d-flex flex-column fitcontentHeight mb-4">
                <FirstBox
                handleChange={handleChange}
                product={values}
                setProduct={setValues}
                selectedOption={selectedOption}
                setSelectedOption={setSelectedOption}
                brands={brands}
                barcode={barcode}
                setBarcode={setBarcode}
                validate={validate}
                setBrandId={setBrandId}
                handleBarcodeChange={handleBarcodeChange}
                edit={false}
              />
                </div>
              </div>
              <MediaUploaderComponent
                setImages={setImages} images={images}
              ></MediaUploaderComponent>
            </div>
            <div className="d-flex flex-row flex-xxl-column col-xxl-4 row mt-4 mt-xxl-0">
              {/* category */}
              <div className="col-6 col-xxl-12 mb-4">
                <div className="whiteBox h-100">
                  {categories.length && (
                    <Categories
                      initialCategories={initialCategories}
                      setCheckedCategories={setCheckedCategories}
                      checkedCategories={checkedCategories}
                    />
                  )}
                </div>
              </div>
              {/* gender */}
              <div className="col-6 col-xxl-12 mb-4">
                <div className="whiteBox p-5 pb-3 pt-4 h-100">
                  <h5 className="inputTitle mb-4">Geslacht</h5>
                  <div>
                    {genders.map((gender, index) => (
                      <div key={index} className="d-flex flex-row mb-3">
                        <label className="checkbox-containerSimple">
                          <input
                            type="checkbox"
                            value={gender.gender_id}
                            onChange={handleGendersChange}
                          />
                          <span className="checkmarkSimple"></span>
                        </label>
                        <h5 className="inputTitle fw-medium">{gender.name}</h5>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              {/* prices component */}
              <div className="col-6 col-xxl-12 pb-4">
                <PriceBox
                  selectedTax={selectedTax}
                  setSelectedTax={setSelectedTax}
                  setTax={setTax}
                  taxClass={taxClass}
                  setTaxClass={setTaxClass}
                  handleChange={handleChange}
                  prices={prices}
                  setPrices={setPrices}
                  validate={validate}
                  edit={false}
                />
              </div>
             {/* Stock */}
             <div className="mb-4 col-6 col-xxl-12"> 
              <ThirdBox
                  stock={stock}
                  setStock={setStock}
                  validate={validate}
                  edit={false}
                />
                </div>
              {/* sales channels */}
              <div className="mb-4 col-6 col-xxl-12">
                <div className="whiteBox p-4 h-100">
                  <div className="d-flex flex-column h-100">
                    <h5 className="inputTitle mb-4">Verkoopkanaal</h5>
                    <div className="px-2 d-flex h-100 flex-column justify-content-around">
                      {salesChannels.length > 0 ? (
                        salesChannels.map((salesChannel, index) => (
                          <div
                            key={index}
                            className="d-flex flex-row mb-3 me-3 slidersSellchannel justify-content-between"
                          >
                            <h6 className="switchText">
                              {salesChannel.sales_channel_name}
                            </h6>
                            <label className="defaultSwitch ms-4">
                              <input
                                type="checkbox"
                                value={salesChannel.sales_channel_id}
                                onChange={handleSalesChannelsChange}
                              />
                              <span className="defaultSlider round"></span>
                            </label>
                          </div>
                        ))
                      ) : (
                        <p>Er zijn nog geen verkoopkanalen gemaakt.</p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              {/* Suppliers */}
              <div className="pb-4 col-6 col-xxl-12">
                <FourthBox
                  suppliers={suppliers}
                  setSuppliers={setSuppliers}
                  businesses={businesses}
                  setBusinesses={setBusinesses}
                  validate={validate}
                  attributeConnections={attributeConnections}
                  setAttributeConnections={setAttributeConnections}
                  edit={false}
                />
              </div>
            </div>
          </div>
        </div>
      </form>
    </PageTemplate>
  );
};

export default AddSimple;
