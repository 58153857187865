import React, { useEffect, useState } from "react";


const Rangefilter = ({ setFilters, filter }) => {
    const [min, setMin] = useState(filter.value?.min ?? undefined);
    const [max, setMax] = useState(filter.value?.max ?? undefined);
    const setFilterValue = () => {
        setFilters((filters) => {
            const newFilters = [...filters];
            newFilters[filters.findIndex((e) => e.index == filter.index)].value = { min: min, max: max }
            return newFilters
        })
    }
    useEffect(() => {
        setFilterValue();
    }, [min, max])
    const setMinValue = (value) => {
        setMin(value);
    }
    const setMaxValue = (value) => {
        setMax(value);
    }
    return <div className="d-flex w-100 h-100">
        <div className="bg-white rounded-4 p-4 border border-gray w-100">
            <h6 className="fw-bold">{filter.name}</h6>
            <div className="d-flex flex-row align-items-center">
                <input type="number" min={filter.min || 0} value={filter.value?.min ?? ''} onChange={(e) => setMinValue(e.target.value)} placeholder="0,00" className="standardInput w-100 placeholderStyle" />
                <span className="px-2"> - </span>
                <input type="number" max={filter.max || null} value={filter.value?.max ?? ''} onChange={(e) => setMaxValue(e.target.value)} placeholder="0,00" className="standardInput w-100 placeholderStyle" />
            </div>
        </div>
    </div>
}

export default Rangefilter;