// AnimatedLineWithDoubleBump.js
import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

const AnimatedLineWithDoubleBump = ({
  startX = 50,
  startY = 100,
  endX = 450,
  endY = 100,
  lineColor = '#009cde',
  lineWidth = 5,
  bumpAmplitude = 5,
  bumpLength = 0.05,
  duration = '6000ms', // Aangepast naar 6000ms
  svgWidth = 500,
  svgHeight = 200,
  randomStart = false,
  animationClass,
  hubAnimationDuration = '6000ms', // Aangepast naar 6000ms
  animationDelay = 0,
}) => {
  const pathRef = useRef(null);
  const requestRef = useRef();
  const startTimeRef = useRef();
  const svgRef = useRef(null);
  const initialOffset = useRef(0);

  const parseDuration = (durationStr) => {
    if (typeof durationStr === 'string') {
      if (durationStr.endsWith('ms')) {
        return parseFloat(durationStr.slice(0, -2));
      } else if (durationStr.endsWith('s')) {
        return parseFloat(durationStr.slice(0, -1)) * 1000;
      }
    } else if (typeof durationStr === 'number') {
      return durationStr;
    }
    return 0;
  };

  const parsedDuration = parseDuration(duration) || 6000; // Standaard 6000ms
  const hubAnimationDurationMs = parseDuration(hubAnimationDuration) || 6000; // Standaard 6000ms

  const getAnimationOffset = (animationClass, t) => {
    let deltaX = 0;
    let deltaY = 0;

    // Progressie van 0 naar 1 en weer terug naar 0
    const progress = t < 0.5 ? (t / 0.5) : ((1 - t) / 0.5);

    switch (animationClass) {
      case 'floatUpDown':
        deltaX = 5 * progress;
        deltaY = -5 * progress;
        break;
      case 'floatDownUp':
        deltaX = -5 * progress;
        deltaY = 5 * progress;
        break;
      case 'floatLeftRight':
        deltaX = -10 * progress;
        deltaY = 5 * progress;
        break;
      case 'floatRightLeft':
        deltaX = 10 * progress;
        deltaY = -5 * progress;
        break;
      default:
        deltaX = 0;
        deltaY = 0;
        break;
    }
    return { deltaX, deltaY };
  };

  useEffect(() => {
    if (randomStart) {
      initialOffset.current = Math.random() * parsedDuration;
    } else {
      initialOffset.current = 0;
    }

    const totalDelay = animationDelay || 0;

    const timeoutId = setTimeout(() => {
      startTimeRef.current = performance.now() - initialOffset.current;

      const animate = (timestamp) => {
        const elapsed = timestamp - startTimeRef.current;

        const progress = (elapsed % parsedDuration) / parsedDuration;
        const t = 1 - progress; // Voor consistente animatie met hubs

        const hubElapsed = elapsed;
        const hubProgress = ((hubElapsed % hubAnimationDurationMs) / hubAnimationDurationMs);

        const { deltaX, deltaY } = getAnimationOffset(animationClass, hubProgress);

        const currentEndX = endX + deltaX;
        const currentEndY = endY + deltaY;

        const pathData = generatePathWithDoubleBump(
          startX,
          startY,
          currentEndX,
          currentEndY,
          t,
          bumpAmplitude,
          bumpLength
        );

        if (pathRef.current) {
          pathRef.current.setAttribute('d', pathData);
        }

        requestRef.current = requestAnimationFrame(animate);
      };

      requestRef.current = requestAnimationFrame(animate);
    }, totalDelay);

    return () => {
      clearTimeout(timeoutId);
      if (requestRef.current) {
        cancelAnimationFrame(requestRef.current);
      }
    };
  }, [
    startX,
    startY,
    endX,
    endY,
    bumpAmplitude,
    bumpLength,
    parsedDuration,
    randomStart,
    animationClass,
    hubAnimationDurationMs,
    animationDelay,
  ]);

  const generatePathWithDoubleBump = (
    startX,
    startY,
    endX,
    endY,
    t,
    amplitude,
    bumpLengthFraction
  ) => {
    const dx = endX - startX;
    const dy = endY - startY;
    const length = Math.sqrt(dx * dx + dy * dy);

    if (length === 0) {
      return '';
    }

    const directionX = dx / length;
    const directionY = dy / length;

    const perpX = -directionY;
    const perpY = directionX;

    const bumpPosX = startX + dx * t;
    const bumpPosY = startY + dy * t;

    const bumpLength = length * bumpLengthFraction;

    const bumpStartX1 = bumpPosX - (bumpLength / 2) * directionX;
    const bumpStartY1 = bumpPosY - (bumpLength / 2) * directionY;
    const bumpEndX1 = bumpPosX + (bumpLength / 2) * directionX;
    const bumpEndY1 = bumpPosY + (bumpLength / 2) * directionY;

    const controlX1 = bumpPosX + perpX * amplitude;
    const controlY1 = bumpPosY + perpY * amplitude;

    const bumpStartX2 = bumpPosX - (bumpLength / 2) * directionX;
    const bumpStartY2 = bumpPosY - (bumpLength / 2) * directionY;
    const bumpEndX2 = bumpPosX + (bumpLength / 2) * directionX;
    const bumpEndY2 = bumpPosY + (bumpLength / 2) * directionY;

    const controlX2 = bumpPosX - perpX * amplitude;
    const controlY2 = bumpPosY - perpY * amplitude;

    const path =
      `M ${startX} ${startY} ` +
      `L ${bumpStartX1} ${bumpStartY1} ` +
      `Q ${controlX1} ${controlY1} ${bumpEndX1} ${bumpEndY1} ` +
      `L ${bumpStartX2} ${bumpStartY2} ` +
      `Q ${controlX2} ${controlY2} ${bumpEndX2} ${bumpEndY2} ` +
      `L ${endX} ${endY}`;

    return path;
  };

  return (
    <svg
      ref={svgRef}
      width={svgWidth}
      height={svgHeight}
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        overflow: 'visible',
        pointerEvents: 'none',
      }}
    >
      {/* Definieer de blauwe gloed filter */}
      <defs>
        <filter id="blue-glow" x="-50%" y="-50%" width="200%" height="200%">
          <feGaussianBlur in="SourceGraphic" stdDeviation="4" result="blur" />
          <feMerge>
            <feMergeNode in="blur" />
            <feMergeNode in="SourceGraphic" />
          </feMerge>
        </filter>
      </defs>
      <path
        ref={pathRef}
        d={generatePathWithDoubleBump(
          startX,
          startY,
          endX,
          endY,
          1,
          bumpAmplitude,
          bumpLength
        )}
        stroke={lineColor}
        strokeWidth={lineWidth}
        fill="none"
        filter="url(#blue-glow)"
      />
    </svg>
  );
};

AnimatedLineWithDoubleBump.propTypes = {
  startX: PropTypes.number,
  startY: PropTypes.number,
  endX: PropTypes.number,
  endY: PropTypes.number,
  lineColor: PropTypes.string,
  lineWidth: PropTypes.number,
  bumpAmplitude: PropTypes.number,
  bumpLength: PropTypes.number,
  duration: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  svgWidth: PropTypes.number,
  svgHeight: PropTypes.number,
  randomStart: PropTypes.bool,
  animationClass: PropTypes.string,
  hubAnimationDuration: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  animationDelay: PropTypes.number,
};

export default AnimatedLineWithDoubleBump;
