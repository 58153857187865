// edit
import React, { useEffect, useState, useMemo } from "react";
import middleware from "../../../../Api/Middleware";
import TablePreset from "../../../../Table/TablePreset";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGear, faRotate, faBarcode } from "@fortawesome/free-solid-svg-icons";
import { useTable } from "react-table";
import { useNavigate, useParams } from "react-router-dom";
import ToastError from "../../../../Toasts/ToastError";
import { logDOM } from "@testing-library/react";
import { BarcodeIcon } from "lucide-react";
import barcodeIcon from "../../../../Main/Icons/BarcodeIcon.png";
import CurrencyInput from "react-currency-input-field";

const BoxVariation = (props) => {
  const navigate = useNavigate();
  // usestates
  const [addVariation, setAddVariation] = useState({
    size: "",
    color: "",
  });
  const [sizeVariations, setSizeVariations] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [variations, setVariations] = useState([]);
  const [colorVariations, setColorVariations] = useState([]);
  const [selectedVariationIds, setSelectedVariationIds] = useState([]);
  const [selectedIds, setSelectedIds] = useState([]);
  const [sizeFilter, setSizeFilter] = useState('');
  const [colorFilter, setColorFilter] = useState('');
  const [variationPrices, setVariationPrices] = useState([]);
  const [pagination, setPagination] = useState({
    page_size: 10,
    page: 1,
    max_items: 0,
    begin_of_page: 0,
    end_of_page: 0,
  });

  // useeffectes
  useEffect(() => {
      updatePagination();
  }, [props.allVariations?.length, pagination.size, pagination.page, sizeFilter, colorFilter])

  const updatePagination = () => {
    const colorFiltered = (colorFilter != '' && colorFilter != null) ? props.allVariations.filter((e) => e.color == colorFilter) : props.allVariations;
    const sizeFiltered =  (sizeFilter != '' && sizeFilter != null) ? colorFiltered.filter((e) => e.size == sizeFilter) : colorFiltered;
    const totalVariations = sizeFiltered.length;
    const { page_size, page } = pagination;
    const maxPage = Math.ceil(totalVariations / page_size);
    const newPage = Math.max(1, page > maxPage ? maxPage : page);
    const begin_of_page = (page - 1) * page_size;
    const end_of_page = Math.min(begin_of_page + page_size, totalVariations);
    const paginatedVariations = sizeFiltered.slice(begin_of_page, end_of_page);
    setPagination({
      page_size: page_size,
      max_items: totalVariations,
      page: newPage,
      begin_of_page: totalVariations === 0 ? 0 : begin_of_page + 1,
      end_of_page: end_of_page
    });
    setVariations(paginatedVariations);
  }

  const generateVariations = (variations = null) => {
    variations = variations ? variations : props.allVariations;
    const totalVariations = variations.length;
    const { page_size, page } = pagination;
    const maxPage = Math.ceil(totalVariations / page_size);
    const newPage = Math.max(1, page > maxPage ? maxPage : page);

    const begin_of_page = (page - 1) * page_size;
    const end_of_page = Math.min(begin_of_page + page_size, totalVariations);

    const paginatedVariations = variations.slice(begin_of_page, end_of_page);

    setVariations(paginatedVariations);
  };
  const handleProductSelection = (productId, isSelected) => {
    if (isSelected) {
      setSelectedVariationIds([...selectedVariationIds, productId]);
    } else {
      setSelectedVariationIds(
        selectedVariationIds.filter((id) => id !== productId)
      );
    }
  };
  const handleSelection = (id, isSelected) => {
    if (isSelected) {
      setSelectedIds((previous) => [...previous, id]);
    } else {
      setSelectedIds((previous) => previous.filter((id) => id !== id));
    }
  };
  const generate_barcodes = async () => {
    let count = 0;
    const generated_barcodes = await generateMultipleBarcodes(selectedVariationIds.length);
    // generate barcodes on selected fields without a barcode
    selectedVariationIds.forEach(selectedId => {
      let vari = props.allVariations.find((variation) => variation.id == selectedId);
      if (vari.barcode.barcode == null || vari.barcode.barcode == "") {
        handleBarcodeChange(generated_barcodes[count], vari.id);
      }
      count++;
    });
  }

  const delete_barcodes = () => {
    // delete selected barcodes
    selectedVariationIds.forEach(selectedId => {
      let vari = props.allVariations.find((variation) => variation.id == selectedId);
      if (vari.barcode != null || vari.barcode != "") {
        props.setAllVariations((prevVariations) => prevVariations.map((variation) => variation.id === vari.id ? {...variation, barcode: {...variation.barcode, barcode: null} } : variation));
        updatePagination();
      }
    });
  }

  const delete_variations = () => {
    props.setAllVariations((previous) =>
      previous.filter(
        (variation) => !selectedVariationIds.includes(variation.id)
      )
    );
    setSizeFilter('');
    setColorFilter('')
    setSelectedVariationIds([]);
  };
  const handleAction = async (event) => {
    if (event === "generate-barcodes") {
      generate_barcodes();
    } else if (event === "delete-barcodes") {
      delete_barcodes();
    } else if (event === "delete") {
      props.setUpdatedVariations(true);
      delete_variations();
    }
  };
  const generateBarcode = async (event, id) => {
    if (event != null) {
      event?.preventDefault();
    }

    const response = await middleware.get("products/generateBarcode");
    const newBarcode = response.data.generatedBarcode;

    handleBarcodeChange(newBarcode, id);
    // Set the pagination so the generated barcode is visible
    updatePagination();
  };

  const generateMultipleBarcodes = async (amount) => {
    const response = await middleware.get(`products/generateMultipleBarcodes?barcodeAmount=${amount}`);
    const newBarcodes = response.data.generatedBarcode;
    return newBarcodes;
  }

  const checkBarcodeFormat = (barcode) => {
    if (barcode == null || barcode == "") {
      return true;
    }
    // check correct barcode length
    if (barcode.length == 13) {
      // check if prefix is between 021 - 029
      let isCorrectPrefix = true;
      const barcodePrefix = parseInt(barcode.toString().slice(0, 3), 10);
      isCorrectPrefix = barcodePrefix >= 21 && barcodePrefix <= 29;
      if (isCorrectPrefix) {
        // Check if checksum is correct
        const digits = barcode.toString().split('').map(Number);

        const sum = digits
          .slice(0, 12) // Get the first 12 digits
          .reduce((acc, digit, index) => {
            return acc + digit * (index % 2 === 0 ? 1 : 3);
          }, 0);

        const checksum = (10 - (sum % 10)) % 10;
        if (checksum === digits[12]) {
          return true;
        }
        else {
          ToastError("Het laatse berekende veld van de barcode (checksum) is niet correct ingevuld");
        }
      }
      else {
        ToastError("Eerste 3 getallen van de barcode voldoen niet aan het EAN-13 formaat (tussen 021 en 029)");
      }
    } else {
      ToastError("Barcode voldoet niet aan het EAN-13 formaat");
    }
    return false;
  }

  const handleFilterChange = (value, field) => {
    if(field == 'size') {
      setSizeFilter(value);
    }
    if(field == 'color') {
      setColorFilter(value);
    }
  };

  const handleBarcodeChange = async (newBarcode, id) => {
    props.setErrors([]);
    // Check if barcode is valid
    if (newBarcode != "" && newBarcode != null) {
      if (!checkBarcodeFormat(newBarcode)) {
        props.setErrors([...props.errors, id]);
      }
    }
    else if (newBarcode == "" || newBarcode == null) {
      props.setErrors([...props.errors, id]);
    }
    if (newBarcode != props.allVariations.find((variation) => variation.id === id)?.barcode ?? null) {
      props.setUpdatedVariations(true);
    }
    props.setAllVariations((prevVariations) =>
      prevVariations.map((variation) =>
        variation.id === id ? { ...variation, barcode: {...variation.barcode, barcode: newBarcode} } : variation
      )
    );
    // Rerender variations
    updatePagination();
  };

  

  const handleStockChange = (newStock, id) => {
    // set the updated stock to true if the field changeds
    if (newStock != props.allVariations.find((variation) => variation.id === id)?.stock ?? null) {
      props.setUpdatedVariations(true);
    }
    props.setAllVariations((prevVariations) =>
      prevVariations.map((variation) =>
        variation.id === id ? { ...variation, stock: newStock } : variation
      )
    );
  };

  const handleVariationPricesChange = (event) => {
    console.log(event.target.value);
  };
  // table

  const columns = useMemo(() => {
    const cols = [
      {
        Header: (
          <>
            <label className="checkbox-container">
              <input
                type="checkbox"
                onChange={(e) => {
                  const checked = e.target.checked;
                  setSelectedVariationIds(
                    checked ? variations.map((variation) => variation.id) : []
                  );
                }}
                checked={selectedVariationIds.length > 0}
              />
              <span className="checkmark"></span>
            </label>
          </>
        ),
        accessor: "select",
        Cell: ({ row }) => {
          const isSelected = selectedVariationIds.includes(row.original.id);
          return (
            <label className="checkbox-container">
              <input
                type="checkbox"
                checked={isSelected}
                onChange={(e) =>
                  handleProductSelection(row.original.id, e.target.checked)
                }
              />
              <span className="checkmark"></span>
            </label>
          );
        },
      },
      {
        Header: "Voorraad",
        accessor: "stock",
        Cell: ({ row }) => {
          const stock =
            props.allVariations.find(
              (variation) => variation.id === row.original.id
            )?.stock?.stock || "";
          return (
            <div className="d-flex justify-content-center" style={{width: "60px"}}>
              <input
                onBlur={(event) =>
                  handleStockChange(event.target.value, row.original.id)
                }
                defaultValue={stock}
                type="number"
                className="stockBox webkitRemove"
                placeholder="0"
                style={{width: "60px"}}
              />
              {/* <input type="number" className='stockBox w-25 webkitRemove' placeholder='0'/> */}
            </div>
          );
        },
      },
      {
        Header: <div className="text-center">Barcode</div>,
        accessor: "barcode",
        Cell: ({ row }) => {
          const barcode =
            props.allVariations.find(
              (variation) => variation.id === row.original.id
            )?.barcode?.barcode || "";
          return (
            <div className="d-flex flex-row gap-2">
              <div>
                <input
                  onBlur={(event) =>
                    handleBarcodeChange(event.target.value, row.original.id)
                  }
                  defaultValue={barcode}
                  type="number"
                  className={"barcodeBox webkitRemove " + (props.errors?.find((e) => e == row.original.id) != null ? "border-danger" : "")}
                />
              </div>
              {/* barcode icon btn wich generated a barcode for the specific field */}
              <div className="d-flex justify-content-center align-items-center generateBarcodeBtn" onClick={(e) => generateBarcode(e, row.original.id)}>
              <FontAwesomeIcon icon={faBarcode} />
              </div>
            </div>
          );
        },
      },
      // Dit is een extra optie die later toegevoegd moet worden.
      {
        Header: <div className="d-flex justify-content-center">Variabele prijs</div>,
        accessor: "incl_tax",
        Cell: ({row}) => {
          return (
            <div className='d-flex justify-content-center gap-1'>
              {/* Plus and minus boxes */}
              <div className="d-flex justify-content-center flex-row">
              <div className={true ? "VariationPriceBtnPlusSelected" : "VariationPriceBtnPlus"}
              onClick={(e) => props.setUpdatedVariations(true)}>+</div>
              <div className={false ? "VariationPriceBtnMinusSelected" : "VariationPriceBtnMinus"}
              onClick={(e) => props.setUpdatedVariations(true)}>-</div>
              </div>
              <CurrencyInput
              placeholder={`€ 0,00`}
              className='stockBox variablePriceBox px-2'
              decimalsLimit={2}
              groupSeparator="."
              decimalSeparator=","
              prefix="€ "
              />
              <span className="align-self-center">= € 10,00</span>
            </div>
          )
        }
      },
    ];
    const hascolors = props.allVariations.find((e) => e.colors != null && e.colors.length != 0) ? true : false;
    const hassizes = props.allVariations.find((e) => e.sizes != null && e.sizes.length != 0) ? true : false;
    
    // Conditionally add the color_name column if colors are selected
    if (hascolors) {
      cols.splice(1, 0, {
        Header: (
          <select style={{width: "130px"}} value={colorFilter} onChange={(e) => handleFilterChange(e.target.value, 'color')} className="smallStandardInput fw-bold standardDropdownArrow">
            <option value={''}>Filter kleur</option>
            {props.allColors.filter(e => props.allVariations.find(b => b.color_name == e.name)).map((e, index) => 
              <option key={index} value={e.color_id}>{e.name}</option>
            )}
          </select>
        ),
        accessor: "color_name",
        Cell: ({ row }) => {
          return row.original.colors.length > 0 && row.original.colors[0]?.name ? (
            <span className="d-flex flex-row">
              <div
                className=" rounded-circle me-2 mt-1 colorCircle"
                style={{
                  backgroundColor: row.original.colors[0]?.hex,
                  width: "15px",
                  height: "15px",
                }}
              ></div>
              <div className="text-nowrap colorNameVariationTable" data-bs-toggle="tooltip" data-bs-placement="bottom" title={row.original.colors[0].name}>
                {row.original.colors[0].name}
              </div>
            </span>
          ) : (
            ""
          );
        },
      });
    }
    // Conditionally add the size_name column if sizes are selected
    if (hassizes) {
      cols.splice(hascolors ? 2 : 1, 0, {
        Header: (
          <select style={{width: "120px"}} value={sizeFilter} onChange={(e) => handleFilterChange(e.target.value, 'size')} className="smallStandardInput fw-bold standardDropdownArrow">
            <option value={''}>Filter maat</option>
            {props.allSizes.filter(e => props.allVariations.find(b => b.size_name == e.name)).map((e, index) => 
              <option key={index} value={e.size_id}>{e.name}</option>
            )}
          </select>
        ),
        accessor: "size_name",
        Cell: ({ row }) => {
          return (
            <span className="d-flex justify-content-center" data-bs-toggle="tooltip" data-bs-placement="bottom" title={row.original.sizes[0]?.name ?? ""}>
              {row.original.sizes[0]?.name ?? ""}
            </span>
          );
        },
      });
    }
    return cols;
  }, [selectedVariationIds, pagination, props.errors]);

  //   Table instances
  const tableInstance = useTable({ columns, data: variations });
  const handleNewVariation = async () => {
    const size = props.allSizes.filter(
      (size) => size.primary_key === Number(addVariation.size)
    )[0];
    const color = props.allColors.filter(
      (color) => color.primary_key === Number(addVariation.color)
    )[0];
    if (!size && !color) {
      ToastError("Je moet een maat of kleur selecteren");
      return;
    }
    if (props.allVariations.find((e) => e.colors[0]?.color_id == color?.color_id && e.sizes[0]?.size_id == size?.size_id)) {
      ToastError("Die variatie bestaat al");
      return;
    }
    const response = await middleware.get("products/generateBarcode");
    const newBarcode = response.data.generatedBarcode;
    const newVariation = {
      id: props.allVariations.length + 1,
      size: size?.size_id ?? null,
      size_name: size?.name ?? null,
      color: color?.color_id ?? null,
      color_name: color?.name ?? null,
      colors: color != null ? [
        {
          color_id: color?.color_id ?? null,
          name: color?.name ?? null,
          hex: color?.hex ?? null,
        }
      ] : [],
      sizes: size != null ? [
        {
          size_id: size?.size_id ?? null,
          name: size?.name ?? null, 
        }
      ] : [],
      hex: color?.hex ?? null,
      barcode: newBarcode
    };
    const sizeVariation = {
      id: size?.size_id ?? null,
      name: size?.name ?? null
    }
    const colorVariation = {
      id: color?.color_id ?? null,
      name: color?.name ?? null
    }
    if (sizeVariation.id) {
      setSizeVariations([sizeVariation, ...sizeVariations])
    }
    if (colorVariation.id) {
      setColorVariations([colorVariation, ...colorVariations])
    }
    props.setAllVariations([newVariation, ...props.allVariations]);
    props.setUpdatedVariations(true);
  };
  return (
    <>
      <div className="whiteBox p-4 formVariationProduct mb-4">
        <h5 className="fw-semibold">Huidige variaties</h5>
        <div className="widthColorTable">
          {isLoading ? (
            <TablePreset
              data={[
                {
                  selectedIds: selectedIds,
                  tableInstance: tableInstance,
                  pagination: pagination,
                },
              ]}
              tableLoading={false} // Isloading
              setPagination={(e) => setPagination(e)}
              handleSelection={handleSelection}
              actionOptions={[
                {
                  title: "Genereer barcodes",
                  function: () => handleAction("generate-barcodes"),
                },
                {
                  title: "Verwijder barcodes",
                  function: () => handleAction("delete-barcodes"),
                },
                { title: "Verwijder", function: () => handleAction("delete") },
              ]}
              rightDropdowns={[
                {
                  title: "Selecteer een maat",
                  primary_key: "primary_key",
                  options: props.allSizes,
                  function: (event) =>
                    setAddVariation({
                      ...addVariation,
                      size: event.target.value,
                    }),
                  value: addVariation.size,
                },
                {
                  title: "Selecteer een kleur",
                  primary_key: "primary_key",
                  options: props.allColors,
                  function: (event) =>
                    setAddVariation({
                      ...addVariation,
                      color: event.target.value,
                    }),
                  value: addVariation.color,
                },
              ]}
              rightButtons={[
                { title: "Variatie aanmaken", function: handleNewVariation },
              ]}
            />
          ) : (
            <div className="table-container table-responsive">
              <table>
                <thead>
                  <tr>
                    <td>
                      <div>Loading</div>
                    </td>
                  </tr>
                </thead>
              </table>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default BoxVariation;
