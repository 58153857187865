import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import gearIcon from '../../../../Main/Icons/setting.png';
import PriceSettingsModal from '../../../modals/PriceSettingsModal';
import CurrencyInput from 'react-currency-input-field';
import { el } from "date-fns/locale";

const PriceBox = (props) => {
  const [priceModalVisible, setPriceModalVisible] = useState(false);
  const [inclVAT, setInclVAT] = useState(1);

  useEffect(() => {
    if (props.prices != null && props.prices.length) {
      let updatedPrices;
      // set the prices correct arcording to the tax change
      if (inclVAT == '1') {
        updatedPrices = props.prices.map(item => ({
          ...item,
          excl_tax: item.incl_tax ? (item.incl_tax.replace(",", ".") / (1 + props.selectedTax / 100)).toFixed(2) : ""
        }));
      }
      else if (inclVAT == '0') {
        updatedPrices = props.prices.map(item => ({
          ...item,
          incl_tax: item.excl_tax ? typeof item.excl_tax === 'string' ?(item.excl_tax.replace(",", ".") * (1 + props.selectedTax / 100)).toFixed(2) : item.excl_tax : ""
        }));
      }
      else {
        updatedPrices = props.prices;
      }
      // don't update the product and prices if there are no changes
      let isPriceNotSame = false;
      for (let i = 0; i < props.prices.length; i++) {
        if(props.prices[i].incl_tax != updatedPrices[i].incl_tax || props.prices[i].excl_tax != updatedPrices[i].excl_tax) {
          isPriceNotSame = true;
        }
      }
      if (isPriceNotSame || !props.edit) {
      props.setPrices(updatedPrices);
      props.edit && (props.setUpdatedTax(true));
      }
    }
  }, [props.taxClass])

  const roundToTwoDecimals = (event) => {
    if (inclVAT == '1') {
      props.setPrices(prevPrices =>
        prevPrices.map(price =>
          price.type === event.target.name
            ? {
              ...price, incl_tax: typeof price.incl_tax === 'string' ? Number(price.incl_tax.replace(",", ".")).toFixed(2) :
                Number(price.incl_tax).toFixed(2)
            } // update only the matching type
            : price // return the other prices unchanged
        )
      );
    }
    else {
      props.setPrices(prevPrices =>
        prevPrices.map(price =>
          price.type === event.target.name
            ? {
              ...price, excl_tax: typeof price.excl_tax === 'string' ? Number(price.excl_tax.replace(",", ".")).toFixed(2) :
                Number(price.excl_tax).toFixed(2)
            } // update only the matching type
            : price // return the other prices unchanged
        )
      );
    }
    props.handleChange();
  }

  const handlePriceValueChange = (value, name, values) => {
    let priceType;
    let inclTax;
    let exclTax;
    if (inclVAT == '1') {
      priceType = name;
      inclTax = value;
      exclTax = inclTax ? (inclTax.replace(",", ".") / (1 + props.selectedTax / 100)).toFixed(2) : "";
    }
    else {
      priceType = name;
      exclTax = value;
      inclTax = exclTax ? (parseFloat(exclTax.replace(",", ".")) * (1 + props.selectedTax / 100)).toFixed(2) : "";
    }
    props.setPrices((prevPrices) => {
      let newprices = [...prevPrices];
      let index = newprices.findIndex((e) => e.type == priceType);
      if (index == -1) {
        newprices.push({ incl_tax: inclTax, excl_tax: exclTax, type: priceType });
      } else {
        newprices[index].incl_tax = inclTax;
        newprices[index].excl_tax = exclTax;
      }
      return newprices;
    });
    // props.HandlePriceChange(value, name, values); // Call the external handler
  };
  const handleBuyInPriceValueChange = (value, name, values) => {
    let buyInPriceExists = false;
    const updatedPrices = props.prices.map(price => {
      if (price.type === name) {
        buyInPriceExists = true;
        return {
          ...price,
          incl_tax: value ? (parseFloat(value.replace(",", ".")) * (1 + props.selectedTax / 100)).toFixed(2) : "",
          excl_tax: value,
        };
      }
      return price; // No change if the type doesn't match
    });
    // if buyinprice doesn't exists create it
    if (!buyInPriceExists) {
      updatedPrices.push(
        {
          type: name,
          incl_tax: value ? (parseFloat(value.replace(",", ".")) * (1 + props.selectedTax / 100)).toFixed(2) : "",
          excl_tax: value, 
        }
      )
    }
    props.setPrices(updatedPrices);
    props.edit && (props.setUpdatedTax(true));
  }
  const handleTaxChange = (event) => {
    setInclVAT(event.target.value);
    if (!props.edit) {
      // Set prices
      const newPrice = props.prices.reduce((acc, curr) => {
        acc.push({
          price_id: curr.price_id,
          type: curr.type,
          incl_tax: typeof curr.incl_tax === 'string' ? Number(curr.incl_tax.replace(",", ".")).toFixed(2) : Number(curr.incl_tax).toFixed(2),
          excl_tax: typeof curr.excl_tax === 'string' ? Number(curr.excl_tax.replace(",", ".")).toFixed(2) : Number(curr.excl_tax).toFixed(2),
        });
        return acc;
      }, []);
      props.setPrices(newPrice);
    }
    props.edit && (props.setUpdatedTax(true));
  };
  return (
    <>
      <PriceSettingsModal
        setPriceModalVisible={setPriceModalVisible}
        priceModalVisible={priceModalVisible}
        taxClass={props.taxClass}
        setTaxClass={props.setTaxClass}
        setSelectedTax={props.setSelectedTax}
      />
      <div className={!props.edit ? "whiteBox p-5 h-100" : "whiteBox p-5 h-100 ms-xxl-4"}>
        <div className="d-flex justify-content-between mb-4">
          <h4 className="fw-bold m-0">Prijzen</h4>
          <div className="d-flex flex-row align-items-center">
            <select
              className="smallStandardInput standardDropdownArrow me-2 pe-4"
              onChange={(e) => handleTaxChange(e)}
              value={inclVAT}
            >
              <option value={0}>
                Prijzen excl. BTW
              </option>
              <option value={1}>
                Prijzen incl. BTW
              </option>
            </select>
            <button className='rounded-pill px-2 text-secondary gearButtonPrices d-flex align-items-center h-100' type='button'>
              <img src={gearIcon} onClick={() => { setPriceModalVisible(true) }} height="17px" width="17px" />
            </button>
          </div>
        </div>
        {/* bovenste rij prijzen box */}
        <div className=''>
          <div className='d-flex flex-column'>
            <div className='d-flex flex-row justify-content-between standardSaleParent'>
              <div className="d-flex flex-column me-4">
                <label className="fw-medium mb-2">Standaard prijs</label>
                <CurrencyInput
                  step="any"
                  name='price'
                  className="standardInput w-100 placeholderStyle mb-1"
                  placeholder="€ 0,00"
                  decimalsLimit={2}
                  prefix="€ "
                  decimalSeparator=","
                  groupSeparator="."
                  min={0}
                  value={props.prices ? inclVAT == '1' ? props.prices.find(item => item.type === 'price')?.incl_tax ?? null
                    : props.prices.find(item => item.type === 'price')?.excl_tax ?? null : null}
                  onValueChange={handlePriceValueChange}
                  onBlur={(e) => props.edit && (props.setUpdatedTax(true)) + roundToTwoDecimals(e)}
                />
                {props.prices && inclVAT == "1" ? <p className="bottomText fw-medium">€ {parseFloat(props.prices.find(item => item.type === 'price')?.excl_tax ?? 0).toFixed(2)} Excl. BTW</p> :
                  <p className="bottomText fw-medium">€ {parseFloat(props.prices.find(item => item.type === 'price')?.incl_tax ?? 0).toFixed(2)} Incl. BTW</p>}
              </div>
              <div className="d-flex flex-column me-2">
                <label className="fw-medium mb-2">Kortingsprijs</label>
                <CurrencyInput
                  step="any"
                  name='sale_price'
                  className="standardInput w-100 placeholderStyle mb-1"
                  placeholder="€ 0,00"
                  decimalsLimit={2}
                  prefix="€ "
                  decimalSeparator=","
                  groupSeparator="."
                  min={0}
                  value={props.prices ? inclVAT == '1' ? props.prices.find(item => item.type === 'sale_price')?.incl_tax ?? null
                    : props.prices.find(item => item.type === 'sale_price')?.excl_tax ?? null : null}
                  onValueChange={handlePriceValueChange}
                  onBlur={(e) => props.edit && (props.setUpdatedTax(true)) + roundToTwoDecimals(e)}
                />
                {props.prices && inclVAT == '1' ? <p className="bottomText fw-medium">€ {parseFloat(props.prices.find(item => item.type === 'sale_price')?.excl_tax ?? 0).toFixed(2)} Excl. BTW</p> :
                  <p className="bottomText fw-medium">€ {parseFloat(props.prices.find(item => item.type === 'sale_price')?.incl_tax ?? 0).toFixed(2)} Incl. BTW</p>}
              </div>
            </div>
            <div className='d-flex flex-row justify-content-between standardSaleParent'>
              <div className="d-flex flex-column me-4">
                <label className="fw-medium mb-2">Inkoopprijs excl. BTW</label>
                <CurrencyInput
                  step="any"
                  name='buy_in_price'
                  className="standardInput w-100 placeholderStyle"
                  placeholder="€ 0,00"
                  decimalsLimit={2}
                  prefix="€ "
                  decimalSeparator=","
                  groupSeparator="."
                  min={0}
                  value={props.prices.find(item => item.type === 'buy_in_price')?.excl_tax}
                  onValueChange={handleBuyInPriceValueChange}
                  onBlur={(e) => props.edit && (props.setUpdatedTax(true)) + roundToTwoDecimals(e)}

                />
              </div>
              <div className="d-flex flex-column heightSalePrice me-2">
                <label className="fw-medium mb-2">
                  Marge excl. BTW
                </label>
                <CurrencyInput
                  step="any"
                  className="standardInput w-100 placeholderStyle"
                  placeholder="€ -"
                  decimalsLimit={2}
                  prefix="€ "
                  decimalSeparator=","
                  groupSeparator="."
                  min={0}
                />
              </div>
            </div>
          </div>
        </div>
        {/* onderste rij prijzen box*/}
        {/* onderste rij prijzen box eind*/}
      </div>
      {/* Second Box end */}
    </>
  );
};

export default PriceBox;
