import React from "react";

const BetalingComponent = ({ order, setPayRequestModalVisible }) => {


    return (
        <div className="whiteBox p-5 pt-4 mt-4 align-center">
            <h5 className="mb-4 fw-bold" style={{color: "#A9D974"}}>Betaling</h5>
            <div className="d-flex justify-content-between">
                <div className="col-6 ps-0 pe-0 betaling-overzicht-container">
                    <div className="fw-bold betaling-overzicht-container-headers">
                        <p className="ms-3 pt-2 pb-2">Bestelling overzicht</p>
                    </div>
                    <div className="ms-3 me-3 mb-1">
                        <div className="bottom-border-gray pb-2">
                            <div className="d-flex justify-content-between pb-1">
                                <div>Totaal</div>
                                <div>€ 104,40</div>
                            </div>
                            <div className="d-flex justify-content-between pb-1">
                                <div>Extra korting</div>
                                <div className="d-flex gap-4">
                                    <div>10%</div>
                                    <div>- € 10,44</div>
                                </div>
                            </div>
                            <div className="d-flex justify-content-between pb-1">
                                <div>Verzenkosten</div>
                                <div>€ 6,00</div>
                            </div>
                            <div className="d-flex justify-content-between pb-1">
                                <div>Verzenkosten</div>
                                <div>€ 0,25</div>
                            </div>
                        </div>

                        <div className="bottom-border-gray pt-2 pb-2">
                            <div className="d-flex justify-content-between fw-bold pb-3">
                                <div>Subtotaal</div>
                                <div>€ 100,21</div>
                            </div>
                            <div className="d-flex justify-content-between pb-1">
                                <div>Totaal excl. BTW</div>
                                <div>€ 82,82</div>
                            </div>
                            <div className="d-flex justify-content-between pb-2">
                                <div>BTW</div>
                                <div className="d-flex gap-4">
                                    <div>NL 21%</div>
                                    <div>€ 17,39</div>
                                </div>
                            </div>
                        </div>

                        <div>
                            <div className="d-flex justify-content-between fw-bold pt-2 pb-4">
                                <div>Totaal incl. BTW</div>
                                <div>€ 100,21</div>
                            </div>
                        </div>

                    </div>
                </div>



                <div className="col-6 ps-0 pe-0 betaling-overzicht-container">
                    <div className="fw-bold betaling-overzicht-container-headers">
                        <p className="ms-3 pt-2 pb-2">Bestelling historie</p>
                    </div>
                    <div className="ms-3 me-3 mb-1">
                        <div className="bottom-border-gray pb-2">
                            <div className="d-flex justify-content-between">
                                <div>Bestelling: #{order.order_number}</div>
                                <div className="text-nowrap">€ 100,21</div>
                            </div>
                            <div className="d-flex justify-content-between">
                                <div>Betalingsmethode: IDEAL</div>
                            </div>
                            <div className="d-flex justify-content-between">
                                <div>23 oktober 2024 19:40</div>
                            </div>
                            <div className="d-flex justify-content-between">
                                <a href="#" className="new-link-color text-decoration-underline" onClick={() => {setPayRequestModalVisible(true)}}>Link naar betaalverzoek</a>
                            </div>
                        </div>

                        <div className="bottom-border-gray pb-2 pt-2">
                            <div className="d-flex justify-content-between">
                                <div>Bestelling: #{order.order_number}</div>
                                <span className="text-nowrap" style={{"color": "red"}} >- € 62,10</span>
                            </div>
                            <div className="d-flex justify-content-between">
                                <div>Betalingsmethode: Terugboeking op cadeaukaart</div>
                            </div>
                            <div className="d-flex justify-content-between">
                                <div>23 oktober 2024 19:40</div>
                            </div>
                            <div className="d-flex justify-content-between">
                                <a href="#" className="new-link-color text-decoration-underline" onClick={() => {setPayRequestModalVisible(true)}}>Link naar betaalverzoek</a>
                            </div>
                        </div>

                        <div class="d-flex justify-content-between pt-2 pb-2">
                            <div className="d-flex flex-column">
                                <span className="price-title">Openstaand</span>
                                <span className="price-title">Betaald</span>
                                <span className="price-title">Terugbetaald</span>
                            </div>
                            <div className="d-flex flex-column">
                                <span className="price">€ -</span>
                                <span className="price">€ 100,21</span>
                                <span className="price">€ 62,10</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default BetalingComponent;
