import React from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import { Modal } from "react-bootstrap";
import { faXmark } from "@fortawesome/free-solid-svg-icons";

const PayRequestModalComponent = (props) => {
    const navigate = useNavigate();

    return (
    <Modal
        id="modalPayRequest"
        show={props.payRequestModalVisible}
        onHide={{}}
        className="colormodals"
        size="lg"
        centered
        backdrop="static"
    >
        <Modal.Header className="modalTop justify-content-between px-5" >
        <h3>Jouw betaalverzoek</h3>
            <button variant="transparent" className=' btn' onClick={() => {props.setPayRequestModalVisible(false)}}>
                <FontAwesomeIcon icon={faXmark} className="closeModal" />
            </button>
        </Modal.Header>
        <Modal.Body className="modalCenter d-flex flex-column">
            <h6>Link voor betaalverzoek</h6>
            <div class="input-button-wrapper">
                <input
                    type="text"
                    value="https://xd.adobe.com/view/1a67019b-a497-4810-806f-4ae5fc1593c1-4f34/"
                    disabled
                    class="standardInput inputWithInInput placeholderStyle"
                />
                <button className="btn-in-input">kopiëren</button>
            </div>
            
            <h6>Stuur een e-mail met een betalingsverzoek</h6>
            <div class="input-button-wrapper">
                <input
                    type="text"
                    placeholder="Voer e-mailadres in"
                    class="standardInput inputWithInInput placeholderStyle"
                />
                <button className="btn-in-input">verstuur verzoek</button>
            </div>
        </Modal.Body>
        <Modal.Footer className="modalBottom">
        </Modal.Footer>
    </Modal>
  )
}

export default PayRequestModalComponent
