import React, { useState, useEffect, useCallback } from "react";
import { ChevronLeft, ChevronRight } from "lucide-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSort } from "@fortawesome/free-solid-svg-icons";
import middleware from "../../Api/Middleware";
import CreateNoteModal from "../../Modals/Orders/CreateNoteModal";
import "../../Modals/Orders/ReceiveEmailModal.css";

const NotitieComponent = ({ order, setOrder }) => {
    const [itemsPerPage, setItemsPerPage] = useState(5);
    const [currentPage, setCurrentPage] = useState(1);
    const [createNoteModalVisible, setCreateNoteModalVisible] = useState(false);
    const pageCount = 1;

    return (
        <div className="whiteBox email-main-container pt-4 pb-4">
            <CreateNoteModal createNoteModalVisible={createNoteModalVisible} setCreateNoteModalVisible={setCreateNoteModalVisible}/>
            {/* Title and new note button */}
            <div className="email-header">
                <h2>Notities</h2>
                <button className="lightBlueStandardButton" style={{ width: "160px" }} onClick={() => setCreateNoteModalVisible(true)}>
                    Nieuwe notitie
                </button>
            </div>
            {/* Search bar */}
            <div className="search-bar">
                <input
                    type="text"
                    placeholder="Zoeken"
                    value={undefined}
                    onChange={undefined}
                    className="ps-4 search-input rounded-5"
                />
                <div
                    className="SearchIcon"
                    style={{ width: "42px", height: "42px", position: "absolute", right: "52px" }}
                />
            </div>
            {/* Notes table */}
            <div>
                <div className="email-container pt-2 pb-0 rounded-4 mt-4">
                    {/* Email List Table */}
                    <table className="w-100" style={{ minHeight: "140px" }}>
                        {/* Table Header */}
                        <thead className="bottom-border-line">
                            <tr>
                                <th className="ps-3">
                                    <label className="checkbox-container">
                                        <input
                                            type="checkbox"
                                            checked={undefined}
                                            onChange={undefined}
                                        />
                                        <span className="checkmark"></span>
                                    </label>
                                </th>
                                <th onClick={undefined}>
                                    <div className="flex items-center gap-2">
                                        <span>Onderwerp </span>
                                        <FontAwesomeIcon
                                            icon={faSort}
                                            style={{ fontSize: "10px" }}
                                        />
                                    </div>
                                </th>
                                <th onClick={undefined}>
                                    <div className="flex items-center gap-2">
                                        <span>Datum aangemaakt </span>
                                        <FontAwesomeIcon
                                            icon={faSort}
                                            style={{ fontSize: "10px" }}
                                        />
                                    </div>
                                </th>
                            </tr>
                        </thead>

                        {/* Table Body */}
                        <tbody>
                            <tr key={0} style={{height: "70px !important"}}>
                                <td className="ps-3">
                                    <label className="checkbox-container">
                                        <input
                                            type="checkbox"
                                            checked={undefined}
                                            onChange={undefined}
                                        />
                                        <span className="checkmark"></span>
                                    </label>
                                </td>
                                <td>
                                    <a
                                        onClick={() => {}}
                                        className="new-link-color"
                                        style={{ fontSize: "14px" }}
                                    >
                                        Lorem ipsum
                                    </a>
                                </td>
                                <td className="pr-4">
                                    <div className="flex flex-col" style={{ fontSize: "14px" }}>
                                        <span>
                                            {new Date("2024-10-22T17:40:00.000000Z").toLocaleDateString("nl-NL", {
                                                day: "numeric",
                                                month: "long",
                                                year: "numeric",
                                            }) + " "}
                                        </span>
                                        <span>
                                            {new Date("2024-10-22T17:40:00.000000Z").toLocaleTimeString([], {
                                                hour: "2-digit",
                                                minute: "2-digit",
                                            })}
                                        </span>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                {/* Pagination */}
                <div className="pagination flex items-center justify-between mt-2 mb-4">
                    <div className="d-flex gap-2">
                        <select
                            value={itemsPerPage}
                            onChange={(e) => setItemsPerPage(Number(e.target.value))}
                            className="page-sizer email-page-sizer"
                        >
                            <option value={5}>5</option>
                            <option value={10}>10</option>
                        </select>
                        <div className="page-display text-nowrap me-4">
                            {currentPage * itemsPerPage - itemsPerPage + 1}-
                            {Math.min(currentPage * itemsPerPage, 1)} van {1}
                        </div>
                    </div>

                    <div className="pagination-controls d-flex gap-1">
                        <button
                            onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
                            disabled={currentPage === 1}
                            className="pagination-btn"
                        >
                            <ChevronLeft size={18} />
                        </button>

                        {/* {getPaginationRange().map((pageNumber) => (
                            <button
                                key={pageNumber}
                                onClick={() => setCurrentPage(pageNumber)}
                                className={"pagination-btn"}
                            >
                                {pageNumber}
                            </button>
                        ))} */}

                        <button
                            key={1}
                            onClick={() => setCurrentPage(1)}
                            className={"pagination-btn"}
                        >
                            {1}
                        </button>

                        <button
                            onClick={() => setCurrentPage((prev) => Math.min(prev + 1, pageCount))}
                            disabled={currentPage === pageCount}
                            className="pagination-btn"
                        >
                            <ChevronRight size={18} />
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default NotitieComponent;
