import React, { useState, useEffect } from "react";
import middleware from "../Api/Middleware";
import { useNavigate, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Modal } from "react-bootstrap";
import { faCaretLeft, faPrint, faXmark } from "@fortawesome/free-solid-svg-icons";
import NavbarIcon from "../Navbars/Sidebar/Icons/envelope.png";
import productPic from "../WarehouseSettings/Icons/size.png";
import PageTemplate from "../Templates/PageTemplate";
import countries from 'i18n-iso-countries';
import SendEmailModal from '../Modals/Orders/SendEmailModal';
import ReceiveEmailModal from "../Modals/Orders/ReceiveEmailModal";
import InvoiceIconBlack from "./Icons/paper-black.png";
import PrinterIcon from "./Icons/printer.png";
import Backbutton from "../Products/subpages/images/BackIcon.svg";

// Component imports
import NotitieComponent from "./Components/NotitieComponent";
import CustomerComponent from "./Components/CustomerComponent";
import ReferentieComponent from "./Components/ReferentieComponent";
import EmailComponents from "./Components/EmailComponent";
import VerwerkOrderComponent from "./Components/VerwerkOrderComponent";
import CrediteringComponent from "./Components/CrediteringComponent";
import VerzendComponent from "./Components/VerzendComponent";
import BetalingComponent from "./Components/BetalingComponent";
import CancelModalComponent from "./Components/CancelModalComponent";
import TerugbetalenModalComponent from "./Components/TerugbetalenModalComponent";
import PayRequestModalComponent from "./Components/PayRequestModalComponent";

countries.registerLocale(require("i18n-iso-countries/langs/nl.json"));

const OrdersEdit = () => {
  const { order_number } = useParams();
  const navigate = useNavigate();
  const [pageIsLoading, setPageIsLoading] = useState(true);
  const [order, setOrder] = useState({});
  const [customer, setCustomer] = useState({});
  const [emails, setEmails] = useState([]);
  const [phoneNumbers, setPhoneNumbers] = useState([]);
  const [deliveryAddress, setDeliveryAddress] = useState(null);
  const [invoiceAddress, setInvoiceAddress] = useState(null);
  const [shipments, setShipments] = useState([]);
  const [shownShipments, setShownShipments] = useState({});
  const [productsToProcess, setProductsToProcess] = useState([]);
  const [productsToProcessFullyShown, setProductsToProcessFullyShown] = useState([]);
  const [cancelModalVisible, setCancelModalVisible] = useState(false);
  const [paybackModalVisible, setPaybackModalVisible] = useState(false);
  const [payRequestModalVisible, setPayRequestModalVisible] = useState(false);
  const [prices, setPrices] = useState({});

  const fetchAll = async () => {
    try {
      setPageIsLoading(true);

      const [orderResponse, countryCodesResponse] = await Promise.all([
        middleware.get(`orders?order_number=${order_number}`),
        middleware.get(`countryCodes`),
      ]);

      const order = orderResponse.data[0];
      setOrder(order);

      console.log("orderorder", order)

      const customerType = order.internal_id.charAt(0) === "C" ? 'consumers' : 'businesses';
      const { order_id, internal_id } = order;

      const [
        customerResponse,
        addressResponse,
        soldProductsResponse,
        PriceResponse,
        emailResponse,
        phoneNumberResponse,
        shipmentsResponse
      ] = await Promise.all([
        middleware.get(`${customerType}?internal_id=${internal_id}`),
        middleware.get(`addresses?internal_id=${internal_id}`),
        middleware.get(`orders/soldproducts?order_id=${order_id}`),
        middleware.get(`/sp/orderPricesTally?order_id=${order_id}`),
        middleware.get(`emails?internal_id=${internal_id}`),
        middleware.get(`phonenumbers?internal_id=${internal_id}`),
        middleware.get(`shipments/order?order_id=${order_id}`),
      ]);

      // Handle addresses
      if (addressResponse.data.length > 0) {
        addressResponse.data.forEach((address) => {
          const country = countryCodesResponse.data.find(code => code.country_code_id === address.country_code_id).country_code_2;
          address.country = country;
          (address.address_kind === 0 || address.address_kind === 2) ? setDeliveryAddress(address) : setInvoiceAddress(address);
        });
      }

      // Handle sold products
      const soldProducts = soldProductsResponse.data;
      const unprocessedProducts = soldProducts.filter(product => product.amount - product.items_processed > 0)
        .map(product => ({
          ...product,
          amount: product.amount - product.items_processed
        }));

      setCustomer(customerResponse.data[0] || {});
      setEmails(emailResponse.data || []);
      setPhoneNumbers(phoneNumberResponse.data || []);
      setPrices(PriceResponse.data[0] || {});
      setProductsToProcess(unprocessedProducts);

      // Handle shipments
      const fetchedShipments = shipmentsResponse.data.Response || [];
      setShipments(fetchedShipments);
      setShownShipments(fetchedShipments.reduce((acc, _, index) => ({ ...acc, [index]: true }), {}));

      setPageIsLoading(false);
    } catch (error) {
      console.error(error);
      // Handle error (e.g., show error message to user)
    }
  };

  useEffect(() => {
    fetchAll();
  }, []);

  const calculateSalePrice = soldProduct => {
    switch (soldProduct.sale_unit) {
      case 0: // Percentage
        return Number(soldProduct.price_incl_tax) * Number(soldProduct.amount) / 100 * Number(soldProduct.sale_amount);
      case 1: // Euro
        return Number(soldProduct.sale_amount) * Number(soldProduct.amount);
      default:
        return 0;
    }
  };

  const showPrice = price => {
    return `€ ${Number(price).toFixed(2).replace('.', ',').replace(',00', ',-')}`;
  };

  const showSalePrice = soldProduct => {
    const salePrice = calculateSalePrice(soldProduct);
    return showPrice(Number(soldProduct.price_incl_tax) - salePrice / soldProduct.amount);
  };

  console.log("order ", order)
  console.log("Products ", productsToProcess)

  return (
    <PageTemplate navbarTitle={`Order Hub`} pageIsLoading={pageIsLoading} iconNavbar={NavbarIcon}>
      <CancelModalComponent cancelModalVisible={cancelModalVisible} setCancelModalVisible={setCancelModalVisible} orderId={order.order_number}/>
      <TerugbetalenModalComponent paybackModalVisible={paybackModalVisible} setPaybackModalVisible={setPaybackModalVisible}/>
      <PayRequestModalComponent payRequestModalVisible={payRequestModalVisible} setPayRequestModalVisible={setPayRequestModalVisible}/>

      <div className="container-fluid py-4">
        <div className="row mb-4">
          <div className="col-12">
            <div className="d-flex justify-content-between">
              <div className="d-flex align-items-center gap-2">
                <img className="back-button-img" src={Backbutton} onClick={() => navigate("/bestellingen")} />
                <h5 className="mb-0">{`#${order.order_number}`}</h5>
              </div>
              <div className="d-flex gap-3">
                <button className="buttonPresetStyle" onClick={() => {}}><img style={{"height": "14px"}} className="me-2" src={PrinterIcon}/>Print pakbon</button>
                <button className="buttonPresetStyle" onClick={() => {}}><img style={{"height": "14px"}} className="me-2" src={InvoiceIconBlack}/> Print factuur</button>
                <button className="buttonPresetStyle" onClick={() => {}}>Bewerken</button>
                <button className="buttonPresetStyle" onClick={() => {setCancelModalVisible(true)}}>Annuleren</button>
              </div>
            </div>
          </div>
        </div>

        <div className="row g-4">
          <div className="col-lg-8">
            {/* VerwerkOrderComponent */}
            <div>
              <VerwerkOrderComponent
                productsToProcess={productsToProcess}
                productPic={productPic}
                showPrice={showPrice}
                showSalePrice={showSalePrice}
                calculateSalePrice={calculateSalePrice}
                statuses={[order.order_status, order.payment_status, order.delivery_status]}
                orderId={order.order_number}
              // order={order}
              // middleware={middleware}
              // fetchAll={fetchAll}
              // emails={emails}
              />
            </div>

            {/* BetalingComponent */}
            <div>
              <CrediteringComponent
                productsToProcess={productsToProcess}
                order={order}
                prices={prices}
                showPrice={showPrice}
                setPaybackModalVisible={setPaybackModalVisible}
              />
            </div>

            {/* VerzendComponent */}
            <div>
                <VerzendComponent
                  orderId={order.order_number}
                  productsToProcess={productsToProcess}
                //Eem kiekn
                // shipments={shipments}
                // shownShipments={shownShipments}
                // setShownShipments={setShownShipments}
                // productPic={productPic}
                // productsToProcessFullyShown={productsToProcessFullyShown}
                // setProductsToProcessFullyShown={setProductsToProcessFullyShown}
                // showPrice={showPrice}
                // showSalePrice={showSalePrice}
                // calculateSalePrice={calculateSalePrice}
                />
            </div>

            {/* BetalingComponent */}
            <div>
              <BetalingComponent
                order={order}
                setPayRequestModalVisible={setPayRequestModalVisible}
                />
            </div>
          </div>

          <div className="col-lg-4">
            {/* CustomerComponent */}
            <div className="mb-4">
              <CustomerComponent
                mode="show"
                customer={customer}
                emails={emails}
                phoneNumbers={phoneNumbers}
                deliveryAddress={deliveryAddress}
                countries={countries}
                invoiceAddress={invoiceAddress}
              />
            </div>

            {/* ReferentieComponent */}
            {/* <div className="card shadow-sm mb-4">
              <div className="card-body">
                <ReferentieComponent reference={order.reference} />
              </div>
            </div> */}

            {/* EmailComponents */}
            <div className="mb-4">
              <EmailComponents
                ReceiveEmailModal={ReceiveEmailModal}
                SendEmailModal={SendEmailModal}
                order={order}
                emails={emails}
                productsToProcess={productsToProcess}
              />
            </div>

            {/* NotitieComponent */}
                <NotitieComponent
                  order={order}
                  setOrder={setOrder}
                />
          </div>
        </div>
      </div>
    </PageTemplate>
  );
};

export default OrdersEdit;