import React, { useEffect, useState, useMemo } from 'react'
import PageTemplate from '../Templates/PageTemplate'
import middleware from '../Api/Middleware'
import { useParams } from 'react-router';
import TablePreset from '../Table/TablePreset';
import CheckboxHeader from '../Table/TableComponents/CheckboxHeader';
import SortableHeader from '../Table/TableComponents/SortableHeader';
import CheckboxCell from '../Table/TableComponents/CheckboxCell';
import { useTable } from 'react-table';
import TDSTextNavigation from '../Templates/TDSTextNavigation';
import { useNavigate } from 'react-router';
import ToastError from '../Toasts/ToastError';


const Packages = () => {
  const [packages, setPackages] = useState([]);
  const [loadingPage, setLoadingPage] = useState(false);
  const [loading, setLoading] = useState(true);
  const [selectedIds, setSelectedIds] = useState([]);
  const nav = useNavigate();
  const [pagination, setPagination] = useState({
      "page_size": 10,
      "page": 1,
      "max_items": 0,
      "begin_of_page": 0,
      "end_of_page": 0,
  });
  const [order, setOrder] = useState({
    orderBy: '',
    direction: ''
  });

  const { shipment_id } = useParams();

  const fetchAll = async () => {
    const [packageRes] = await Promise.all([
      middleware.get(`sp/shipmentPackagesTable?shipment_id=${shipment_id}&page=${pagination.page}&page_size=${pagination.page_size}${order.direction !== '' ? `&orderBy=${order.orderBy}&orderDirection=${order.direction}` : ""}`)
    ]);
    setPackages(packageRes.data.data)
    setPagination({
      ...pagination,
      max_items: packageRes.data.total,
      begin_of_page: packageRes.data.from,
      end_of_page: packageRes.data.to,
    });
    setLoading(false)
  }

  const handleSelection = (shipmentPackageId, isSelected) => {
    if (isSelected) {
        setSelectedIds(previous => [...previous, shipmentPackageId]);
    } else {
        setSelectedIds(previous => previous.filter(id => id !== shipmentPackageId));
    }
  };

  const generateShipmentLabel = async (packageId) => {
    try {
      const response = await middleware.post(`/myParcel/generateShipmentLabel?package_id=${packageId}`);
      console.log(response);
    } catch (error) {
      console.error("Failed to generate pakbon:", error);
    }
  };

  const generateReferenceId = async (packageId) => {
    try {
      const response = await middleware.post(`/myParcel/createShipments`, {
        package_id: packageId
      });
      console.log(response);
    } catch (error) {
      ToastError(error.response?.data?.Message || "An unknown error occurred");
    }
  };

  useEffect(() => {
    fetchAll();
  }, [order, pagination.page, pagination.page_size]);

  const columns = useMemo(
    () => {
      let columnsArray = [
          {
          Header: (
              <CheckboxHeader currentItem={packages} selectedIds={selectedIds} setSelectedIds={setSelectedIds} pk={'shipment_package_id'}>
              </CheckboxHeader>
          ),
          accessor: "select",
          Cell: ({ row }) => {
              return <CheckboxCell row={row} handleSelection={handleSelection} selectedIds={selectedIds} pk={'shipment_package_id'} />
          }
          },
          {
              Header: () => (
                  <SortableHeader currentItem={order} setItem={setOrder} field={'package_number'} pos={'center'}>
                  Pakket
                  </SortableHeader>
              ),
              accessor: 'package_number',
              Cell: ({ row }) => {
                  return (
                    <div className='d-flex flex-column text-center'>
                      <TDSTextNavigation text={row.original.package_number}/>
                      <div>
                        {row.original.package_name}
                      </div>
                    </div>
                  )
              },
          },
          {
              Header: () => (
                  <SortableHeader currentItem={order} setItem={setOrder} field={'package_label_url'} pos={'start'}>
                  Label url
                  </SortableHeader>
              ),
              accessor: 'package_label_url',
              Cell: ({ row }) => {
                if(row.original.package_label_url !== null ) {
                  return (
                    <button className='btn btn-success'  onClick={() => window.open(`https://${row.original.package_label_url}`, '_blank')}>
                      Download verzendlabel
                    </button>
                  )
                } else if (row.original.reference_id !== null) {
                  return (<button className='btn btn-primary' onClick={() => generateShipmentLabel(row.original.shipment_package_id)}>
                      Genereer verzendlabel
                    </button>
                  )
                } else {
                  return (<button className='btn btn-warning' onClick={() => generateReferenceId(row.original.shipment_package_id)}>
                    Koppel aan MyParcel
                  </button>)
                }
              },
          },
          {
              Header: () => (
                  <SortableHeader currentItem={order} setItem={setOrder} field={'status'} pos={'start'}>
                  Status
                  </SortableHeader>
              ),
              accessor: 'status',
              Cell: ({ row }) => {
                  return (
                    row.original.status || 0
                  )
              },
          },
          {
              Header: () => (
                  <SortableHeader currentItem={order} setItem={setOrder} field={'created_at'} pos={'start'}>
                  Aangemaakt op
                  </SortableHeader>
              ),
              accessor: 'created_at',
              Cell: ({ row }) => {
                  return (
                    row.original.created_at
                  )
              },
          },
      ];
      return columnsArray;
      },
      [setOrder, order.orderBy, order.direction, selectedIds, setSelectedIds, packages]
  );

  const tableInstance = useTable({ columns, data: packages || [] });

  return (
    <PageTemplate navbarTitle={"Paketten"} pageIsLoading={loadingPage} backValue={"/verzendingen"}>
      <div className="productOverview">
        <div className="productTable w-100">
          <TablePreset
            data={[{ 'selectedIds': selectedIds, 'tableInstance': tableInstance, 'pagination': pagination }]}
            tableLoading={loading}
            setPagination={(e) => setPagination(e)}
            handleSelection={handleSelection}
            primary_key={'shipment_package_id'}
          />
        </div>
      </div>
    </PageTemplate>
  )
}

export default Packages