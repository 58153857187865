const allOptions = [
    {
        // General information
        TableName: "Warehouse\\Products",
        DisplayName: "Producten",

        // Columns in the database with their index being the model in the api
        TableColumns: {
            "Warehouse\\Products": [ // Primary table
                "product_name",
                "product_number",
                "product_description",
                "status",
                "tax_class",
                "type",
                "supplier_id",
                "head_product_id",
                "image",
            ],
            
            // Sub tables
            "Warehouse\\Barcodes": [
                "barcode"
            ],
        },

        // Foreign key among all the tables
        SharedId: "product_id",

        // Columns that are required in order to process the import
        RequiredColumns: ["product_name"],

        // Keys which can be used as leading key for updating.
        UpdateKeys: {
            "Warehouse\\Products": ["product_number"],
            "Warehouse\\Barcodes": ["barcode"],
        },

        // Display information to direct the user to the object that has been made
        DirectInfo: {
            displayValues: ["product_name"], // Values that will be displayed as the object, when multiple it will have a space between
            url: "/producten/bewerken", // Url of the edit/view page 
        },

        // User-friendly display names for columns
        DisplayNames: {
            product_name: "Product naam",
            product_number: "Product nummer",
            product_description: "Product omschrijving",
            status: "Status",
            tax_class: "Belastingklasse",
            type: "Type",
            supplier_id: "Leverancier ID",
            head_product_id: "Hoofdproduct ID",
            barcode: "Barcode",
            image: "Afbeelding",
        },
    },
    {
        // General information
        TableName: "Consumers",
        DisplayName: "Customers",

        // Columns in the database with their index being the model in the api
        TableColumns: {
            Consumers: [ // Primary table
                "prefix",
                "first_name",
                "last_name",
                "phone_number",
                "birthday",
                "language",
                "fax_number",
                "loyalty_points",
            ],
            
            // Sub tables
            Emails: ["email"],
            Addresses: ["city", "streetname", "housenumber", "addition", "postalcode"],
        },

        // Foreign key among all the tables
        SharedId: "internal_id",

        // Columns that are required in order to process the import
        RequiredColumns: ["first_name", "last_name"],

        // Keys which can be used as leading key for updating.
        UpdateKeys: { 
            consumers: ["first_name"] 
        },

        // Display information to direct the user to the object that has been made
        DirectInfo: {
            displayValues: ["first_name", "last_name"], // Values that will be displayed as the object, when multiple it will have a space between
            url: "/klanten/bewerken", // Url of the edit/view page 
        },

        // User-friendly display names for columns
        DisplayNames: {
            prefix: "Voorvoegsel",
            first_name: "Voornaam",
            last_name: "Achternaam",
            phone_number: "Telefoonnummer",
            birthday: "Geboortedatum",
            language: "Taal",
            fax_number: "Faxnummer",
            loyalty_points: "Loyaltypunten",
            email: "E-mail",
            city: "Stad",
            streetname: "Straatnaam",
            housenumber: "Huisnummer",
            addition: "Toevoeging",
            postalcode: "Postcode",
        },
    },
];

export default allOptions;