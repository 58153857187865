import React from 'react'

const CheckboxGrid = ({ exportOptions, selectedTable, selectedGroupTables, handleCheckboxChange }) => {
    let itemList = {};
    let checkboxesAdded = 0;
    
    // Build itemList grouping every 3 elements
    Object.entries(exportOptions.groupOptions[exportOptions.exportTypes[selectedTable].displayName]).forEach(([key, value]) => {
        const groupIndex = Math.floor(checkboxesAdded / 3);
        if (!itemList[groupIndex]) {
            itemList[groupIndex] = [];
        }
        itemList[groupIndex].push({ key, displayName: value.displayName });
        checkboxesAdded++;
    });

    return (
        <div className="checkbox-grid-container d-flex flex-row gap-3">
            {Object.values(itemList).map((group, groupIndex) => (
                <div key={groupIndex} className="d-flex flex-column"> {/* Each group of three in a separate div */}
                    {group.map(({ key, displayName }) => (
                        <div key={key} className="form-check" style={{ flexBasis: '33.33%' }}> {/* Take up roughly 1/3 of the width */}
                            <input
                                className="form-check-input"
                                type="checkbox"
                                id={`checkbox-${key}`}
                                checked={selectedGroupTables.includes(key)}
                                onChange={() => handleCheckboxChange(key)}
                            />
                            <label className="form-check-label text-nowrap" htmlFor={`checkbox-${key}`}>
                                {displayName}
                            </label>
                        </div>
                    ))}
                </div>
            ))}
        </div>
    );
}

export default CheckboxGrid;
