import React from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import { Modal } from "react-bootstrap";
import { faXmark } from "@fortawesome/free-solid-svg-icons";

const ItemNoteModalComponent = (props) => {
    const navigate = useNavigate();

    return (
    <Modal
        id="modalItemNote"
        show={props.itemNoteModalVisible}
        onHide={{}}
        className="colormodals"
        size="xl"
        centered
        backdrop="static"
    >
        <Modal.Header className="modalTop justify-content-between px-5" >
        <h3>Orderregel wijzigen</h3>
            <button variant="transparent" className=' btn' onClick={() => {props.setItemNoteModalVisible(false)}}>
                <FontAwesomeIcon icon={faXmark} className="closeModal" />
            </button>
        </Modal.Header>
        <Modal.Body className="modalCenter d-flex flex-column">
            <h6>Productnaam</h6>
            <input type="text" value={undefined} onChange={undefined} className="standardInput w-100 placeholderStyle mb-4" />
            <h6>Opmerking wordt op onderregel getoond</h6>
            <textarea
                className="form-control rounded-4"
                rows="4"
                value={undefined}
                onChange={undefined}></textarea>
        </Modal.Body>
        <Modal.Footer className="modalBottom">
            <div className="d-flex justify-content-end" >
                <div className="buttonPresetStyle lightGrayStandardButton2 newButtons" onClick={() => {props.setItemNoteModalVisible(false)}}>Opslaan</div>
            </div>
        </Modal.Footer>
    </Modal>
  )
}

export default ItemNoteModalComponent
