import React, { useEffect, useMemo, useState } from "react";
import { read, utils } from "xlsx-js-style";
import { useDropzone } from "react-dropzone";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileImport, faCloudUploadAlt, faXmark, faSpinner } from "@fortawesome/free-solid-svg-icons";

import PageTemplate from "../Templates/PageTemplate";
import allOptions from "./Components/tableOptions";
import ToastError from "../Toasts/ToastError";
import ExcelFileIcon from "./ExcelFile.png";

import "./ImportExport.css";

function ImportPage({ setCsvData, setDownloadFiles, setSelectedTable }) {
    const [uploadedFiles, setUploadedFiles] = useState([]); 
    const [selectedTableValue, setSelectedTableValue] = useState(""); 
    const [selectedProductIdentifier, setSelectedProductIdentifier] = useState(""); 
    const [isProcessing, setIsProcessing] = useState(false); // New state for processing files
    const navigate = useNavigate();

    useEffect(() => {
        const img = new Image();
        img.src = ExcelFileIcon;
    }, []);

    // Helper function to format all sorts of values that can be screwed with by excel
    const formatDate = (cell) => {
        if (typeof cell === 'string' && /^[+-]?\d+(\.\d+)?[Ee][+-]?\d+$/.test(cell)) {
            return Number(cell).toLocaleString('fullwide', { useGrouping: false });
        }
        if (typeof cell === 'number') {
            if (cell > 1e15) {
                return cell.toLocaleString('fullwide', { useGrouping: false });
            }
        }
        return cell === "" ? "-" : cell;
    };

    // Helper function to clean up CSV data by removing empty rows and columns
    const processCSVData = (data) => {
        const cleanedData = data
            .filter((row) => row.some((cell) => cell !== "")) // Remove empty rows
            .map((row) => row.filter((cell) => cell !== "")); // Remove empty cells
        setCsvData((prevArray) => {
            const newArray = [...prevArray, cleanedData];
            return newArray;
        });
        setDownloadFiles(uploadedFiles); // Set files for download
    };

    // Configure the Dropzone for file upload
    const { fileRejections, getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } = useDropzone({
        maxFiles: 6,
        accept: {
            "text/csv": [".csv"],
            "application/vnd.ms-excel": [".xls"],
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [".xlsx"],
        },
        onFileDialogOpen: () => {
            setIsProcessing(true);
        },
        onFileDialogCancel: () => {
            setIsProcessing(false); // Reset processing if file dialog is closed without selecting files
        },
        onDrop: (acceptedFiles) => {
            const totalFiles = uploadedFiles.length + acceptedFiles.length;
            // Check if the total files exceed the limit
            if (totalFiles > 6) {
                ToastError("Je mag maximaal 6 bestanden tegelijkertijd uploaden.");
                return;
            }

            setIsProcessing(true);
                        
            // Filter out duplicates
            const newFiles = acceptedFiles.filter((newFile) => 
                !uploadedFiles.some((uploadedFile) =>
                    uploadedFile.name === newFile.name && uploadedFile.size === newFile.size
                )
            );
            
            // Update uploaded files
            setUploadedFiles((prevFiles) => [...prevFiles, ...newFiles]);
            
            // Simulate a small delay to show processing state
            setIsProcessing(false);
        },
        onDropRejected: (rejectedFiles) => {
            // Loop through rejected files to find rejections due to exceeding the file limit
            const hasLimitExceeded = rejectedFiles.some((file) => file.errors.some((error) => error.code === 'too-many-files'));
            if (hasLimitExceeded) {
                ToastError("Je mag maximaal 6 bestanden tegelijkertijd uploaden.");
            }
        },
    });
    
    // Styling for Dropzone
    const style = useMemo(() => ({
        borderWidth: 2,
        borderRadius: "12px",
        padding: "30px",
        borderStyle: "dashed",
        backgroundColor: "#f1f5f9",
        color: isDragAccept ? "#00e676" : isDragReject ? "#ff1744" : "#9ca3af",
        borderColor: isFocused ? "#3b82f6" : "#e2e8f0",
        cursor: "pointer",
        textAlign: "center",
        transition: "all 0.25s ease-in-out",
    }), [isFocused, isDragAccept, isDragReject]);

    // Render list of accepted files
    const acceptedFileItems = uploadedFiles.length > 0 ? (
        uploadedFiles.map((file) => (
            <div key={file.name} className="file-card">
                <img style={{maxHeight: "80%", maxWidth: "80%", width: "auto", height: "auto"}} src={ExcelFileIcon}/>
                <button
                    onClick={(e) => {
                        e.stopPropagation(); // Prevents the file explorer to open because it's inside of the drag zone
                        removeFile(file.name); // Remove file on button click
                    }}
                    className="remove-btn"
                >
                    <FontAwesomeIcon icon={faXmark} className="text-white" />
                </button>
                <div className="file-info">
                    <p className="text-black">{file.name}</p>
                    <small>{(file.size / 1024).toFixed(2)} kB</small>
                </div>
            </div>
        ))
    ) : ( // Standard message when no files have been uploaded yet
        <span className="no-files">Nog geen bestanden geüpload</span>
    );

    // Dropzone content with processing state
    const dropzoneContent = (
        <>
            {isProcessing ? (
                <div className="processing-overlay">
                <l-hourglass
                size="40"
                bg-opacity="0.1"
                speed="1.75" 
                color="gray" 
                ></l-hourglass>
                    <p>Bestanden aan het uploaden...</p>
                </div>
            ) : (
                <>
                    <FontAwesomeIcon icon={faCloudUploadAlt} size="2x" />
                    <p className="mb-1">Klik hier om 1 of meer bestanden te selecteren</p>
                    <p>(Je kunt maximaal 6 bestanden hier neerzetten)</p>
                </>
            )}
            <div className="file-card-container">{acceptedFileItems}</div>
        </>
    );
    

    // Define custom error messages for rejected files
    const errorMessages = {
        'file-invalid-type': 'Ongeldig bestandstype. Alleen .csv, .xls en .xlsx zijn toegestaan.',
        'file-too-large': 'Bestand is te groot. Het maximale bestandsgrootte is overschreden.',
        'too-many-files': 'Te veel bestanden.',
    };

// Render list of rejected files with error messages, filtering out "too-many-files" rejections
const fileRejectionItems = fileRejections
    .filter(({ errors }) => !errors.some((e) => e.code === 'too-many-files')) // Filter out "too-many-files" errors
    .map(({ file, errors }) => (
        <li key={file.name} className="file-rejection">
            <strong>{file.name}</strong>
            {errors.map((e) => (
                <span key={e.code}>{errorMessages[e.code] || e.message}</span>
            ))}
        </li>
    ));

    // Handle form submission
    function handleCSV(e) {
        e.preventDefault();
        if (!selectedTableValue) {
            ToastError("Selecteer een import type."); // Error if no table type is selected
            return;
        }
        if (uploadedFiles.length === 0) {
            ToastError("Upload minimaal 1 bestand om verder te gaan."); // Error if no files are uploaded
            return;
        }
        if (selectedTableValue == "Warehouse\\Products" && selectedProductIdentifier == "") {
            ToastError("Selecteer een leidende kolom."); // Error if no leading product identifier
            return;    
        }
        setCsvData([]); // Reset CSV data

        // Read each file and parse its contents
        uploadedFiles.forEach((file) => {
            const reader = new FileReader();
            reader.onload = (event) => {
                const data = new Uint8Array(event.target.result);
                const workbook = read(data, { type: "array", cellDates: true, cellStyles: true });
                const sheetName = workbook.SheetNames[0];
                const worksheet = workbook.Sheets[sheetName];
                const rawData = utils.sheet_to_json(worksheet, {
                    header: 1,
                    raw: true,
                    dateNF: "yyyy-mm-dd hh:mm",
                    defval: "",
                    blankrows: false,
                    rawNumbers: true,
                });

                const processedData = rawData.map((row) => row.map((cell) => formatDate(cell)));
                processCSVData(processedData); // Process the cleaned data
            };
            reader.readAsArrayBuffer(file);
        });
        navigate("/import/kolom-configuratie", {state: {selectedProductIdentifier: selectedTableValue == "Warehouse\\Products" ? selectedProductIdentifier : ""}}); // Navigate to column configuration page
    }

    // Remove file from uploaded files list
    const removeFile = (fileName) => {
        setUploadedFiles((prevFiles) => prevFiles.filter((file) => file.name !== fileName));
    };

    return (
        <PageTemplate navbarTitle={"Nieuwe Import"} backValue={"/import-export-center"}>
            <div className="import-page">
                <form onSubmit={handleCSV} className="import-form">
                    <section className="mb-3">
                        {/* Dropdown to select import type */}
                        <select
                            value={selectedTableValue}
                            onChange={(e) => {
                                setSelectedTable(e.target.value);
                                setSelectedTableValue(e.target.value);
                            }}
                            className="form-select mb-3"
                        >
                            <option value={""} hidden>--- Selecteer een import type ---</option>
                            {allOptions.map((type, index) => (
                                <option value={type.TableName} key={index}>{type.DisplayName}</option>
                            ))}
                        </select>
                        {selectedTableValue == "Warehouse\\Products" ? <select
                            value={selectedProductIdentifier}
                            onChange={(e) => {
                                setSelectedProductIdentifier(e.target.value);
                            }}
                            className="form-select mb-3"
                        >
                            <option value={""} hidden>--- Selecteer de leidende kolom ---</option>
                            <option value={"product_number"} key={0}>Product nummer</option>
                            <option value={"barcode"} key={1}>Barcode</option>
                        </select> : ""}
                        <h2>Importeer je bestanden</h2>
                        {/* Dropzone for file upload */}
                        <div {...getRootProps({ style })} className="dropzone">
                            <input {...getInputProps()} />
                            {dropzoneContent}
                        </div>
                        {/* Display rejected files */}
                        <aside className="file-info">
                            {fileRejectionItems.length > 0 && (
                                <>
                                    <h4>Afgewezen bestanden</h4>
                                    <ul className="file-list">{fileRejectionItems}</ul>
                                </>
                            )}
                        </aside>
                    </section>
                    {/* Submit button */}
                    <button type="submit" className="btn btn-primary submit-btn"><FontAwesomeIcon icon={faFileImport}/> Bestanden importeren</button>
                </form>
            </div>
        </PageTemplate>
    );
}

export default ImportPage;
