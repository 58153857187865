import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faXmark, faClock, faHourglassHalf } from "@fortawesome/free-solid-svg-icons";

// Returns status text with an icon
const getStatusText = (statusCode) => {
    let statusIcon;
    let statusTextClass;
    let statusMessage;

    switch (statusCode) {
        case 0:
            statusIcon = faXmark;
            statusTextClass = "status-text-red";
            statusMessage = "Taak mislukt";
            break;
        case 1:
            statusIcon = faCheck;
            statusTextClass = "status-text-green";
            statusMessage = "Taak afgerond";
            break;
        case 2:
        case 3:
            statusIcon = statusCode === 2 ? faClock : faHourglassHalf;
            statusTextClass = "status-text-orange";
            statusMessage = statusCode === 2 ? "In behandeling" : "Taak in afwachting";
            break;
        case 4:
            statusIcon = faXmark;
            statusTextClass = "status-text-red";
            statusMessage = "Taak geannuleerd";
            break;
        default:
            statusIcon = faXmark;
            statusTextClass = "status-text-unchanged";
            statusMessage = "Onbekend";
            break;
    }

    return (<span className={`me-1 ${statusTextClass}`}><FontAwesomeIcon icon={statusIcon} /> {statusMessage}</span>);
};

export default getStatusText;