// MainPage.js
import React, { useEffect, useState } from "react";
import "./MainPage.css";
import Loading from "../LoadingScreen/LoadingScreen";
import Logo from "./Icons/Logo Unified SMB - Blauw.png";
import PageTemplate from "../Templates/PageTemplate";
import middleware from "../Api/Middleware";
import { useNavigate } from "react-router-dom";
import AnimatedLineWithDoubleBump from './line/AnimatedLineWithDoubleBump'; // Import the component

const MainPage = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [hubs, setHubs] = useState([]);
  const savedCollapsed = localStorage.getItem("CollapsedSidebar");
  const [collapsedSidebar, setCollapsedSidebar] = useState(
    savedCollapsed ? JSON.parse(savedCollapsed) : false
  );
  const [viewWidth, setViewWidth] = useState(
    window.innerWidth - (collapsedSidebar ? 100 : 270)
  );
  const viewHeight = 751; // Fixed height of the viewing area
  const navigate = useNavigate(); // Initialize the navigate function

  // Define animation classes and their corresponding parameters
  const animationMappings = [
    {
      className: "floatUpDown",
      x2Values: (x) => `${x}; ${x + 5}; ${x}`,
      y2Values: (y) => `${y}; ${y - 5}; ${y}`,
      cssAnimation: "floatUpDown",
      duration: "6000ms",
    },
    {
      className: "floatDownUp",
      x2Values: (x) => `${x}; ${x - 5}; ${x}`,
      y2Values: (y) => `${y}; ${y + 5}; ${y}`,
      cssAnimation: "floatDownUp",
      duration: "8000ms",
    },
    {
      className: "floatLeftRight",
      x2Values: (x) => `${x}; ${x - 10}; ${x}`,
      y2Values: (y) => `${y}; ${y + 5}; ${y}`,
      cssAnimation: "floatLeftRight",
      duration: "7000ms",
    },
    {
      className: "floatRightLeft",
      x2Values: (x) => `${x}; ${x + 10}; ${x}`,
      y2Values: (y) => `${y}; ${y - 5}; ${y}`,
      cssAnimation: "floatRightLeft",
      duration: "9000ms",
    },
  ];

  const fetchAll = async () => {
    setIsLoading(true);
    try {
      const fetchHubs = await middleware.get("sp/dashboardHubs");
      setHubs(fetchHubs.data);
    } catch (error) {
      console.error("Error fetching hubs:", error);
    }
    setIsLoading(false);
  };

  const GetCollapsedSidebar = (collapsedSidebar) => {
    setCollapsedSidebar(collapsedSidebar);
  };

  useEffect(() => {
    // Event handler for storage changes
    const handleStorageChange = (event) => {
      if (event.key === "CollapsedSidebar") {
        setCollapsedSidebar(event.newValue ? JSON.parse(event.newValue) : false);
      }
    };

    // Event handler for custom localStorageChange event
    const handleLocalStorageChange = () => {
      const saved = localStorage.getItem("CollapsedSidebar");
      setCollapsedSidebar(saved ? JSON.parse(saved) : false);
    };

    // Listen to both storage and custom event
    window.addEventListener("storage", handleStorageChange);
    window.addEventListener("localStorageChange", handleLocalStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
      window.removeEventListener("localStorageChange", handleLocalStorageChange);
    };
  }, []);

  useEffect(() => {
    // Update width when window size changes
    const handleResize = () => {
      setViewWidth(window.innerWidth - (collapsedSidebar ? 100 : 270));
    };

    window.addEventListener("resize", handleResize); // Listen to window resizing
    return () => {
      window.removeEventListener("resize", handleResize); // Clean up the event listener
    };
  }, [collapsedSidebar]);

  useEffect(() => {
    // Update width when collapsedSidebar changes
    setViewWidth(window.innerWidth - (collapsedSidebar ? 100 : 270));
  }, [collapsedSidebar]);

  useEffect(() => {
    fetchAll();
  }, []);

  if (isLoading) {
    return (
      <div className="loading-container">
        <Loading />
      </div>
    );
  }

  // Function to adjust the size of hubs based on their status
  const getNodeSize = (status) => {
    const baseSize = 150; // Default size for hubs
    switch (status) {
      case 0: // Active
        return baseSize * 1.2; // Active hubs are larger
      case 1: // Installing
      case 2: // Inactive
        return baseSize * 1; // Installing and inactive hubs are medium-sized
      default:
        return baseSize;
    }
  };

  // Function to get the status text
  const getStatusText = (status) => {
    switch (status) {
      case 0:
        return "Actief";
      case 1:
        return "Installing";
      case 2:
        return "Inactive";
      default:
        return "Onbekend";
    }
  };

  // Function to get the background color for the status icon
  const getStatusColor = (status) => {
    switch (status) {
      case 0:
        return "#009cde"; // Active - Blue
      case 1:
        return "#ffb36d"; // Installing - Orange/Yellow
      case 2:
        return "#f97374"; // Inactive - Red
      default:
        return "gray"; // Unknown status
    }
  };

  // Function to get the line style based on hub status
  const getLineStyle = (status) => {
    switch (status) {
      case 0: // Active
        return {
          stroke: "#009cde", // Blue
          strokeWidth: 4,
          strokeDasharray: "none",
          strokeDashoffset: 0,
          className: "active-line",
          filter: "url(#shadow)", // Use drop shadow filter for active lines
        };
      case 1: // Installing
        return {
          stroke: "#abcadc",
          strokeWidth: 2,
          strokeDasharray: "7",
          strokeDashoffset: 7,
          className: "installing-line",
          filter: "none",
        };
      case 2: // Inactive
        return {
          stroke: "#abcadc",
          strokeWidth: 2,
          strokeDasharray: "none",
          strokeDashoffset: 0,
          className: "inactive-line",
          filter: "none",
        };
      default:
        return {
          stroke: "gray",
          strokeWidth: 2,
          strokeDasharray: "none",
          strokeDashoffset: 0,
          className: "default-line",
          filter: "none",
        };
    }
  };

  // Function to calculate positions along a rounded rectangle and inside
  const getDynamicPositions = (hubs) => {
    const maxPerimeterHubs = 14; // Maximum hubs along the perimeter
    const maxInnerHubsPerSide = 2; // Maximum 2 hubs per side inside the rectangle
    const maxInnerHubs = maxInnerHubsPerSide * 2; // Total of 4 hubs inside
    const perimeterHubs = hubs.slice(0, maxPerimeterHubs);
    const innerHubs = hubs.slice(maxPerimeterHubs, maxPerimeterHubs + maxInnerHubs);
    const outerHubs = hubs.slice(maxPerimeterHubs + maxInnerHubs);

    // Calculate positions for perimeter hubs
    const perimeterPositions = calculatePerimeterPositions(perimeterHubs);

    // Calculate positions for inner hubs
    const innerPositions = calculateInnerPositions(innerHubs);

    // Calculate positions for outer hubs
    const outerPositions = calculateOuterPositions(outerHubs);

    // Combine all positions
    return [...perimeterPositions, ...innerPositions, ...outerPositions];
  };

  // Function to calculate positions for perimeter hubs
  const calculatePerimeterPositions = (hubs) => {
    const rectWidth = viewWidth - 500; // Subtract margins
    const rectHeight = viewHeight - 200; // Subtract margins
    const startX = 260; // Margin from left
    const startY = 100; // Margin from top
    const cornerRadius = 300; // Radius of the rounded corners

    // Lengths of the straight sides
    const sideLengthX = rectWidth - 2 * cornerRadius;
    const sideLengthY = rectHeight - 2 * cornerRadius;

    // Length of the corner arcs (quarter circles)
    const arcLength = (Math.PI / 2) * cornerRadius;

    // Total perimeter
    const perimeter = 2 * (sideLengthX + sideLengthY) + 4 * arcLength;

    // Distance between hubs along the path
    const hubSpacing = perimeter / hubs.length;

    const positions = [];

    hubs.forEach((hub, index) => {
      let distance = index * hubSpacing;
      let x, y;

      if (distance < sideLengthX) {
        // Top side
        x = startX + cornerRadius + distance;
        y = startY;
      } else if (distance < sideLengthX + arcLength) {
        // Top-right corner arc
        const angle = (distance - sideLengthX) / cornerRadius;
        x = startX + rectWidth - cornerRadius + cornerRadius * Math.sin(angle);
        y = startY + cornerRadius - cornerRadius * Math.cos(angle);
      } else if (distance < sideLengthX + arcLength + sideLengthY) {
        // Right side
        x = startX + rectWidth;
        y = startY + cornerRadius + (distance - sideLengthX - arcLength);
      } else if (distance < sideLengthX + 2 * arcLength + sideLengthY) {
        // Bottom-right corner arc
        const angle = (distance - sideLengthX - arcLength - sideLengthY) / cornerRadius;
        x = startX + rectWidth - cornerRadius + cornerRadius * Math.cos(angle);
        y = startY + rectHeight - cornerRadius + cornerRadius * Math.sin(angle);
      } else if (distance < 2 * sideLengthX + 2 * arcLength + sideLengthY) {
        // Bottom side
        x = startX + rectWidth - cornerRadius - (distance - sideLengthX - 2 * arcLength - sideLengthY);
        y = startY + rectHeight;
      } else if (distance < 2 * sideLengthX + 3 * arcLength + sideLengthY) {
        // Bottom-left corner arc
        const angle = (distance - 2 * sideLengthX - 2 * arcLength - sideLengthY) / cornerRadius;
        x = startX + cornerRadius - cornerRadius * Math.sin(angle);
        y = startY + rectHeight - cornerRadius + cornerRadius * Math.cos(angle);
      } else if (distance < 2 * sideLengthX + 3 * arcLength + 2 * sideLengthY) {
        // Left side
        x = startX;
        y = startY + rectHeight - cornerRadius - (distance - 2 * sideLengthX - 3 * arcLength - sideLengthY);
      } else {
        // Top-left corner arc
        const angle = (distance - 2 * sideLengthX - 3 * arcLength - 2 * sideLengthY) / cornerRadius;
        x = startX + cornerRadius - cornerRadius * Math.cos(angle);
        y = startY + cornerRadius - cornerRadius * Math.sin(angle);
      }

      // Select the appropriate animation based on index
      const animMapping = animationMappings[index % animationMappings.length];

      positions.push({
        ...hub,
        position: { x, y },
        animationClass: animMapping.cssAnimation,
        animateX2Values: animMapping.x2Values(x),
        animateY2Values: animMapping.y2Values(y),
        animationDuration: animMapping.duration,
      });
    });

    return positions;
  };

  // Function to calculate positions for inner hubs (maximum 2 per side)
  const calculateInnerPositions = (hubs) => {
    const positions = [];
    const innerHubOffsets = [
      { x: -200, y: -100 }, // Left top
      { x: 200, y: -100 },  // Right top
      { x: -200, y: 100 },  // Left bottom
      { x: 200, y: 100 },   // Right bottom
    ];

    const centerX = viewWidth / 2;
    const centerY = viewHeight / 2;

    hubs.forEach((hub, index) => {
      if (index >= innerHubOffsets.length) return; // Limit to 4 hubs

      const offset = innerHubOffsets[index];
      const x = centerX + offset.x;
      const y = centerY + offset.y;

      // Select the appropriate animation based on index
      const animMapping = animationMappings[(index + 14) % animationMappings.length];

      positions.push({
        ...hub,
        position: { x, y },
        animationClass: animMapping.cssAnimation,
        animateX2Values: animMapping.x2Values(x),
        animateY2Values: animMapping.y2Values(y),
        animationDuration: animMapping.duration,
      });
    });

    return positions;
  };

  // Function to calculate positions for outer hubs (beyond the main ring)
  const calculateOuterPositions = (hubs) => {
    const positions = [];
    const radius = Math.max(viewWidth, viewHeight) / 2 + 200; // Position outside the main ring
    const centerX = viewWidth / 2;
    const centerY = viewHeight / 2;
    const totalOuterHubs = hubs.length;
    const angleBetweenHubs = (2 * Math.PI) / totalOuterHubs;

    hubs.forEach((hub, index) => {
      const angle = index * angleBetweenHubs;
      const x = centerX + radius * Math.cos(angle);
      const y = centerY + radius * Math.sin(angle);

      // Select the appropriate animation based on index
      const animMapping = animationMappings[(index + 18) % animationMappings.length];

      positions.push({
        ...hub,
        position: { x, y },
        animationClass: animMapping.cssAnimation,
        animateX2Values: animMapping.x2Values(x),
        animateY2Values: animMapping.y2Values(y),
        animationDuration: animMapping.duration,
      });
    });

    return positions;
  };

  // Pre-calculated positions for all hubs
  const hubPositions = getDynamicPositions(hubs);

  return (
    <PageTemplate GetCollapsedSidebar={GetCollapsedSidebar}>
      <div className="hubsWeb">
        {/* Central Circle */}
        <div className="backgroundCenterCircle">
          <img className="logoCenterCircle" src={Logo} alt="Hub Logo" />
          <p className="text-white text-center mb-0">Hub</p>
        </div>

        {/* SVG Filter Definitions for Glow Effect */}
        <svg width="0" height="0">
          <defs>
            <filter id="shadow" x="-50%" y="-50%" width="200%" height="200%">
              <feDropShadow
                dx="3"
                dy="1"
                stdDeviation="5"
                floodColor="rgba(0, 156, 222)"
              />
            </filter>
          </defs>
        </svg>

        {/* Connections and Nodes */}
        <svg className="hub-connections" width="100%" height="100%">
          {/* Render lines first */}
          {hubPositions.map((hub, index) => {
            const {
              position,
              animationClass = 'floatUpDown', // Default value
              animateX2Values,
              animateY2Values,
              animationDuration = '6000ms', // Default value
            } = hub;
            const {
              stroke,
              strokeWidth,
              strokeDasharray,
              strokeDashoffset,
              filter,
              className,
            } = getLineStyle(hub.status);

            const lineStartX = viewWidth / 2;
            const lineStartY = viewHeight / 2;
            const lineEndX = position.x;
            const lineEndY = position.y;
            const animationDelay = 0; // No delay, all animations start simultaneously

            if (hub.status === 0) { // Active line
              return (
                <AnimatedLineWithDoubleBump
                  key={`animated-line-${hub.id || index}`}
                  startX={lineStartX}
                  startY={lineStartY}
                  endX={lineEndX}
                  endY={lineEndY}
                  lineColor="#009cde"
                  lineWidth={4}
                  bumpAmplitude={5}
                  bumpLength={0.05}
                  duration={animationDuration}
                  svgWidth={viewWidth}
                  svgHeight={viewHeight}
                  randomStart={true}
                  animationClass={animationClass}
                  hubAnimationDuration={animationDuration}
                  animationDelay={animationDelay}
                />
              );
            }
            // For non-active lines, render the standard <line>
            return (
              <line
                key={`line-${hub.id || index}`}
                x1={lineStartX}
                y1={lineStartY}
                x2={lineEndX}
                y2={lineEndY}
                stroke={stroke}
                strokeWidth={strokeWidth}
                strokeDasharray={strokeDasharray}
                strokeDashoffset={strokeDashoffset}
                className={`${className}`}
                filter={filter}
              >
                {/* Animate x2 */}
                <animate
                  attributeName="x2"
                  values={animateX2Values}
                  dur={animationDuration}
                  repeatCount="indefinite"
                  begin="0s" // Start immediately
                />
                {/* Animate y2 */}
                <animate
                  attributeName="y2"
                  values={animateY2Values}
                  dur={animationDuration}
                  repeatCount="indefinite"
                  begin="0s" // Start immediately
                />
              </line>
            );
          })}

          {/* Render nodes next */}
          {hubPositions.map((hub, index) => {
            const {
              position,
              animationClass = 'floatUpDown', // Default value
              animationDuration = '6000ms',    // Default value
            } = hub;
            const nodeSize = getNodeSize(hub.status);
            const animationDelay = "0s"; // No delay

            return (
              <foreignObject
                key={`node-${hub.id || index}`}
                x={position.x - nodeSize / 2}
                y={position.y - nodeSize / 2}
                width={nodeSize}
                height={nodeSize}
                overflow="visible"
              >
                <div
                  className={`hub-node ${hub.status === 0 ? "active-status" : ""} ${animationClass}`}
                  style={{
                    width: `${nodeSize}px`,
                    height: `${nodeSize}px`,
                    cursor: hub.status === 0 ? "pointer" : "default",
                    animationDelay: animationDelay,
                    animationDuration: animationDuration,
                  }}
                  onClick={() => {
                    if (hub.status === 0) {
                      navigate(hub.redirect_path); // Navigate to the specified route
                    }
                  }}
                >
                  {/* Status Icon */}
                  <div
                    className="status-icon"
                    style={{
                      backgroundColor: getStatusColor(hub.status),
                      width: "50px",
                      height: "50px",
                      borderRadius: "50%",
                      position: "absolute",
                      top: "-3px",
                      left: "-3px",
                    }}
                  >
                    <img className="status-icon" src={hub.icon} alt="Icon" />
                  </div>

                  {/* Status and Hub Name */}
                  <p className="mb-0" style={{ fontSize: "10px" }}>
                    {getStatusText(hub.status)}
                  </p>
                  <h3
                    className="mb-0"
                    style={{
                      fontSize: `${Math.min(Math.max(nodeSize / 10, 18), 24)}px`,
                    }}
                  >
                    {hub.name.endsWith("HUB") ? hub.name.slice(0, -3) : hub.name}
                  </h3>
                  <p className="px-2" style={{ fontSize: "8px" }}>
                    {hub.description}
                  </p>
                </div>
              </foreignObject>
            );
          })}
        </svg>
      </div>
    </PageTemplate>
  );
};

export default MainPage;
