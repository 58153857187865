import { useEffect } from 'react';
import { useNavigate } from 'react-router';
import Cookies from "universal-cookie";

const UnauthorizedEventListener = () => {
    const navigate = useNavigate();
    const cookies = new Cookies();

    useEffect(() => {
        const handleUnauthorized = () => {
            cookies.remove('token', { path: '/' });
            navigate('/login');
        };

        window.addEventListener('unauthorized-event', handleUnauthorized);

        // Cleanup the event listener on component unmount
        return () => {
            window.removeEventListener('unauthorized-event', handleUnauthorized);
        };
    }, [navigate]);

    return null; // This component does not render anything
}

export default UnauthorizedEventListener;
