import React, { useState, useEffect, useRef } from 'react';
import Cookies from "universal-cookie";
import { useNavigate, useParams } from "react-router";
import middleware from "../Api/Middleware";
import ToastError from "../Toasts/ToastError";
import Key from "./Users/EditUsers/Icons/key.svg";
import Eye from "./Users/EditUsers/Icons/eye.svg";
import EyeClosed from "./Users/EditUsers/Icons/eye-closed.svg";
import ToastInfo from "../Toasts/ToastInfo";
import ToastSuccess from "../Toasts/ToastSuccess";
import PasswordRequirementsModal from "./Users/EditUsers/Modals/PasswordRequirementsModal";
import SecurityCodeModal from "./Users/EditUsers/Modals/SecurityCodeModal";
import DeactivateUserModal from "./Users/EditUsers/Modals/DeactivateUserModal";
import PageTemplate from "../Templates/PageTemplate";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import './EditProfile.css'
import NoPhoto from "./nopfp.png";
import FsLightbox from "fslightbox-react";
import { hourglass } from 'ldrs'


const languageOptions = [
  {value: "NL", label: "Nederlands"},
  {value: "EN", label: "English"},
  {value: "DE", label: "Deutsch"},
  {value: "FR", label: "Français"}
];

const EditProfile = () => {
    const cookies = new Cookies();
    const navigate = useNavigate();
    const [user, setUser] = useState([]);
    const [updateUserValues, setUpdateUserValues] = useState({});
    const [showPassword, setShowPassword] = useState(false);
    const [lightBoxOpened, setLightBoxOpened] = useState(false);
    const [generatedPassword, setGeneratedPassword] = useState(false);
    const [generatedSecurityCode, setGeneratedSecurityCode] = useState(false);
    const [showSecurityCode, setShowSecurityCode] = useState(false);
    const [loading, setLoading] = useState(true);
    const [loadingScreen, setLoadingScreen] = useState(true);
    const [emailError, setEmailError] = useState("");
    const [passwordError, setPasswordError] = useState("");
    const [changedPfp, setChangedPfp] = useState(false);
    const [updatingPicture, setUpdatingPicture] = useState(false);
    const { user_id } = useParams();
    const imageInputRef = useRef(null);
    const [modals, setModals] = useState({
        password: false,
        securityCode: false,
        deactivateUser: false
    });
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^A-Za-z\d])[A-Za-z\d\S]{8,}$/;

    const toggleModal = (modal) => setModals((prev) => ({ ...prev, [modal]: !prev[modal] }));
    
    hourglass.register();

  const fetchAll = async () => {
    try {
      setLoading(true);
      const [fetchUserRes, pfpRes] = await Promise.all([
        middleware.get(`users?api_token=${cookies.get("token")}`),
        middleware.get(`profilepicture`),
      ]);

      const userData = {
        ...fetchUserRes.data[0],
        profile_picture: pfpRes.data.profile_picture_url
      };
  
      setUser(userData);
      setUpdateUserValues(userData);
        setLoading(false)
    } catch (error) {
      ToastError(error.message)
    }
  };

  useEffect(() => {
    fetchAll();
  }, []);
  

  const validateEmail = (email) => {
    if (!emailRegex.test(email)) {
      setEmailError("Ongeldig e-mailadres.");
    } else {
      setEmailError("");
    }
  };

  const validatePassword = (password) => {
    if (!passwordRegex.test(password)) {
      setPasswordError("Wachtwoord moet minimaal 8 tekens bevatten, inclusief hoofdletters, kleine letters, een cijfer en een speciaal teken.");
    } else {
      setPasswordError("");
    }
  };

  const hasChanges = () => {
    for (const key in updateUserValues) {
      if (updateUserValues[key] !== user[key]) {
        return true;
      }
    }
    return false;
  };

  const generateRandomPassword = async (event) => {
    event.preventDefault();
  
    const length = 15; // Minimum length is 8, so 15 is sufficient
    const lowercase = "abcdefghijklmnopqrstuvwxyz";
    const uppercase = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    const digits = "0123456789";
    const specialChars = "!@#$%^&*";
    
    // Ensure each type is included at least once
    let password = [
      lowercase[Math.floor(Math.random() * lowercase.length)],
      uppercase[Math.floor(Math.random() * uppercase.length)],
      digits[Math.floor(Math.random() * digits.length)],
      specialChars[Math.floor(Math.random() * specialChars.length)],
    ];
  
    // Fill the rest of the password
    const allChars = lowercase + uppercase + digits + specialChars;
    while (password.length < length) {
      password.push(allChars[Math.floor(Math.random() * allChars.length)]);
    }
  
    // Shuffle the password to ensure randomness
    password = password.sort(() => Math.random() - 0.5).join('');
  
    // Update state
    setUpdateUserValues({ ...updateUserValues, password });
    setGeneratedPassword(true)
  
    try {
      await navigator.clipboard.writeText(password);
      ToastInfo("Wachtwoord gekopieerd naar clipboard", 1500);
    } catch (err) {
      console.error("Failed to copy password: ", err);
    }
  };

  const generateRandomSecurityCode = async event => {
    event.preventDefault();
    const length = 6;
    const charset = "0123456789";
    let code = "";

    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * charset.length);
      let char = charset[randomIndex];      
      code += char;
    }

    setUpdateUserValues({...updateUserValues, "security_code": code});
    await updateUser();

    try {
      await navigator.clipboard.writeText(code);
      ToastInfo(`Beveiligingspin gekopieerd naar clipboard`, 1500);
    } catch (err) {
      console.error('Failed to copy password: ', err);
    }
  }

  const updateUser = async () => {
    try {
      if(hasChanges()) {
        if(emailError == "" && passwordError == "") {
          const { profile_picture, profile_picture_path, ...filteredUpdateUserValues } = updateUserValues;
          const [putUser] = await Promise.all([
            middleware.put('users', filteredUpdateUserValues)
          ]);
          ToastSuccess("Gebruikergegevens bijgewerkt")
        } else if (emailError !== "") {
          ToastError(emailError)
        } else if (passwordError !== "") {
          ToastError(passwordError)
        }
      }
    } catch (error) {
      ToastError(error.message);
    }
  }

  const changeUser = async (column, event) => {
    const value = event.target.value;

    if (column === "email") validateEmail(value);
    if (column === "password") validatePassword(value);
    
    setUpdateUserValues({...updateUserValues, [column]: event.target.value})
  }

  const handleImageUpload = async (event) => {
    const file = event.target.files[0];
    setUpdatingPicture(true);
  
    if (file) {
      const fileType = file.type;
      const validImageTypes = ["image/png", "image/jpg", "image/jpeg", "image/webp", "image/gif"];
      const maxSize = 2 * 1024 * 1024;
  
      if (!validImageTypes.includes(fileType)) {
        ToastError("Ongeldig foto. Alleen PNG, JPG, JPEG, WEBP en GIF zijn toegestaan.");
        return;
      }
  
      const reader = new FileReader();
  
      reader.onload = async (e) => {
        const base64String = e.target.result;
  
        try {
          const response = await middleware.put('profilepicture', { "profile_picture": base64String }, cookies.get("token"));
  
          ToastSuccess(response.data.message || "Profielafbeelding succesvol bijgewerkt.");
  
          if(response.data.profile_picture_url) {
            const url = response.data.profile_picture_url;
            const token = cookies.get('token');
            const navInfoKey = `navInfo[${token}]`;
            const navInfoString = localStorage.getItem(navInfoKey);
            let navInfo = JSON.parse(navInfoString) || {};
            navInfo.profile_picture_url = url
            const updatedNavInfoString = JSON.stringify(navInfo);
            localStorage.setItem(navInfoKey, updatedNavInfoString);
            
            setUser(prevUser => ({
              ...prevUser,
              profile_picture: url
            }));
            setUpdateUserValues(prevValues => ({
              ...prevValues,
              profile_picture: url
            }));
          }
        } catch (error) {
          if (error.response && error.response.status === 422) {
            const validationErrors = error.response.data.errors;
            Object.values(validationErrors).forEach(errMessages => {
              errMessages.forEach(msg => ToastError(msg));
            });
          } else {
            ToastError(error.response?.data?.message || "Fout bij het uploaden van de profielafbeelding.");
          }
          console.error(error);
        } finally {
          setUpdatingPicture(false);
          if (imageInputRef.current) {
            imageInputRef.current.value = null;
          }
        }
      };
      reader.readAsDataURL(file);
    }
  };    
  
  const handleImageDelete = async () => {
    try {
      setUpdatingPicture(true);
      const response = await middleware.delete('profilepicture')
  
      ToastSuccess(response.data.message)
      const token = cookies.get('token');
      const navInfoKey = `navInfo[${token}]`;
      const navInfoString = localStorage.getItem(navInfoKey);
      let navInfo = JSON.parse(navInfoString) || {};
      navInfo.profile_picture_url = null
      const updatedNavInfoString = JSON.stringify(navInfo);
      localStorage.setItem(navInfoKey, updatedNavInfoString);
  
      setUser(prevUser => ({
        ...prevUser,
        profile_picture: null
      }));
      setUpdateUserValues(prevValues => ({
        ...prevValues,
        profile_picture: null
      }));
      setUpdatingPicture(false);
    } catch (error) {
      if (error.response && error.response.status === 422) {
        const validationErrors = error.response.data.errors;
        Object.values(validationErrors).forEach(errMessages => {
          errMessages.forEach(msg => ToastError(msg));
        });
      } else {
        ToastError(error.response?.data?.message || "Oeps. Er is iets mis gegaan. Probeer het nog een keer");
      }
      console.error(error)
    }
  };

  useEffect(() => {
    updateUser();
  }, [updateUserValues.language, generatedPassword, generatedSecurityCode])

  useEffect(() => {
    if(changedPfp) {
      updateUser();
      setChangedPfp(false);
    }
  }, [changedPfp])

  return (
    <>
      {!updatingPicture &&
        <FsLightbox toggler={lightBoxOpened}
          sources={[
            <img src={updateUserValues.profile_picture}></img>
          ]}/>
      }
      <PasswordRequirementsModal show={modals.password} onHide={() => toggleModal("password")}/>
      <SecurityCodeModal show={modals.securityCode} onHide={() => toggleModal("securityCode")}/>
      <DeactivateUserModal show={modals.deactivateUser} onHide={() => toggleModal("deactivateUser")} updateUserValues={updateUserValues} setState={setUpdateUserValues} updateUser={updateUser}/>

      <PageTemplate navbarTitle={"Profiel"} pageIsLoading={loading} backValue={-1}>
        <form className="w-100" onSubmit={(e) => e.preventDefault()}>
          <div className="whiteBox px-5 py-4">
            <div className="d-flex col-12 border-bottom py-4">
              {changedPfp || updatingPicture ? (
                <div className="profilePictureLoadingBox">
                  <l-hourglass size="40" bg-opacity="0.1" speed="1" color="gray"></l-hourglass>
                </div>
              ) : (
                <div className="editProfilePicture">
                  <img src={updateUserValues.profile_picture || NoPhoto} onClick={() => setLightBoxOpened(!lightBoxOpened)} className="" alt="Profilepicture" />
                </div>
              )}
              <div className="d-flex flex-row gap-2">
                <div className="uploadImageBox">
                  <label htmlFor="imageUpload" className="btn btn-techdog">Nieuwe foto</label>
                  <input
                    type="file"
                    className="imageUpload"
                    name="imageUpload"
                    disabled={changedPfp ? true : false}
                    id="imageUpload"
                    ref={imageInputRef}
                    onChange={(event) => handleImageUpload(event)}
                  />
                </div>
                <div className="uploadImageBox">
                  <div
                    type="button"
                    className={`btn btn-danger ${updateUserValues.profile_picture ? "" : "disabled"}`}
                    disabled={updateUserValues.profile_picture ? false : true}
                    onClick={handleImageDelete}>
                      <FontAwesomeIcon icon={faTrashCan} />
                    </div>
                  <div></div>
                </div>
              </div>
            </div>
            <div className="d-flex col-12 border-bottom py-4">
              <h5 className="titlesEditUsers inputTitle col-3 ps-3">Volledige naam</h5>
              <input 
                className="inputFieldEditUser mx-2 col-4 ps-2" 
                type="text" 
                placeholder="Voornaam" 
                name="first_name"
                  onChange={(event) => changeUser("first_name", event)}
                  onBlur={updateUser}
                value={updateUserValues.first_name ?? ''}>
                </input>
              <input
                className="inputFieldEditUser mx-3 ms-4 col-4 ps-2"
                type="text"
                placeholder="Achternaam"
                name="last_name"
                  onChange={(event) => changeUser("last_name", event)}
                  onBlur={updateUser}
                value={updateUserValues.last_name ?? ''}>
              </input>
            </div>
            <div className="d-flex col-12 border-bottom py-4">
              <h5 className="titlesEditUsers inputTitle col-3 ps-3">E-mail</h5>
              <div className="col-9 d-flex flex-column">
                <div className="d-flex flex-row">
                  <input
                    className={`inputFieldEditUser ms-2 w-100 ps-2 ${emailError ? "error" : ""}`}
                    type="email"
                    name="email"
                    onChange={(event) => changeUser("email", event)}
                    onBlur={updateUser}
                    value={updateUserValues.email ?? ''}
                  ></input>
                  {/* <button disabled className="btn btnEditUser col-2 px-3" onClick={event => event.preventDefault()}>
                    Gebruik systeememail
                  </button> */}
                </div>
                {/* <small className="ms-4 text-secondary">
                  Het is niet mogelijk om uw wachtwoord te herstellen met
                  systeem-e-mail.
                </small> */}
              </div>
            </div>
            <div className="d-flex col-12 border-bottom py-4">
              <h5 className="titlesEditUsers inputTitle col-3 ps-3">
                Wijzig wachtwoord{" "}
                <FontAwesomeIcon className="iconInfoStyle" onClick={() => toggleModal("password")} icon={faCircleInfo} />
              </h5>
              <div className="col-9 d-flex">
                <input
                  className="inputFieldEditUser ps-2 ms-2 col-10"
                  type={showPassword ? 'text' : 'password'}
                  name="password"
                  onChange={(event) => changeUser("password", event)}
                  onBlur={updateUser}
                  value={updateUserValues.password ?? ''}
                  placeholder="Wijzig wachtwoord"
                  style={{
                    borderTopRightRadius: "0px",
                    borderBottomRightRadius: "0px",
                  }}
                ></input>
                <button className="btn btnPasswordShow" onClick={event => {
                  event.preventDefault();
                  setShowPassword(!showPassword);
                }}>
                  <img className="h-100" src={showPassword ? Eye : EyeClosed} />
                </button>
                <button className="btn btnEditUser" onClick={generateRandomPassword}>
                  <img className="h-100" src={Key} />
                </button>
              </div>
            </div>
            <div className="d-flex col-12 border-bottom py-4">
              <h5 className="titlesEditUsers inputTitle col-3 ps-3">
                Beveiligingspin instellen {" "}
                <FontAwesomeIcon className="iconInfoStyle" onClick={() => toggleModal("securityCode")} icon={faCircleInfo} />
              </h5>
              <div className="col-9 d-flex">
                <input
                  className="inputFieldEditUser ps-2 ms-2 col-10"
                  type={showSecurityCode ? 'text' : 'password'}
                  name="security_code"
                  value={updateUserValues.security_code ?? ''}
                  onChange={(event) => changeUser("security_code", event)}
                  onBlur={updateUser}
                  placeholder="Beveiligingspin instellen"
                  style={{
                    borderTopRightRadius: "0px",
                    borderBottomRightRadius: "0px",
                  }}
                ></input>
                <button className="btn btnPasswordShow" onClick={event => {
                  event.preventDefault();
                  setShowSecurityCode(!showSecurityCode);
                }}>
                  <img className="h-100" src={showSecurityCode ? Eye : EyeClosed} />
                </button>
                <button className="btn btnEditUser" onClick={generateRandomSecurityCode}>
                  <img className="h-100" src={Key} />
                </button>
              </div>
            </div>
            <div className="d-flex col-12 py-4">
              <h5 className="titlesEditUsers inputTitle mb-0 col-3 ps-3">Taal</h5>
              <div className="col-9 d-flex flex-column">
                <div className="d-flex flex-row">
                  <select
                    className="inputFieldEditUser ps-2 ms-2 col-11"
                    name="language"
                    value={updateUserValues.language}
                    onChange={(event) => {changeUser("language", event)}}
                  >
                    {languageOptions.map((language, index) =>
                      <option key={index} value={language.value}>{language.label}</option>
                    )}
                  </select>
                </div>
              </div>
            </div>
          </div>
        </form>
      </PageTemplate>
    </>
  );
};

export default EditProfile;