import React, { useEffect, useRef, useState } from 'react';
import PageTemplate from '../../Templates/PageTemplate';
import TextEditor from '../../Main/TextEditor';
import { useNavigate, useParams } from 'react-router';
import middleware from '../../Api/Middleware';
import Backbutton from "../BackIcon.svg";
import ToastError from '../../Toasts/ToastError';
import ToastSuccess from '../../Toasts/ToastSuccess';
import noimage from '../noimage.jpg'
import { faTrashCan } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
const CatEdit = () => {
  const navigate = useNavigate();
  const { primary_key } = useParams();
  const [category, setCategory] = useState({ title: '', description: ' ' });
  const [seo, setSeo] = useState({ title: '', image: '', keywords: '', description: '', slug: ' ' });
  const [oldCategory, setOldCategory] = useState({});
  const [oldSeo, setOldSeo] = useState({});
  const [availableCategories, setAvailableCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [changedImage, setChangedImage] = useState(false);
  const imageInputRef = useRef(null);

  const timeout = useRef();
  const fetchCategory = async () => {
    const [categoryRes, availableCategoriesRes, seoRes] = await Promise.all([
      middleware.get(`/products/category?category_id=${primary_key}`),
      middleware.get(`/products/category`),
      middleware.get(`/products/seos?category_id=${primary_key}`)
    ])
    setCategory(categoryRes.data);
    setOldCategory(categoryRes.data);

    setSeo(seoRes.data[0]);
    setOldSeo(seoRes.data[0]);
    setAvailableCategories(availableCategoriesRes.data.filter((e) => e.primary_key !== primary_key));
    setLoading(false);

  }

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    console.log(file);

    if (file) {
      const fileType = file.type;
      const validImageTypes = ["image/png", "image/jpg", "image/jpeg", "image/webp", "image/gif"];

      if (!validImageTypes.includes(fileType)) {
        ToastError("Ongeldig foto. Alleen PNG, JPG, JPEG, WEBP, en GIF zijn toegestaan.");
        return; // Exit if the file type is not valid
      }

      const reader = new FileReader();
      reader.onload = (e) => {
        setCategory({ ...category, image: e.target.result })
        setChangedImage(true);
        if (imageInputRef.current) {
          imageInputRef.current.value = null; // Clear the file input
        }
      };
      reader.readAsDataURL(file);
    }
  };

  const handleImageDelete = () => {
    setCategory({ ...category, image: null });
    setChangedImage(true);
  };
  useEffect(() => {
    if(category.title != oldCategory.title)
    setSeo({...seo, slug: '/' + (category.title || '').toLowerCase().replaceAll(' ', '-') + '/' })
    
  }, [category.title])
  const handleDelete = async () => {
    if (window.confirm('Weet je zeker dat je deze categorie wil verwijderen?')) {
      try {
        await middleware.delete(`/products/category?category_id=${primary_key}`);
        navigate('/warehouse/instellingen/categorieën');
      } catch (e) {
        ToastError('Er is een fout opgetreden tijdens het verwijderen.');
      }
    }
  }

  useEffect(() => {
    fetchCategory();
  }, [])
  useEffect(() => {
    if (!loading) {
      if (category !== oldCategory || seo !== oldSeo) {
        if (timeout.current) {
          clearTimeout(timeout.current);
        }
        timeout.current = setTimeout(async () => {
          try {
            let missing = [];
            let wrong = [];
            if (!category.title || category.title == '') {
              missing.push('Naam')
            }

            if (missing && missing.length) {
              if (missing.length > 1) {
                ToastError("Vul de volgende velden in: " + missing.join(', '));
              } else {
                ToastError("Vul het " + missing[0] + ' veld in.')
              }
              setCategory(oldCategory);
              return;
            }
            await middleware.put(`/products/category?category_id=${primary_key}`, category);
            if (oldSeo) {
              await middleware.put(`/products/seos?category_id=${primary_key}`, seo);
              setOldSeo(seo);
            } else {
              if (seo.description && seo.keywords && seo.slug && seo.title) {
                const res = await middleware.post(`/products/seos?category_id=${primary_key}`, seo);
                setOldSeo({ ...seo, seo_id: res.data.seo_id });
                setSeo({ ...seo, seo_id: res.data.seo_id });
              }
            }
            setOldCategory(category);
            ToastSuccess("Category opgeslagen.")
          } catch (e) {
            ToastError('Er is een fout opgetreden tijdens het opslaan.');
          }
        }, 1000);
      }
    }
  }, [category, seo])
  return (
    <PageTemplate pageIsLoading={loading} navbarTitle={"Categorie Bewerken"} backValue={"/warehouse/instellingen/categorieën"}>
      <div className='d-flex w-100 flex-column'>
        <div className='d-flex mb-4 mt-4 flex-row justify-content-between'>
          <div className='d-flex'>
            <div className="d-flex flex-row me-5 align-items-center">
              <img className="back-button-img" src={Backbutton} onClick={() => navigate(-1)} />
              <h5 className="p-0 m-0">Terug</h5>
            </div>
            {/* Published btn*/}
            <div className="d-flex flex-column align-items-center">
              <p className="mb-1 p-0">Gepubliceerd</p>
              <label className="switchActiveProduct">
                <input type="checkbox" checked={category?.status || false}
                  onChange={(e) => setCategory({ ...category, status: !category?.status })} />
                <span className="sliderActiveProduct round">
                </span>
              </label>
            </div>
          </div>
          <div>
            <button className="darkBlueStandardButton" onClick={handleDelete}>Verwijderen</button>
          </div>
        </div>
        <div className='whiteBox w-100 px-5 py-4 fitcontentHeight' style={{ minheight: '80vh' }}>
          <div className='d-flex flex-row justify-content-between pb-3 mb-3'>
            <h3 className='mb-0 fw-bold'>Categorie aanpassen</h3>
          </div>

          <div>
            <div className='d-flex flex-row w-100 mb-3'>
              <div className='d-flex flex-column w-50 pe-3'>
                <label className='mb-2 fw-semibold'>Naam</label>
                <input className='standardInput' onChange={(e) => setCategory({ ...category, title: e.target.value })} value={category.title || ''} />
              </div>
              <div className='d-flex flex-column w-50'>
                <label className='mb-2 fw-semibold'>Slug</label>
                <input className='standardInput' value={seo?.slug || ''} onChange={(e) => setSeo({ ...seo, slug: e.target.value })} />
              </div>
            </div>
            <div className='mb-3'>
              <label className='mb-2 fw-semibold'>Hoofdcategorie</label>
              <select value={category?.head_category_id || ''} onChange={(e) => setCategory({ ...category, head_category_id: e.target.value })} className='standardInput standardDropdownArrow w-100'>
                <option hidden>Selecteer een hoofdcategorie</option>
                {availableCategories && availableCategories.map((e) =>
                  <option key={e.category_id} value={e.category_id}>
                    {e.title}
                  </option>
                )}
              </select>
            </div>
            <div className='mb-3'>
              <label className='mb-2 fw-semibold'>Omschrijving</label>
              <TextEditor value={category?.description || ''} onChange={(e) => setCategory({ ...category, description: e })} />
            </div>
            <div className='mb-5'>
              <label className='mb-2 fw-semibold'>Afbeelding</label>

              <div className="d-flex flex-row gap-2">
                <div className="editCategoryImage">
                  <img src={category.image || noimage} alt="Categorie" />
                </div>
                <div className="uploadImageBox">
                  <label htmlFor="imageUpload" className="btn btn-techdog">Nieuwe foto</label>
                  <input
                    type="file"
                    className="imageUpload"
                    name="imageUpload"
                    id="imageUpload"
                    ref={imageInputRef}
                    onChange={(event) => handleImageUpload(event)}
                  />
                </div>
                <div className="uploadImageBox">
                  <div
                    type="button"
                    className={`btn btn-danger ${category?.image ? "" : "disabled"}`}
                    disabled={category.image ? false : true}
                    onClick={handleImageDelete}>
                    <FontAwesomeIcon icon={faTrashCan} />
                  </div>
                  <div></div>
                </div>
              </div>
            </div>
          </div>
          <div className='mt-4'>
            <h3 className=' fw-semibold mb-3'>SEO</h3>
            <div className='d-flex flex-column mb-3'>
              <label className='mb-2 fw-semibold'>SEO titel</label>
              <input className='standardInput' value={seo?.title || ''} onChange={(e) => setSeo({ ...seo, title: e.target.value })} />
            </div>
            <div className='d-flex flex-column mb-3'>
              <label className='mb-0 fw-semibold'>SEO zoekwoorden</label>
              <small style={{ color: "#c9c9c9" }} className='mb-2'>Vul een zoekwoord in en druk op enter om het zoekwoord toe te voegen.</small>
              <textarea className='standardInput p-2' style={{ height: "125px" }} value={seo?.keywords || ''} onChange={(e) => setSeo({ ...seo, keywords: e.target.value })} />
            </div>
            <div className='d-flex flex-column mb-4'>
              <label className='mb-2 fw-semibold'>SEO omschrijving</label>
              <textarea className='standardInput p-2' style={{ height: "125px" }} value={seo?.description || ''} onChange={(e) => setSeo({ ...seo, description: e.target.value })} />
            </div>
          </div>
        </div>
      </div>
    </PageTemplate>
  );
}

export default CatEdit;
