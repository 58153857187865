import React, { useState, useEffect, useCallback } from "react";
import { Modal, Form, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import "../ModalStyle.css";

function CreateNoteModal({createNoteModalVisible, setCreateNoteModalVisible}) {
    const navigate = useNavigate();

    return (
    <Modal
        id="modalNoteCreation"
        show={createNoteModalVisible}
        onHide={{}}
        className="colormodals"
        size="xl"
        centered
        backdrop="static"
    >
        <Modal.Header className="modalTop justify-content-between px-5" >
            <h2>Nieuwe notitie</h2>
            <button variant="transparent" className=' btn' onClick={() => {setCreateNoteModalVisible(false)}}>
                <FontAwesomeIcon icon={faXmark} className="closeModal" />
            </button>
        </Modal.Header>
        <Modal.Body className="modalCenter">
            <label className="standardLabel mb-1">Titel</label>
            <input className="standardInput mb-4" type="text"/>
            <label className="standardLabel mb-1">Bericht</label>
            <textarea className="standardInput" style={{minHeight: "150px"}}/>
        </Modal.Body>
        <Modal.Footer className="modalBottom">
            <div className="d-flex justify-content-end" >
                <div className="buttonPresetStyle standardBlueButton newButtons" style={{"width": "200px"}} onClick={() => {setCreateNoteModalVisible(false)}}>Opslaan</div>
            </div>
        </Modal.Footer>
    </Modal>
  )
}

export default CreateNoteModal