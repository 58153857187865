import React, { useMemo, useState, useEffect, Children, useRef } from 'react';
import Cookies from 'universal-cookie';
import { useTable } from "react-table";
import { useNavigate } from 'react-router';
import middleware from '../../../Api/Middleware';
import salesChannelsApi from '../../../Api/SalesChannels';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGear, faRotate, faBarcode } from '@fortawesome/free-solid-svg-icons';
import barcodeIcon from "../../../Main/Icons/BarcodeIcon.png";
import '../css/AddProductPages.css';
import '../../../Main/Main.css';
import "../../product.css";
import TablePreset from '../../../Table/TablePreset';
import ToastError from '../../../Toasts/ToastError';
import ToastSuccess from '../../../Toasts/ToastSuccess';
import NavbarIcon from "../../../Navbars/Sidebar/Icons/globe.png";
import ThirdBox from '../edit/edit_components/boxthird';
import PriceBox from "../edit/edit_components/boxPrice";
// Media
import '../css/UploadArea.css'
import CategoryList from "../sub_components/CategoryList";
import PageTemplate from '../../../Templates/PageTemplate';
import Backbutton from "../images/BackIcon.svg";
import TextEditor from '../../../Main/TextEditor';
import { id } from 'date-fns/locale';
import MediaUploaderComponent from '../../../MediaUpload/MediaUploaderComponent';
import Categories from '../sub_components/Categories';

// boxes
import FirstBox from "../edit/edit_components/boxfirst";
import FourthBox from "../edit/edit_components/boxfourth";
import { size } from 'lodash';
const AddVariation = () => {
  const navigate = useNavigate();
  const [selectedIds, setSelectedIds] = useState([]);
  const [pagination, setPagination] = useState({
    "page_size": 10,
    "page": 1,
    "max_items": 0,
    "begin_of_page": 0,
    "end_of_page": 0,
  });
  const [variationSwitch, setVariationSwitch] = useState(false);
  const [brands, setBrands] = useState([]);
  const [genders, setGenders] = useState([]);
  const [salesChannels, setSalesChannels] = useState([]);
  const [selectedSalesChannels, setSelectedSalesChannels] = useState([]);
  const [selectedGenders, setSelectedGenders] = useState([]);
  const [categories, setCategories] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedVariationIds, setSelectedVariationIds] = useState([]);
  const [loadingScreen, setLoadingScreen] = useState(true);
  const [buttonPressed, setbuttonPressed] = useState('');
  const [brandId, setBrandId] = useState("")
  const [description, setDescription] = useState("")
  const [productNumberValue, setProductNumberValue] = useState('');
  const [btw, setBtw] = useState('0');
  const [taxClass, setTaxClass] = useState(1);
  const [selectedTax, setSelectedTax] = useState("21");
  const [colorVariations, setColorVariations] = useState([]);
  const [images, setImages] = useState([]);
  const [variations, setVariations] = useState([]);
  const [allVariations, setAllVariations] = useState([]);
  const [allSizes, setAllSizes] = useState([]);
  const [allColors, setAllColors] = useState([]);
  const [stock, setStock] = useState({});
  const [status, setStatus] = useState(false);
  const [suppliers, setSuppliers] = useState([]);
  const [businesses, setBusinesses] = useState([]);
  const [attributeConnections, setAttributeConnections] = useState([]);
  const [addVariation, setAddVariation] = useState({
    size: '',
    color: '',
  });
  const [sizeVariations, setSizeVariations] = useState([]);
  const [values, setValues] = useState({
    product_name: '',
    product_number: '',
    type: 2,
  });
  const [initialCategories, setInitialCategories] = useState([
    { primary_key: 1, id: 1, headId: null, name: 'Categories' }
  ]);

  const [initialCategoriesNew, setInitialCategoriesNew] = useState([
    { primary_key: 1, id: 1, headId: null, name: 'Categories' }
  ]);

  // A helper function that recursively organizes the products based on their headId.
  const organizeCategory = (categories, parentId = null) => {
    // Ensure the input is an array
    return categories
      .filter(category => category.headId === parentId)
      .map(category => {
        return {
          ...category,
          subcategories: organizeCategory(categories, category.id)
        }
      });
  }
  const [searchQuery, setSearchQuery] = useState('');
  const [searchQueryNew, setSearchQueryNew] = useState('');
  const [checkedCategories, setCheckedCategories] = useState({});
  const [checkedCategory, setCheckedCategory] = useState({});
  const [checkedCategoryId, setCheckedCategoryId] = useState({});
  const [categoryTree, setCategoryTree] = useState(organizeCategory(initialCategories));
  const [categoryTreeNew, setCategoryTreeNew] = useState(organizeCategory(initialCategoriesNew));
  const [filteredCategory, setFilteredCategory] = useState(categoryTree);
  const [filteredCategoryNew, setFilteredCategoryNew] = useState(categoryTreeNew);
  const [tax, setTax] = useState('0');
  const [prices, setPrices] = useState([]);

  const childRef = useRef();

  const fetchAll = async () => {
    setIsLoading(true);
    const [fetchCategories, fetchBrands, fetchGenders, fetchSalesChannels, fetchSizes, fetchColors] = await Promise.all([
      middleware.get(`products/category`),
      middleware.get(`products/brands`),
      middleware.get(`products/genders`),
      salesChannelsApi.get('saleschannels?status=1'),
      middleware.get(`products/sizes`),
      middleware.get(`products/colors`),
    ]);
    const cat = fetchCategories.data.map(map => {
      return {
        primary_key: map.primary_key,
        id: map.category_id,
        headId: map.head_category_id,
        name: map.title,
      };
    });
    setInitialCategories(cat);
    setInitialCategoriesNew(cat);
    setCategories(fetchCategories.data);
    setBrands(fetchBrands.data);
    setGenders(fetchGenders.data);
    setSalesChannels(fetchSalesChannels.data);
    setAllSizes(fetchSizes.data);
    setAllColors(fetchColors.data);
    setLoadingScreenFunc();
  }
  const blobToBase64 = (blob) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onload = () =>
        resolve(reader.result.replace("data:", "").replace(/^.+,/, ""));
      reader.onerror = (error) => reject(error);
    });
    const validateImages = async() => {
      //Check if there are any images that are faulty (like too big or unsupported file type)
      let faultyImages = false;
      for (let index = 0; index < images.length; index++) {
        const CurrentImage = images[index];
        if (CurrentImage.error) {
          faultyImages = true;
        }
      }
      let everythingFilledIn = true;
        //Check if every file has a color assigned to it
      for (let index = 0; index < images.length; index++) {
        const CurrentImage = images[index];
        if (CurrentImage.colorId === undefined) {
          everythingFilledIn = false;
        }
      }
      if(images.find((e) => {
        return images.filter((j) => (e.colorId != null && (j.colorId === e.colorId)) || (e.sizeId != null && (j.sizeId === e.sizeId))).length > 3;
      })) {
        return "Je mag maar 3 images per variatie toevoegen."
      }
      //If there are any images that are faulty, a pop-up will appear and will stop the code from running any further
      if (faultyImages) {
        return "Verwijder de foto's die ongeldig zijn voordat je opslaat.";
      }
      // If there are any images that have no color assigned to them, a pop-up will appear and will stop the code from running any further
      else if (!everythingFilledIn) {
        return "Vul alle kleuren/maten in!";
      } // else {
      //   //This is so if the upload went succesful and you add a new image, it won't have a red ring around it.
      //   // setRequiredCheck(false);
      //   // if (setCheck != null) {
      //   //   setCheck(false);
      //   // }
      // }
      return true;
    }
  const saveImages = async (productID) => {
    try {
      let currentProductId = productID;
      const currentMonth = (new Date().getMonth() + 1)
        .toString()
        .padStart(2, "0");
      const currentYear = new Date().getFullYear();
      const folderPath = `warehouse/uploads/${currentYear}/${currentMonth}/${currentProductId}`;

      //The string with all the data that will be send to the API so it saves the image path, order and color
      let bulkDataString = "";
      let imagestrings = [];

      //Loop through the images and preparing them to upload
      for (let index = 0; index < images.length; index++) {
        // if(!images[index].changed) {
        //   continue;
        // }
        const CurrentImage = images[index];
        // This displays the image that is currently being uploaded
        // setUploadImgStatus(
        //   `${CurrentImage.name} (${CurrentImage.file.size.toString().length >= 6
        //     ? (CurrentImage.file.size / 1024 / 1000).toFixed(1) + " MB"
        //     : (CurrentImage.file.size / 1024).toFixed(1) + " kB"
        //   })`
        // );

        const base64String = await blobToBase64(CurrentImage.file);
        imagestrings.push(base64String);

        // Increase the upload count to notify the user the image has been uploaded
        // setUploadCount((prev) => prev + 1);

        //Add the data to the bulkdata that will eventually be sent to the middleware API
        bulkDataString += `${CurrentImage.name}|${
          CurrentImage.colorId
        }|${folderPath}/${CurrentImage.name.replace(/\s/g, "_")}|${
          index + 1
        }|${CurrentImage.sizeId}`;
        if (index < images.length - 1) {
          bulkDataString += ",";
        }
      }
      // Post request to the middleware api to save the image data to the database and delete the older information
      await middleware.post(
        `products/media/upload?product_id=${currentProductId}&bulk_data=${encodeURIComponent(
          bulkDataString
        )}`,
        { imagestrings: imagestrings }
      );
      
      return true;
    } catch (error) {
      // When something goes wrong the user gets logged out and send to the login page
      return (
        error.response?.data?.Message ??
        error.response?.data?.error ??
        false
      );
    }
  }
useEffect(() => {
  console.log(status)
}, [status])
  useEffect(() => {
    setFilteredCategory(categoryTree);
  }, [categoryTree]);

  useEffect(() => {
    setFilteredCategoryNew(categoryTreeNew);
  }, [categoryTreeNew]);

  useEffect(() => {
    filterCategory(searchQuery);
  }, [searchQuery]);

  useEffect(() => {
    filterCategoryNew(searchQueryNew);
  }, [searchQueryNew]);

  useEffect(() => {
    fetchAll();
  }, []);

  useEffect(() => {
    filterCategory();
  }, [initialCategories]);

  useEffect(() => {
    filterCategoryNew();
  }, [initialCategoriesNew]);

  const validate = (e) => {
    e.target.checkValidity();
    e.target.reportValidity();
  }

  // useEffect(() => {
  //   // Function defined inside useEffect to avoid dependencies on external functions
  //   const recalculatePrices = () => {
  //     setPrices((currentPrices) => {
  //       const updatedPrices = {};

  //       for (const [key, value] of Object.entries(currentPrices)) {
  //         const inclBTW = value.inclBTW;
  //         const exclBTW = inclBTW ? (inclBTW / (1 + btw / 100)).toFixed(3) : '';

  //         updatedPrices[key] = { ...value, exclBTW };
  //       }

  //       return updatedPrices;
  //     });
  //   };

  //   recalculatePrices();
  // }, [btw]);

  useEffect(() => {
    const colorsCache = localStorage.getItem('colorsAddVariationCache');
    const sizesCache = localStorage.getItem('sizesAddVariationCache');

    if (!colorsCache || !sizesCache) {
      navigate('/producten');
      return;
    }

    const colors = JSON.parse(colorsCache);
    const sizes = JSON.parse(sizesCache);

    const variations = [];
    if (colors.length === 0 && sizes.length > 0) {
      sizes.forEach(size => {
        variations.push({
          id: variations.length + 1,
          size_id: size.id,
          size_name: size.name,
          sizes: size != null ? [
            {
              size_id: size?.id ?? null,
              name: size?.name ?? null, 
            }
          ] : [],
          barcode: null,
          stock: null
        });
      });
    } else if (sizes.length === 0 && colors.length > 0) {
      colors.forEach(color => {
        variations.push({
          id: variations.length + 1,
          color_id: color.color_id,
          color_name: color.name,
          hex: color.hex,
          colors: color != null ? [
            {
              color_id: color?.color_id ?? null,
              name: color?.name ?? null,
              hex: color?.hex ?? null,
            }
          ] : [],
          barcode: null,
          stock: null
        });
      });
    } else {
      colors.forEach(color => {
        sizes.forEach(size => {
          variations.push({
            id: variations.length + 1,
            color_id: color.color_id,
            color_name: color.name,
            hex: color.hex,
            colors: color != null ? [
              {
                color_id: color?.color_id ?? null,
                name: color?.name ?? null,
                hex: color?.hex ?? null,
              }
            ] : [],
            size_id: size.id,
            size_name: size.name,
            sizes: size != null ? [
              {
                size_id: size?.id ?? null,
                name: size?.name ?? null, 
              }
            ] : [],
            barcode: null,
            stock: null
          });
        });
      });
    }

    setColorVariations(colors);
    setSizeVariations(sizes);
    setAllVariations(variations);
    generateVariations(variations);
  }, [navigate]);

  useEffect(() => {
    if (allVariations != null) {
      generateVariations();
    }
  }, [pagination.page_size, pagination.page, allVariations]);

  useEffect(() => {
    if (allVariations?.length) {
      const totalVariations = allVariations.length;
      const { page_size, page } = pagination;
      const maxPage = Math.ceil(totalVariations / page_size);
      const newPage = Math.max(1, page > maxPage ? maxPage : page);

      const begin_of_page = (page - 1) * page_size;
      const end_of_page = Math.min(begin_of_page + page_size, totalVariations);

      const paginatedVariations = allVariations.slice(begin_of_page, end_of_page);
      setPagination({
        page_size: page_size,
        max_items: totalVariations,
        page: newPage,
        begin_of_page: totalVariations === 0 ? 0 : begin_of_page + 1,
        end_of_page: end_of_page
      });
      setVariations(paginatedVariations);
    }
  }, [allVariations?.length, pagination.size, pagination.page])

  const generateVariations = (variations = null) => {
    variations = variations ? variations : allVariations;
    const totalVariations = variations.length;
    const { page_size, page } = pagination;
    const maxPage = Math.ceil(totalVariations / page_size);
    const newPage = Math.max(1, page > maxPage ? maxPage : page);

    const begin_of_page = (page - 1) * page_size;
    const end_of_page = Math.min(begin_of_page + page_size, totalVariations);

    const paginatedVariations = variations.slice(begin_of_page, end_of_page);

    setVariations(paginatedVariations);
  }

  const filterCategoryRecursively = (query, categories) => {
    const lowerCaseQuery = query.toLowerCase();

    return categories
      .map(category => {
        let categoryMatches = category.name.toLowerCase().includes(lowerCaseQuery);
        let filteredSubcategory = [];
        let includedDueToChild = false;

        if (category.subcategory) {
          filteredSubcategory = filterCategoryRecursively(query, category.subcategory);
          includedDueToChild = filteredSubcategory.length > 0;
        }

        if (categoryMatches || includedDueToChild) {
          return {
            ...category,
            subcategory: filteredSubcategory,
            isIncludedDueToChild: !categoryMatches && includedDueToChild
          };
        }

        return null;
      })
      .filter(Boolean);
  }

  const filterCategoryRecursivelyNew = (query, categories) => {
    const lowerCaseQuery = query.toLowerCase();

    return categories
      .map(category => {
        let categoryMatches = category.name.toLowerCase().includes(lowerCaseQuery);
        let filteredSubcategory = [];
        let includedDueToChild = false;

        if (category.subcategory) {
          filteredSubcategory = filterCategoryRecursivelyNew(query, category.subcategory);
          includedDueToChild = filteredSubcategory.length > 0;
        }

        if (categoryMatches || includedDueToChild) {
          return {
            ...category,
            subcategory: filteredSubcategory,
            isIncludedDueToChild: !categoryMatches && includedDueToChild
          };
        }

        return null;
      })
      .filter(Boolean);
  }

  // get only the number of the id and not the whole id
  const extractNumericId = (complexId) => {
    const regex = /(\d+)-(\d+)-(\d+)-(\d+)$/;
    const match = complexId.match(regex);
    console.log(match);
    if (!match) {
      return null;
    }
    return { primary_key: match[1], category_id: match[2] };
  }


  const Category = ({ category }) => {

    if (Object.keys(checkedCategories).includes(`1-${category.primary_key}-${category.id}-${category.headId ?? 0}`)) {
      return (
        <div style={{ marginLeft: category.headId ? '20px' : '0px' }}>
          <div className='d-flex flex-row align-items-center ms-2'>
            {category.headId != undefined && <div className='lineCategories me-2'></div>}
            <p className='mb-0 fw-medium'> {category.name}</p>
          </div>
          {category.subcategories && category.subcategories.length > 0 && (
            <div>
              {category.subcategories.map(sub => (
                <Category key={sub.id} category={sub} />
              ))}
            </div>
          )}
        </div>
      );
    }
  };

  const CategoryHierarchy = ({ data }) => {
    return data.map((e) => {
      return (
        <div key={e.id}>
          <Category category={e} />
        </div>
      );
    })
  };


  function findCategoryById(categories, targetId) {
    for (const category of categories) {
      if (category.id === targetId) {
        return category; // If the ID matches, return the category
      }

      if (category.subcategories && category.subcategories.length > 0) {
        const result = findCategoryById(category.subcategories, targetId); // Recursively search in subcategories
        if (result) {
          return result; // If the category is found in subcategories, return it
        }
      }
    }

    return null; // Return null if no matching category is found
  }
  function checkCategoryChildren(object, checkedC) {
    if (object.subcategories) {
      object.subcategories.forEach((e) => {
        delete checkedC[`1-${e.primary_key}-${e.id}-${e.headId ?? 0}`];
        checkedC = checkCategoryChildren(e, checkedC);
      })
    }
    return checkedC;
  }
  function recurisveToggle(object, checkedC, checked) {
    if (object.headId) {
      let element = initialCategories.find((e) => e.id == object.headId);
      if (checked) {
        checkedC[`1-${element.primary_key}-${element.id}-${element.headId ?? 0}`] = true;
      } else {
        delete checkedC[`1-${element.primary_key}-${element.id}-${element.headId ?? 0}`];
      }
      if (element.headId) {
        checkedC = recurisveToggle(element, checkedC, checked);
      }
    }
    return checkedC;
  }
  const toggleSimple = (id) => {
    setCheckedCategories(prev => {
      let parts = id.split('-');
      let v = initialCategories.find((e) => e.id == parts[2]);
      let newCheckedCategory = { ...prev }
      if (!newCheckedCategory[id] == false) {
        newCheckedCategory = checkCategoryChildren(findCategoryById(filteredCategory, v.id), newCheckedCategory);
        delete newCheckedCategory[id];
        return newCheckedCategory;
      }
      newCheckedCategory = recurisveToggle(v, newCheckedCategory, !prev[id])
      if (newCheckedCategory[id] == true) {
        delete newCheckedCategory[id];
      } else {
        newCheckedCategory[id] = true;
      }
      return newCheckedCategory;
    });
  }

  // A function to filter products.
  const filterCategory = (query) => {
    if (!query) {
      setCategoryTree(organizeCategory(initialCategories));
      return;
    }

    setCategoryTree(filterCategoryRecursively(query, initialCategories));
  }

  const filterCategoryNew = (query) => {
    if (!query) {
      setCategoryTreeNew(organizeCategory(initialCategories));
      return;
    }

    setCategoryTreeNew(filterCategoryRecursivelyNew(query, initialCategoriesNew));
  }

  const verwerkCategorieIds = (obj) => {
    const regex = /^(\d+)-(\d+)-(\d+)-(\d+)$/;
    const categoryIds = [];
    const HeadIds = [];

    Object.entries(obj)
      .filter(([key, value]) => value === true && regex.test(key))
      .forEach(([key]) => {
        const match = key.match(regex);
        if (match) {
          categoryIds.push(match[3]); // Voeg het derde deel toe aan match3
          HeadIds.push(match[4]); // Voeg het vierde deel toe aan match4
        }
      });

    return {
      categoryIds,
      HeadIds,
    };
  }

  const transformData = (data) => {
    let headCategoryMap = {};

    data.HeadIds.forEach(headId => {
      const headCategory = categories.find(cat => cat.category_id === parseInt(headId));
      if (headCategory) {
        headCategoryMap[headCategory.title] = data.categoryIds
          .map(catId => {
            const category = categories.find(cat => cat.category_id === parseInt(catId));
            return category && category.head_category_id === headCategory.category_id ? category.title : null;
          })
          .filter(title => title !== null);
      }
    });

    return headCategoryMap;
  }

  const transformedData = transformData(verwerkCategorieIds(checkedCategories));

  const handleChange = event => {
    setValues({ ...values, [event.target.name]: event.target.value });
  }

  const handleGendersChange = event => {
    const valueAsInt = parseInt(event.target.value, 10); // Zet de waarde om naar een integer

    if (event.target.checked) {
      setSelectedGenders([...selectedGenders, valueAsInt]); // Voeg de integer waarde toe
    } else {
      setSelectedGenders(selectedGenders.filter(e => e !== valueAsInt)); // Verwijder de integer waarde
    }
  }

  const handleSalesChannelsChange = event => {
    const valueAsInt = parseInt(event.target.value, 10); // Zet de waarde om naar een integer

    if (event.target.checked) {
      setSelectedSalesChannels([...selectedSalesChannels, valueAsInt]); // Voeg de integer waarde toe
    } else {
      setSelectedSalesChannels(selectedSalesChannels.filter(e => e !== valueAsInt)); // Verwijder de integer waarde
    }
  }

  function setLoadingScreenFunc() {
    setIsLoading(false);
    setTimeout(() => {
      setLoadingScreen(false);
    }, 500);
  }

  const handleProductSelection = (productId, isSelected) => {
    if (isSelected) {
      setSelectedVariationIds([...selectedVariationIds, productId]);
    } else {
      setSelectedVariationIds(selectedVariationIds.filter(id => id !== productId));
    }
  }

  const handleSelection = (id, isSelected) => {
    if (isSelected) {
      setSelectedIds(previous => [...previous, id]);
    } else {
      setSelectedIds(previous => previous.filter(id => id !== id));
    }
  }

  const generateBarcode = async (event, id) => {
    if (event != null) {
      event.preventDefault();
    }
    const response = await middleware.get('products/generateBarcode');
    const newBarcode = response.data.generatedBarcode;

    handleBarcodeChange(newBarcode, id)

    if (allVariations?.length) {
      const totalVariations = allVariations.length;
      const { page_size, page } = pagination;
      const maxPage = Math.ceil(totalVariations / page_size);
      const newPage = Math.max(1, page > maxPage ? maxPage : page);

      const begin_of_page = (page - 1) * page_size;
      const end_of_page = Math.min(begin_of_page + page_size, totalVariations);

      const paginatedVariations = allVariations.slice(begin_of_page, end_of_page);
      setPagination({
        page_size: page_size,
        max_items: totalVariations,
        page: newPage,
        begin_of_page: totalVariations === 0 ? 0 : begin_of_page + 1,
        end_of_page: end_of_page
      });
      setVariations(paginatedVariations);
    }
  }

  const generateMultipleBarcodes = async (amount) => {
    const response = await middleware.get(`products/generateMultipleBarcodes?barcodeAmount=${amount}`);
    const newBarcodes = response.data.generatedBarcode;
    return newBarcodes;
  }

  const handleBarcodeChange = (newBarcode, id) => {
    setAllVariations(prevVariations =>
      prevVariations.map(variation =>
        variation.id === id
          ? { ...variation, barcode: newBarcode }
          : variation
      )
    );
    // Rerender the variations
    if (allVariations?.length) {
      const totalVariations = allVariations.length;
      const { page_size, page } = pagination;
      const maxPage = Math.ceil(totalVariations / page_size);
      const newPage = Math.max(1, page > maxPage ? maxPage : page);

      const begin_of_page = (page - 1) * page_size;
      const end_of_page = Math.min(begin_of_page + page_size, totalVariations);

      const paginatedVariations = allVariations.slice(begin_of_page, end_of_page);
      setPagination({
        page_size: page_size,
        max_items: totalVariations,
        page: newPage,
        begin_of_page: totalVariations === 0 ? 0 : begin_of_page + 1,
        end_of_page: end_of_page
      });
      setVariations(paginatedVariations);
    }
  }

  const handleStockChange = (newStock, id) => {
    setAllVariations(prevVariations =>
      prevVariations.map(variation =>
        variation.id === id
          ? { ...variation, stock: newStock }
          : variation
      )
    );
  }

  const columns = useMemo(() => {
    const cols = [
      {
        Header: (
          <>
            <label className="checkbox-container">
              <input
                type="checkbox"
                onChange={e => {
                  const checked = e.target.checked;
                  setSelectedVariationIds(checked ? variations.map(variation => variation.id) : []);
                }}
                checked={selectedVariationIds.length > 0}
              />
              <span className="checkmark"></span>
            </label>
          </>
        ),
        accessor: "select",
        Cell: ({ row }) => {
          const isSelected = selectedVariationIds.includes(row.original.id);
          return (
            <label className="checkbox-container">
              <input
                type="checkbox"
                checked={isSelected}
                onChange={e => handleProductSelection(row.original.id, e.target.checked)}
              />
              <span className="checkmark"></span>
            </label>
          );
        }
      },
      {
        Header: "Voorraad",
        accessor: "stock",
        Cell: ({ row }) => {
          const stock = allVariations.find(variation => variation.id === row.original.id)?.stock || '';
          return (
            <div className='d-flex justify-content-center' style={{width: "60px"}}>
              <input
                onBlur={(event) => handleStockChange(event.target.value, row.original.id)}
                defaultValue={stock}
                type="number"
                className="stockBox webkitRemove"
                placeholder='0'
                style={{width: "60px"}}
              />
              {/* <input type="number" className='stockBox w-25 webkitRemove' placeholder='0'/> */}
            </div>
          );
        },
      },
      {
        Header: "Barcode",
        accessor: "barcode",
        Cell: ({ row }) => {
          const barcode = allVariations.find(variation => variation.id === row.original.id)?.barcode || '';
          return (
            <div className='d-flex flex-row gap-2'>
              <div>
                <input
                  onBlur={(event) => handleBarcodeChange(event.target.value, row.original.id)}
                  defaultValue={barcode}
                  type="number"
                  className='barcodeBox webkitRemove'
                />
              </div>
              {/* barcode icon btn wich generated a barcode for the specific field */}
              <div className="d-flex justify-content-center align-items-center generateBarcodeBtn" onClick={(e) => generateBarcode(e, row.original.id)}>
              <FontAwesomeIcon icon={faBarcode} />
              </div> 
            </div>
          );
        },
      },
      // Dit is een extra optie die later toegevoegd moet worden.
      {
        Header: "Variabele prijs",
        accessor: "incl_tax",
        Cell: ({row}) => {
          return (
            <div className='d-flex justify-content-center gap-1'>
              {/* Plus and minus boxes */}
              <div className="d-flex justify-content-center flex-row">
              <div className={true ? "VariationPriceBtnPlusSelected" : "VariationPriceBtnPlus"}>+</div>
              <div className={false ? "VariationPriceBtnMinusSelected" : "VariationPriceBtnMinus"}>-</div>
              </div>
              <input type="number" placeholder={`€ 10,00`} className='stockBox variablePriceBox px-2'/>
              <span className="align-self-center">= € 10,00</span>
            </div>
          )
        }
      },
    ];

    // Conditionally add the color_name column if colors are selected
    if (colorVariations.length > 0) {
      cols.splice(1, 0, {
        Header: (
          <select className='smallStandardInput fw-bold standardDropdownArrow w-100'>
            <option hidden>Filter kleur</option>
          </select>
        ),
        accessor: "color_name",
        Cell: ({ row }) => {
          return (
            row.original.color_name ? (
              <span className='d-flex flex-row'>
                <div className=' rounded-circle me-2 mt-1 colorCircle' style={{ backgroundColor: row.original.hex, width: "15px", height: "15px", }}></div>
                <div className='text-nowrap colorNameVariationTable'>{row.original.color_name}</div>
              </span>
            ) : ''
          );
        },
      });
    }

    // Conditionally add the size_name column if sizes are selected
    if (sizeVariations.length > 0) {
      cols.splice(colorVariations.length > 0 ? 2 : 1, 0, {
        Header: (
          <select className='smallStandardInput fw-bold standardDropdownArrow w-100'>
            <option hidden>Filter maat</option>
          </select>
        ),
        accessor: "size_name",
        Cell: ({ row }) => {
          return (
            <span className='d-flex justify-content-center'>
              {row.original.size_name}
            </span>
          );
        },
      });
    }

    return cols;
  }, [selectedVariationIds, pagination]);

  //   Table instance
  const tableInstance = useTable({ columns, data: variations });

  // Destructure table properties

  const submitNew = async (event) => {
    event.preventDefault(); // Voorkom de standaardactie van het formulier
    let newProductId = null;
    const newprices = prices.reduce((acc, curr) => {
      acc[curr.type] = {
        price_id: curr.price_id,
        incl_tax: typeof curr.incl_tax === 'string' ? Number(curr.incl_tax.replace(",", ".")).toFixed(2) : Number(curr.incl_tax).toFixed(2),
        excl_tax: typeof curr.excl_tax === 'string' ? Number(curr.excl_tax.replace(",", ".")).toFixed(2) : Number(curr.excl_tax).toFixed(2),
      };
      return acc;
    }, {});
    try {
      // Veronderstel dat we eerst controleren of het productnummer uniek is
      const existingProduct = (await middleware.get(`products?product_number=${encodeURIComponent(event.target.product_number.value)}`)).data;
      if (existingProduct.length > 0) {
        ToastError("Productnummer bestaat al.");
        return;
      }
      let newvariations = allVariations.map((variation) => {
        return {
          ...variation.barcode && { barcode: variation.barcode },
          ...variation.color_id && { color: variation.color_id },
          ...variation.stock && { stock: variation.stock },
          ...variation.size_id && { size: variation.size_id }
        }
      })
      const validate = await validateImages();
      if(validate !== true) {
        ToastError(validate);
        return false;
      }
      // Product aanmaken
      const productResponse = await middleware.post("products/create/variation", {
        product: {
          name: event.target.product_name?.value ?? values.product_name,
          number: event.target.product_number?.value ?? values.product_number,
          description: description,
          tax_id: taxClass,
          status: status,
          variation_type: variationSwitch,
        },
        prices: newprices,
        stock: stock,
        variations: newvariations,
        category_ids: Object.keys(checkedCategories).map((e) => e.split('-')[2]),
        gender_ids: selectedGenders,
        brand_ids: brandId ?? null,
        sales_channel_ids: selectedSalesChannels
      });
      newProductId = productResponse.data.headProduct.product_id;
      await saveImages(newProductId);

      ToastSuccess("Product is aangemaakt");
      if (buttonPressed == 1) {
        window.location.reload();
      } else if (buttonPressed == 2) {
        navigate("/producten");
      }
    } catch (err) {
      console.error(err);
      ToastError("Er is een fout opgetreden bij het aanmaken van het product.");
    }
  }
  // useEffect(() => {
  //   // Function defined inside useEffect to avoid dependencies on external functions
  //   const recalculatePrices = () => {
  //     setPrices((currentPrices) => {
  //       const updatedPrices = {};

  //       for (const [key, value] of Object.entries(currentPrices)) {
  //         const inclTax = value.incl_tax;
  //         const exclTax = inclTax ? (inclTax / (1 + tax / 100)).toFixed(3) : '';

  //         updatedPrices[key] = { ...value, excl_tax: exclTax };
  //       }

  //       return updatedPrices;
  //     });
  //   };

  //   recalculatePrices();
  // }, [tax]);

  const handlePriceChange = (event) => {
    const priceType = event.target.name;
    const inclTax = event.target.value;
    const exclTax = inclTax ? (inclTax / (1 + tax / 100)).toFixed(3) : '';

    setPrices(prevPrices => ({
      ...prevPrices,
      [priceType]: { incl_tax: inclTax, excl_tax: exclTax },
    }));
  }
  const handleTaxChange = (event) => {
    setTax(event.target.value)

    if (event.target.value == 9) {
      setTaxClass(2)
    }
    else if (event.target.value == 21) {
      setTaxClass(1)
    }
    else {
      setTaxClass(5)
    }
  }
  const handleVariationSwitch = (event) => {
    setVariationSwitch(event.target.checked);
  }

  const checkBarcodeFormat = (barcode) => {
    if (barcode == null || barcode == "") {
      return true;
    }
    // check correct barcode length
    if (barcode.length == 13) {
      // check if prefix is between 021 - 029
      let isCorrectPrefix = true;
      const barcodePrefix = parseInt(barcode.toString().slice(0, 3), 10);
      isCorrectPrefix = barcodePrefix >= 21 && barcodePrefix <= 29;
      if (isCorrectPrefix) {
        // Check if checksum is correct
        const digits = barcode.toString().split('').map(Number);

        const sum = digits
          .slice(0, 12) // Get the first 12 digits
          .reduce((acc, digit, index) => {
            return acc + digit * (index % 2 === 0 ? 1 : 3);
          }, 0);

        const checksum = (10 - (sum % 10)) % 10;
        if (checksum === digits[12]) {
          return true;
        }
        else {
          ToastError("Het laatse berekende veld(checksum) van barcode: (" + barcode + ") is niet correct ingevuld");
        }
      }
      else {
        ToastError("Eerste 3 getallen van de barcode: (" + barcode + ") voldoen niet aan het EAN-13 formaat (tussen 021 en 029)");
      }
    } else {
      ToastError("Barcode: (" + barcode + ") voldoet niet aan het EAN-13 formaat");
    }
    return false;
  }

  const generate_barcodes = async () => {
    let count = 0;
    const generated_barcodes = await generateMultipleBarcodes(selectedVariationIds.length);
    // generate barcodes on selected fields without a barcode
    selectedVariationIds.forEach(selectedId => {
      let vari = allVariations.find((variation) => variation.id == selectedId);
      if (vari.barcode == null || vari.barcode == "") {
        handleBarcodeChange(generated_barcodes[count], vari.id);
      }
      count++;
    });
  }

  const delete_barcodes = () => {
    // delete selected barcodes
    selectedVariationIds.forEach(selectedId => {
      let vari = allVariations.find((variation) => variation.id == selectedId);
      if (vari.barcode != null || vari.barcode != "") {
        setAllVariations((prevVariations) => prevVariations.map((variation) => variation.id === vari.id ? {...variation, barcode: null } : variation));
        // way too much code just for a rerender :(
        if (allVariations?.length) {
          const totalVariations = allVariations.length;
          const { page_size, page } = pagination;
          const maxPage = Math.ceil(totalVariations / page_size);
          const newPage = Math.max(1, page > maxPage ? maxPage : page);
    
          const begin_of_page = (page - 1) * page_size;
          const end_of_page = Math.min(begin_of_page + page_size, totalVariations);
          setPagination({
            page_size: page_size,
            max_items: totalVariations,
            page: newPage,
            begin_of_page: totalVariations === 0 ? 0 : begin_of_page + 1,
            end_of_page: end_of_page
          });
        }
      }
    });
  }

  // a function that unselects all the selects in the table
  const unselect_all = () => {
    setSelectedVariationIds([]);
  }

  const delete_variations = () => {
    setAllVariations(previous => previous.filter(variation => !selectedVariationIds.includes(variation.id)));
    setSelectedVariationIds([]);
  }

  const handleAction = async (event) => {
    if (event === "generate-barcodes") {
      generate_barcodes();
    } else if (event === "delete-barcodes") {
      delete_barcodes();
    } else if (event === "delete") {
      delete_variations();
    }
  }

  const handleNewVariation = () => {
    const size = allSizes.filter(size => size.primary_key === Number(addVariation.size))[0];
    const color = allColors.filter(color => color.primary_key === Number(addVariation.color))[0];

    if (!size && !color) {
      ToastError('Je moet een maat of kleur selecteren');
      return;
    }
    if (allVariations.find((e) => e.color_id == color?.color_id && e.size_id == size?.size_id)) {
      ToastError("Die variatie bestaat al");
      return;
    }

    const newVariation = {
      id: allVariations.length + 1,
      size_id: size?.size_id ?? null,
      size_name: size?.name ?? null,
      color_id: color?.color_id ?? null,
      color_name: color?.name ?? null,
      hex: color?.hex ?? null,
      colors: color != null ? [
        {
          color_id: color?.color_id ?? null,
          name: color?.name ?? null,
          hex: color?.hex ?? null,
        }
      ] : [],
      sizes: size != null ? [
        {
          size_id: size?.size_id ?? null,
          name: size?.name ?? null, 
        }
      ] : [],
    };
    const sizeVariation = {
      id: size?.size_id ?? null,
      name: size?.name ?? null
    }
    const colorVariation = {
      id: color?.color_id ?? null,
      name: color?.name ?? null
    }
    if (sizeVariation.id) {
      setSizeVariations([sizeVariation, ...sizeVariations])
    }
    if (colorVariation.id) {
      setColorVariations([colorVariation, ...colorVariations])
    }
    setAllVariations([newVariation, ...allVariations]);
  }
  return (
    <PageTemplate navbarTitle={"Warehouse HUB"} pageIsLoading={isLoading} iconNavbar={NavbarIcon}>
      <form onSubmit={submitNew} method="post" className='d-flex justify-content-center minFitContent w-100'>
        <div className="mb-3 d-flex flex-column formItems  minFitContent w-100">
          <div className="d-flex justify-content-between mb-4 w-100 flex-wrap-reverse">
          <div className="d-flex gap-5">
              <div className="d-flex flex-row gap-2 align-items-center">
                <img className="back-button-img" src={Backbutton} onClick={() => navigate("/producten")} />
                <h5 className="p-0 m-0">Terug</h5>
              </div>
              {/* Published btn*/}
              <div className="d-flex flex-column align-items-center">
              {!status ? <p className="mb-1 p-0">Gepubliceerd</p> : <p className="mb-1 p-0">Gearchiveerd</p>}
                <label class="switchActiveProduct">
                  <input type="checkbox" checked={!status}
                   onChange={(e) => setStatus(!e.target.checked)}/>
                  <span class="sliderActiveProduct round">
                  </span>
                </label>
              </div>
            </div>
            <div className=' fitContent text-nowrap'>
              <button
                type="submit"
                id='1'
                className="btn darkBlueStandardButton Nunito me-3 fitContent px-4"
                onClick={(e) => setbuttonPressed(e.target.id)}
              >
                Aanmaken en nieuwe toevoegen
              </button>
              <button
                type="submit"
                id='2'
                className="btn lightBlueStandardButton Nunito"
                onClick={(e) => setbuttonPressed(e.target.id)}
              >
                Aanmaken
              </button>
            </div>
          </div>
          <div className='d-flex flex-column flex-xxl-row justify-content-between'>
            <div className='col-xxl-8'>
              <div>
                <div className='d-flex flex-column fitcontentHeight mb-4' >
                <FirstBox
                handleChange={handleChange}
                product={values}
                setProduct={setValues}
                brands={brands}
                validate={validate}
                setBrandId={setBrandId}
                edit={false}
              />
                  {/* <div className="d-flex flex-column whiteBox p-4 mb-4 minFitContent">
                    <div className="d-flex flex-row parentBoxSellChannelProduct">
                      <div className='w-100'>
                        <div className="d-flex flex-column mb-3">
                          <label className="inputTitle mt-3 mb-2">
                            Productnaam *
                          </label>
                          <input
                            className="inputField col-12 standardInput"
                            type="text"
                            name="product_name"
                            value={values.product_name ?? ''}
                            onChange={handleChange}
                            required
                          ></input>
                        </div>
                        <div className="d-flex flex-row productNumSelectLists col-12 justify-content-between">
                          <div className="d-flex flex-column" style={{ width: "49%" }}>
                            <div className='d-flex flex-nowrap'>
                              <label className="inputTitle me-2">
                                Productnummer
                              </label>
                            </div>
                            <input
                              type="text"
                              className="inputField px-2 standardInput webkitRemove"
                              name="product_number"
                              value={values.product_number}
                              onChange={handleChange}
                            />
                          </div>

                          <div className="d-flex flex-column" style={{ width: "49%" }}>
                            <label className="inputTitle">
                              Merk
                            </label>
                            <select
                              className="standardInput mb-2 px-2 standardDropdownArrow"
                              onChange={(e) => setBrandId(e.target.value)}
                            >
                              <option hidden></option>
                              {brands.map((brand, index) => {
                                return <option value={brand.brand_id} key={index}>{brand.name}</option>;
                              })}
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className=' mb-2'>
                      <TextEditor onChange={(e) => setDescription(e)} />
                    </div>
                  </div> */}
                </div>
              </div>
              <div className='whiteBox p-4 formVariationProduct mb-4'>
                <h5 className='fw-semibold'>Huidige variatie</h5>
                <div className="widthColorTable">
                  {!isLoading ? (
                    <TablePreset
                      data={[{ 'selectedIds': selectedIds, 'tableInstance': tableInstance, 'pagination': pagination }]}
                      tableLoading={isLoading}
                      setPagination={(e) => setPagination(e)}
                      handleSelection={handleSelection}
                      actionOptions={[
                        { title: "Genereer barcodes", function: () => handleAction('generate-barcodes') },
                        { title: "Verwijder barcodes", function: () => handleAction('delete-barcodes') },
                        { title: "Verwijder", function: () => handleAction('delete') }
                      ]}
                      rightDropdowns={[
                        {
                          title: 'Selecteer een maat',
                          primary_key: 'primary_key',
                          options: allSizes,
                          function: (event) => setAddVariation({ ...addVariation, size: event.target.value }),
                          value: addVariation.size
                        },
                        {
                          title: 'Selecteer een kleur',
                          primary_key: 'primary_key',
                          options: allColors,
                          function: (event) => setAddVariation({ ...addVariation, color: event.target.value }),
                          value: addVariation.color
                        }
                      ]}
                      rightButtons={[
                        { title: 'Handmatig toevoegen', function: handleNewVariation }
                      ]}
                    />
                  ) : (
                    <div className="table-container table-responsive">
                      <table>
                        <thead>
                          <tr>
                            <td>
                              <div>Loading</div>
                            </td>
                          </tr>
                        </thead>
                      </table>
                    </div>
                  )}
                </div>
              </div>
              {allVariations &&
              <MediaUploaderComponent variationSwitch={variationSwitch}
              setVariationSwitch={setVariationSwitch}
              setImages={setImages}
              images={images}
              useColors={true}
              availableVariations={allVariations}>
              </MediaUploaderComponent> }
            </div>
            <div className="d-flex flex-row flex-xxl-column col-xxl-4 row mt-4 mt-xxl-0">
              {/* category */}
              <div className="col-6 col-xxl-12 mb-4">
                <div className="whiteBox h-100">
              {categories.length &&
                <Categories
                  initialCategories={initialCategories}
                  setCheckedCategories={setCheckedCategories}
                  checkedCategories={checkedCategories}
                />}
                </div>
                </div>

              {/* gender */}
              <div className="col-6 col-xxl-12 mb-4">
                <div className="whiteBox p-5 pb-3 pt-4 h-100">
                <h5 className="inputTitle mb-4">Geslacht</h5>
                <div>
                  {genders.map((gender, index) => (
                    <div key={index} className="d-flex flex-row mb-3">
                      <label className="checkbox-containerSimple">
                        <input
                          type="checkbox"
                          value={gender.gender_id}
                          onChange={handleGendersChange}
                        />
                        <span className="checkmarkSimple"></span>
                      </label>
                      <h5 className="inputTitle fw-medium">{gender.name}</h5>
                    </div>
                  ))}
                </div>
                </div>
              </div>
              {/* prices component */}
              <div className="col-6 col-xxl-12 pb-4">
                <PriceBox
                  selectedTax={selectedTax}
                  setSelectedTax={setSelectedTax}
                  setTax={setTax}
                  taxClass={taxClass}
                  setTaxClass={setTaxClass}
                  handleChange={handleChange}
                  prices={prices}
                  setPrices={setPrices}
                  validate={validate}
                  edit={false}
                />
              </div>
              {/* sales channels */}
              <div className="mb-4 col-6 col-xxl-12">
                <div className="whiteBox p-4 h-100">
                <div className="d-flex flex-column h-100">
                  <h5 className="inputTitle mb-4">Verkoopkanaal</h5>
                  <div className='px-2 d-flex h-100 flex-column justify-content-around'>
                    {salesChannels.length > 0 ? (
                      salesChannels.map((salesChannel, index) => (
                        <div key={index} className="d-flex flex-row mb-3 me-3 slidersSellchannel justify-content-between">
                          <h6 className="switchText">{salesChannel.sales_channel_name}</h6>
                          <label className="defaultSwitch ms-4">
                            <input type="checkbox" value={salesChannel.sales_channel_id} onChange={handleSalesChannelsChange} />
                            <span className="defaultSlider round"></span>
                          </label>
                        </div>
                      ))
                    ) : (
                      <p>Er zijn nog geen verkoopkanalen gemaakt.</p>
                    )}
                  </div>
                </div>
                </div>
              </div>
              {/* Suppliers */}
              <div className="pb-4 col-6 col-xxl-12">
                <FourthBox
                  suppliers={suppliers}
                  setSuppliers={setSuppliers}
                  businesses={businesses}
                  setBusinesses={setBusinesses}
                  validate={validate}
                  attributeConnections={attributeConnections}
                  setAttributeConnections={setAttributeConnections}
                  edit={false}
                />
              </div>
            </div>
          </div>
        </div>
      </form>
    </PageTemplate>
  );
}

export default AddVariation