import React from "react";
import TextEditor from "../../../../Main/TextEditor";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlus,
  faPenToSquare,
  faCaretLeft,
  faGear,
} from "@fortawesome/free-solid-svg-icons";

const FirstBox = (props) => {
  return (
    <>
      {/* First Box */}
      <div className="whiteBox p-5 mt-0 align-center">
        <div className="d-flex flex-column mb-3">
          <label className="inputTitle standardLabel fw-medium">Productnaam</label>
          <input
            className="inputField col-12 standardInput"
            value={props?.product?.product_name ?? ""}
            onBlur={props.handleChange}
            onChange={(e) =>
              props.setProduct({
                ...props.product,
                product_name: e.target.value,
              })
            }
          />
        </div>
        <div className="d-flex flex-row productNumSelectLists col-12 justify-content-between">
          <div className="d-flex flex-column mb-3" style={props.product.type != 2 ? ({ width: "32.5%" }) : ({ width: "48%" })}>
            <div className="d-flex flex-nowrap w-100">
              <label className="inputTitle standardLabel fw-medium">Productnummer</label>
            </div>
            <input
              type="text"
              className="inputField standardInput webkitRemoves"
              name="product_number"
              value={props?.product?.product_number ?? ""}
              onBlur={(e) => { props.handleChange(e); props.validate(e) }}
              onChange={(e) =>
                props.setProduct({
                  ...props.product,
                  product_number: e.target.value,
                })
              }
            // disabled={!edit}
            />
          </div>
          {props.product.type != 2 ? (
            <div className="d-flex flex-column" style={{ width: "32.5%" }}>
              <div className="d-flex flex-row justify-content-between">
                <label className="inputTitle standardLabel fw-medium">Barcode</label>
                <select
                  className="optionsList productOptions me-2"
                  id="sel1"
                  name="sellist1"
                  value={props?.selectedOption ?? ""}
                  onChange={(e) => {
                    props.setSelectedOption(e.target.value);
                  }}
                >
                  <option value="3" hidden>-- Selecteer een optie --</option>
                  <option value="0">Aangepaste barcode</option>
                  <option value="2">Automatisch gegenereerd</option>
                </select>
              </div>
              <div className="d-flex flex-column mt-0 marginRight0">
                {props.selectedOption === "0" && (
                  <input
                    type="number"
                    className="mb-4 inputField standardInput webkitRemove"
                    placeholder="Barcode (ISBN, UPS, GTIN, etc.)"
                    value={props.edit ? props?.adjustedBarcode : props?.barcode ?? ""} //null
                    onChange={(e) => {
                      props.setBarcode(e.target.value);
                      props.edit && props?.setUpdatedBarcode(true);
                      props.handleChange(e);
                    }}
                  />
                )}
                {props.selectedOption === "2" && (
                  <input
                    type="number"
                    className="mb-4 inputField standardInput webkitRemove"
                    placeholder="Barcode (ISBN, UPS, GTIN, etc.)"
                    value={props.edit ? props?.generatedBarcode ?? "" : props.barcode}
                    onChange={(e) => {
                      props.edit ? props.setBarcode(e.target.value) : props.handleBarcodeChange();
                      props.setGeneratedBarcode(e.target.value);
                      props.edit && props?.setUpdatedBarcode(true);
                      props.handleChange(e);
                    }}
                  />
                )}
                {props.selectedOption === "3" && (
                  <input
                    type="number"
                    className="mb-4 inputField standardInput webkitRemove"
                    placeholder="Barcode (ISBN, UPS, GTIN, etc.)"
                    value={props?.barcode ?? ""}
                    onChange={(e) => {
                      props.setBarcode(e.target.value);
                      props.edit && props?.setUpdatedBarcode(true);
                      props.handleChange(e);
                    }}
                  />
                )}
              </div>
            </div>
          ) : (
            <></>
          )}
          <div className="d-flex flex-column" style={props.product.type != 2 ? ({ width: "32.5%" }) : ({ width: "48%" })}>
            <div className="d-flex flex-column">
              <label className="inputTitle standardLabel fw-medium">
                Merk
                {/* Add brand btn */}
                {/* <props.FontAwesomeIcon
                  icon={props.faPlus}
                  color="#6a6a6a"
                  className="hover"
                  onClick={() => props.setShowBrandModal(true)}
                  data-bs-toggle="exampleModal"
                  data-bs-target="#exampleModal"
                /> */}
              </label>
              {props.edit ? (
                // Edit brand
                <select
                  className="standardInput standardDropdownArrow"
                  id="sel1"
                  name="sellist1"
                  value={props.brand?.brand_id ?? ""}
                  onChange={(e) => {
                    props.setProductBrand({
                      ...props.brand,
                      brand_id: e.target.value,
                    });
                    props.edit && props.setUpdatedProduct(true);
                  }
                  }
                >
                  <option hidden></option>
                  {props.brands.map((brands) => {
                    return (
                      <option key={brands.brand_id} value={brands.brand_id}>
                        {brands.name}
                      </option>
                    );
                  })}
                </select>
              ) : (
                // Add pages brand
                <select
                  className="standardInput standardDropdownArrow"
                  onChange={(e) => props.setBrandId(e.target.value)}
                >
                  <option hidden></option>
                  {props.brands.map((brands, index) => {
                    return (
                      <option value={brands.brand_id} key={index}>
                        {brands.name}
                      </option>
                    );
                  })}
                </select>
              )}
            </div>
          </div>
        </div>
        <div>
          <div className="mb-2">
            <label className="inputTitle ms-3 fw-medium">Productomschrijving</label>
            <TextEditor
              onChange={(e) => {
                props.setProduct({ ...props.product, product_description: e });
                props.edit && props.setUpdatedProduct(true);
              }
              }
              onBlur={props.handleBlur}
              value={props.product.product_description}
            />
          </div>
        </div>
      </div>

      {/* First Box end */}
    </>
  );
};

export default FirstBox;
