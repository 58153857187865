import React, { useState } from "react";


const Dropdownfilter = ({ setFilters, filter }) => {

    const setFilterValue = (e) => {
        setFilters((filters) => {
            const newFilters = [...filters];
            newFilters[filters.findIndex((e) => e.index == filter.index)].value = e.target.value;
            return newFilters
        })
    }
    return <div className="d-flex w-100 h-100">
        <div className="bg-white rounded-4 p-4 h-100 border border-gray w-100">
            <h6 className="fw-bold">{filter.name}</h6>
            <div className="d-flex flex-row h-100">
                <select value={filter.value} onChange={(e) => setFilterValue(e)} className="standardInput w-100 mb-2 px-2 standardDropdownArrow">
                    <option selected={filter.value == null} value={''}>{filter.placeholder || "Selecteer een item"}</option>
                    {filter.options.map((e, index) => 
                        <option key={index} value={e.value}>{e.name}</option>
                    )}
                </select>
            </div>
        </div>
    </div>
}

export default Dropdownfilter;