import React from 'react';
import Modal from 'react-bootstrap/Modal';

const SecurityCodeModal = ({ show, onHide }) => (
  <Modal show={show} onHide={onHide}>
    <Modal.Header closeButton className="bg-white">
      <Modal.Title>Beveiligingscode</Modal.Title>
    </Modal.Header>
    <Modal.Body className="bg-white">
      <p>Beveiligingspin kan worden gebruikt als snelle inlogmethode in plaats van wachtwoord.</p>
      <p>Je kunt het gebruiken in POS apparaten, de POS App, InStore en InControl.</p>
    </Modal.Body>
    <Modal.Footer className="bg-white">
      <button className="btn btn-outline-primary" onClick={onHide}>
        Ik heb het begrepen
      </button>
    </Modal.Footer>
  </Modal>
);

export default SecurityCodeModal;