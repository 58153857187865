import React, { useState } from "react";


const CheckboxFilter = ({ setFilters, filter }) => {
    const setFilterValue = (e) => {
        setFilters((filters) => {
            const newFilters = [...filters];
            let values = newFilters[filters.findIndex((e) => e.index == filter.index)].value;
            if(!e.target.checked) {
                newFilters[filters.findIndex((e) => e.index == filter.index)].value = values.filter((a) => a != e.target.value);
            }
            if(e.target.checked) {
                values.push(Number(e.target.value));
                newFilters[filters.findIndex((e) => e.index == filter.index)].value = values;
            }
            return newFilters
        })
    }
    return <div className="d-flex w-100 h-100">
        <div className="bg-white rounded-4 h-100 p-4 border border-gray w-100">
            <h6 className="fw-bold">{filter.name}</h6>
            <div className="d-flex flex-row w-100 flex-wrap">
                {filter.options.map((e) => <div>
                    <input className="me-2 " type="checkbox" key={e.index} checked={filter.value.find(b => b == e.value) != null} onChange={(b) => setFilterValue(b)} value={e.value} name={filter.index} id={filter.index} />
                    <label className="pe-2">{e.name}</label>
                </div>)
                }
            </div>
        </div>
    </div>
}

export default CheckboxFilter;