import React from 'react'

const FourthBox = (props) => {
  return (
    <>
        <div className={props.edit ? ("whiteBox p-5 align-center ms-xxl-4") : ("whiteBox p-5 align-center")}>
            <h4 className='fw-bold'>Leveranciers</h4>
            <div className=''>
                {props.suppliers.length != 0 ? (
                    <table>
                    <thead>
                        <tr>
                            <th className='ps-0'>Leveranciers</th>
                            <th className='ms-5 ps-5'>Website</th>
                        </tr>
                    </thead>
                    <tbody>
                        {props.suppliers.map(supplier => 
                        <tr key={supplier.supplier_id} onClick={() => console.log(supplier.internal_id)} style={{border: "none"}}>
                            <td className='py-2 ps-0 pe-5'>{props.businesses.filter(bus => bus.internal_id == supplier.internal_id).map(busi => busi.company_name)}</td>
                            <td className='py-2 ms-5 ps-5'><button className='newButtonPresetStyle newButtons' target='_' href={props.businesses.filter(bus => bus.internal_id == supplier.internal_id).map(busi => busi.url)}>Bezoeken</button></td>
                        </tr>)}
                    </tbody>
                </table>
                ) : (
                    <p>Er zijn geen leveranciers gevonden</p>
                )}               
            </div>
        </div>
    </>
  )
}

export default FourthBox