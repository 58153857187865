import React, { useState, useEffect } from 'react';
import { Modal, Form, Button } from 'react-bootstrap';
import { IoMdSend } from 'react-icons/io';
import middleware from "../../Api/Middleware";
import ToastSuccess from "../../Toasts/ToastSuccess";
import ToastError from "../../Toasts/ToastError";
import "../ModalStyle.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import "../ModalStyle.css";

// Constants for input limitations based on DB constraints
const MAX_EMAIL_LENGTH = 255;
const MAX_SUBJECT_LENGTH = 255;
const MAX_CONTENT_LENGTH = 5000;
const SENDER_EMAIL = 'noreply@techdogsoftware.nl';

// wrappings
const wrapStyle = { overflowWrap: 'break-word' };
const contentStyle = { ...wrapStyle, whiteSpace: 'pre-wrap' };

// Helper function to create initial email data with proper order handling
const initialEmailData = (orderId) => ({
  order_id: orderId || '',
  receiver_email: '',
  subject: orderId ? `Nieuw bericht voor bestelling # ${orderId}` : '',
  content: ''
});

function SendEmailModal({ order, emails, handleEmailSent }) {
  const [show, setShow] = useState(false);
  const [isEmailSending, setIsEmailSending] = useState(false);
  const [isCustomEmail, setIsCustomEmail] = useState(false);
  const [showPreview, setShowPreview] = useState(false);
  const [emailData, setEmailData] = useState(() => initialEmailData(order?.order_id));
  const [touched, setTouched] = useState({
    receiver_email: false,
    subject: false,
    content: false
  });
  const [emailError, setEmailError] = useState('');

  const hasUnsavedChanges = () => {
    const currentOrderId = order?.order_id || '';
    const defaultSubject = currentOrderId ? `Nieuw bericht voor bestelling # ${currentOrderId}` : '';

    return emailData.receiver_email !== '' ||
      emailData.subject !== defaultSubject ||
      emailData.content !== '';
  };

  useEffect(() => {
    if (order) {
      setEmailData(prevData => ({
        ...prevData,
        order_id: order.order_id || '',
        subject: order.order_id ? `Nieuw bericht voor bestelling # ${order.order_id}` : prevData.subject
      }));
    }
  }, [order]);

  const handleClose = () => {
    closeModal();
  };

  const closeModal = () => {
    setShow(false);
    setIsCustomEmail(false);
    setEmailError('');
    setShowPreview(false);
    setEmailData(initialEmailData(order?.order_id));
    setTouched({
      receiver_email: false,
      subject: false,
      content: false
    });
  };

  const handleShow = () => setShow(true);

  const validateEmail = (email) => {
    if (email.length > MAX_EMAIL_LENGTH) {
      return false;
    }
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  const handleEmailInputChange = (e) => {
    const { name, value } = e.target;

    setTouched(prev => ({
      ...prev,
      [name]: true
    }));

    if (name === 'subject' && value.length > MAX_SUBJECT_LENGTH) {
      ToastError(`Onderwerp mag niet langer zijn dan ${MAX_SUBJECT_LENGTH} tekens`);
      return;
    }

    if (name === 'content' && value.length > MAX_CONTENT_LENGTH) {
      ToastError(`Inhoud mag niet langer zijn dan ${MAX_CONTENT_LENGTH} tekens`);
      return;
    }

    if (name === 'receiver_email') {
      if (value === 'custom') {
        setIsCustomEmail(true);
        setEmailData(prevState => ({ ...prevState, receiver_email: '' }));
      } else {
        setIsCustomEmail(false);
        setEmailData(prevState => ({ ...prevState, receiver_email: value }));
        setEmailError('');
      }
    } else if (name === 'custom_email') {
      if (value.length > MAX_EMAIL_LENGTH) {
        setEmailError(`E-mailadres mag niet langer zijn dan ${MAX_EMAIL_LENGTH} tekens`);
        return;
      }
      setEmailData(prevState => ({ ...prevState, receiver_email: value }));
      if (value && !validateEmail(value)) {
        setEmailError('Voer een geldig e-mailadres in');
      } else {
        setEmailError('');
      }
    } else {
      setEmailData(prevState => ({ ...prevState, [name]: value }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setTouched({
      receiver_email: true,
      subject: true,
      content: true
    });

    if (!emailData.receiver_email) {
      setEmailError('Selecteer een ontvanger');
      return;
    }

    if (emailError) {
      return;
    }

    if (emailData.subject.length > MAX_SUBJECT_LENGTH) {
      ToastError(`Onderwerp mag niet langer zijn dan ${MAX_SUBJECT_LENGTH} tekens`);
      return;
    }
    if (emailData.content.length > MAX_CONTENT_LENGTH) {
      ToastError(`Inhoud mag niet langer zijn dan ${MAX_CONTENT_LENGTH} tekens`);
      return;
    }

    if (!emailData.order_id) {
      ToastError('Geen geldig order ID gevonden');
      return;
    }

    setIsEmailSending(true);

    try {
      const response = await middleware.post('orders/emails', {
        ...emailData,
        sender_email: SENDER_EMAIL
      });

      if (response.status !== 200) {
        throw new Error('Failed to send email');
      }
      ToastSuccess("E-mail is succesvol verzonden.");

      if (handleEmailSent) {
        handleEmailSent();
      }

      closeModal();
    } catch (err) {
      ToastError(`Er is een fout opgetreden bij het verzenden van de e-mail: ${err.message}`);
    } finally {
      setIsEmailSending(false);
    }
  };

  return (
    <>
      {/* New email button */}
      <Button className="lightBlueStandardButton" style={{width: "160px"}} onClick={handleShow}>
        Nieuwe mail
      </Button>

      {/* Modal */}
      <Modal
      id="modalNoteCreation"
      show={show}
      onHide={handleClose}
      className="colormodals"
      size="xl"
      centered
      backdrop="static"
      >
          <Modal.Header className="modalTop justify-content-between px-5" >
              <h2>Nieuwe e-mail</h2>
              <button variant="transparent" className=' btn' onClick={handleClose}>
                  <FontAwesomeIcon icon={faXmark} className="closeModal" />
              </button>
          </Modal.Header>
          <Modal.Body className="modalCenter">
              <label className="mb-1">Verzender</label>
              <input className="standardInput mb-4" type="text"/>
              <label className="mb-1">Ontvanger</label>
              <input className="standardInput mb-4" type="text"/>
              <label className="mb-1">Template</label>
              <select
                  value={undefined}
                  onChange={undefined}
                  className="standardInput mb-4 standardDropdownArrow"
              >
                <option value={undefined} hidden>Selecteer een e-mail template</option>
                <option value={1}>Template #1</option>
                <option value={2}>Template #2</option>
                <option value={3}>Template #3</option>
                <option value={4}>Template #4</option>
                <option value={5}>Template #5</option>
              </select>
              <label className="mb-1">Bericht</label>
              <textarea className="standardInput" style={{minHeight: "150px"}}/>
          </Modal.Body>
          <Modal.Footer className="modalBottom">
              <div className="d-flex justify-content-end" >
                  <div className="buttonPresetStyle standardBlueButton newButtons" style={{"width": "200px"}} onClick={handleClose}>Versturen</div>
              </div>
          </Modal.Footer>
      </Modal>
    </>
  )

  return (
    <>
      <Button className="lightBlueStandardButton" style={{width: "160px"}} onClick={handleShow}>
        Nieuwe mail
      </Button>
      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header closeButton className='emailModalHeader'>
          <Modal.Title>
            {showPreview ? 'Voorbeeld van e-mail' : 'Stuur e-mail'}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className='bg-white'>
          {showPreview ? (
            <div className="email-preview">
              <p style={wrapStyle}><strong>Van:</strong> {SENDER_EMAIL}</p>
              <p style={wrapStyle}><strong>Aan:</strong> {emailData.receiver_email}</p>
              <p style={wrapStyle}><strong>Onderwerp:</strong>{emailData.subject}</p>
              <hr />
              <p style={contentStyle}>{emailData.content}</p>
            </div>
          ) : (
            <Form onSubmit={handleSubmit}>
              <Form.Group className="mb-3" controlId="receiver_email">
                <Form.Label>Ontvanger</Form.Label>
                <Form.Select
                  name="receiver_email"
                  value={isCustomEmail ? 'custom' : emailData.receiver_email}
                  onChange={handleEmailInputChange}
                  isInvalid={touched.receiver_email && !emailData.receiver_email && !isCustomEmail}
                  required
                >
                  <option value="">Selecteer ontvanger</option>
                  {emails.map((email, index) => (
                    <option key={index} value={email.email}>{email.email}</option>
                  ))}
                  <option value="custom">Ander e-mailadres</option>
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  Selecteer een ontvanger
                </Form.Control.Feedback>
              </Form.Group>
              {isCustomEmail && (
                <Form.Group className="mb-3" controlId="custom_email">
                  <Form.Control
                    type="email"
                    name="custom_email"
                    value={emailData.receiver_email}
                    onChange={handleEmailInputChange}
                    placeholder="Voer een e-mailadres in"
                    isInvalid={touched.receiver_email && (!emailData.receiver_email || !!emailError)}
                    required
                    maxLength={MAX_EMAIL_LENGTH}
                  />
                  <Form.Control.Feedback type="invalid">
                    {emailError || 'Voer een e-mailadres in'}
                  </Form.Control.Feedback>
                  <Form.Text className="text-muted">
                    {emailData.receiver_email.length}/{MAX_EMAIL_LENGTH} tekens
                  </Form.Text>
                </Form.Group>
              )}
              <Form.Group className="mb-3" controlId="subject">
                <Form.Label>Onderwerp</Form.Label>
                <Form.Control
                  type="text"
                  name="subject"
                  value={emailData.subject}
                  onChange={handleEmailInputChange}
                  required
                  maxLength={MAX_SUBJECT_LENGTH}
                  isInvalid={touched.subject && !emailData.subject}
                />
                <Form.Control.Feedback type="invalid">
                  Vul een onderwerp in
                </Form.Control.Feedback>
                <Form.Text className="text-muted">
                  {emailData.subject.length}/{MAX_SUBJECT_LENGTH} tekens
                </Form.Text>
              </Form.Group>
              <Form.Group className="mb-3" controlId="content">
                <Form.Label>Content</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={5}
                  name="content"
                  value={emailData.content}
                  onChange={handleEmailInputChange}
                  required
                  maxLength={MAX_CONTENT_LENGTH}
                  isInvalid={touched.content && !emailData.content}
                />
                <Form.Control.Feedback type="invalid">
                  Vul de inhoud van de e-mail in
                </Form.Control.Feedback>
                <Form.Text className="text-muted">
                  {emailData.content.length}/{MAX_CONTENT_LENGTH} tekens
                </Form.Text>
              </Form.Group>
            </Form>
          )}
        </Modal.Body>
        <Modal.Footer className='emailModalFooter'>
          <div className="d-flex justify-content-between w-100">
            <Button
              variant="link"
              onClick={() => setShowPreview(!showPreview)}
              className="text-secondary"
            >
              {showPreview ? 'Terug naar bewerken' : 'Voorbeeld van e-mail'}
            </Button>
            <div>
              <Button className="darkBlueStandardButton m-2" onClick={handleClose}>
                Annuleren
              </Button>
              {!showPreview && (
                <Button className="lightBlueStandardButton m-2" onClick={handleSubmit} disabled={isEmailSending}>
                  {isEmailSending ? 'Verzenden...' : 'Versturen'}
                </Button>
              )}
            </div>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default SendEmailModal;