import React, { useEffect, useState } from 'react';
import { Modal, Button } from 'react-bootstrap';

const DeactivateUser = ({ show, onHide, updateUserValues, setState, updateUser }) => {
    const [statusChanged, setStatusChanged] = useState(false);

    const statusChange = async () => {
        const newStatus = updateUserValues.account_status === 1 ? 0 : 1;
        await setState((prev) => ({ ...prev, account_status: newStatus }));
        setStatusChanged(true); // Set status change as requested
    }

    useEffect(() => {
        if(statusChanged) {
            updateUser();
            onHide();
            setStatusChanged(false);
        }
    }, [statusChanged])

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton className="bg-white">
        <Modal.Title>{updateUserValues.account_status === 1 ? "Blokkeer" : "De-blokkeer"} gebruiker</Modal.Title>
      </Modal.Header>
      <Modal.Body className="bg-white">
        Weet u zeker dat u deze gebruiker wilt {updateUserValues.account_status === 1 ? "blokkeren" : "De-blokkeren"}?
      </Modal.Body>
      <Modal.Footer className="bg-white">
        <Button variant="secondary" onClick={onHide}>Annuleren</Button>
        <Button
            variant={updateUserValues.account_status === 1 ? "danger" : "success"}
            onClick={async () => {
                await statusChange();
            }}
            >
            {updateUserValues.account_status === 1 ? "Blokkeer" : "De-blokkeer"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DeactivateUser;
