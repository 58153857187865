import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import warning from "../Icons/Warning-sign.svg";
import middleware from '../../../Api/Middleware';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ToastError from '../../../Toasts/ToastError';
import { useParams } from 'react-router';

const AddContactsModal = ({ setDataUpdated, setUpdatedContacts, closeAddContacts, openAddContacts, rowToEdit, setRowToEdit }) => {
  const [showAddContacts, setShowAddContacts] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [birthDay, setBirthDay] = useState('');
  const [prefix, setPrefix] = useState('');
  const { internal_id } = useParams();
  useEffect(() => {
    if (openAddContacts) {
      if (rowToEdit) {
        setFirstName(rowToEdit.first_name)
        setLastName(rowToEdit.last_name)
        setBirthDay(rowToEdit.birthday)
        setPrefix(rowToEdit.prefix)
      }
      setShowAddContacts(true);
    }
  }, [openAddContacts]);

  const closeAddContactsModal = () => {
    setFirstName('');
    setLastName('');
    setBirthDay('');
    setPrefix('');
    setRowToEdit(null);
    setShowAddContacts(false);
    closeAddContacts();
  }

  const submitNewContact = async (e) => {
    e.preventDefault();
    if (firstName == "") {
      ToastError("Vul een voornaam in");
      return;
    }
    if (lastName == "") {
      ToastError("Vul een achternaam in");
      return;
    }
    if (rowToEdit) {
      const [putContactsRes] = await Promise.all([
        middleware.put(`businessescontacts?internal_id=${internal_id}`, {
          first_name: firstName,
          last_name: lastName,
          birthday: birthDay,
          prefix: prefix,
          business_contact_id: rowToEdit.business_contact_id
        })]);
    } else {
      const [postContactsRes] = await Promise.all([
        middleware.post(`businessescontacts?internal_id=${internal_id}`, {
          first_name: firstName,
          last_name: lastName,
          birthday: birthDay,
          prefix: prefix
        })]);
    }
    setUpdatedContacts(true);
    closeAddContactsModal();
  }

  return (
    <Modal
      id="modalBlueprint"
      show={showAddContacts}
      onHide={closeAddContactsModal}
      className="colormodals"
      size="lg"
      centered
      backdrop="static"
    >
      <Modal.Header className="modalTop justify-content-between" >
        <h3>{rowToEdit ? "Edit een contactpersoon" : "Voeg een contactpersoon toe"}</h3>
        <button variant="transparent" className=' btn' onClick={() => closeAddContactsModal()}>
          <FontAwesomeIcon icon={faXmark} className="closeModal" />
        </button>
      </Modal.Header>
      <Modal.Body className="modalCenter d-flex justify-content-center">
        <div className='d-flex flex-column justify-content-center w-100'>
          <form onSubmit={submitNewContact} className='w-100'>
            <div className='d-flex flex-column mb-3'>
              <label className='mb-2 fw-medium'>Aanhef</label>
              <select className='form-select inputFieldAddCustomer dropdownsNewCustomers' onChange={(e) => setPrefix(e.target.value)} value={prefix}>
                <option hidden>Selecteer een aanhef</option>
                <option key={0} value={0}>Dhr</option>
                <option key={1} value={1}>Mvr</option>
                <option key={2} value={2}>Dhr/Mvr</option>
              </select>
            </div>
            <div className='d-flex flex-column mb-3'>
              <label className='mb-2 fw-medium'>Voornaam</label>
              <input className='standardInput p-3' onChange={(e) => setFirstName(e.target.value)} defaultValue={firstName}></input>
            </div>
            <div className='d-flex flex-column mb-3'>
              <label className='mb-2 fw-medium'>Achternaam</label>
              <input className='standardInput p-3' onChange={(e) => setLastName(e.target.value)} defaultValue={lastName}></input>
            </div>
            <div className='d-flex flex-column mb-3'>
              <label className='mb-2 fw-medium'>Geboortedatum</label>
              <input type='date' className='standardInput diffDateIcon p-3' onChange={(e) => setBirthDay(e.target.value)} defaultValue={birthDay}></input>
            </div>
            <div className='d-flex justify-content-end'>
              <button className='btn lightBlueStandardButton'>{rowToEdit ? "Edit" : "Toevoegen"}</button>
            </div>
          </form>
        </div>
      </Modal.Body>
      <Modal.Footer className="modalBottom">
      </Modal.Footer>
    </Modal>
  );
}

export default AddContactsModal;
