import React, { useEffect, useMemo, useState } from "react";
import { Box, LinearProgress, Typography, Divider } from "@mui/material";
import { useParams } from "react-router-dom";
import { useTable } from "react-table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faXmark, faDownload, faMinus, faSquare, faClock, faHourglassHalf } from "@fortawesome/free-solid-svg-icons";
import { format } from "date-fns";
import { useResultsLogic } from "./Components/resultsLogic";

import TablePreset from "../Table/TablePreset";
import PageTemplate from "../Templates/PageTemplate";
import SortableHeader from "../Table/TableComponents/SortableHeader";

import "./ImportExport.css";

function Results() {
    const { import_id } = useParams();
    const [pagination, setPagination] = useState({
        page_size: 10,
        page: 1,
        max_items: 0,
        begin_of_page: 0,
        end_of_page: 0,
    });
    const [order, setOrder] = useState({ orderBy: "", direction: "" });
    
    // Componen that's containing the main logic
    const {
        loading,
        downloadable,
        importResults,
        execelBlobFormat,
        importDetails,
        progressCount,
        succesfulCount,
        processedCount,
        pressedCancel,
        statusText,
        currentTableOptions,
        fetchAll,
        handleCancel,
        downloadOriginalFile,
    } = useResultsLogic(import_id, order, pagination, setPagination);

    // Columns for the table
    const columns = useMemo(() => [
        {
            Header: () => (
                <SortableHeader
                    currentItem={order}
                    setItem={setOrder}
                    pos={"start"}
                    field="import_table_row_id"
                >
                    Object
                </SortableHeader>
            ),
            accessor: "import_table_row_id",
            Cell: ({ row }) => {
                if (Object.keys(currentTableOptions).length > 0) {
                    const displayValues = currentTableOptions.DirectInfo.displayValues;
                    const parsedData = JSON.parse(row.original.data);
                    let displayName = "";

                    displayValues.forEach((valueIndex) => {
                        if (parsedData[valueIndex]) {
                            displayName += parsedData[valueIndex] + " ";
                        }
                    });

                    const url = `${currentTableOptions.DirectInfo.url}/${row.original.import_table_row_id}`;
                    return (
                        <a
                            href={url}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="text-info text-decoration-none"
                        >
                            {displayName}
                        </a>
                    );
                }
                return "";
            },
        },
        {
            Header: () => (
                <SortableHeader
                    currentItem={order}
                    setItem={setOrder}
                    pos={"start"}
                    field="status"
                >
                    Status
                </SortableHeader>
            ),
            accessor: "status",
            Cell: ({ row }) => {
                let statusIcon = <p>?</p>;
                const statusClass = "d-flex justify-content-center";

                if (row.original.status === 0 || row.original.status === 1) {
                    statusIcon = <FontAwesomeIcon className="status-text-green" icon={faCheck} />;
                } else if (row.original.status === 2) {
                    statusIcon = <FontAwesomeIcon className="status-text-unchanged" icon={faMinus} />;
                } else if (row.original.status === 3) {
                    statusIcon = <FontAwesomeIcon className="status-text-red" icon={faXmark} />;
                }
                return <div className={statusClass}>{statusIcon}</div>;
            },
        },
        {
            Header: () => (
                <SortableHeader
                    currentItem={order}
                    setItem={setOrder}
                    pos={"start"}
                    field="message"
                >
                    Message
                </SortableHeader>
            ),
            accessor: "internal_id",
            Cell: ({ row }) => (
                <div dangerouslySetInnerHTML={{ __html: row.original.message }} />
            ),
        },
    ], [order.orderBy, order.direction, currentTableOptions]);
    const tableInstance = useTable({ columns, data: importResults });

    console.log(importDetails);

    // Fetch upon first load and when changing table settings
    useEffect(() => {
        fetchAll(true);
    }, [pagination.page_size, pagination.page, order.orderBy, order.direction]);

    return (
        <PageTemplate navbarTitle={"Importeer Details"} pageIsLoading={loading} backValue={"/import-export-center#imports"}>
            <div className="d-flex flex-column justify-content-center w-100">
                {/* Details of the import */}
                <div className="detailsImport" style={{ width: "90%" }}>
                    {/* Progress Bar */}
                    <div className="mb-2">
                        <p className="mb-0 fw-bold">Progressie:</p>
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                            <Box sx={{ width: "100%", mr: 1 }}>
                                <LinearProgress
                                    variant="determinate"
                                    value={progressCount}
                                    sx={{
                                        height: "7px",
                                        bgcolor: "gray",
                                        "& .MuiLinearProgress-bar": {
                                            backgroundColor: "lightgreen",
                                            height: "10px",
                                        },
                                    }}
                                />
                            </Box>
                            <Box sx={{ minWidth: 35 }}>
                                <Typography variant="body2" color="text.secondary">
                                    {progressCount.toFixed(2)}%
                                </Typography>
                            </Box>
                        </Box>
                    </div>

                    {/* Import details like who, type, date, succesful */}
                    {importDetails.status !== 3 ? (
                        <div>
                            <div className="row justify-content-between">
                                <div className="col-md-4">
                                    <p>
                                        Type: <b>{currentTableOptions.DisplayName}</b>
                                    </p>
                                    <p>
                                        Door wie: <b>{importDetails.username}</b>
                                    </p>
                                </div>

                                <div className="col-md-4">
                                    <p>
                                        Wanneer gestart:{" "}
                                        <b>{importDetails.created_at
                                        ? format(new Date(importDetails.created_at), "dd-MM-yyyy HH:mm:ss")
                                        : "Onbekende datum"}</b>
                                    </p>
                                    <p>
                                        Slagingspercentage:{" "}
                                        <b>
                                            {succesfulCount}/{processedCount} (
                                            {processedCount === 0
                                                ? 0
                                                : ((succesfulCount / processedCount) * 100).toFixed(
                                                      2
                                                  )}
                                            %)
                                        </b>
                                    </p>
                                </div>

                                <div className="col-md-4 text-left d-flex flex-column">
                                    <button
                                        className="btn btn-primary"
                                        disabled={execelBlobFormat == null}
                                        onClick={downloadOriginalFile}
                                    >
                                        <FontAwesomeIcon icon={faDownload} /> Download originele
                                        bestand
                                    </button>
                                    <p className="text-center">
                                        {!downloadable ? (
                                            <b className="text-danger">(Download verlopen)</b>
                                        ) : (
                                            <span>
                                                Verloopt op: <b>{<b>{format(Date.parse(importDetails.file_expire_date), "dd-MM-yyyy HH:mm:ss")}</b>}</b>
                                            </span>
                                        )}
                                    </p>
                                </div>
                            </div>
                            <br />

                            <div className="row justify-content-between">
                                <div className="col-md-4">
                                    <p className="fw-bold mb-0">Instellingen:</p>
                                    <div className="mb-3">
                                        <span>
                                            {importDetails.updating ? (
                                                <FontAwesomeIcon
                                                    className="status-text-green"
                                                    icon={faCheck}
                                                />
                                            ) : (
                                                <FontAwesomeIcon
                                                    className="status-text-red"
                                                    icon={faXmark}
                                                />
                                            )}{" "}
                                            Bestaande producten updaten
                                        </span>
                                    </div>
                                    {(importDetails.status === 2 || importDetails.status === 3) &&
                                    !pressedCancel ? (
                                        <button onClick={handleCancel} className="btn btn-danger">
                                            <FontAwesomeIcon icon={faXmark} /> Annuleer import
                                        </button>
                                    ) : (
                                        ""
                                    )}
                                </div>

                                <div className="col-md-4">
                                    <p className="fw-bold mb-0">Status:</p>
                                    {statusText}
                                </div>

                                <div className="col-md-4">
                                    <p className="fw-bold mb-0">Legenda:</p>
                                    <div className="mb-3">
                                        <p className="mb-0">
                                            <FontAwesomeIcon
                                                className="status-text-green"
                                                icon={faSquare}
                                            />{" "}
                                            <span>Toegevoegd</span>
                                        </p>
                                        <p className="mb-0">
                                            <FontAwesomeIcon
                                                className="status-text-orange"
                                                icon={faSquare}
                                            />{" "}
                                            <span>Bijgewerkt</span>
                                        </p>
                                        <p className="mb-0">
                                            <FontAwesomeIcon
                                                className="status-text-red"
                                                icon={faSquare}
                                            />{" "}
                                            <span>Dubbele waarde</span>
                                        </p>
                                        <p className="mb-0">
                                            <FontAwesomeIcon
                                                className="status-text-unchanged"
                                                icon={faSquare}
                                            />{" "}
                                            <span>Niet veranderd</span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className="mb-3 d-flex align-items-center">
                            <div
                                className="spinner-border spinner-border-sm ml-auto text-black me-2"
                                role="status"
                                aria-hidden="true"
                            ></div>
                            Het importeren is nog niet gestart, hier zal data worden getoond zodra
                            het proces is begonnen (Maximaal 1 minuut).
                        </div>
                    )}
                </div>
                {/* Table containing all the row logs */}
                <div className="tableLogs" style={{ width: "90%" }}>
                    {importResults.length === 0 ? (
                        <div className="text-center my-4 whiteBox">
                            <span className="text-secondary">{importDetails.status === 2 || importDetails.status === 3 || importDetails.status == undefined ? "Er zijn nog geen logs beschikbaar voor deze import" : "Er zijn geen logs meer beschikbaar voor deze import"}</span>
                        </div>
                    ) : (
                        <TablePreset
                            data={[
                                {
                                    tableInstance: tableInstance,
                                    pagination: pagination,
                                    primary_key: "consumer_id",
                                },
                            ]}
                            setPagination={setPagination}
                            handleSelection={() => {}}
                        />
                    )}
                </div>
            </div>
        </PageTemplate>
    );
}

export default Results;
