import React, { useState, useEffect } from "react";
import PageTemplate from "../../../Templates/PageTemplate";
import middleware from "../../../Api/Middleware";
import { useNavigate, useParams } from "react-router";
import Key from "./Icons/key.svg";
import Eye from "./Icons/eye.svg";
import EyeClosed from "./Icons/eye-closed.svg";
import "./EditUsers.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo, faBan, faUnlockKeyhole } from "@fortawesome/free-solid-svg-icons";
import Modal from 'react-bootstrap/Modal';
import ToastSuccess from "../../../Toasts/ToastSuccess";
import ToastError from "../../../Toasts/ToastError";
import PasswordRequirementsModal from './Modals/PasswordRequirementsModal';
import SecurityCodeModal from './Modals/SecurityCodeModal';
import RoleInfoModal from './Modals/RoleInfoModal';
import ToastInfo from "../../../Toasts/ToastInfo";
import DeactivateUserModal from "./Modals/DeactivateUserModal";

const roleOptions = [
  { value: 0, label: "Alleen bestellingen" },
  { value: 1, label: "Medewerker" },
  { value: 2, label: "Leidinggevende" },
  { value: 3, label: "Manager" },
  { value: 4, label: "Admin" },
];

const languageOptions = [
  {value: "NL", label: "Nederlands"},
  {value: "BE", label: "Belgisch"},
  {value: "DE", label: "Deutsch"},
  {value: "FR", label: "Français"}
];

const EditUsers = ({ ownUserRole, ownUserId }) => {
  const navigate = useNavigate();
  const [user, setUser] = useState([]);
  const [updateUserValues, setUpdateUserValues] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  const [showSecurityCode, setShowSecurityCode] = useState(false);
  const [loading, setLoading] = useState(true);
  const [loadingScreen, setLoadingScreen] = useState(true);
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const { user_id } = useParams();
  const [modals, setModals] = useState({
    password: false,
    role: false,
    securityCode: false,
    deactivateUser: false
  });
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^A-Za-z\d])[A-Za-z\d\S]{8,}$/;

  const toggleModal = (modal) => setModals((prev) => ({ ...prev, [modal]: !prev[modal] }));


  const fetchAll = async () => {
    try {
      setLoading(true);
      const [fetchUser] = await Promise.all([
        middleware.get(`users?user_id=${user_id}`),
      ]);
      setUser(fetchUser.data);
      setUpdateUserValues(fetchUser.data)
      setLoading(false)
    } catch (error) {
      ToastError(error.message)
    }
  };

  useEffect(() => {
    fetchAll();
  }, []);

  const validateEmail = (email) => {
    if (!emailRegex.test(email)) {
      setEmailError("Ongeldig e-mailadres.");
    } else {
      setEmailError("");
    }
  };

  const validatePassword = (password) => {
    if (!passwordRegex.test(password)) {
      setPasswordError("Wachtwoord moet minimaal 8 tekens bevatten, inclusief hoofdletters, kleine letters, een cijfer en een speciaal teken.");
    } else {
      setPasswordError("");
    }
  };

  const hasChanges = () => {
    for (const key in updateUserValues) {
      if (updateUserValues[key] !== user[key]) {
        console.log(updateUserValues[key], user[key]);
        
        return true;
      }
    }
    return false;
  };

  const generateRandomPassword = async (event) => {
    event.preventDefault();
  
    const length = 15; // Minimum length is 8, so 15 is sufficient
    const lowercase = "abcdefghijklmnopqrstuvwxyz";
    const uppercase = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    const digits = "0123456789";
    const specialChars = "!@#$%^&*";
    
    // Ensure each type is included at least once
    let password = [
      lowercase[Math.floor(Math.random() * lowercase.length)],
      uppercase[Math.floor(Math.random() * uppercase.length)],
      digits[Math.floor(Math.random() * digits.length)],
      specialChars[Math.floor(Math.random() * specialChars.length)],
    ];
  
    // Fill the rest of the password
    const allChars = lowercase + uppercase + digits + specialChars;
    while (password.length < length) {
      password.push(allChars[Math.floor(Math.random() * allChars.length)]);
    }
  
    // Shuffle the password to ensure randomness
    password = password.sort(() => Math.random() - 0.5).join('');
  
    // Update state
    setUpdateUserValues({ ...updateUserValues, password });
    await updateUser();
  
    try {
      await navigator.clipboard.writeText(password);
      ToastInfo("Wachtwoord gekopieerd naar clipboard", 1500);
    } catch (err) {
      console.error("Failed to copy password: ", err);
    }
  };

  const generateRandomSecurityCode = async event => {
    event.preventDefault();
    const length = 6;
    const charset = "0123456789";
    let code = "";

    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * charset.length);
      let char = charset[randomIndex];      
      code += char;
    }

    setUpdateUserValues({...updateUserValues, "security_code": code});
    await updateUser();

    try {
      await navigator.clipboard.writeText(code);
      ToastInfo(`Beveiligingspin gekopieerd naar clipboard`, 1500);
    } catch (err) {
      console.error('Failed to copy password: ', err);
    }
  }

  const updateUser = async () => {
    try {
      if(hasChanges()) {
        if(emailError == "" && passwordError == "") {
          const [putUser] = await Promise.all([
            middleware.put('users', updateUserValues)
          ])
          ToastSuccess("Gebruikergegevens bijgewerkt")
        } else if (emailError !== "") {
          ToastError(emailError)
        } else if (passwordError !== "") {
          ToastError(passwordError)
        }
      }
      else {
        console.log(user, updateUserValues);
        
      }
    } catch (error) {
      ToastError(error.message);
    }
  }

  console.log(updateUserValues);
  
  

  const changeUser = async (column, event) => {
    const value = event.target.value;

    if (column === "email") validateEmail(value);
    if (column === "password") validatePassword(value);
    
    setUpdateUserValues({...updateUserValues, [column]: event.target.value})
  }

  return (
    <>
      <PasswordRequirementsModal show={modals.password} onHide={() => toggleModal("password")}/>
      <SecurityCodeModal show={modals.securityCode} onHide={() => toggleModal("securityCode")}/>
      <RoleInfoModal show={modals.role} onHide={() => toggleModal("role")}/>
      <DeactivateUserModal show={modals.deactivateUser} onHide={() => toggleModal("deactivateUser")} updateUserValues={updateUserValues} setState={setUpdateUserValues} updateUser={updateUser}/>

      <PageTemplate navbarTitle={"Gebruiker bewerken"} pageIsLoading={loading} backValue={"/algemene/instellingen/gebruikers"}>
        <form className="w-100" >
          <div className="whiteBox parentBoxEditUser">
            <div className="m-3">
              <div className="m-3">
                <div className="d-flex col-12 border-bottom py-4">
                  <h5 className="titlesEditUsers inputTitle col-3 ps-3">Volledige naam</h5>
                  <input 
                    className="inputFieldEditUser mx-2 col-4 ps-2" 
                    type="text" 
                    placeholder="Voornaam" 
                    name="first_name"
                      onChange={(event) => changeUser("first_name", event)}
                      onBlur={updateUser}
                    value={updateUserValues.first_name ?? ''}>
                    </input>
                  <input
                    className="inputFieldEditUser mx-3 ms-4 col-4 ps-2"
                    type="text"
                    placeholder="Achternaam"
                    name="last_name"
                      onChange={(event) => changeUser("last_name", event)}
                      onBlur={updateUser}
                    value={updateUserValues.last_name ?? ''}>
                  </input>
                </div>
                <div className="d-flex col-12 border-bottom py-4">
                  <h5 className="titlesEditUsers inputTitle col-3 ps-3">E-mail</h5>
                  <div className="col-9 d-flex flex-column">
                    <div className="d-flex flex-row">
                      <input
                        className={`inputFieldEditUser ms-2 col-9 ps-2 ${emailError ? "error" : ""}`}
                        type="email"
                        name="email"
                        onChange={(event) => changeUser("email", event)}
                        onBlur={updateUser}
                        value={updateUserValues.email ?? ''}
                        style={{
                          borderTopRightRadius: "0px",
                          borderBottomRightRadius: "0px",

                        }}
                      ></input>
                      {/* <button disabled className="btn btnEditUser col-2 px-3" onClick={event => event.preventDefault()}>
                        Gebruik systeememail
                      </button> */}
                    </div>
                    <small className="ms-4 text-secondary">
                      Het is niet mogelijk om uw wachtwoord te herstellen met
                      systeem-e-mail.
                    </small>
                  </div>
                </div>
                <div className="d-flex col-12 border-bottom py-4">
                  <h5 className="titlesEditUsers inputTitle col-3 ps-3">
                    Wijzig wachtwoord{" "}
                    <FontAwesomeIcon className="iconInfoStyle" onClick={() => toggleModal("password")} icon={faCircleInfo} />
                  </h5>
                  <div className="col-9 d-flex">
                    <input
                      className="inputFieldEditUser ps-2 ms-2 col-10"
                      type={showPassword ? 'text' : 'password'}
                      name="password"
                      onChange={(event) => changeUser("password", event)}
                      onBlur={updateUser}
                      value={updateUserValues.password ?? ''}
                      placeholder="Wijzig wachtwoord"
                      style={{
                        borderTopRightRadius: "0px",
                        borderBottomRightRadius: "0px",
                      }}
                    ></input>
                    <button className="btn btnPasswordShow" onClick={event => {
                      event.preventDefault();
                      setShowPassword(!showPassword);
                    }}>
                      <img className="h-100" src={showPassword ? Eye : EyeClosed} />
                    </button>
                    <button className="btn btnEditUser" onClick={generateRandomPassword}>
                      <img className="h-100" src={Key} />
                    </button>
                  </div>
                </div>
                <div className="d-flex col-12 border-bottom py-4">
                  <h5 className="titlesEditUsers inputTitle col-3 ps-3">
                    Beveiligingspin instellen {" "}
                    <FontAwesomeIcon className="iconInfoStyle" onClick={() => toggleModal("securityCode")} icon={faCircleInfo} />
                  </h5>
                  <div className="col-9 d-flex">
                    <input
                      className="inputFieldEditUser ps-2 ms-2 col-10"
                      type={showSecurityCode ? 'text' : 'password'}
                      name="security_code"
                      value={updateUserValues.security_code ?? ''}
                      onChange={(event) => changeUser("security_code", event)}
                      onBlur={updateUser}
                      placeholder="Beveiligingspin instellen"
                      style={{
                        borderTopRightRadius: "0px",
                        borderBottomRightRadius: "0px",
                      }}
                    ></input>
                    <button className="btn btnPasswordShow" onClick={event => {
                      event.preventDefault();
                      setShowSecurityCode(!showSecurityCode);
                    }}>
                      <img className="h-100" src={showSecurityCode ? Eye : EyeClosed} />
                    </button>
                    <button className="btn btnEditUser" onClick={generateRandomSecurityCode}>
                      <img className="h-100" src={Key} />
                    </button>
                  </div>
                </div>
                <div className="d-flex col-12 border-bottom py-4">
                  <h5 className="titlesEditUsers inputTitle mb-0 col-3 ps-3">Taal</h5>
                  <div className="col-9 d-flex flex-column">
                    <div className="d-flex flex-row">
                      <select
                        className="inputFieldEditUser ps-2 ms-2 col-11"
                        name="language"
                        defaultChecked={updateUserValues.language}
                        onChange={(event) => changeUser("language", event)}
                        onBlur={updateUser}
                      >
                        {languageOptions.map((language, index) =>
                          <option key={index} value={language.value}>{language.label}</option>
                        )}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="d-flex col-12 border-bottom py-4">
                  <h5 className="titlesEditUsers inputTitle mb-0 col-3 ps-3">Rol{" "}<FontAwesomeIcon className="iconInfoStyle" onClick={() => toggleModal("role")} icon={faCircleInfo} /></h5>
                  <div className="col-9 d-flex flex-column">
                    <div className="d-flex flex-column">
                      <div className="d-flex flex-column">
                        <select 
                          className="inputFieldEditUser ps-2 ms-2 col-11" 
                          defaultValue={updateUserValues.role} 
                          disabled={ownUserId === updateUserValues.user_id}
                          onChange={(event) => changeUser("role", event)}
                          onBlur={updateUser}
                        >
                          {roleOptions.map((role, index) =>
                            <option key={index} value={role.value}>{role.label}</option>
                          )}
                        </select>
                      </div>
                      {ownUserId === updateUserValues.user_id && (
                        <small className="ms-4 text-secondary">
                          Je kan je eigen rol niet aanpassen
                        </small>
                      )}
                      <div>
                      </div>
                    </div>
                  </div>
                </div>
                {ownUserId !== updateUserValues.user_id && (
                  <div className="d-flex col-12 justify-content-center align-items-center py-4">
                      {updateUserValues.account_status === 0 ? (
                        <div
                          className="btn rounded-pill"
                          style={{backgroundColor: "#50C878", color: "white"}}
                          onClick={() => {
                            toggleModal("deactivateUser");
                          }}
                        >
                          <FontAwesomeIcon icon={faUnlockKeyhole}/> {" "}
                          De-blokkeer gebruiker van het systeem {" "}
                          <FontAwesomeIcon icon={faUnlockKeyhole}/>
                        </div>
                      ) : (
                        <div
                          className="btn btn-danger rounded-pill"
                          onClick={() => {
                            toggleModal("deactivateUser");
                          }}
                        >
                          <FontAwesomeIcon icon={faBan}/> {" "}
                          Blokkeer gebruiker van het systeem {" "}
                          <FontAwesomeIcon icon={faBan}/>
                        </div>
                      )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </form>
      </PageTemplate>
    </>
  );
};

export default EditUsers;