import React, { useState, useEffect } from 'react';
import { Modal, Toast } from 'react-bootstrap';
import warning from "../Icons/Warning-sign.svg";
import ToastWarning from '../../../Toasts/ToastWarning';
import ToastError from '../../../Toasts/ToastError';
import middleware from '../../../Api/Middleware';
import RandExp from 'randexp';

const ConvertToBusiness = ({ openConvertToBusiness, closeConvertToBusiness, onConvertConfirmed }) => {
  const [showConvertToBusiness, setShowConvertToBusiness] = useState(false);
  const [confirmationStep, setConfirmationStep] = useState(false);
  const [formData, setFormData] = useState({
    companyName: '',
    companyNumber: '',
    vatNumber: '',
    countryCodeId: ''
  });
  const [btwPlaceholder, setBtwPlaceholder] = useState('');
  const [selectedCountry, setSelectedCountry] = useState([]);
  const [countries, setCountries] = useState([]);

  const vatRegexPatterns = {
    AT: /^ATU\d{8}$/,                        // Austria
    BE: /^BE0\d{9}$/,                        // Belgium
    BG: /^BG\d{9,10}$/,                      // Bulgaria
    CY: /^CY\d{8}[A-Z]$/,                    // Cyprus
    CZ: /^CZ\d{8,10}$/,                      // Czech Republic
    DE: /^DE\d{9}$/,                         // Germany
    DK: /^DK\d{8}$/,                         // Denmark
    EE: /^EE\d{9}$/,                         // Estonia
    EL: /^EL\d{9}$/,                         // Greece
    ES: /^ES[A-Z0-9]\d{7}[A-Z0-9]$/,         // Spain
    FI: /^FI\d{8}$/,                         // Finland
    FR: /^FR[A-Z0-9]{2}\d{9}$/,              // France
    GB: /^GB(\d{9}|\d{12}|GD\d{3}|HA\d{3})$/, // United Kingdom
    HR: /^HR\d{11}$/,                        // Croatia
    HU: /^HU\d{8}$/,                         // Hungary
    IE: /^IE\d[A-Z0-9+*]\d{5}[A-Z]$/,        // Ireland
    IT: /^IT\d{11}$/,                        // Italy
    LT: /^LT(\d{9}|\d{12})$/,                // Lithuania
    LU: /^LU\d{8}$/,                         // Luxembourg
    LV: /^LV\d{11}$/,                        // Latvia
    MT: /^MT\d{8}$/,                         // Malta
    NL: /^NL\d{9}B\d{2}$/,                   // Netherlands
    PL: /^PL\d{10}$/,                        // Poland
    PT: /^PT\d{9}$/,                         // Portugal
    RO: /^RO\d{2,10}$/,                      // Romania
    SE: /^SE\d{12}$/,                        // Sweden
    SI: /^SI\d{8}$/,                         // Slovenia
    SK: /^SK\d{10}$/                         // Slovakia
  };



  useEffect(() => {
    if (selectedCountry != '') {
      let t = countries.find((e) => e.country_code_id == selectedCountry).country_code_2
      let regex = vatRegexPatterns[t];
      const randexp = new RandExp(regex);
      setBtwPlaceholder(randexp.gen());
    }
  }, [selectedCountry])

  const fetchCountries = async () => {
    const [countryRes] = await Promise.all([
      middleware.get(`/countryCodes`),
    ]);
    setCountries(countryRes.data);
  }
  useEffect(() => {
    if (openConvertToBusiness) {
      fetchCountries();
      setShowConvertToBusiness(true);
      setConfirmationStep(false);
    }
  }, [openConvertToBusiness]);

  const closeConvertToBusinessModal = () => {
    setShowConvertToBusiness(false);
    closeConvertToBusiness();
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const isValidForm = () => {
    
    const vatRegex = vatRegexPatterns[selectedCountry.country_code_2];

    if (formData.companyNumber.length > 12) {
      ToastError("KVK nummer mag niet langer zijn dan 12 nummers")
    } else if (formData.vatNumber && vatRegex && !vatRegex.test(formData.vatNumber)) {
      ToastError(`Ongeldig BTW-nummer formaat voor ${selectedCountry.country}.`);
      return;
    } else if (formData.companyName && formData.companyNumber && formData.vatNumber && formData.countryCodeId) {
      return true
    } else {
      ToastWarning("Vul alle velden in om de conversie te voltooien.");
    }

  };


  const handleConfirm = async () => {
    if (confirmationStep) {
      await onConvertConfirmed(formData);
      closeConvertToBusinessModal();
    } else {
      // Controleer of alle velden zijn ingevuld voordat je doorgaat
      
      if (isValidForm()) {
        setConfirmationStep(true);
      }
    }
  };

  return (
    <Modal
      id="modalBlueprint"
      show={showConvertToBusiness}
      onHide={closeConvertToBusinessModal}
      className="colormodals"
      size="lg"
      centered
      backdrop="static"
    >
      <Modal.Header closeButton className="modalTop justify-content-end" style={{ height: "60px" }}>
      </Modal.Header>
      <Modal.Body className="modalCenter d-flex justify-content-center">
        {!confirmationStep ? (
          <div className='d-flex flex-column justify-content-center w-100'>
            <h3>Vul ontbrekende info in om te converteren naar bedrijf</h3>
            <div className='d-flex flex-column'>
              <label className='fw-semibold mb-2' >Bedrijfs naam</label>
              <input
                type="text"
                name="companyName"
                placeholder="Bedrijfsnaam"
                value={formData.companyName}
                onChange={handleInputChange}
                className="mb-3 inputFieldAddCustomer px-3"
              />
            </div>
            <div className='d-flex flex-column'>
              <label className='fw-semibold mb-2' >Land</label>
              <select name='countryCodeId'
                className="form-select inputFieldAddCustomer dropdownsNewCustomers mb-3"
                onChange={(e) => handleInputChange(e) + setSelectedCountry(e.target.value)}
              >
                <option hidden>Kies een land</option>
                {countries?.length > 0 && countries.map(country => (
                  <option key={country.country_code_id} value={country.country_code_id}>
                    {country.country}
                  </option>
                ))}
              </select>
            </div>
            <div className='d-flex flex-column'>
              <label className='fw-semibold mb-2' >Kvk nummer</label>
              <input
                type="number"
                name="companyNumber"
                placeholder="KvK-nummer"
                max={12}
                min={0}
                required
                value={formData.companyNumber}
                onChange={handleInputChange}
                className="mb-3 inputFieldAddCustomer px-3"
              />
            </div>
            <div className='d-flex flex-column'>
              <label className='fw-semibold mb-2' >BTW nummer</label>
              <input
                type="text"
                name="vatNumber"
                disabled={btwPlaceholder == ''}
                placeholder={btwPlaceholder != '' ? 'Bijv. ' + btwPlaceholder : 'Kies een land'}
                value={formData.vatNumber}
                onChange={handleInputChange}
                className="mb-3 inputFieldAddCustomer px-3"
              />
            </div>

            <button className='btn lightBlueStandardButton' onClick={handleConfirm}>Bevestigen</button>
          </div>
        ) : (
          <div className='d-flex flex-column justify-content-center'>
            <img style={{ width: "75px" }} className=' d-flex align-self-center mb-4' src={warning} alt="Warning" />
            <div className='w-100 d-flex flex-column justify-content-center mb-3'>
              <h3 className='text-center'>Weet je zeker dat je deze consumer wilt veranderen naar een bedrijf?</h3>
              <h3 className='text-center text-danger'>Dit kan niet ongedaan gemaakt worden.</h3>
            </div>
            <div className='d-flex justify-content-between align-self-center' style={{ width: "60%" }}>
              <button className='btn DarkStandardButton' onClick={closeConvertToBusinessModal}>Annuleren</button>
              <button style={{ padding: "5px 15px" }} className='btn lightBlueStandardButton' onClick={handleConfirm}>Ja, ik weet het zeker</button>
            </div>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer className="modalBottom">
      </Modal.Footer>
    </Modal>
  );
};

export default ConvertToBusiness;
