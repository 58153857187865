import React, { useMemo, useState, useEffect } from "react";
import { useTable } from "react-table";
import middleware from "../../../Api/Middleware";
import salesChannels from "../../../Api/SalesChannels";
import TablePreset from "../../../Table/TablePreset";
import "../../../Table/Table.css";
import "../../../Orders/Orders.css";
import { useNavigate, useParams } from "react-router";
import { format } from 'date-fns'
import { nl } from 'date-fns/locale';
import TDSTextNavigation from "../../../Templates/TDSTextNavigation";
import CheckboxHeader from "../../../Table/TableComponents/CheckboxHeader";
import CheckboxCell from "../../../Table/TableComponents/CheckboxCell";
import SortableHeader from "../../../Table/TableComponents/SortableHeader";

const OrdersTab = () => {
  const navigate = useNavigate();
  const { internal_id } = useParams();
  const [loading, setLoading] = useState(false);

  const [consumers, setConsumers] = useState([]);
  const [businesses, setBusinesses] = useState([]);
  const [salesChannel, setSalesChannel] = useState([]);
  const [searchFieldValue, setSearchFieldValue] = useState('');
  const [orders, setOrders] = useState([]);
  const [orderIds, setOrderIds] = useState([]);
  const [pagination, setPagination] = useState({
    "page_size": 10,
    "page": 1,
    "max_items": 0,
    "begin_of_page": 0,
    "end_of_page": 0,
  });
  const [order, setOrder] = useState({orderBy: '', direction: ''});
  const [searchBar, setSearchBar] = useState("");
  const [timeoutId, setTimeoutId] = useState(null);

  const fetchAll = async () => {
    setLoading(true);
      try {
          const [ordersResponse] = await Promise.all([
              middleware.get(`sp/consumerOrders?internal_id=${internal_id}&page=${pagination.page}&page_size=${pagination.page_size}&${order.orderBy !== '' && order.direction !== '' ? `&orderBy=${order.orderBy}&direction=${order.direction}` : ''}${searchFieldValue ? `&searchTerm=${searchBar}` : ''}`),
          ]);
          setOrders(ordersResponse.data.data);
          
          setOrderIds(ordersResponse.data.data.map(data => data.order_id));
          setPagination({...pagination, "max_items": ordersResponse.data.total, "begin_of_page": ordersResponse.data.from, "end_of_page": ordersResponse.data.to});
      } catch (error) {
          console.error('Error fetching data:', error);
      }
      setLoading(false);
  };

  const delayedSetSearchBar = (value) => {
    setSearchFieldValue(value); // Set search field value immediately

    // Clear previous timeout if it exists
    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    // Set new timeout
    const newTimeoutId = setTimeout(() => {
      setSearchBar(value);
    }, 500);

    setTimeoutId(newTimeoutId); // Store the new timeout ID
  }

  useEffect(() => {
    try {
      fetchAll();
    } catch (error) {
      console.warn(error)
    }
  }, [pagination.page_size, pagination.page, order, order.direction, searchBar]);


  const [selectedIds, setSelectedIds] = useState([]);

  // Handle checkbox selection
  const handleSelection = (productId, isSelected) => {
    if (isSelected) {
      setSelectedIds(previous => [...previous, productId]);
    } else {
      setSelectedIds(previous => previous.filter(id => id !== productId));
    }
  };

  // Columns for the table
  const columns = useMemo(
    () => [
      {
        Header: (
          <CheckboxHeader currentItem={orders} selectedIds={selectedIds} setSelectedIds={setSelectedIds} pk={'order_id'}>
          </CheckboxHeader>
        ),
        accessor: "select",
        Cell: ({ row }) => {
          return <CheckboxCell row={row} handleSelection={handleSelection} selectedIds={selectedIds} pk={'order_id'}/>
        }
      },
      {
        Header: () => (
          <SortableHeader currentItem={order} setItem={setOrder} pos={"start"} field="order_number">
            Bestelling
          </SortableHeader>
        ),
        accessor: 'order_number',
        Cell: ({ row }) => {
          return (
            <div className="d-flex flex-column">
              <TDSTextNavigation
                text={row.original.order_number}
                link={`/bestellingen/bewerken/${row.original.order_number}`}
                extraClassNames={'text-nowrap'}
              />

              <div>
                <label className="d-flex flex-column"><span>{row.original.created_date}</span> <span>{row.original.created_time}</span></label>
              </div>
            </div>
          )
        }
      },
      {
        Header: () => (
          <SortableHeader currentItem={order} setItem={setOrder} field="price_incl">
            Prijs incl.
          </SortableHeader>
        ),
        accessor: 'price_incl',
        Cell: ({ row }) => {
          return (
            <div className="d-flex flex-column text-center">
              <div>€{Number(row.original.price_incl).toFixed(2).replace('.', ',')}</div>
            </div>
          );
        }
      },
      {
        Header: () => (
          <SortableHeader currentItem={order} setItem={setOrder} field="sales_channel_id">
            Verkooplocatie
          </SortableHeader>
        ),
        accessor: 'sales_channel_id',
        Cell: ({ row }) => {
          return (
            <div className="text-center">{row.original.sales_channel_name}</div>
          )
        }
      },
      {
        Header: () => (
          <SortableHeader currentItem={order} setItem={setOrder} field="order_status">
            Status
          </SortableHeader>
        ),
        accessor: 'order_status',
        Cell: ({ row }) => {
          return <div className={`text-center ${row.original.order_status == 0 ? 'text-danger' : 'text-success'}`}>{row.original.order_status_name}</div>;
        }
      },
      {
        Header: () => (
          <SortableHeader currentItem={order} setItem={setOrder} field="payment_status">
            Betaling
          </SortableHeader>
        ),
        accessor: 'payment_status',
        Cell: ({ row }) => {
          return (
            <div className={`text-center ${row.original.payment_status == 0 ? 'text-danger' : 'text-success'}`}>{row.original.payment_status_name}</div>
          )
        }
      },
      {
        Header: () => (
          <SortableHeader currentItem={order} setItem={setOrder} field="delivery_status">
            Verzending
          </SortableHeader>
        ),
        accessor: 'delivery_status',
        Cell: ({ row }) => {
          return (
            <div className={`text-center ${row.original.delivery_status == 0 ? 'text-danger' : 'text-success'}`}>{row.original.delivery_status_name}</div>
          )
        }
      },
    ], [order.orderBy, order.direction, selectedIds, setSelectedIds, orders]
  );

  // Table instance
  const tableInstance = useTable({ columns, data: orders });


  return (
    <div className="ordersOverviewTab h-100 mt-4">
      <div className="mb-3 componentMarginTop2_5 tablesCustomerEdit  h-100">
        <TablePreset 
          data={[{'selectedIds': selectedIds, 'tableInstance': tableInstance, 'pagination': pagination, 'primary_key': 'order_id'}]} 
          setPagination={setPagination} 
          handleSelection={handleSelection} 
          searchBar={[{ shown: true, value: searchFieldValue, function: delayedSetSearchBar}]}
          leftButtons={[{title: "Filteren", function: () => console.log('a')}]} // leftButtons={[{title: "a", function: () => console.log('a')}, {title: "b", function: () => console.log('b')}]} 
          rightButtons={[{title: "Nieuwe bestelling", function: () => navigate('/bestellingen/nieuw')}]} // rightButtons={[{title: "c", function: () => console.log('c')}]}
        />
      </div>
    </div>
  );
}

export default OrdersTab;