import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCloudArrowDown } from "@fortawesome/free-solid-svg-icons";
import { format } from "date-fns";

import PageTemplate from "../Templates/PageTemplate";
import middleware from "../Api/Middleware";
import exportOptions from "./Components/exportOptions";
import handleExcelDownloadCloud from "./Components/handleExcelDownloadCloud";

function ExportInfoPage() {
    const [loading, setLoading] = useState(true);
    const [isCSV, setIsCSV] = useState(false);
    const [downloadDisabled, setDownloadDisabled] = useState(false);
    const [exportDetails, setExportDetails] = useState({});
    const { export_id } = useParams();

    //Refs
    const downloadDebounce = useRef(false);

    // Fetch details of current export
    const fetchDetails = async () => {
        try {
            // Fetch results
            const exportDetailsResult = await middleware.get(`sp/exportDetails?export_id=${export_id}`);
            
            // Set data
            if (exportDetailsResult.data[0]) {
                setExportDetails(exportDetailsResult.data[0]);
            }
        } catch (error) {
            console.error("Error fetching import data:", error);
        }

        setLoading(false);
    };
    // Download the exported data
    const DownloadCSV = () => {
        if (!downloadDebounce.current) {
            setDownloadDisabled(true);
            downloadDebounce.current = true;
            handleExcelDownloadCloud(export_id, isCSV ? "csv" : "xlsx")
            // const base64Data = exportDetails.file_data;
            // const fileName = `${exportDetails.description}`;
            // handleExcelDownload(base64Data, fileName, ";", isCSV);
            setTimeout(function() {
                downloadDebounce.current = false
                setDownloadDisabled(false);
            }, 1000)
        }
    };

    // Fetch only once when the page loads in 
    useEffect(() => {
        fetchDetails();
    }, []);

    console.log(exportDetails);

    return (
        <PageTemplate
            pageIsLoading={loading}
            navbarTitle={"Export Details"}
            backValue={"/import-export-center#exports"}
        >
            <div className="whiteBox w-100 p-4">
                <h1>{exportDetails.description}</h1>
    
                {/* Export Type Badge */}
                {exportOptions.exportTypes[exportDetails.table_name] && (
                    <div className="mb-3">
                        <p className="d-block mb-2">Type:</p>
                        <span
                            className="badge text-white p-2"
                            style={{ fontSize: "0.9rem", borderRadius: "10px", backgroundColor: "#025a7a" }}
                        >
                            {exportOptions.exportTypes[exportDetails.table_name].displayName}
                        </span>
                    </div>
                )}
    
                {/* Selected Groups with Badge Styling */}
                {exportDetails.selected_groups_label && (
                    <div className="mb-3">
                        <span className="d-block mb-2">Geselecteerde groepopties:</span>
                        <div className="d-flex flex-wrap gap-2 align-content-center">
                            {exportDetails.selected_groups_label.split(", ").map((group, index) => (
                                <span
                                    key={index}
                                    className="badge text-white p-2"
                                    style={{ fontSize: "0.9rem", borderRadius: "10px", backgroundColor: "#009fe3" }}
                                >
                                    {group}
                                </span>
                            ))}
                        </div>
                    </div>
                )}
    
                <p>Gestart door: <b>{exportDetails.username}</b></p>
                <p>
                    Gestart op:{" "}<b>{exportDetails.created_at
                        ? format(new Date(exportDetails.created_at), "dd-MM-yyyy HH:mm:ss")
                        : "Onbekende datum"}</b>
                </p>
    
                {/* Toggle between XLSX and CSV */}
                <div className="mb-3 d-flex gap-3">
                    <div className="form-check">
                        <label className="form-check-label">
                            <input
                                className="form-check-input"
                                type="radio"
                                value="csv"
                                checked={isCSV}
                                onChange={() => setIsCSV(true)}
                            />{" "}
                            CSV
                        </label>
                    </div>
                    <div className="form-check">
                        <label className="form-check-label">
                            <input
                                className="form-check-input"
                                type="radio"
                                value="xlsx"
                                checked={!isCSV}
                                onChange={() => setIsCSV(false)}
                            />{" "}
                            XLSX
                        </label>
                    </div>
                </div>
    
                {/* Download file */}
                <div>
                    <button
                        onClick={DownloadCSV}
                        disabled={
                            (!exportDetails.csv_path && !exportDetails.xlsx_path) || downloadDisabled
                        }
                        className="btn btn-success me-2"
                        style={{
                            background: "linear-gradient(145deg, #4CAF50, #66BB6A)",
                            border: "none",
                            borderRadius: "5px",
                        }}
                    >
                        <FontAwesomeIcon icon={faCloudArrowDown} /> Download bestand
                    </button>
                    <span>
                        {!exportDetails.csv_path && !exportDetails.xlsx_path ? (
                            <b className="text-danger">(Download verlopen)</b>
                        ) : (
                            <span>
                                Verloopt op:{" "}
                                <b>
                                    {exportDetails.file_expire_date
                                        ? format(
                                              new Date(exportDetails.file_expire_date),
                                              "dd-MM-yyyy HH:mm:ss"
                                          )
                                        : "Onbekende datum"}
                                </b>
                            </span>
                        )}
                    </span>
                </div>
            </div>
        </PageTemplate>
    );
    
    
}

export default ExportInfoPage;